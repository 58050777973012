import React from "react";
import { Button, Typography } from "@mui/material";
import { colors } from "../../theme/Colors";
import modifyIcon from "../../imgs/imgV2/Modify.svg";
import downloadIcon from "../../imgs/imgV2/download.svg";
import addColoredIcon from "../../imgs/imgV2/plusColoredIcon.svg";
import downloadGrayIcon from "../../imgs/imgV2/downloadGray.svg";
import XIcon from "../../imgs/imgV2/xiCON.svg";
import downloadBlueIcon from "../../imgs/imgV2/downloadColored.svg";
import deleteIcon from "../../imgs/imgV2/deleteIcon.svg";
import AddIcon from "../../imgs/imgV2/plusIcon.svg";
import AddIconWhite from "../../imgs/imgV2/plusIconWhite.svg";
import MessageIcon from "../../imgs/imgV2/envoyerMsg.svg";
import IconGrayClose from "../../imgs/imgV2/XiconGray.svg";
import listIcon from "../../imgs/imgV2/listIcon.svg";
import pointsIcon from "../../imgs/imgV2/ThreePoints.svg";
import validateIcon from "../../imgs/imgV2/imgCheck.svg";
import deleteIconWhite from "../../imgs/imgV2/deleteWhite.svg";
import consulter from "../../imgs/imgV2/consulter.svg";
import closeIcon from "../../imgs/imgV2/closeIcon.svg";
import openIcon from "../../imgs/imgV2/openIcon.svg";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
function ButtonSquare({ number, action, ...props }) {
  switch (action) {
    //modify button
    case "3points":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "40px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={pointsIcon} alt="modify-icon" />
        </Button>
      );
    case "3points_outlined":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "32px", height: "32px", border: "1px solid #9499A8", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none", border: "1px solid #9499A8" }}>
          <img src={pointsIcon} alt="modify-icon" />
        </Button>
      );
    case "modify":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "40px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={modifyIcon} alt="modify-icon" />
        </Button>
      );
    case "orange-raccourcis":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.main}`, "&:hover": { backgroundColor: colors.main, filter: "brightness(0.7)" } }}>
          <img src={downloadIcon} alt="download-icon" style={{ transform: "rotate(-90deg)" }} />
        </Button>
      );

    case "close":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "40px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={IconGrayClose} alt="close-icon" />
        </Button>
      );
    //download button
    case "download":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "40px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` } }}>
          <img src={downloadGrayIcon} alt="download-icon" />
        </Button>
      );
    //download variant button
    case "download-orange":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.main}`, "&:hover": { backgroundColor: colors.main, filter: "brightness(0.7)" } }}>
          <img src={downloadIcon} alt="download-icon" />
        </Button>
      );
    case "download-green":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.mj_green}`, "&:hover": { backgroundColor: colors.mj_green, filter: "brightness(0.7)" } }}>
          <img src={downloadIcon} alt="download-icon" style={{ transform: "rotate(180deg)" }} />
        </Button>
      );
    case "send-message":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "35px", width: "40px", borderRadius: "50%", padding: "0", backgroundColor: `${colors.main}`, "&:hover": { backgroundColor: colors.main, filter: "brightness(0.7)" } }}>
          <img src={MessageIcon} alt="download-icon" />
        </Button>
      );
    case "download-blue":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "40px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` } }}>
          <img src={downloadBlueIcon} alt="download-icon" />
        </Button>
      );
    case "add-outlined":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ height: "35px", width: "40px", border: "1px solid #818592", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` } }}>
          <img src={AddIcon} alt="add-icon" />
        </Button>
      );
    //add outlined orange
    case "add-orange":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "35px", width: "35px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.main}`, "&:hover": { backgroundColor: colors.main, filter: "brightness(0.7)" } }}>
          <img src={AddIconWhite} alt="download-icon" />
        </Button>
      );
    case "green-raccourcis":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "35px", width: "35px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.mj_green}`, "&:hover": { backgroundColor: colors.mj_green, filter: "brightness(0.7)" } }}>
          <img src={AddIconWhite} alt="download-icon" />
        </Button>
      );
    case "add":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ height: "35px", width: "40px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` } }}>
          <img src={addColoredIcon} alt="add-icon" />
        </Button>
      );
    case "list":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ height: "42px", width: "42px", border: "1px solid #818592", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` } }}>
          <img src={listIcon} alt="list-icon" />
        </Button>
      );
    case "refuse":
      return (
        <Button {...props} onClick={props.onClick} sx={{ backgroundColor: "#CC0000", height: "35px", width: "40px", borderRadius: "6px", "&:hover": { backgroundColor: "#CC0000", filter: "brightness(0.7)" } }}>
          <img src={XIcon} alt="refuse-icon" />
        </Button>
      );
    case "refuse-certification":
      return (
        <Button {...props} onClick={props.onClick} sx={{ backgroundColor: "#CC0000", height: "30px", width: "20px", borderRadius: "6px", "&:hover": { backgroundColor: "#CC0000", filter: "brightness(0.7)" } }}>
          <img src={XIcon} style={{ height: "10px", width: "10px" }} alt="refuse-icon" />
        </Button>
      );
    case "validate-certification":
      return (
        <Button {...props} onClick={props.onClick} sx={{ backgroundColor: `${colors.mj_green}`, height: "30px", width: "20px", borderRadius: "6px", "&:hover": { backgroundColor: `${colors.mj_green}`, filter: "brightness(0.7)" } }}>
          <img src={validateIcon} style={{ height: "10px", width: "10px" }} alt="refuse-icon" />
        </Button>
      );
    case "delete":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "40px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` } }}>
          <img src={deleteIcon} alt="delete-icon" />
        </Button>
      );
    case "number-active":
      return (
        <Button {...props} onClick={props.onClick} sx={{ display: `${number === "Next &raquo;" || number === "&laquo; Previous" ? "none" : "inherit"}`, height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.main}`, "&:hover": { backgroundColor: colors.main } }}>
          <Typography color="white">{number}</Typography>
        </Button>
      );
    case "number":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{ color: "black", display: `${number === "Next &raquo;" || number === "&laquo; Previous" ? "none" : "inherit"}`, height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `white`, "&:hover": { color: "white", backgroundColor: colors.main } }}
        >
          <Typography>{number}</Typography>
        </Button>
      );
    case "delete-red":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ backgroundColor: "#cc0000", width: "40px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#cc0000` } }}>
          <img src={deleteIconWhite} alt="delete-icon" />
        </Button>
      );
    case "close-red":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ backgroundColor: "transparent", borderRadius: "50%", minWidth: "30px", height: "32px", padding: "0px", margin: "0px", "&:hover": { backgroundColor: `transparent` } }}>
          <img src={closeIcon} style={{ width: "32px", height: "32px" }} alt="delete-icon" />
        </Button>
      );
    case "open-green":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ backgroundColor: "transparent", borderRadius: "50%", minWidth: "30px", height: "32px", padding: "0px", margin: "0px", "&:hover": { backgroundColor: `transparent` } }}>
          <img src={openIcon} style={{ width: "32px", height: "32px" }} alt="delete-icon" />
        </Button>
      );
    case "consulter":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ padding: "0px", height: "35px", width: "25px", border: "1px solid #818592", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` } }}>
          <img src={consulter} alt="add-icon" />
        </Button>
      );

    case "accept-green":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.mj_green}`, "&:hover": { backgroundColor: colors.mj_green, filter: "brightness(0.7)" } }}>
          <CheckRoundedIcon htmlColor="white" />
        </Button>
      );

    case "refuse-red":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ backgroundColor: "#cc0000", width: "40px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#cc0000` } }}>
          <CloseRoundedIcon htmlColor="white" />
        </Button>
      );
  }
}

export default ButtonSquare;
