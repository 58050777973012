import React, { useState } from "react";
import { TextField } from "@mui/material";

const TimePicker = ({ children, form, field, color, ...props }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const [time, setTime] = useState("");

  const handleTimeChange = (event) => {
    const input = event.target.value;

    // Remove non-digit characters
    let formattedTime = input.replace(/\D/g, "");

    if (formattedTime.length >= 2) {
      const hours = formattedTime.slice(0, 2);
      const minutes = formattedTime.slice(2, 4);

      // Validate hours and minutes
      if (parseInt(hours) > 24) {
        formattedTime = "24" + (minutes ? ":" + minutes : "");
      } else if (parseInt(minutes) > 59) {
        formattedTime = hours + ":59";
      } else {
        formattedTime = hours + (minutes ? ":" + minutes : "");
      }
    }

    setTime(formattedTime);
    setFieldValue(name, formattedTime);
    // console.log(formattedTime);
  };

  const handleBlur = (event) => {
    const input = event.target.value;

    if (input.length !== 5 || input.length !== 0) {
      // Remove non-digit characters
      let formattedTime = input.replace(/\D/g, "");

      if (formattedTime.length >= 2) {
        const hours = formattedTime.slice(0, 2);
        const minutes = formattedTime.slice(2, 4);

        // Validate hours and minutes
        if (parseInt(hours) > 24) {
          formattedTime = "24" + (minutes ? ":" + minutes : "");
        } else if (parseInt(minutes) > 59) {
          formattedTime = hours + ":59";
        } else {
          formattedTime = hours + (minutes ? ":" + minutes : "");
        }
      }

      const hoursMinutes = input.split(":");
      const hours = hoursMinutes[0];
      const minutes = hoursMinutes[1];
      let newHours = "0";
      let newMinutes = "0";

      // console.log("i am blur function your input is ", input);
      // console.log("i am blur function hoursMinutes is  ", hoursMinutes);
      // console.log("i am blur function hours !! is  ", hours);
      // console.log("i am blur function minutes !! is  ", minutes);

      if (hours && minutes === undefined) {
        // console.log("CONDITION VERIFIé ///////////////////////");
        // console.log("CONDITION VERIFIé /////////////////////// hours", hours);
        // console.log("CONDITION VERIFIé /////////////////////// minutes", minutes);
        if (hours < 10) {
          if (hours.length === 2) {
            newHours = hours;
            newMinutes = "00";
          } else if (hours.length === 1) {
            newHours = "0" + hours;
            newMinutes = "00";
          }
        } else {
          newHours = hours;
          newMinutes = "00";
        }
      } else if (minutes < 10) {
        if (minutes.length === 2) {
          newHours = hours;
          newMinutes = minutes;
        } else if (minutes.length === 1) {
          newHours = hours;
          newMinutes = "0" + minutes;
        }
      } else {
        newHours = hours;
        newMinutes = minutes;
      }

      // console.log("i am blur function hours Minutes after process  is ", newHours, ":", newMinutes);
      event.target.value = newHours + ":" + newMinutes;
      if (hours || minutes) {
        setTime(event.target.value);
        setFieldValue(name, event.target.value);
      }
    }
  };

  return (
    <TextField
      sx={{
        width: props.width,
        "& input": {
          fontWeight: "700", // Change the fontWeight to your desired value
          fontSize: "12px",
        },
        "& .MuiInputBase-root": {
          minHeight: "32px",
          padding: "3px 10px",
        },
        "& .MuiInputBase-input": {
          minHeight: "32px",
          padding: "3px 10px",
        },
        "& .MuiOutlinedInput-root": {
          padding: "0px",
          "& fieldset": {
            borderColor: "#818592", // Replace with your desired border color
            borderRadius: "6px",
            fontSize: "12px",
            lineHeight: "19.2px",
            fontWeight: "400",
            padding: "0px 10px",
          },
          "&:hover fieldset": {
            border: `1px solid #818592`,
            borderRadius: "6px",
            fontSize: "12px",
            lineHeight: "19.2px",
            fontWeight: "400",
            padding: "0px 10px",
          },
          "&.Mui-focused fieldset": {
            border: `1px solid #818592`,
            borderRadius: "6px",
            fontSize: "12px",
            lineHeight: "19.2px",
            fontWeight: "400",
            padding: "0px 10px",
          },
        },
      }}
      placeholder="00:00"
      value={value}
      onChange={handleTimeChange}
      onBlur={handleBlur}
    />
  );
};

export default TimePicker;
