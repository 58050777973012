import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TextField, FormHelperText, Select, Typography, Box, MenuItem, Button, InputLabel, FormControl } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { colors } from "../../theme/Colors";
import * as yup from "yup";
import frLocale from "date-fns/locale/fr";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CvFormation from "./CvFormation";
import CvExperience from "./CvExperience";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useLocation } from "react-router-dom";

function InterimaireCV() {
  const { user } = useAuthContext();
  const [interimaire, setInterimaire] = useState();
  const location = useLocation();
  const idInt = location.pathname.split("/")[4];
  const access_token = user?.access_token;
  const [monthsExperience, setMonth] = useState();
  const [metier, setMetier] = useState();
  const [change, setChange] = useState(0);
  const [experiences, setExperiences] = useState();
  const [formations, setformations] = useState();
  const [checkboxFormatino, setCheckboxFormation] = useState(false);
  const [disableDayPickerFormation, setDisableDatePickerFormation] = useState(false);
  const [checkboxExperience, setCheckboxExperience] = useState(false);
  const [disableDayPickerExperience, setDisableDatePickerExperience] = useState(false);
  let schema = yup.object().shape({
    poste: yup.string().required("Ce champ est obligatoire"),
  });
  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      poste: "",
    },
    onSubmit: () => {
      console.log(formik.values.poste, Urls.UPDATE_RENARD_METIER + idInt + "/update-metier");
      Api.patch(
        Urls.UPDATE_RENARD_METIER + idInt + "/update-metier",
        {
          metier_id: formik?.values?.poste,
        },
        {
          headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          console.log(res);
          setChange(change + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  // handle checkbox formation
  const handleClickCheckBoxFormation = () => {
    if (checkboxFormatino === false) {
      setCheckboxFormation(true);
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();
      const date = `${year}-${month}`;
      formik.setFieldValue("date_fin_formation", date);
      setDisableDatePickerFormation(true);
    } else {
      setCheckboxFormation(false);
      formik.setFieldValue("date_fin_formation", "");
      setDisableDatePickerFormation(false);
    }
  };
  // handle checkbox experience
  const handleClickCheckBoxExperience = () => {
    if (checkboxExperience === false) {
      setCheckboxExperience(true);
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();
      const date = `${year}-${month}`;
      formik.setFieldValue("date_fin", date);
      setDisableDatePickerExperience(true);
    } else {
      setCheckboxExperience(false);
      formik.setFieldValue("date_fin", "");
      setDisableDatePickerExperience(false);
    }
  };

  //get cv interimaire
  useEffect(() => {
    Api.get(Urls.GET_RENARD_CV + idInt + "/CV", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res);
        setInterimaire(res?.data?.user);
        formik.setFieldValue("poste", res?.data?.user?.profile?.metier?.id ? res?.data?.user?.profile?.metier?.id : "");
        setformations(res?.data?.user?.profile?.formations ? res?.data?.user?.profile?.formations : []);
        setExperiences(res?.data?.user?.profile?.experiences ? res?.data?.user?.profile?.experiences : []);
        setMonth(res?.data?.user?.profile?.experience_months);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);
  //get metier
  useEffect(() => {
    Api.get(Urls.GET_METIERS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setMetier(res?.data?.data?.metiers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Box sx={{ maxWidth: "800px" }}>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" mb>
          Gestion des intérimaires
        </Typography>
        <Typography variant="h5" component="h1">
          Détails du CV
        </Typography>
      </Box>
      <Box sx={{ margin: "1rem 0rem" }}>
        <Typography variant="detail_orange">
          {interimaire?.first_name ? interimaire.first_name : "-"} {interimaire?.last_name ? interimaire.last_name : "-"} CV
        </Typography>
      </Box>
      {/* form section */}
      <form onSubmit={formik.handleSubmit}>
        <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
          <Typography variant="detail_title">Métier</Typography>
          <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
            {formik.values.poste ? null : <InputLabel id="demo-multiple-name-label">Poste</InputLabel>}
            <Select placeholder="Poste" error={formik.touched.poste && Boolean(formik.errors.poste)} fullWidth value={formik.values.poste} onChange={(e) => formik.setFieldValue("poste", e.target.value)} input={formik.values.poste ? null : <OutlinedInput sx={{ color: "black" }} label="Name" />}>
              {metier?.map((metier) => (
                <MenuItem key={metier?.id} value={metier?.id}>
                  {metier.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.poste && <FormHelperText sx={{ color: `${colors.mj_error}`, fontSize: `0.875rem` }}>{formik?.errors?.poste}</FormHelperText>}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button sx={{ margin: "1rem 1rem 0rem 0rem" }} type="submit" variant="mj_primary">
            Enregistrer
          </Button>
        </Box>
      </form>
      <CvExperience monthsExperience={monthsExperience} access_token={access_token} setChange={setChange} idInt={idInt} experiences={experiences} setExperiences={setExperiences} />
      <CvFormation access_token={access_token} setChange={setChange} idInt={idInt} formations={formations} setFormations={setformations} />
      <Box display="flex" alignContent="center" justifyContent="center" sx={{ flexWrap: "wrap", margin: "2rem 0rem" }}>
        <Button sx={{ margin: "1rem 1rem 0rem 0rem" }} type="submit" variant="mj_primary">
          Télécharger CV
        </Button>
      </Box>
    </Box>
  );
}

export default InterimaireCV;
