import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Checkbox, IconButton, FormControlLabel, Modal, Alert, Autocomplete, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";

import { ArrowBack, ArrowBackIosOutlined, ArrowForward, ArrowLeft, ArrowRight, CheckBox, ErrorSharp, Flag } from "@mui/icons-material";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik } from "formik";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import axios from "axios";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { colors } from "../../theme/Colors";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonSquare from "../../components/ui/ButtonSquare";
import MyChip from "../../components/ui/MyChip";
import moment from "moment";

function DeclarerHeures() {
  const [rowsPerPage, setRowsPer] = useState(10);
  const columns = [
    { field: "id", headerName: "Id contrat", width: 100, headerClassName: "grid-header" },
    {
      field: "interim_id",
      headerName: "ID intérimaire",
      width: 100,
      renderCell: (params) => {
        if (params.row.interim_id !== null) {
          return `${params.row.interim_id}`;
        } else return "-";
      },
    },
    {
      field: "enterprise_id",
      headerName: "ID entreprise",
      width: 100,
      renderCell: (params) => {
        if (params.row.enterprise_id !== null) {
          return `${params.row.enterprise_id}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "job_title",
      headerName: "contrat",
      width: 200,
    },
    {
      field: "enterprise_name",
      headerName: "Entreprise",
      width: 150,
    },
    {
      field: "interim_first_name",
      headerName: "Intérimaire",
      width: 200,
      renderCell: (params) => {
        return params.row.interim_first_name + " " + params.row.interim_last_name;
      },
    },
    {
      field: "start_date",
      headerName: "Date début",
      width: 150,
      renderCell: (params) => {
        return moment(params.row.start_date).format("DD MMMM yyyy");
      },
    },
    {
      field: "end_date",
      headerName: "Date fin",
      width: 150,
      renderCell: (params) => {
        return moment(params.row.end_date).format("DD MMMM yyyy");
      },
    },

    {
      field: "is_declared_hours_by_admin",
      headerName: "Etat",
      description: "This column has a value getter and is not sortable.",
      width: 150,
      renderCell: (params) => {
        return (
          <Box>
            {params.row.is_declared_hours_by_admin === 0 && <MyChip variant="rejected_offer" label="Non complet" />}
            {params.row.is_declared_hours_by_admin === 1 && <MyChip variant="ended_offer" label="complet" />}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            <ArrowCircleRightIcon action={"details"} sx={{ cursor: "pointer", color: `${colors.mjGreenOutline}`, fontSize: 40 }} onClick={() => navigate(`/admin/declarer-les-heures/details/${params.row.id}`, { state: { status: params.row.status } })} />
          </Box>
        );
      },
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openParams, setOpenParams] = React.useState(false);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const { user } = useAuthContext();
  const location = useLocation();
  const idEnt = location?.state?.id ? location?.state?.id : "";
  const idInt = location?.state?.idinterim ? location?.state?.idinterim : "";
  const [initialValues, setInitialValues] = useState({
    id_contrat: "",
    nom: "",
    prenom: "",
    entreprise: "",
    type_contrat: "",
    oid_ent: "",
    oid_interim: "",
    contrat: "",
    id_offre: "",
    id_entreprise: idEnt,
    id_interimaire: idInt,
    signed_by: "",
    etat: "",
    start_min: "",
    start_max: "",
    end_min: "",
    end_max: "",
  });
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      console.log(formik.values);
      setChange(change + 1);
    },
  });
  const [selectedIDs, setSelectedIDs] = useState(null);
  const [change, setChange] = useState(0);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [id, setId] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [action, setAction] = useState("");
  const access_token = user?.access_token;
  useEffect(() => {
    Api.get(Urls.GET_CONTRAT_ADMIN_DECALRE_HOURS + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(formik.values.id_contrat !== "" && { id: formik.values.id_contrat }),
        ...(formik.values.type_contrat !== "" && { isAvenant: formik.values.type_contrat }),
        ...(formik.values.id_interimaire !== "" && { interimId: formik.values.id_interimaire }),
        ...(formik.values.id_entreprise !== "" && { enterpriseId: formik.values.id_entreprise }),
        ...(formik.values.contrat !== "" && { jobTitle: formik.values.contrat }),
        ...(formik.values.entreprise !== "" && { enterpriseName: formik.values.entreprise }),
        ...(formik.values.prenom !== "" && { interimFirstName: formik.values.prenom }),
        ...(formik.values.nom !== "" && { interimLastName: formik.values.nom }),
        ...(formik.values.start_min !== "" && formik.values.start_min !== null && { startDateMin: formik.values.start_min }),
        ...(formik.values.start_max !== "" && formik.values.start_max !== null && { startDateMax: formik.values.start_max }),
        ...(formik.values.end_min !== "" && formik.values.end_min !== null && { endDateMin: formik.values.end_min }),
        ...(formik.values.end_max !== "" && formik.values.end_max !== null && { endDateMax: formik.values.end_max }),
        ...(formik.values.etat !== "" && { isDeclaredByAdmin: formik.values.etat }),
      },
    })
      .then((res) => {
        setData(res.data);
        setRows(res?.data.contracts);
        setLoading(false);
        console.log(res?.data?.contracts);
        setTotal(res?.data?.meta?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formik.values]);
  //pagination next
  //handle Pagination
  const handlePagination = (link) => {
    setLoading(true);
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.contracts);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
        console.log(res?.data?.meta?.links, "link pag");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };
  //custom footer
  const customFooter = () => {
    return <Box></Box>;
  };

  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };
  const etat = [
    { id: 0, name: "à déclarer" },
    { id: 1, name: "complet" },
  ];

  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des déclarations d'heures
        </Typography>
      </Box>

      {/* Form Section */}
      <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column", lg: "column" } }}>
        <Box>
          <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
            Filtre :
          </Typography>
        </Box>

        {/*Search fields  */}

        <Box display="flex" flex={1} alignItems="center" marginRight="1rem" gap="1rem" flexWrap="wrap" mb>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <TextField type="number" name="id_contrat" onChange={formik.handleChange} placeholder="ID contrat" />
          </Box>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <Box>
              <Autocomplete
                sx={{ minWidth: "200px" }}
                fullWidth
                options={[{ id: 1, name: "Avenant" }]}
                //options={candidaturesStates}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(e) => e.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    console.log("selecting candidature state value", value);
                    console.log("selecting candidature state value", value.id);
                    formik.setFieldValue("type_contrat", value.id);
                    console.log(value);
                    console.log("selectedCandidatureState");
                  } else {
                    formik.setFieldValue("type_contrat", "");
                    console.log(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Type de contrat" />}
              />
            </Box>
          </Box>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <TextField type="number" name="id_interimaire" value={formik.values.id_interimaire} onChange={formik.handleChange} placeholder="ID intérimaire" />
          </Box>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <TextField name="id_entreprise" onChange={formik.handleChange} value={formik.values.id_entreprise} placeholder="ID entreprise" />
          </Box>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <TextField name="contrat" onChange={formik.handleChange} placeholder="Intitulé du poste" />
          </Box>
        </Box>
        <Box display="flex" flex={1} alignItems="center" marginRight="1rem" gap="1rem" flexWrap="wrap" mb>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <TextField type="input" name="entreprise" onChange={formik.handleChange} placeholder="Entreprise" />
          </Box>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <TextField name="nom" onChange={formik.handleChange} placeholder="Nom" />
          </Box>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <TextField name="prenom" onChange={formik.handleChange} placeholder="Prénom" />
          </Box>
        </Box>
        <Box display="flex" flex={1} alignItems="center" marginRight="1rem" gap="1rem" flexWrap="wrap" mb>
          <Box gap="0.3rem" display="flex" alignItems="center">
            <Box>
              <Autocomplete
                sx={{ minWidth: "200px" }}
                fullWidth
                options={etat}
                //options={candidaturesStates}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(e) => e.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    console.log("selecting candidature state value", value);
                    console.log("selecting candidature state value", value.id);
                    formik.setFieldValue("etat", value.id);
                    console.log(value);
                    console.log("selectedCandidatureState");
                  } else {
                    formik.setFieldValue("etat", "");
                    console.log(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Etat" />}
              />
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" alignItems="center" gap="0.3rem">
            <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarMonthIcon }}
                value={formik.values.start_min}
                onChange={(value) => formik.setFieldValue("start_min", value)}
                maxDateTime={formik.values.createdAtMax}
                maxDate={formik.values.start_max ? formik.values.start_max : new Date()}
                InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                renderInput={(params) => {
                  return (
                    <TextField
                      type="date"
                      {...params}
                      name="start_min"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Date min de début",
                        disabled: "true",
                      }}
                    />
                  );
                }}
              ></DatePicker>
            </LocalizationProvider>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("start_min", null)} />

            <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarMonthIcon }}
                value={formik.values.start_max}
                onChange={(value) => formik.setFieldValue("start_max", value)}
                minDateTime={formik.values.start_min}
                minDate={formik.values.start_min ? formik.values.start_min : null}
                InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                renderInput={(params) => {
                  return (
                    <TextField
                      type="date"
                      {...params}
                      name="dateMax"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Date max de début",
                        disabled: "true",
                      }}
                    />
                  );
                }}
              ></DatePicker>
            </LocalizationProvider>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("start_max", null)} />
          </Box>
          <Box display="flex" flexWrap="wrap" alignItems="center" gap="0.3rem">
            <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarMonthIcon }}
                value={formik.values.end_min}
                onChange={(value) => formik.setFieldValue("end_min", value)}
                maxDateTime={formik.values.createdAtMax}
                maxDate={formik.values.start_max ? formik.values.start_max : new Date()}
                InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                renderInput={(params) => {
                  return (
                    <TextField
                      type="date"
                      {...params}
                      name="end_min"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Date min de fin",
                        disabled: "true",
                      }}
                    />
                  );
                }}
              ></DatePicker>
            </LocalizationProvider>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("end_min", null)} />

            <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarMonthIcon }}
                value={formik.values.end_max}
                onChange={(value) => formik.setFieldValue("end_max", value)}
                minDateTime={formik.values.end_min}
                minDate={formik.values.end_min ? formik.values.end_min : null}
                InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                renderInput={(params) => {
                  return (
                    <TextField
                      type="date"
                      {...params}
                      name="dateMax"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Date max de fin",
                        disabled: "true",
                      }}
                    />
                  );
                }}
              ></DatePicker>
            </LocalizationProvider>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("end_max", null)} />
          </Box>
        </Box>
      </Box>
      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1250px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          page={page - 1}
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          loading={loading}
          checkboxSelection
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
            footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} lignes selectionnées` : `${count.toLocaleString()} ligne selectionnée`),
          }}
          paginationMode="server"
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" marginTop="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DeclarerHeures;
