import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg width="302" height="160" viewBox="0 0 302 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.85">
          <path d="M153.453 71.9676C153.453 71.9676 215.111 40.7526 257.116 52.3136C299.121 63.8746 295.267 110.119 286.404 117.056C277.541 123.993 161.931 117.056 161.931 117.056L153.453 71.9676Z" fill="#F07F3C" />
          <path d="M191.586 147.153C249.67 147.153 296.758 140.937 296.758 133.271C296.758 125.604 249.67 119.389 191.586 119.389C133.501 119.389 86.4136 125.604 86.4136 133.271C86.4136 140.937 133.501 147.153 191.586 147.153Z" fill="#E6E6E7" />
          <path d="M145.912 68.6694C145.912 68.6694 151.523 51.6334 163.012 46.6694C169.883 43.699 177.458 42.7506 184.85 43.9354C184.85 43.9354 189.916 73.8094 168.311 79.6174L145.912 68.6694Z" fill="#1F1F1F" />
          <path d="M154.665 69.3773C154.665 69.3773 157.917 59.5723 164.614 56.7273C169.063 54.8982 173.66 53.4523 178.356 52.4053C178.356 52.4053 180.34 72.3923 167.745 75.7053L154.665 69.3773Z" fill="#7B7B7B" />
          <path d="M120.472 71.9859C120.472 71.9859 117.765 57.621 124.011 49.554C127.742 44.7293 132.772 41.0691 138.511 39.0029C138.511 39.0029 153.892 58.3229 140.764 70.9749L120.472 71.9859Z" fill="#1F1F1F" />
          <path d="M165.014 114.551C165.014 114.551 135.919 119.368 137.268 126.497C138.617 133.626 149.982 131.507 156.149 129.197C162.316 126.887 191.989 126.306 203.165 124.572C214.341 122.838 209.109 113.077 205.092 111.272C201.075 109.467 165.014 114.551 165.014 114.551Z" fill="#1F1F1F" />
          <path
            d="M85.3745 117.278C85.3745 117.278 88.6355 131.462 103.701 132.89C118.765 134.318 133.723 128.242 147.766 119.747C161.809 111.252 181.208 114.026 181.208 114.026C181.208 114.026 178.496 102.526 170.182 99.5782C170.182 99.5782 182.043 92.8922 191.81 94.2552C191.926 94.2642 192.05 94.2832 192.164 94.3022C192.164 94.3022 192.044 93.8831 191.763 93.1941C190.513 90.2031 185.996 82.0552 173.531 80.5942C173.531 80.5942 164.49 51.3842 129.832 62.8852C123.195 65.0852 118.249 68.1912 111.496 84.0282C104.743 99.8652 105.926 110.799 98.6065 113.857C94.3944 115.661 89.9329 116.814 85.3745 117.278Z"
            fill="#FF8C46"
          />
          <path
            d="M85.3745 117.278C85.3745 117.278 88.6355 131.462 103.701 132.89C118.765 134.318 133.723 128.242 147.766 119.747C161.809 111.251 181.208 114.025 181.208 114.025C181.208 114.025 178.496 102.525 170.182 99.5775C170.182 99.5775 182.043 92.8915 191.81 94.2545C191.782 93.5765 191.765 93.1935 191.765 93.1935C191.765 93.1935 178.517 86.0625 164.887 88.9045C151.257 91.7465 141.815 115.381 123.307 121.456C104.799 127.531 85.3745 117.278 85.3745 117.278Z"
            fill="#FEE7DA"
          />
          <path d="M235.921 113.587C235.921 113.587 245.038 84.8871 267.521 86.2261C267.521 86.2261 257.121 81.7951 243.628 89.1171C230.135 96.4391 230.911 114.551 230.911 114.551L235.921 113.587Z" fill="#1F1F1F" />
          <path d="M90.3826 111.757C88.1496 110.67 83.3176 109.997 81.4706 116.146C79.6236 122.295 86.6847 119.736 87.9937 119.658C89.3027 119.58 96.1886 114.583 90.3826 111.757Z" fill="#1F1F1F" />
          <path d="M126.282 86.1847C126.282 86.1847 129.613 78.3047 139.537 82.6297C139.537 82.6297 139.859 79.0797 137.142 78.5237C137.142 78.5237 137.557 76.6887 140.72 75.2167C140.72 75.2167 127.104 73.3847 126.282 86.1847Z" fill="#FEE7DA" />
          <path d="M123.419 100.299C123.419 100.299 125.239 104.047 132.412 102.784C139.585 101.521 142.918 94.2842 142.918 94.2842L141.178 93.7422C141.178 93.7422 136.188 100.253 131.962 100.072C129.471 99.982 127.052 99.207 124.972 97.8322L123.419 100.299Z" fill="#1F1F1F" />
          <path
            d="M262.469 157.121C252.027 158.884 241.418 159.438 230.849 158.774C213.049 157.838 197.508 154.462 187.901 153.849C172.871 152.889 152.258 152.254 144.166 159.028C144.166 159.028 149.946 136.889 167.666 128.612C185.386 120.335 196.372 121.097 196.372 121.097L184.429 116.276C184.429 116.276 196.892 103.559 217.921 108.761C219.829 109.235 221.783 109.675 223.747 110.079C243.447 114.137 265.055 114.879 269.784 112.611C274.984 110.111 282.663 109.247 289.484 96.6804C291.599 92.7734 288.732 79.6055 288.732 79.6055C288.732 79.6055 300.998 94.6924 301.773 113.386C302.548 132.08 292.527 151.733 262.469 157.121Z"
            fill="#FF8C46"
          />
          <path
            d="M224.364 137.098C224.364 137.098 234.953 138.058 235.924 142.879C236.896 147.7 222.792 150.197 222.792 150.197C222.792 150.197 230.109 153.515 230.849 158.775C213.049 157.839 197.508 154.463 187.901 153.85C172.871 152.89 152.258 152.255 144.166 159.029C144.166 159.029 149.946 136.89 167.666 128.613C185.386 120.336 196.372 121.098 196.372 121.098L184.429 116.277C184.429 116.277 196.892 103.56 217.921 108.762C219.829 109.236 221.783 109.676 223.747 110.08C228.591 114.739 219.921 117.052 219.921 117.052C219.921 117.052 238.615 118.601 238.997 125.919C239.379 133.237 224.364 137.098 224.364 137.098Z"
            fill="#FEE7DA"
          />
          <path d="M257.06 67.3618C257.06 67.3618 261.026 66.2547 263.934 67.9617C266.842 69.6687 266.098 71.6568 257.06 67.3618Z" fill="#FEE7DA" />
          <path d="M253.409 71.393C253.409 71.393 257.375 70.286 260.283 71.993C263.191 73.7 262.447 75.688 253.409 71.393Z" fill="#FEE7DA" />
          <path d="M249.074 62.5946C249.074 62.5946 252.889 61.0457 255.974 62.4147C259.059 63.7837 258.542 65.8366 249.074 62.5946Z" fill="#FEE7DA" />
          <path d="M264.542 72.9719C264.542 72.9719 268.655 72.7939 271.099 75.1199C273.543 77.4459 272.365 79.2059 264.542 72.9719Z" fill="#FEE7DA" />
          <path d="M262.313 63.42C262.313 63.42 266.38 62.7761 269.072 64.8101C271.764 66.8441 270.797 68.728 262.313 63.42Z" fill="#FEE7DA" />
          <path d="M245.142 67.3528C245.142 67.3528 248.663 65.2178 251.925 66.0818C255.187 66.9458 255.003 69.0528 245.142 67.3528Z" fill="#FEE7DA" />
          <path d="M269.266 70.8008C269.266 70.8008 273.374 70.5058 275.883 72.7598C278.392 75.0138 277.269 76.8088 269.266 70.8008Z" fill="#FEE7DA" />
          <path
            opacity="0.7"
            d="M31.6177 99.5984C36.0483 93.561 41.6227 88.4534 48.0237 84.5664C47.4167 88.0824 44.3237 95.8664 43.1577 99.5984C41.6577 104.38 42.5276 101.51 40.6396 106.967C40.1516 107.856 38.2397 111.736 38.8397 112.556C45.9104 107.171 54.123 103.481 62.8447 101.77"
            stroke="#F29258"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path opacity="0.7" d="M11.4937 60.0752C15.6412 54.0383 20.985 48.9181 27.1936 45.0322C24.0506 53.6322 19.8546 61.7942 15.6726 69.9322C28.3023 62.5774 41.9864 57.2055 56.2466 54.0042" stroke="#F29258" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" />
          <g opacity="0.7">
            <path d="M33.2698 3.67383L25.5269 28.2579C41.7859 17.7579 59.0658 7.59083 78.2798 5.28583" stroke="#F29258" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M33.6808 2.97754L23.7928 8.45355C16.6148 12.4296 9.34082 16.4765 3.48682 22.2265" stroke="#F29258" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" />
          </g>
        </g>
      </svg>

      <Box sx={{ mt: 3 }}>Aucune ligne de données disponible pour le moment</Box>
    </StyledGridOverlay>
  );
}

function EmptyDataGridOverlay() {
  return (
    <StyledGridOverlay>
      <svg width="302" height="160" viewBox="0 0 302 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.85">
          <path d="M153.453 71.9676C153.453 71.9676 215.111 40.7526 257.116 52.3136C299.121 63.8746 295.267 110.119 286.404 117.056C277.541 123.993 161.931 117.056 161.931 117.056L153.453 71.9676Z" fill="#F07F3C" />
          <path d="M191.586 147.153C249.67 147.153 296.758 140.937 296.758 133.271C296.758 125.604 249.67 119.389 191.586 119.389C133.501 119.389 86.4136 125.604 86.4136 133.271C86.4136 140.937 133.501 147.153 191.586 147.153Z" fill="#E6E6E7" />
          <path d="M145.912 68.6694C145.912 68.6694 151.523 51.6334 163.012 46.6694C169.883 43.699 177.458 42.7506 184.85 43.9354C184.85 43.9354 189.916 73.8094 168.311 79.6174L145.912 68.6694Z" fill="#1F1F1F" />
          <path d="M154.665 69.3773C154.665 69.3773 157.917 59.5723 164.614 56.7273C169.063 54.8982 173.66 53.4523 178.356 52.4053C178.356 52.4053 180.34 72.3923 167.745 75.7053L154.665 69.3773Z" fill="#7B7B7B" />
          <path d="M120.472 71.9859C120.472 71.9859 117.765 57.621 124.011 49.554C127.742 44.7293 132.772 41.0691 138.511 39.0029C138.511 39.0029 153.892 58.3229 140.764 70.9749L120.472 71.9859Z" fill="#1F1F1F" />
          <path d="M165.014 114.551C165.014 114.551 135.919 119.368 137.268 126.497C138.617 133.626 149.982 131.507 156.149 129.197C162.316 126.887 191.989 126.306 203.165 124.572C214.341 122.838 209.109 113.077 205.092 111.272C201.075 109.467 165.014 114.551 165.014 114.551Z" fill="#1F1F1F" />
          <path
            d="M85.3745 117.278C85.3745 117.278 88.6355 131.462 103.701 132.89C118.765 134.318 133.723 128.242 147.766 119.747C161.809 111.252 181.208 114.026 181.208 114.026C181.208 114.026 178.496 102.526 170.182 99.5782C170.182 99.5782 182.043 92.8922 191.81 94.2552C191.926 94.2642 192.05 94.2832 192.164 94.3022C192.164 94.3022 192.044 93.8831 191.763 93.1941C190.513 90.2031 185.996 82.0552 173.531 80.5942C173.531 80.5942 164.49 51.3842 129.832 62.8852C123.195 65.0852 118.249 68.1912 111.496 84.0282C104.743 99.8652 105.926 110.799 98.6065 113.857C94.3944 115.661 89.9329 116.814 85.3745 117.278Z"
            fill="#FF8C46"
          />
          <path
            d="M85.3745 117.278C85.3745 117.278 88.6355 131.462 103.701 132.89C118.765 134.318 133.723 128.242 147.766 119.747C161.809 111.251 181.208 114.025 181.208 114.025C181.208 114.025 178.496 102.525 170.182 99.5775C170.182 99.5775 182.043 92.8915 191.81 94.2545C191.782 93.5765 191.765 93.1935 191.765 93.1935C191.765 93.1935 178.517 86.0625 164.887 88.9045C151.257 91.7465 141.815 115.381 123.307 121.456C104.799 127.531 85.3745 117.278 85.3745 117.278Z"
            fill="#FEE7DA"
          />
          <path d="M235.921 113.587C235.921 113.587 245.038 84.8871 267.521 86.2261C267.521 86.2261 257.121 81.7951 243.628 89.1171C230.135 96.4391 230.911 114.551 230.911 114.551L235.921 113.587Z" fill="#1F1F1F" />
          <path d="M90.3826 111.757C88.1496 110.67 83.3176 109.997 81.4706 116.146C79.6236 122.295 86.6847 119.736 87.9937 119.658C89.3027 119.58 96.1886 114.583 90.3826 111.757Z" fill="#1F1F1F" />
          <path d="M126.282 86.1847C126.282 86.1847 129.613 78.3047 139.537 82.6297C139.537 82.6297 139.859 79.0797 137.142 78.5237C137.142 78.5237 137.557 76.6887 140.72 75.2167C140.72 75.2167 127.104 73.3847 126.282 86.1847Z" fill="#FEE7DA" />
          <path d="M123.419 100.299C123.419 100.299 125.239 104.047 132.412 102.784C139.585 101.521 142.918 94.2842 142.918 94.2842L141.178 93.7422C141.178 93.7422 136.188 100.253 131.962 100.072C129.471 99.982 127.052 99.207 124.972 97.8322L123.419 100.299Z" fill="#1F1F1F" />
          <path
            d="M262.469 157.121C252.027 158.884 241.418 159.438 230.849 158.774C213.049 157.838 197.508 154.462 187.901 153.849C172.871 152.889 152.258 152.254 144.166 159.028C144.166 159.028 149.946 136.889 167.666 128.612C185.386 120.335 196.372 121.097 196.372 121.097L184.429 116.276C184.429 116.276 196.892 103.559 217.921 108.761C219.829 109.235 221.783 109.675 223.747 110.079C243.447 114.137 265.055 114.879 269.784 112.611C274.984 110.111 282.663 109.247 289.484 96.6804C291.599 92.7734 288.732 79.6055 288.732 79.6055C288.732 79.6055 300.998 94.6924 301.773 113.386C302.548 132.08 292.527 151.733 262.469 157.121Z"
            fill="#FF8C46"
          />
          <path
            d="M224.364 137.098C224.364 137.098 234.953 138.058 235.924 142.879C236.896 147.7 222.792 150.197 222.792 150.197C222.792 150.197 230.109 153.515 230.849 158.775C213.049 157.839 197.508 154.463 187.901 153.85C172.871 152.89 152.258 152.255 144.166 159.029C144.166 159.029 149.946 136.89 167.666 128.613C185.386 120.336 196.372 121.098 196.372 121.098L184.429 116.277C184.429 116.277 196.892 103.56 217.921 108.762C219.829 109.236 221.783 109.676 223.747 110.08C228.591 114.739 219.921 117.052 219.921 117.052C219.921 117.052 238.615 118.601 238.997 125.919C239.379 133.237 224.364 137.098 224.364 137.098Z"
            fill="#FEE7DA"
          />
          <path d="M257.06 67.3618C257.06 67.3618 261.026 66.2547 263.934 67.9617C266.842 69.6687 266.098 71.6568 257.06 67.3618Z" fill="#FEE7DA" />
          <path d="M253.409 71.393C253.409 71.393 257.375 70.286 260.283 71.993C263.191 73.7 262.447 75.688 253.409 71.393Z" fill="#FEE7DA" />
          <path d="M249.074 62.5946C249.074 62.5946 252.889 61.0457 255.974 62.4147C259.059 63.7837 258.542 65.8366 249.074 62.5946Z" fill="#FEE7DA" />
          <path d="M264.542 72.9719C264.542 72.9719 268.655 72.7939 271.099 75.1199C273.543 77.4459 272.365 79.2059 264.542 72.9719Z" fill="#FEE7DA" />
          <path d="M262.313 63.42C262.313 63.42 266.38 62.7761 269.072 64.8101C271.764 66.8441 270.797 68.728 262.313 63.42Z" fill="#FEE7DA" />
          <path d="M245.142 67.3528C245.142 67.3528 248.663 65.2178 251.925 66.0818C255.187 66.9458 255.003 69.0528 245.142 67.3528Z" fill="#FEE7DA" />
          <path d="M269.266 70.8008C269.266 70.8008 273.374 70.5058 275.883 72.7598C278.392 75.0138 277.269 76.8088 269.266 70.8008Z" fill="#FEE7DA" />
          <path
            opacity="0.7"
            d="M31.6177 99.5984C36.0483 93.561 41.6227 88.4534 48.0237 84.5664C47.4167 88.0824 44.3237 95.8664 43.1577 99.5984C41.6577 104.38 42.5276 101.51 40.6396 106.967C40.1516 107.856 38.2397 111.736 38.8397 112.556C45.9104 107.171 54.123 103.481 62.8447 101.77"
            stroke="#F29258"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path opacity="0.7" d="M11.4937 60.0752C15.6412 54.0383 20.985 48.9181 27.1936 45.0322C24.0506 53.6322 19.8546 61.7942 15.6726 69.9322C28.3023 62.5774 41.9864 57.2055 56.2466 54.0042" stroke="#F29258" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" />
          <g opacity="0.7">
            <path d="M33.2698 3.67383L25.5269 28.2579C41.7859 17.7579 59.0658 7.59083 78.2798 5.28583" stroke="#F29258" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M33.6808 2.97754L23.7928 8.45355C16.6148 12.4296 9.34082 16.4765 3.48682 22.2265" stroke="#F29258" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 3 }}>Aucune ligne de données disponible pour le moment</Box>
    </StyledGridOverlay>
  );
}

export default EmptyDataGridOverlay;
