import React from "react";
import PauseIcon from "@mui/icons-material/Pause";
import { IconButton, Icon } from "@mui/material";
import { colors } from "../../theme/Colors";
import Trash from "../../imgs/trash.svg";
import Edit from "../../imgs/edit.svg";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const TrashIcon = () => (
  <Icon sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <img src={Trash} width={15.5} />
  </Icon>
);

const EditIcon = () => (
  <Icon sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <img src={Edit} width={13} />
  </Icon>
);

function ButtonDataGrid({ disabled, action, ...props }) {
  switch (action) {
    //suspend button
    case "suspend":
      return (
        <IconButton disabled={disabled ? disabled : false} {...props} onClick={props.onClick} sx={{ width: "32px", height: "32px", backgroundColor: `${colors.mj_red}`, "&:hover": { backgroundColor: `${colors.mj_red_light}` } }}>
          <PauseIcon htmlColor="white" />
        </IconButton>
      );

    //button delete
    case "delete":
      return (
        <IconButton {...props} onClick={props.onClick} sx={{ width: "32px", height: "32px", backgroundColor: `${colors.mj_red}`, "&:hover": { backgroundColor: `${colors.mj_red_light}` } }}>
          <TrashIcon htmlColor="white" />
        </IconButton>
      );

    //accept button
    case "accept":
      return (
        <IconButton {...props} onClick={props.onClick} sx={{ width: "32px", height: "32px", backgroundColor: `${colors.mj_green}`, "&:hover": { backgroundColor: `${colors.mj_green_light}` } }}>
          <CheckRoundedIcon htmlColor="white" />
        </IconButton>
      );

    //edit button
    case "edit":
      return (
        <IconButton onClick={props.onClick} sx={{ width: "32px", height: "32px", backgroundColor: `${colors.main_mj}`, "&:hover": { backgroundColor: `${colors.light_mj}` } }}>
          <EditIcon htmlColor="white" />
        </IconButton>
      );

    //refuse button
    case "refuse":
      return (
        <IconButton onClick={props.onClick} sx={{ width: "32px", height: "32px", backgroundColor: `${colors.mj_red}`, "&:hover": { backgroundColor: `${colors.mj_red_light}` } }}>
          <ClearRoundedIcon htmlColor="white" />
        </IconButton>
      );

    //refuse demande
    case "refuse_demande":
      return (
        <IconButton onClick={props.onClick} sx={{ width: "32px", height: "32px", backgroundColor: "white", border: `2px solid ${colors.mj_red}` }}>
          <ClearRoundedIcon htmlColor={colors.mj_red} />
        </IconButton>
      );

    //mask button
    case "mask":
      return (
        <IconButton onClick={props.onClick} sx={{ width: "32px", height: "32px", backgroundColor: `${colors.main_mj}`, "&:hover": { backgroundColor: `${colors.light_mj}` } }}>
          <VisibilityOffIcon htmlColor="white" />
        </IconButton>
      );

    //UNmask button
    case "unmask":
      return (
        <IconButton onClick={props.onClick} sx={{ width: "32px", height: "32px", backgroundColor: `${colors.main_mj}`, "&:hover": { backgroundColor: `${colors.light_mj}` } }}>
          <VisibilityIcon htmlColor="white" />
        </IconButton>
      );
  }
}

export default ButtonDataGrid;
