import React, { useEffect, useLayoutEffect, useState } from "react";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Box, Button, Divider, Modal, Typography, FormControl, RadioGroup, Radio, FormControlLabel, Select, MenuItem, InputLabel, CircularProgress, TextField, InputAdornment, IconButton } from "@mui/material";
import { colors } from "../../../theme/Colors";
import { modalStyle } from "../../../theme/ModalStyle";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import MyTextField from "../../../components/ui/MyTextField";
import MyDatePicker from "../../../components/ui/MyDatePicker";
import MyDP from "../../../components/ui/MyDP";
import checkLinkicon from "../../../imgs/imgV2/checkLinkIcon.svg";
import { useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import plusIcon from "../../../imgs/imgV2/plusIcon.svg";
import * as Yup from "yup";
import CheckedCircle from "../../../components/ui/CheckedCircle";
import MySwitch from "../../../components/ui/MySwitch/MySwitch";
import { toast, ToastContainer } from "react-toastify";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";

import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { FormatPaint, TtyRounded } from "@mui/icons-material";
function InfoAdministrativeEntreprise({ userFetch, userId, idEnt, change, setChange, modify }) {
  const [type, setType] = useState("virement_bancaire");
  const [open, setOpen] = useState(false);
  const [OpenSign, setOpenSign] = useState(false);
  const { user } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const access_token = user?.access_token;
  //
  //
  const handleModifyClick = () => {
    setOpen(true);
  };
  const [initialValuesInfoAdmin, setInitialValuesinfoAdmin] = useState({});

  //validation schema for the infos personnelle form
  const validationSchemaInfoAdmin = Yup.object({
    num_siret: Yup.number().positive().required("ce champ est obligatoire"),
    form_jur: Yup.string().required("Ce champ est obligatoire"),
  });
  //GET USER
  const [data, setData] = useState([]);
  const [formJur, setFormeJur] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_FORME_JURIDIQUE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res, "forme");
        setFormeJur(res?.data?.forme_juridiques);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useLayoutEffect(() => {
    if (userFetch) {
      setInitialValuesinfoAdmin({
        num_siret: userFetch.enterprise?.siret ? userFetch.enterprise?.siret : "",
        RIB: userFetch.enterprise?.rib ? userFetch.enterprise?.rib : "",
        form_jur: userFetch.enterprise?.forme_juridique?.id ? userFetch.enterprise?.forme_juridique?.id : 1,
        code_ape: userFetch.enterprise?.code_ape ? userFetch.enterprise?.code_ape : "",
        num_tva: userFetch.enterprise?.num_tva ? userFetch.enterprise?.num_tva : "",
      });
      setData([
        {
          id: "0",
          name: "Numéro de siret",
          validity: userFetch.enterprise?.siret ? true : false,
        },
        {
          id: "1",
          name: "RIB",
          validity: userFetch.enterprise?.rib ? true : false,
        },
        {
          id: "2",
          name: "Forme juridique",
          validity: userFetch.enterprise?.forme_juridique ? true : false,
        },
        {
          id: "3",
          name: "Code APE",
          validity: userFetch.enterprise?.code_ape ? true : false,
        },
        {
          id: "4",
          name: "Signature du mandat SEPA",
          validity: userFetch.enterprise?.sepa ? true : false,
        },
        {
          id: "5",
          name: "Numéro de TVA",
          validity: userFetch.enterprise?.num_tva ? true : false,
        },
      ]);
    }
  }, [userFetch]);
  useLayoutEffect(() => {
    if (userFetch) {
      setInitialValuesinfoAdmin({
        num_siret: userFetch.enterprise?.siret ? userFetch.enterprise?.siret : "",
        RIB: userFetch.enterprise?.rib ? userFetch.enterprise?.rib : "",
        form_jur: userFetch.enterprise?.forme_juridique?.id ? userFetch.enterprise?.forme_juridique?.id : 1,
        code_ape: userFetch.enterprise?.code_ape ? userFetch.enterprise?.code_ape : "",
        num_tva: userFetch.enterprise?.num_tva ? userFetch.enterprise?.num_tva : "",
      });
      setData([
        {
          id: "0",
          name: "Numéro de siret",
          validity: userFetch.enterprise?.siret ? true : false,
        },
        {
          id: "1",
          name: "RIB",
          validity: userFetch.enterprise?.rib ? true : false,
        },
        {
          id: "2",
          name: "Forme juridique",
          validity: userFetch.enterprise?.forme_juridique ? true : false,
        },
        {
          id: "3",
          name: "Code APE",
          validity: userFetch.enterprise?.code_ape ? true : false,
        },
        {
          id: "4",
          name: "Signature du mandat SEPA",
          validity: userFetch.enterprise?.sepa ? true : false,
        },
        {
          id: "5",
          name: "Numéro de TVA",
          validity: userFetch.enterprise?.num_tva ? true : false,
        },
      ]);
      setType(userFetch?.enterprise?.payment_mode);
    }
  }, []);
  const ModifyProfile = (values) => {
    console.log(values, "form values");
    Api.patch(
      Urls?.UPDATE_PROFILE_ENTREPRISE + idEnt,
      {
        forme_juridique_id: values.form_jur,
        ...(values.RIB === "" ? { rib: null } : { rib: values.RIB }),
        ...(values.num_tva === "" ? { num_tva: null } : { num_tva: values.num_tva }),
        ...(values.code_ape === "" ? { code_ape: null } : { code_ape: values.code_ape }),
        siret: values.num_siret,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setOpen(false);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };
  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }
  const SignMandat = (values) => {
    Api.post(
      Urls.SIGN_MADNDAT_SEPA + idEnt + "/sign-sepa",
      {
        password: values.password,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setChange(!change);
        setOpenSign(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const formMandat = useRef();
  const validationSchemaMandat = Yup.object({
    password: Yup.string().required("Ce champ est obligatoire"),
  });
  return (
    <Box position="relative">
      <ToastContainer />
      {loading === false ? (
        <>
          <BoxContainer display="flex" flexDirection="column">
            <Box marginBottom="20px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Infos. administratives
              </Typography>
              {modify && <ButtonSquare onClick={handleModifyClick} action="modify" />}
            </Box>
            <Box display="flex" flexDirection="column" gap="10px">
              {data.map((cerf, index) => (
                <>
                  <Box key={cerf.id} display="flex" alignItems="center" gap="40px" justifyContent="space-between">
                    <Typography sx={{ marginLeft: "10px", fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>{cerf.name}</Typography>
                    <Box>
                      <CheckedCircle checked={cerf.validity} />
                    </Box>
                  </Box>
                  {index < 5 ? <Divider /> : null}
                </>
              ))}
            </Box>
          </BoxContainer>
          <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={modalStyle}>
              <BoxContainer>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Informations administratives{" "}
                  </Typography>
                  <ButtonSquare onClick={() => setOpen(false)} action="close" />
                </Box>
                <Formik
                  initialValues={initialValuesInfoAdmin}
                  onSubmit={(values) => {
                    ModifyProfile(values);
                  }}
                  validationSchema={validationSchemaInfoAdmin}
                >
                  {({ values, errors, setFieldValue, touched }) => (
                    <Form>
                      <Box gap="20px" display="flex" flexDirection="column">
                        <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                          <Box width="100%">
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Numéro de siret
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <MyTextField disabled={true} setFieldValue={setFieldValue} value={values.num_siret} name="num_siret" placeholder="Numéro de siret..." />
                            {errors.num_siret && touched.num_siret ? (
                              <Typography color="red" fontSize="12px">
                                {errors.num_siret}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box width="100%">
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                RIB
                              </Typography>
                            </Box>
                            <MyTextField setFieldValue={setFieldValue} value={values.RIB} name="RIB" placeholder="RIB..." />
                            {errors.RIB && touched.RIB ? (
                              <Typography color="red" fontSize="12px">
                                {errors.RIB}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                        <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                          <Box width="100%">
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Forme juridique
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <Box>
                              <FormControl sx={{ height: "42px" }} fullWidth>
                                <InputLabel id="demo-simple-select-label">Forme juridique</InputLabel>
                                <Select sx={{ height: "42px", borderColor: "#DEDFE4" }} name="cat_permis" labelId="demo-simple-select-label" id="demo-simple-select" value={values?.form_jur} label="Forme_juridique" onChange={(e) => setFieldValue("form_jur", e.target.value)}>
                                  {formJur?.map((form) => (
                                    <MenuItem key={form.id} value={form?.id}>
                                      {form?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>{" "}
                              {errors.form_jur && touched.form_jur ? (
                                <Typography color="red" fontSize="12px">
                                  {errors.form_jur}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box width="100%">
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Code ape
                              </Typography>
                            </Box>
                            <MyTextField setFieldValue={setFieldValue} value={values.code_ape} name="code_ape" placeholder="Code ape..." />
                            {errors.code_ape && touched.code_ape ? (
                              <Typography color="red" fontSize="12px">
                                {errors.code_ape}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                        <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                          <Box width="100%">
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Numéro de TVA
                              </Typography>
                            </Box>
                            <MyTextField setFieldValue={setFieldValue} value={values.num_tva} name="num_tva" placeholder="Numéro de TVA..." />
                            {errors.num_tva && touched.num_tva ? (
                              <Typography color="red" fontSize="12px">
                                {errors.num_tva}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                        <Divider sx={{ margin: "15px 0px" }} />
                        <Box display="fex" gap="10px" alignItems="center" marginBottom="20px">
                          <Typography fontSize="20px" fontWeight="700" lineHeight="32px">
                            Reglement
                          </Typography>
                          {userFetch.enterprise?.sepa && (
                            <Box onClick={() => window.open(userFetch.enterprise?.sepa?.url_file, "_blank")} marginTop="2px" alignItems="center" display="flex" gap="7px">
                              <Typography variant="text_clickable">Consulter le fichier</Typography>
                              <img src={checkLinkicon} alt="link-icon" />
                            </Box>
                          )}
                        </Box>
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Sélectionner une méthode
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <FormControl>
                          <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" onChange={(e) => setType(e.target.value)} value={type}>
                            <FormControlLabel value="mandat_sepa" disabled={!userFetch?.enterprise?.sepa && !userFetch?.enterprise?.rib ? true : false} control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Mandat SEPA" />
                            <FormControlLabel value="virement_bancaire" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Virement bancaire" />
                            {!OpenSign && !userFetch.enterprise?.sepa && userFetch.enterprise?.rib && type === "mandat_sepa" && (
                              <Box>
                                <Button onClick={() => setOpenSign(true)} variant="mj_primary_md">
                                  Signer le mandat
                                </Button>
                              </Box>
                            )}
                            {OpenSign && !userFetch.enterprise?.sepa && type === "mandat_sepa" && (
                              <Formik
                                innerRef={formMandat}
                                initialValues={{
                                  password: "",
                                }}
                              >
                                {({ values, errors, setFieldValue, touched }) => (
                                  <Form>
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Mot de passe
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap="10px">
                                      <Box>
                                        <Field name="password" id="password">
                                          {({ field, form, meta }) => (
                                            <TextField
                                              {...field}
                                              fullWidth
                                              placeholder="Saisir votre mot de passe"
                                              autoComplete="Off"
                                              type={showPassword ? "text" : "password"}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                      {showPassword ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                                    </IconButton>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          )}
                                        </Field>{" "}
                                        {errors.password && touched.password ? (
                                          <Typography color="red" fontSize="12px">
                                            {errors.password}
                                          </Typography>
                                        ) : null}
                                      </Box>
                                      <Box display="flex" gap="10px">
                                        <Button onClick={() => setOpenSign(false)} variant="mj_secondary_md">
                                          Annuler
                                        </Button>
                                        <Button disabled={values?.password ? false : true} onClick={() => SignMandat(values)} variant="mj_primary_md">
                                          signer
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Form>
                                )}
                              </Formik>
                            )}
                          </RadioGroup>
                        </FormControl>
                        <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                          <Button onClick={() => setOpen(false)} variant="mj_secondary_md">
                            Annuler
                          </Button>
                          <Button type="submit" variant="mj_primary_md">
                            Enregistrer
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainer>
            </Box>
          </Modal>
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
export default InfoAdministrativeEntreprise;
