import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, Divider, TableHead, TableRow, Typography, tableCellClasses, CircularProgress, Button, Grid, InputAdornment, Avatar, Modal } from "@mui/material";
import MyTextField from "../../../components/ui/MyTextField";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import styled from "@emotion/styled";

import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";

import MissionComponent from "../../../components/ui/MissionComponent";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useLocation } from "react-router-dom";
import DetailsProfileInterim from "../offres/DetailsProfileInterim";
import { modalStyle2 } from "../../../theme/ModalStyle";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DEDFE4",
    color: `#0D173A`,
    fontSize: 12,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: `#666D83`,
    fontWeight: "500",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));
//SOME STYLING
const StyledTable = styled(Table)({
  borderRadius: "6px",
});
function HistoriqueInterim() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [search, setSearch] = useState("");
  const [nom, setnom] = useState("");
  const [userId, setUserId] = useState(null);
  const [prenom, setprenom] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [interims, setInterims] = useState([]);
  const location = useLocation();
  const idEnt = location.pathname.split("/")[4];
  const [status, setStatus] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Api.get(Urls.GET_INTERIMS_BY_ENTERPRISE + idEnt + "/interims", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: { history: 1, ...(search !== "" && { id: search }), ...(nom !== "" && { last_name: nom }), ...(prenom !== "" && { first_name: prenom }) },
    })
      .then((res) => {
        console.log(res, "mission");
        setInterims(res?.data?.users);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [search, nom, prenom]);
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setData(res?.data);
        setInterims(res.data?.users);
      })
      .catch((err) => {
        console.log(err);
        setInterims([]);
      });
  };
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box width="100%">
        <Typography fontSize="28px" fontWeight="700" lineHeight="160%">
          Historique des intérimaires
        </Typography>
      </Box>
      {/* filter */}
      <Box sx={{ backgroundColor: "#FAFAFB", padding: "40px", borderRadius: "6px" }}>
        <Box>
          <Grid container spacing="16px">
            <Grid item xs={12} md={6}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher par id
                </Typography>
              </Box>
              <MyTextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    setSearch(event.target.value);
                  } else {
                    setSearch("");
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher par nom
                </Typography>
              </Box>
              <MyTextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    setnom(event.target.value);
                  } else {
                    setnom("");
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher par prénom
                </Typography>
              </Box>
              <MyTextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    setprenom(event.target.value);
                  } else {
                    setprenom("");
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!loading ? (
        <Box>
          {interims?.length > 0 && (
            <TableContainer sx={{ zIndex: 1, borderRadius: "6px", border: `1px solid #DEDFE4`, maxWidth: "600px" }}>
              <StyledTable aria-label="customized table">
                <TableHead sx={{ backgroundColor: "#DEDFE4" }}>
                  <TableRow>
                    <StyledTableCell>Id</StyledTableCell>
                    <StyledTableCell>Intérimaire</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody position="relative">
                  {interims?.map((interim) => (
                    <StyledTableRow
                      onClick={() => {
                        setOpenProfile(true);
                        setUserId(interim?.id);
                      }}
                    >
                      <StyledTableCell>
                        <Box display="flex" alignItems="center" gap="10px">
                          <Typography>{interim?.id}</Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ cursor: "pointer" }} display="flex" flexDirection="row" alignItems="center" gap="10px">
                          <Avatar src={interim?.url_avatar} />
                          <Typography fontWeight="500" fontSize="14px">
                            {interim?.first_name} {interim?.last_name}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {interims?.length > 0 && <Divider />}
      {interims?.length === 0 && !loading && <Typography textAlign="center">Aucun intérimaire</Typography>}
      {interims?.length > 0 && !loading && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal open={openProfile} onClose={() => setOpenProfile(false)}>
        <Box sx={modalStyle2}>
          <Box sx={{ padding: "24px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpenProfile(false)} action="close" />
                <Typography variant="text_xxl" fontWeight="700">
                  Profile #{userId}
                </Typography>
              </Box>
            </Box>
            <DetailsProfileInterim interimId={userId} type={"detailsProfile"} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default HistoriqueInterim;
