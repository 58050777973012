import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Checkbox, Chip, FormControlLabel, Modal, Alert, Autocomplete } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ArrowBack, ArrowBackIosOutlined, ArrowForward, ArrowLeft, ArrowRight, CheckBox, ErrorSharp, Flag } from "@mui/icons-material";
import { colors } from "../../theme/Colors";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import MjErrorText from "../../components/ui/MjErrorText";
import { Formik, Form, Field, useFormik, ErrorMessage } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import Api from "../../api/Api";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { Urls } from "../../api/Urls";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

function Suspensions() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [id, setId] = useState(null);
  const [action, setAction] = useState("");
  const [actionMultiple, setactionMultiple] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const [total, setTotal] = useState(0);
  const idEnt = location?.state?.id ? location?.state?.id : "";
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [change, setChange] = useState(0);
  const nomPrenom = location?.state?.nom ? location?.state?.nom : "";

  const [status, setStatus] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  //actions single
  const [acceptRefuse, setOpenAcceptRefuse] = useState(false);
  const [actionAcceptRefuse, setActionAcceptRefuse] = useState(false);
  const [idTarget, setIdTarget] = useState(false);

  const initialValuesSingle = {
    date: "",
  };

  const validationSchemaSingle = Yup.object().shape({
    date: Yup.string().required("Ce champs est obligatoire"),
  });

  const AcceptRefuseSuspension = (values, id, accept) => {
    console.log("hi");
    Api.post(
      "/admin/suspensions/" + id + "/accept-or-refuse",
      {
        status: accept ? "ACCEPTED" : "REFUSED",
        date: values.date,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange((change) => change + 1);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const options = ["ENTERPRISE", "INTERIM", ""];
  const [declaredBy, setDeclaredBy] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [initalValues, setInitialValues] = useState({
    entrepriseName: idEnt,
    nomPrenom: nomPrenom,
    contrat: "",
    idSuspension: "",
    status: [],
    declaredBy: "",
    checkedPending: false,
    checkedAccepted: false,
    checkedRefused: false,
  });
  const formik = useFormik({
    initialValues: initalValues,
    onSubmit: () => {
      setChange(change + 1);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setLoading(true);
    console.log("params are : ", {
      // ...(formik.values.entreprise !== "" && { entrepriseName: formik.values.entreprise }),
      // ...(formik.values.nom !== "" && { lastName: formik.values.nom }),
      // ...(formik.values.prenom !== "" && { firstName: formik.values.prenom }),
      // ...(formik.values.contrat !== "" && { id: formik.values.contrat }),
      // ...(status.length !== 0 && { status: status }),

      id: formik.values.idSuspension ? formik.values.idSuspension : null,
      enterpriseName: formik.values.entrepriseName ? formik.values.entrepriseName : null,
      contractId: formik.values.contrat ? formik.values.contrat : null,
      userName: formik.values.nomPrenom ? formik.values.nomPrenom : null,
      status: status.length !== 0 ? status : null,
      declaredBy: formik.values.declaredBy ? formik.values.declaredBy : null,

      // id: formik.values.id ? formik.values.id : null,
    });
    Api.get("/admin/suspensions" + `?perPage=${rowsPerPage}&page=${page}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        // ...(formik.values.entreprise !== "" && { entrepriseName: formik.values.entreprise }),
        // ...(formik.values.nom !== "" && { lastName: formik.values.nom }),
        // ...(formik.values.prenom !== "" && { firstName: formik.values.prenom }),
        // ...(formik.values.contrat !== "" && { id: formik.values.contrat }),
        // ...(status.length !== 0 && { status: status }),

        id: formik.values.idSuspension ? formik.values.idSuspension : null,
        enterpriseName: formik.values.entrepriseName ? formik.values.entrepriseName : null,
        contractId: formik.values.contrat ? formik.values.contrat : null,
        userName: formik.values.nomPrenom ? formik.values.nomPrenom : null,
        status: status.length !== 0 ? status : null,
        declaredBy: formik.values.declaredBy ? formik.values.declaredBy : null,

        // id: formik.values.id ? formik.values.id : null,
      },
    })
      .then((res) => {
        console.log(res.data);
        setRows(res.data.suspensions);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change, page, rowsPerPage]);

  const columns = [
    { field: "id", headerName: "ID Problème", width: 150, headerClassName: "grid-header" },
    {
      field: "contract_id",
      headerName: "N° Contrat",
      width: 150,
    },
    {
      field: "enterprise_name",
      headerName: "Entreprise",
      width: 150,
    },
    {
      field: "user_first_name",
      headerName: "Prénom",
      width: 150,
    },
    {
      field: "user_last_name",
      headerName: "Nom",
      width: 150,
    },
    {
      field: "contract_job_title",
      headerName: "Intitulé du poste",
      width: 150,
    },
    {
      field: "declared_by",
      headerName: "Déclaré par",
      width: 150,
      renderCell: (params) => {
        return <Chip variant="active_offer" label={params.row.declared_by} />;
      },
    },
    {
      field: "status",
      headerName: "Etat",
      description: "This column has a value getter and is not sortable.",
      width: 150,
      align: "center",
      renderCell: (params) => {
        // return <Chip variant="active_offer" label={params.row.status} />;
        // return <Chip variant={params.row.status === "FIXED" ? "ended_offer" : "active_offer"} label={params.row.status === "PENDING" ? "En attente" : "Traité"} />;
        switch (params.row.status) {
          case "ACCEPTED":
            return <Chip variant={"ended_offer"} label={"Accepté"} />;
          case "REFUSED":
            return <Chip variant={"rejected_offer"} label={"Refusé"} />;
          case "PENDING":
            return <Chip variant={"active_offer"} label={"En attente"} />;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            {/* <ArrowCircleRightIcon action={"details"} sx={{ cursor: "pointer", color: `${colors.mjGreenOutline}`, fontSize: 40 }} onClick={() => navigate(`/admin/gestion-des-suspensions/details/${params.row.id}`)} /> */}
            <ButtonSquare
              action={"refuse-red"}
              onClick={() => {
                setIdTarget(params.row.id);
                setActionAcceptRefuse(false);
                setOpenAcceptRefuse(true);
              }}
            />
            <ButtonSquare
              action={"accept-green"}
              onClick={() => {
                setIdTarget(params.row.id);
                setActionAcceptRefuse(true);
                setOpenAcceptRefuse(true);
              }}
            />
            <ButtonSquare
              action={"consulter"}
              onClick={() => {
                navigate(`/admin/gestion-des-suspensions/details/${params.row.id}`);
              }}
            />
            {/* <ButtonSquare
              action={"consulter"}
              onClick={() => {
                navigate(`/admin/gestion-des-suspensions/details/${params.row.id}`);
              }}
            /> */}
          </Box>
        );
      },
      width: 220,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openParams, setOpenParams] = React.useState(false);

  //pagination next
  const handlePaginationNext = () => {
    setLoading(true);
    axios
      .get(data?.links?.next, {
        headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setRows(res.data.problems);
        setData(res.data);
        setPage(page + 1);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //handle pagination prev
  const handlePaginationPrev = () => {
    setLoading(true);

    axios
      .get(data?.links?.prev, {
        headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setRows(res.data.problems);
        setData(res.data);
        setPage(page - 1);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };

  //custom footer
  const customFooter = () => {
    return (
      <Box margin="0.5rem 1rem 0.5rem 0rem" justifyContent="space-between" gap="0.5rem" display="flex" flexDirection="row" alignItems="center">
        <Box marginLeft="1rem">
          <Typography>{selectedIDs?.length ? selectedIDs.length : 0} lignes selectionnées</Typography>
        </Box>
        <Box gap="0.5rem" display="flex">
          {data?.links?.prev ? <ArrowBackIosOutlined onClick={handlePaginationPrev} sx={{ color: `${colors.main}` }} /> : null}
          <Typography>{page}</Typography>
          {data?.links?.next ? <ArrowForwardIosIcon onClick={handlePaginationNext} sx={{ color: `${colors.main}` }} /> : null}
        </Box>
      </Box>
    );
  };

  //refuse problem
  const refuseProblem = (id) => {
    setLoading(true);
    Api.patch(
      Urls.REFUSE_PROBLEM,
      {
        problem_ids: [id],
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //refuse multiple problem
  const refuseMultipleProblem = () => {
    console.log(selectedIDs);
    setLoading(true);
    Api.patch(
      Urls.REFUSE_PROBLEM,
      {
        problem_ids: selectedIDs,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpenMultiple(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const suspendProblem = (id) => {
    setLoading(true);
    Api.patch(
      Urls.SUSPEND_CONTRAT_PROBLEM,
      {
        problem_ids: [id],
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const suspendMultipleProblem = () => {
    console.log(selectedIDs);
    setLoading(true);
    Api.patch(
      Urls.SUSPEND_CONTRAT_PROBLEM,
      {
        problem_ids: selectedIDs,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpenMultiple(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1">
          Gestion des suspensions
        </Typography>
      </Box>

      {/* Form Section */}
      <Box className="filter" flexWrap="wrap" display="flex" flexDirection="column">
        <Box>
          <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
            Filtre :
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
          <Box display="flex" sx={{ flexWrap: "wrap", flexDirection: { md: "row", xs: "column" } }}>
            {/*Search fields  */}
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <Box display="flex" flex={0.5}>
                <label style={{ marginRight: "0.5rem" }} htmlFor="id suspension">
                  Id suspension
                </label>
              </Box>
              <Box display="flex" flex={1}>
                <TextField type="text" name="idProblem" placeholder="Id suspension" onChange={formik.handleChange} value={formik.values.idProblem} />
              </Box>
            </Box>
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <Box display="flex" flex={0.5}>
                <label style={{ marginRight: "0.5rem" }} htmlFor="numero_contrat">
                  N° Contrat
                </label>
              </Box>
              <Box display="flex" flex={1}>
                <TextField type="text" name="contrat" placeholder="Numero du contrat" onChange={formik.handleChange} value={formik.values.contrat} />
              </Box>
            </Box>
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                Intérimaire
              </label>
              <TextField name="nomPrenom" placeholder="intérimaire" value={formik.values.nomPrenom} onChange={formik.handleChange} />
            </Box>
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                Entreprise
              </label>
              <TextField name="entrepriseName" placeholder="Entreprise" onChange={formik.handleChange} value={formik.values.entrepriseName} />
            </Box>
          </Box>
          {/* chekckboxes */}
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: "0.5rem", marginBottom: "0.5rem" }}>
            <Box display="flex" alignItems="center">
              <label htmlFor="state">Etat : </label>
            </Box>

            <FormControlLabel
              label="En attente"
              control={
                <Checkbox
                  checked={formik.values.checkedPending}
                  onChange={(e) => {
                    formik.setFieldValue("checkedPending", formik.values.checkedPending === false ? true : false);
                    formik.values.checkedPending === false ? setStatus((status) => status.concat(["PENDING"])) : setStatus(status.filter((status) => status !== "PENDING"));
                  }}
                />
              }
            />

            <FormControlLabel
              label="Accepté"
              control={
                <Checkbox
                  checked={formik.values.checkedAccepted}
                  onChange={(e) => {
                    formik.setFieldValue("checkedAccepted", formik.values.checkedAccepted === false ? true : false);
                    formik.values.checkedAccepted === false ? setStatus((status) => status.concat(["ACCEPTED"])) : setStatus(status.filter((status) => status !== "ACCEPTED"));
                  }}
                />
              }
            />

            <FormControlLabel
              label="Refusé"
              control={
                <Checkbox
                  checked={formik.values.checkedRefused}
                  onChange={(e) => {
                    formik.setFieldValue("checkedRefused", formik.values.checkedRefused === false ? true : false);
                    formik.values.checkedRefused === false ? setStatus((status) => status.concat(["REFUSED"])) : setStatus(status.filter((status) => status !== "REFUSED"));
                  }}
                />
              }
            />

            <Autocomplete
              value={declaredBy}
              onChange={(event, newValue) => {
                console.log("selected", event.target);
                setDeclaredBy(newValue);
                if (newValue) {
                  formik.setFieldValue("declaredBy", newValue);
                } else {
                  formik.setFieldValue("declaredBy", null);
                }
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={options}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Déclaré par" />}
            />
          </Box>
          {/* End form section */}

          {/* MODAL ACCEPTE REFUSE SINGLE  */}
          <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={acceptRefuse} onClose={() => setOpenAcceptRefuse(false)}>
            <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
              <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="text_xxl" fontWeight="700">
                  {actionAcceptRefuse ? "Accepter la demande de suspension" : "Refuser la demande de suspension"}
                </Typography>
                <ButtonSquare onClick={() => setOpenAcceptRefuse(false)} action="close" />
              </Box>

              <Formik
                //initial values
                initialValues={initialValuesSingle}
                validationSchema={validationSchemaSingle}
                onSubmit={(values) => {
                  AcceptRefuseSuspension(values, idTarget, actionAcceptRefuse);
                  setOpenAcceptRefuse(false);
                  console.log("hello");
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <Box marginBottom="20px">
                    <Typography variant="text_normal"> Saisissez la date de traitement du problème</Typography>
                  </Box> */}
                    <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                      <Field fullWidth name="date" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" label="Date traitement du problème" size="small" />
                      <ErrorMessage name="date" component={MjErrorText} />
                    </Box>

                    <Box display="flex" flexDirection="row-reverse" gap="10px">
                      <Button variant="mj_secondary_md" onClick={() => setOpenAcceptRefuse(false)}>
                        Annuler
                      </Button>
                      <Button variant="mj_primary_md" type="submit">
                        Traiter
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Modal>

          {/* Actions Section */}
          <Box className="actions">
            <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
              <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
                Actions :
              </Typography>
              <Box display="flex" gap="1rem">
                <Button onClick={() => setPage(1)} type="submit" variant="mj_green_md">
                  Filtrer
                </Button>
                {/* <Button
                  onClick={() => {
                    setactionMultiple("suspendre ces contrats");
                    setOpenMultiple(true);
                  }}
                  type="submit"
                  variant="mj_green_md"
                >
                  Traiter les problèmes
                </Button> */}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "985px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          page={page - 1}
          onPageChange={() => {
            setPage(page + 1);
          }}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
            footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} lignes selectionnées` : `${count.toLocaleString()} ligne selectionnée`),
          }}
          checkboxSelection
          disableSelectionOnClick
          loading={loading}
          experimentalFeatures={{ newEditingApi: true }}
          paginationMode="server"
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
}

export default Suspensions;
