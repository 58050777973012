import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Checkbox, IconButton, Modal, Alert, InputAdornment, Autocomplete } from "@mui/material";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import ClearIcon from "@mui/icons-material/Clear";
import { chipSelector } from "../../utils/chipSelector";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import { FormControlLabel } from "@mui/material";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import RechercheIcon from "../../imgs/imgV2/RechercheIcon.svg";
import xIcon from "../../imgs/imgV2/xiCON.svg";
import * as yup from "yup";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { colors } from "../../theme/Colors";
import { useNavigate } from "react-router-dom";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { modalStyle } from "../../theme/ModalStyle";
import BoxContainer from "../../components/ui/BoxContainer";
import MyTextField from "../../components/ui/MyTextField";

function InterimairePot() {
  const [change, setChange] = useState(0);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [id, setId] = useState(null);
  const validationSchema = yup.object().shape({
    id_interimaire: yup.string(),
    telephone: yup.number().typeError("entrer un nombre valide").positive("entrer un numero valid").min(111111111, "entrer un numero valid").max(99999999999, "entrer un numero valid"),
    rib: yup.number("ce champ doit comporter un nombre").typeError("ce champ doit comporter un nombre").max(999999999999999999999999999, "un maximum de 27 chiffres"),
  });
  const validationSchemaCreate = yup.object().shape({
    nom: yup.string().required("Ce champ est obligatoire"),
    prenom: yup.string().required("Ce champ est obligatoire"),
    num_tel: yup.number().typeError("Valeur invalide").required("Ce champ est obligatoire"),
    email: yup.string().email().typeError("Valeur invalide").required("Ce champ est obligatoire"),
  });
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: "grid-header" },
    {
      field: "first_name",
      headerName: "Prénom",
      width: 150,
      renderCell: (params) => {
        if (params.row.first_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.first_name;
        }
      },
    },
    {
      field: "last_name",
      headerName: "Nom",
      width: 150,
      renderCell: (params) => {
        if (params.row.last_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.last_name;
        }
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 170,
      renderCell: (params) => {
        if (params.row.email === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.email;
        }
      },
    },
    {
      field: "birthday",
      headerName: "Date de naissance",
      width: 100,
      renderCell: (params) => {
        if (params.row.birthday === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.birthday;
        }
      },
    },
    {
      field: "phone_number",
      headerName: "Numéro de tel",
      width: 120,
      renderCell: (params) => {
        if (params.row.phone_number === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.phone_number;
        }
      },
    },
    {
      field: "metiers",
      headerName: "Métier",
      width: 150,
      renderCell: (params) => {
        if (params.row.metiers?.length > 0) {
          return <Typography>{params.row.metiers[0].name}</Typography>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "is_potential",
      headerName: "Etat",
      width: 150,
      renderCell: (params) => {
        return chipSelector(params.row.is_potential === 1 ? "potentiel" : "Npotentiel");
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            <ArrowCircleRightIcon action={"details"} sx={{ cursor: "pointer", color: `${colors.mjGreenOutline}`, fontSize: 40 }} onClick={() => navigate(`/admin/gestion-des-interimaires-potentiel/details/${params.row.id}`, { state: { status: params.row.status, type: "potentiel" } })} />
          </Box>
        );
      },
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];

  const { user } = useAuthContext();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState("");
  const [actionMultiple, setactionMultiple] = useState("");
  const [open, setOpen] = useState(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const navigate = useNavigate();
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [statusH, setStatusH] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIDs, setSelectedIDs] = useState(null);
  const [status, setStatus] = useState([]);
  const [openParams, setOpenParams] = React.useState(false);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState();

  const formik = useFormik({
    initialValues: {
      nom: "",
      prenom: "",
      metier: { id: "", name: "" },
      certification: { id: "", name: "" },
      cv: "",
      checkedhandicap: false,
      checkedPrendTt: false,
      checkedAtt: false,
      checkedValid: false,
      checkedRef: false,
      checkedPot: false,
      checkedV: false,
    },
    onSubmit: () => {
      console.log(formik.values, status);
      setChange(change + 1);
    },
    validationSchema: validationSchema,
  });
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_LISTE_RENARD + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        isPotential: 1,
        ...(formik.values.email !== "" && { email: formik.values.email }),
        ...(formik.values.rib !== "" && { rib: formik.values.rib }),
        ...(formik.values.telephone !== "" && { phoneNumber: formik.values.telephone }),
        ...(formik.values.nom !== "" && { lastName: formik.values.nom }),
        ...(formik.values.prenom !== "" && { firstName: formik.values.prenom }),
        ...(status.length !== 0 && { status: status }),
        ...(formik.values.nom !== "" && { lastName: formik.values.nom }),
        ...(formik.values.prenom !== "" && { firstName: formik.values.prenom }),
        ...(formik.values.certification?.id !== "" && { certificateDocumentTypeIds: [formik.values.certification?.id] }),
        ...(formik.values.metier?.id !== "" && { metierIds: [formik.values.metier?.id] }),
        ...(formik.values.checkedhandicap === true && { isHandicapped: 1 }),
        ...(formik.values.checkedPrendTt === true && { isHandicapped: 0 }),
        ...(formik.values.cv !== "" && { cvContent: formik.values.cv }),
      },
    })
      .then((res) => {
        setRows(res.data.interims);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
        console.log("response el gharbi", res.data);
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  }, [change, formik.values]);
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };
  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.interims);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };

  const customFooter = () => {
    return <Box></Box>;
  };
  const createInterimaire = (values) => {
    Api.post(
      Urls.CREATE_INTERIMAIRE_POT,
      {
        email: values.email,
        first_name: values.prenom,
        last_name: values.nom,
        phone_number: values.num_tel,
      },
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setOpenMultiple(false);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [certifications, setCertification] = useState([]);
  //rechercher des certificat pour le autocomplete
  const SearchCertif = (value) => {
    Api.get(Urls.SEARCH_CERTIFICATE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        console.log(res);
        setCertification(res?.data?.certificate_document_types);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //search metier
  const [metierListe, setMetierListe] = useState([]);
  const searchMetier = (value) => {
    Api.get(Urls.GET_METIER_AUTO, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        console.log(res, "search resposne");
        setMetierListe(res?.data?.metiers);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des intérimaires
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box className="filter" display="flex" flexDirection="column" gap="1rem">
          <Box display="flex" sx={{ flexDirection: { md: "column", xs: "column" } }}>
            <Box>
              <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
                Filtre :
              </Typography>
            </Box>

            {/*Search fields  */}
            <Box display="flex" flex={1} alignItems="center" gap="10px" mb>
              <Box alignItems="center" gap="0.3rem" display="flex">
                <TextField type="text" error={formik?.errors?.nom ? true : false} placeholder="Nom de l'intérimaire" name="nom" onChange={formik.handleChange} />
              </Box>
              <Box alignItems="center" gap="0.3rem" display="flex">
                <TextField placeholder="Prénom de l'intérimaire" name="prenom" onChange={formik.handleChange} />
              </Box>
            </Box>
          </Box>

          {/* Checkboxes */}
          <Typography fontWeight="bold">Filtres avancés : </Typography>
          <Box marginBottom="1rem" gap="1rem" display="flex" flexWrap="wrap">
            <Box alignItems="center" gap="0.3rem" display="flex" flexDirection="column">
              <Box alignItems="center" gap="0.3rem" display="flex">
                <Autocomplete
                  sx={{ minWidth: "300px" }}
                  disablePortal
                  fullWidth
                  id="combo-box-demo"
                  value={formik?.values?.metier}
                  onChange={(e, value) => {
                    console.log(value);
                    if (value) {
                      formik.setFieldValue("metier", value);
                    }
                  }}
                  options={metierListe}
                  getOptionLabel={(Option) => Option.name}
                  renderInput={(params) => (
                    <TextField
                      sx={{ maxWidth: "100%" }}
                      {...params}
                      variant="outlined"
                      value={formik.values?.metier}
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          searchMetier(e.target.value);
                        } else {
                          setMetierListe([]);
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={RechercheIcon} alt="end" />
                          </InputAdornment>
                        ),
                        endAdornment: null,
                      }}
                      placeholder="Métier..."
                    />
                  )}
                />{" "}
                <ClearIcon sx={{ color: "black" }} onClick={() => formik.setFieldValue("metier", { id: "", name: "" })} />
              </Box>
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <Autocomplete
                sx={{ minWidth: "300px" }}
                disablePortal
                ListboxProps={{ style: { maxHeight: "180px" } }}
                fullWidth
                id="combo-box-demo"
                value={formik?.values?.certification}
                onChange={(e, value) => {
                  if (value) {
                    formik.setFieldValue("certification", value);
                  }
                }}
                options={certifications}
                getOptionLabel={(Option) => Option.name}
                renderInput={(params) => (
                  <TextField
                    sx={{ maxWidth: "100%" }}
                    value={formik.values.certification}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (e.target.value?.length > 0) {
                          SearchCertif(e?.target?.value);
                        } else {
                          setCertification([]);
                        }
                      }
                    }}
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={RechercheIcon} alt="end" />
                        </InputAdornment>
                      ),
                      endAdornment: null,
                    }}
                    placeholder="Certification..."
                  />
                )}
              />{" "}
              <ClearIcon sx={{ color: "black" }} onClick={() => formik.setFieldValue("certification", { id: "", name: "" })} />
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <TextField placeholder="Contenu CV" type="input" name="cv" onChange={formik.handleChange} />
            </Box>

            <FormControlLabel
              label="Handicapé"
              control={
                <Checkbox
                  checked={formik.values.checkedhandicap}
                  onChange={(e) => {
                    formik.setFieldValue("checkedhandicap", e.target.checked);
                    if (formik.values.checkedPrendTt && e.target.checked) {
                      formik.setFieldValue("checkedPrendTt", false);
                    }
                  }}
                />
              }
            />
            <FormControlLabel
              label="Non handicapé"
              control={
                <Checkbox
                  checked={formik.values.checkedPrendTt}
                  onChange={(e) => {
                    formik.setFieldValue("checkedPrendTt", e.target.checked);
                    if (formik.values.checkedhandicap && e.target.checked) {
                      formik.setFieldValue("checkedhandicap", false);
                    }
                  }}
                />
              }
            />
          </Box>
        </Box>

        {/* Actions Section */}
        <Box className="actions">
          <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
              Actions :
            </Typography>
            <Box display="flex" gap="1rem">
              <Button
                onClick={() => {
                  setOpenMultiple(true);
                }}
                variant="mj_primary_md"
              >
                Créer intérimaire potentiel
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box marginBottom="24px" sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1255px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows ? rows : []}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          page={page - 1}
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          checkboxSelection
          loading={loading}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
          }}
          disableSelectionOnClick
          paginationMode="server"
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal open={openMultiple} onClose={() => setOpenMultiple(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Création d'intérimaire potentiel
              </Typography>
              <ButtonSquare onClick={() => setOpenMultiple(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                nom: "",
                prenom: "",
                num_tel: "",
                email: "",
              }}
              enableReinitialize={true}
              onSubmit={(values) => {
                createInterimaire(values);
              }}
              validationSchema={validationSchemaCreate}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Nom
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.nom} name="nom" placeholder="nom..." />
                        {errors.nom && touched.nom ? (
                          <Typography color="red" fontSize="12px">
                            {errors.nom}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Prénom
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.prenom} name="prenom" placeholder="Prénom..." />
                        {errors.prenom && touched.prenom ? (
                          <Typography color="red" fontSize="12px">
                            {errors.prenom}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Numéro de téléphone
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.num_tel} name="num_tel" placeholder=" Numéro de téléphone..." />
                        {errors.num_tel && touched.num_tel ? (
                          <Typography color="red" fontSize="12px">
                            {errors.num_tel}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Email
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.email} name="email" placeholder="Email..." />
                        {errors.email && touched.email ? (
                          <Typography color="red" fontSize="12px">
                            {errors.email}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" gap="8px">
                      <Button onClick={() => setOpenMultiple(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button variant="mj_primary_md" type="submit">
                        Créer et envoyer email
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default InterimairePot;
