import React, { useEffect, useState, useRef } from "react";
import { Box, TextField, Button, Typography, Checkbox, Chip, FormControlLabel, Modal, Alert, Autocomplete } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ArrowBack, ArrowBackIosOutlined, ArrowForward, ArrowLeft, ArrowRight, CheckBox, ErrorSharp, Flag } from "@mui/icons-material";
import { colors } from "../../theme/Colors";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Formik, Form, Field, useFormik, ErrorMessage } from "formik";
import MjErrorText from "../../components/ui/MjErrorText";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonSquare from "../../components/ui/ButtonSquare";
import * as Yup from "yup";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import MjLabel from "../../components/ui/MjLabel";
import TimeInput from "../../components/ui/TimeInput";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

function Entretiens() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [id, setId] = useState(null);
  const location = useLocation();
  const idEnt = location?.state?.id ? location?.state?.id : "";
  const [action, setAction] = useState("");
  const [actionMultiple, setactionMultiple] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [change, setChange] = useState(0);
  const [status, setStatus] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const refPending = useRef();
  const refEnded = useRef();

  const options = ["PENDING", "FINISHED", ""];
  const [declaredBy, setDeclaredBy] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [openPlanifier, setOpenPlanifier] = useState(false);

  const [searchedOffer, setSearchedOffer] = useState("");
  const [selectedOffer, setSelectedOffer] = useState({});
  const [offers, setOffers] = useState([]);

  const [searchedCandidature, setSearchedCandidature] = useState("");
  const [selectedCandidature, setSelectedCandidature] = useState({});
  const [candidatures, setCandidatures] = useState([]);

  const initialValuesPlanifier = {
    offre: "",
    candidature: "",
    date: "",
    heure: "",
    address: "",
  };

  const validationSchemaPlanier = Yup.object().shape({
    offre: Yup.string(),
    candidature: Yup.string().required("Ce champs est obligatoire"),
    date: Yup.string().required("Ce champs est obligatoire"),
    address: Yup.string().required("Ce champs est obligatoire"),
    heure: Yup.string().required("Ce champs est obligatoire"),
  });

  //get offers SEARCH
  useEffect(() => {
    console.log("GETTIN offres SEARCH ///////////:::::::::::");
    if (searchedOffer) {
      console.log("GETTIN offres PAR ID ///////////:::::::::::");
      console.log("searched offer ///////////////", searchedOffer);
      Api.get("/admin/search/offers" + `?search=${searchedOffer}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          console.log("RESPONSE SEARCH offer ::::::::::::::", response);
          setOffers(response?.data?.offers);
        }
      });
    }
  }, [searchedOffer]);

  //get candidatures SEARCH
  useEffect(() => {
    console.log("GETTIN candidatures SEARCH ///////////:::::::::::");
    if (searchedCandidature && selectedOffer?.id) {
      console.log("GETTIN candidatures PAR ID ///////////:::::::::::");
      console.log("searched candidature ///////////////", searchedCandidature);
      Api.get(`/admin/search/offers/${selectedOffer?.id}/applications` + `?search=${searchedCandidature}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          console.log("RESPONSE SEARCH candidature ::::::::::::::", response);
          setCandidatures(response?.data?.applications);
        }
      });
    }
  }, [searchedCandidature]);

  const createInterview = (values) => {
    console.log("hi");
    Api.post(
      "/admin/interviews",
      {
        application_id: values.candidature,
        address: values.address,
        date_time: values.date + " " + values.heure + ":00",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log("CREATE INTERVIEW RESULT", res);
        setChange((change) => change + 1);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const [initialValues, setInitialValues] = useState({
    id: "",
    application_id: "",
    interim_id: "",
    enterprise_id: idEnt,
    date_time_min: "",
    date_time_max: "",
    address: "",
    status: "",
  });
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      setChange(change + 1);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setLoading(true);
    console.log("params are : ", {
      // ...(formik.values.entreprise !== "" && { entrepriseName: formik.values.entreprise }),
      // ...(formik.values.nom !== "" && { lastName: formik.values.nom }),
      // ...(formik.values.prenom !== "" && { firstName: formik.values.prenom }),
      // ...(formik.values.contrat !== "" && { id: formik.values.contrat }),
      // ...(status.length !== 0 && { status: status }),

      id: formik.values.id ? formik.values.id : null,
      applicationId: formik.values.application_id ? formik.values.application_id : null,
      interimId: formik.values.interim_id ? formik.values.interim_id : null,
      enterpriseId: formik.values.enterprise_id ? formik.values.enterprise_id : null,
      dateTimeMin: formik.values.date_time_min ? formik.values.date_time_min : null,
      dateTimeMax: formik.values.date_time_max ? formik.values.date_time_max : null,
      address: formik.values.address ? formik.values.address : null,
      status: status ? status : null,

      // id: formik.values.id ? formik.values.id : null,
    });
    Api.get("/admin/interviews" + `?perPage=${rowsPerPage}&page=${page}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        // ...(formik.values.entreprise !== "" && { entrepriseName: formik.values.entreprise }),
        // ...(formik.values.nom !== "" && { lastName: formik.values.nom }),
        // ...(formik.values.prenom !== "" && { firstName: formik.values.prenom }),
        // ...(formik.values.contrat !== "" && { id: formik.values.contrat }),
        // ...(status.length !== 0 && { status: status }),

        id: formik.values.id ? formik.values.id : null,
        applicationId: formik.values.application_id ? formik.values.application_id : null,
        interimId: formik.values.interim_id ? formik.values.interim_id : null,
        enterpriseId: formik.values.enterprise_id ? formik.values.enterprise_id : null,
        dateTimeMin: formik.values.date_time_min ? formik.values.date_time_min : null,
        dateTimeMax: formik.values.date_time_max ? formik.values.date_time_max : null,
        address: formik.values.address ? formik.values.address : null,
        status: status ? status : null,

        // id: formik.values.id ? formik.values.id : null,
      },
    })
      .then((res) => {
        console.log(res.data);
        setRows(res.data.interviews);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change, page, rowsPerPage]);

  //  "id": 1,
  //                        "application_id": 2,
  //                        "interim_id": 49,
  //                        "enterprise_id": 47,
  //                        "date_time": "2023-12-12 00:00:00",
  //                        "address": "mlkj",
  //                        "status": "PENDING"

  const columns = [
    { field: "id", headerName: "ID entretien", width: 120, headerClassName: "grid-header" },
    {
      field: "application_id",
      headerName: "ID candidature",
      width: 120,
    },
    {
      field: "interim_id",
      headerName: "ID intérimaire",
      width: 120,
    },
    {
      field: "enterprise_id",
      headerName: "ID entreprise",
      width: 120,
    },
    {
      field: "date_time",
      headerName: "Date et heure",
      width: 200,
    },
    {
      field: "address",
      headerName: "Adresse",
      width: 200,
    },
    {
      field: "status",
      headerName: "Etat",
      width: 150,
      renderCell: (params) => {
        return <Chip variant={params.row.status === "FINISHED" ? "ended_offer" : "active_offer"} label={params.row.status === "FINISHED" ? "Terminé" : "En cours"} />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      width: 150,
      align: "center",
      renderCell: (params) => {
        return (
          <ButtonSquare
            action={"consulter"}
            onClick={() => {
              navigate(`/admin/gestion-des-entretiens/details/${params.row.id}`);
              // console.log("take to detail entretien");
            }}
          />
        );
      },
    },
  ];
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openParams, setOpenParams] = React.useState(false);

  //pagination next
  const handlePaginationNext = () => {
    setLoading(true);
    axios
      .get(data?.links?.next, {
        headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setRows(res.data.interviews);
        setData(res.data);
        setPage(page + 1);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //handle pagination prev
  const handlePaginationPrev = () => {
    setLoading(true);

    axios
      .get(data?.links?.prev, {
        headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setRows(res.data.interviews);
        setData(res.data);
        setPage(page - 1);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };

  //custom footer
  const customFooter = () => {
    return (
      <Box margin="0.5rem 1rem 0.5rem 0rem" justifyContent="space-between" gap="0.5rem" display="flex" flexDirection="row" alignItems="center">
        <Box marginLeft="1rem">
          <Typography>{selectedIDs?.length ? selectedIDs.length : 0} lignes selectionnées</Typography>
        </Box>
        <Box gap="0.5rem" display="flex">
          {data?.links?.prev ? <ArrowBackIosOutlined onClick={handlePaginationPrev} sx={{ color: `${colors.main}` }} /> : null}
          <Typography>{page}</Typography>
          {data?.links?.next ? <ArrowForwardIosIcon onClick={handlePaginationNext} sx={{ color: `${colors.main}` }} /> : null}
        </Box>
      </Box>
    );
  };

  //refuse problem
  const refuseProblem = (id) => {
    setLoading(true);
    Api.patch(
      Urls.REFUSE_PROBLEM,
      {
        problem_ids: [id],
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //refuse multiple problem
  const refuseMultipleProblem = () => {
    console.log(selectedIDs);
    setLoading(true);
    Api.patch(
      Urls.REFUSE_PROBLEM,
      {
        problem_ids: selectedIDs,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpenMultiple(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const suspendProblem = (id) => {
    setLoading(true);
    Api.patch(
      Urls.SUSPEND_CONTRAT_PROBLEM,
      {
        problem_ids: [id],
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const suspendMultipleProblem = () => {
    console.log(selectedIDs);
    setLoading(true);
    Api.patch(
      Urls.SUSPEND_CONTRAT_PROBLEM,
      {
        problem_ids: selectedIDs,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpenMultiple(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1">
          Gestion des entretiens
        </Typography>
      </Box>

      {/* Form Section */}
      <Box className="filter" flexWrap="wrap" display="flex" flexDirection="column">
        <Box>
          <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
            Filtre :
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
          <Box display="flex" sx={{ flexWrap: "wrap", flexDirection: { md: "row", xs: "column" } }}>
            {/*Search fields  */}
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <Box display="flex" flex={0.5}>
                <label style={{ marginRight: "0.5rem" }} htmlFor="id probleme">
                  Id entretien
                </label>
              </Box>
              <Box display="flex">
                <TextField type="text" name="id" placeholder="Id entretien" onChange={formik.handleChange} value={formik.values.id} />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <Box display="flex" flex={0.5}>
                <label style={{ marginRight: "0.5rem" }} htmlFor="numero_contrat">
                  Id candidature
                </label>
              </Box>
              <Box display="flex">
                <TextField type="text" name="application_id" placeholder="Id candidature" onChange={formik.handleChange} value={formik.values.application_id} />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="interim_id">
                Id intérimaire
              </label>
              <TextField name="interim_id" placeholder="intérimaire" value={formik.values.interim_id} onChange={formik.handleChange} />
            </Box>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="interim_id">
                Id entreprise
              </label>
              <TextField name="enterprise_id" placeholder="enterprise_id" onChange={formik.handleChange} value={formik.values.enterprise_id} />
            </Box>
          </Box>

          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="interim_id">
                Date min
              </label>
              <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarMonthIcon }}
                  value={formik.values.date_time_min}
                  views={["year", "month", "day"]}
                  onChange={(value) => {
                    formik.setFieldValue("date_time_min", moment(value)?.format()?.split("T")[0]);
                  }}
                  InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        type="date"
                        {...params}
                        error={formik.touched.datemin && Boolean(formik.errors.date_time_min)}
                        name="date_time_min"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "date",
                          disabled: "true",
                        }}
                      />
                    );
                  }}
                ></DatePicker>
              </LocalizationProvider>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("date_time_min", null)} />
            </Box>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="interim_id">
                Date max
              </label>
              {/* <TextField name="date_time_max" placeholder="date_time_max" onChange={formik.handleChange} value={formik.values.date_time_max} /> */}
              <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarMonthIcon }}
                  value={formik.values.date_time_max}
                  views={["year", "month", "day"]}
                  onChange={(value) => formik.setFieldValue("date_time_max", moment(value)?.format()?.split("T")[0])}
                  InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        type="date"
                        {...params}
                        error={formik.touched.datemin && Boolean(formik.errors.date_time_max)}
                        name="date_time_max"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "date",
                          disabled: "true",
                        }}
                      />
                    );
                  }}
                ></DatePicker>
              </LocalizationProvider>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("date_time_max", null)} />
            </Box>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="interim_id">
                Adresse
              </label>
              <TextField name="address" placeholder="address" onChange={formik.handleChange} value={formik.values.address} />
            </Box>
          </Box>

          {/* chekckboxes */}
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: "0.5rem", marginBottom: "0.5rem" }}>
            <Box display="flex" alignItems="center">
              <label htmlFor="state">Etat : </label>
            </Box>

            <Autocomplete
              value={status}
              onChange={(event, newValue) => {
                console.log("selected", event.target);
                setStatus(newValue);
                if (newValue) {
                  formik.setFieldValue("status", newValue);
                } else {
                  formik.setFieldValue("status", "");
                }
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={options}
              sx={{ width: 200 }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          {/* End form section */}

          {/* Actions Section */}
          <Box className="actions">
            <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
              <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
                Actions :
              </Typography>
              <Box display="flex" gap="1rem">
                <Button onClick={() => setPage(1)} type="submit" variant="mj_green_md">
                  Filtrer
                </Button>
                <Button onClick={() => setOpenPlanifier(true)} type="submit" variant="mj_green_md">
                  Planifier un entretien
                </Button>
                {/* <Button
                  onClick={() => {
                    setactionMultiple("suspendre ces contrats");
                    setOpenMultiple(true);
                  }}
                  type="submit"
                  variant="mj_green_md"
                >
                  Traiter les problèmes
                </Button> */}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>

      {/* MODAL PLANIFIER ENTRETIEN */}
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openPlanifier} onClose={() => setOpenPlanifier(false)}>
        <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
          <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="text_xxl" fontWeight="700">
              Planifier un entretien
            </Typography>
            <ButtonSquare onClick={() => setOpenPlanifier(false)} action="close" />
          </Box>

          <Formik
            //initial values
            initialValues={initialValuesPlanifier}
            validationSchema={validationSchemaPlanier}
            onSubmit={(values) => {
              createInterview(values);
              setOpenPlanifier(false);
              console.log("hello");
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <pre>{JSON.stringify(values, null, 2)}</pre>
                {/* <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                  <Field fullWidth name="date" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" label="Date traitement du problème" size="small" />
                  <ErrorMessage name="date" component={MjErrorText} />
                </Box> */}
                <Box sx={{ marginBottom: "10px" }}>
                  <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                    Offre
                  </MjLabel>
                  <Field name="offre">
                    {({ field, form }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        value={selectedOffer}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (value) {
                            form.setFieldValue("offre", value.id);
                            setSelectedOffer(value);
                            setCandidatures([]);
                            form.setFieldValue("candidature", "");
                            console.log("selected métier is ::", value);
                          } else {
                            setSelectedOffer(null);
                            setSelectedCandidature(null);
                            form.setFieldValue("offre", "");
                            form.setFieldValue("candidature", "");
                          }
                        }}
                        onInputChange={(event, value) => {
                          setSearchedOffer(event?.target?.value);
                          console.log("searching for :", event?.target?.value);
                          console.log(value);
                          if (event?.target?.value === "") {
                            form.setFieldValue("offre", "");
                          }
                        }}
                        options={offers}
                        getOptionLabel={(option) => "#" + option.id + " " + option.job_title}
                        renderInput={(params) => <TextField fullWidth value={selectedOffer} {...params} placeholder="Rechercher un métier..." />}
                      />
                    )}
                  </Field>
                </Box>

                <Box sx={{ marginBottom: "10px" }}>
                  <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                    Candidature
                  </MjLabel>
                  <Field name="candidature">
                    {({ field, form }) => (
                      <Autocomplete
                        disabled={selectedOffer ? false : true}
                        {...field}
                        fullWidth
                        value={selectedCandidature}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (value) {
                            form.setFieldValue("candidature", value.id);
                            setSelectedCandidature(value);
                            console.log("selected candidature is ::", value);
                          } else {
                            setSelectedCandidature(null);
                            form.setFieldValue("candidature", "");
                          }
                        }}
                        onInputChange={(event, value) => {
                          setSearchedCandidature(event?.target?.value);
                          console.log("searching for :", event?.target?.value);
                          console.log(value);
                          if (event?.target?.value === "") {
                            form.setFieldValue("candidature", "");
                          }
                        }}
                        options={candidatures}
                        getOptionLabel={(option) => "#" + option.id + " " + "entreprise : " + option.enterprise_name + " | intérimaire : " + option.user_last_name + " " + option.user_first_name}
                        renderInput={(params) => <TextField fullWidth value={selectedOffer} {...params} placeholder="Rechercher une candidature..." />}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="candidature" component={MjErrorText} />
                </Box>

                <Box sx={{ marginBottom: "10px" }}>
                  <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                    Date de l'entretien
                  </MjLabel>
                  <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                    <Field fullWidth name="date" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" size="small" />
                    <ErrorMessage name="date" component={MjErrorText} />
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "10px" }}>
                  <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                    Heure de l'entretien
                  </MjLabel>

                  <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                    <Field name="heure" width="100%" component={TimeInput} autoComplete="off" />
                    <ErrorMessage name="heure" component={MjErrorText} />
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "10px" }}>
                  <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                    Adresse de l'entretien
                  </MjLabel>

                  <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                    <Field fullWidth name="address" as={TextField} variant="outlined" size="small" />
                    <ErrorMessage name="address" component={MjErrorText} />
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row-reverse" gap="10px">
                  <Button variant="mj_secondary_md" onClick={() => setOpenPlanifier(false)}>
                    Annuler
                  </Button>
                  <Button variant="mj_primary_md" type="submit">
                    Planifier
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "985px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          page={page - 1}
          onPageChange={() => {
            setPage(page + 1);
          }}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
            footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} lignes selectionnées` : `${count.toLocaleString()} ligne selectionnée`),
          }}
          checkboxSelection
          disableSelectionOnClick
          loading={loading}
          experimentalFeatures={{ newEditingApi: true }}
          paginationMode="server"
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            // NoRowsOverlay: EmptyDataGridOverlay,
          }}
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
}

export default Entretiens;
