import React from "react";

import { modalStyle } from "../../theme/ModalStyle";
import { Box, Button, Typography, SvgIcon, Modal, CircularProgress, Menu, MenuItem, InputAdornment, TextField, IconButton, Divider, Grid, Autocomplete, FormControl, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import Xredicon from "../../imgs/imgV2/XredIcon.svg";

import Api from "../../api/Api";
import ArrowUpGreen from "../../imgs/imgV2/ArrowUpGreen.svg";

import { Urls } from "../../api/Urls";

import MyChip from "../../components/ui/MyChip";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import BoxContainer from "../../components/ui/BoxContainer";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import OfferDetails from "./offres/OfferDetails";
import OffreDetails from "./OffreDetails";
import DetailOffreFinale from "./offres/DetailOffreFinale";
import InterimaireDetails from "./details-Interimaire/InterimaireDetails";
import EntrepriseDetails from "./detailEntreprise/EntrepriseDetails";
import { colors } from "../../theme/Colors";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import OmjDatePicker from "../../components/ui/OmjDatePicker";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RightArrowTime from "../../imgs/imgV2/RightArrowTime.svg";
import AddIcon from "../../imgs/imgV2/plusIcon.svg";
import RechercheIcon from "../../imgs/imgV2/RechercheIcon.svg";

import FormikErrorText from "../../components/ui/FormikErrorText";
import MyTextField from "../../components/ui/MyTextField";
import MySwitch from "../../components/ui/MySwitch/MySwitch";
import BoxContainerModal from "../../components/ui/BoxContainerModal";
import TimeInput from "../../components/ui/TimeInput";
import ListeEntretiens from "./ListeEntretiens";

const chipSelector = (etat) => {
  switch (etat) {
    case "APPLIED":
      return "En attente";
      break;
    case "PENDING":
      return "En attente";
      break;
    case "OFFER_REJECTED":
      return "Proposition refusée";
      break;
    case "APPLICATION_REJECTED":
      return "Refusée";
      break;
    case "APPLICATION_CANCELED":
      return "Candidature Annulée";
      break;
    case "ACCEPTED":
      return "Offre envoyée";
      break;
    case "DOCUMENTS_PENDING_VALIDATION":
      return "Offre envoyée";
      break;
    case "CREATING_CONTRACT":
      return "Contrat en cours de création";
      break;
    case "MISSION":
      return "En mission";
      break;
    case "CONTRACT_IN_CREATION":
      return "Création du contrat";
      break;
  }
};
const ItemContainerAgenda = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "white", border: "1px solid #DEDFE4", padding: "18px 16px", borderRadius: "6px", marginBottom: "4px", boxShadow: "0px 2px 3px rgba(0,0,0,0.06)" }}>
      {children}
    </Box>
  );
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
const GrayContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "#F5F5F7", padding: "4px 4px 1px 4px", borderRadius: "6px" }}>
      {children}
    </Box>
  );
};

const ItemContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "white", border: "1px solid #DEDFE4", padding: "9px 16px", borderRadius: "6px", marginBottom: "4px", boxShadow: "0px 2px 3px rgba(0,0,0,0.06)" }}>
      {children}
    </Box>
  );
};
function CandidatureDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [file, setFile] = useState();

  const interimId = location?.state?.interimId;
  const entrepriseId = location?.state?.enterpriseId;
  const offerId = location?.state?.offerId;

  const id = location.pathname.split("/")[4];
  console.log(id, "id pffer");

  const [applicationOpen, setApplicationOpen] = useState();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [selectedTab, setSelectedTab] = useState(1);
  const [showPasswordDel, setShowPasswordDel] = useState(false);
  const [openAccepter, setOpenAccepter] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [openRefuser, setOpenRefuser] = useState(false);
  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  const PushEnAttente = (id) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        admin_status: "PENDING",
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAccepter(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const RejeterCandidature = (id, selected, motif) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        admin_status: "REFUSED",
        ...(selected !== "Autre" ? null : { refusal_reason: motif }),
        refusal_reason_id: selected === "Autre" ? null : selected,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenRefuser(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const AccepterCandidature = (id, password) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        admin_status: "SIGNED",
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAccepter(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  const tabs = [
    {
      id: 1,
      name: "Détails de l’offre",
      icon: "📋",
    },
    {
      id: 2,
      name: "Entreprise",
    },
    {
      id: 3,
      name: "Intérimaire",
    },
    {
      id: 4,
      name: "Entretien",
    },
  ];
  const [detailOffre, setDetailOffre] = useState({});
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState();
  // get detail offer by ID AND PEFECT CANDIDATURES
  useEffect(() => {
    Api.get(Urls.GET_CANDIATURE + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(id !== "" && { applicationId: id }),
      },
    })
      .then((res) => {
        console.log(res);
        setApplications(res.data.applications[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);

  const contentSelector = (contentId = 1) => {
    switch (contentId) {
      case 1:
        return <DetailOffreFinale id={offerId} type="offre" />;
      case 2:
        return <EntrepriseDetails modify={false} idEntreprise={entrepriseId} />;
      case 3:
        return <InterimaireDetails modify={false} idInterimaire={interimId} />;
      case 4:
        return <ListeEntretiens applicationId={id} />;
      default:
        return <DetailOffreFinale id={id} type="offre" />;
    }
  };
  const [motifsR, setMotifsR] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_REFUSAL_REASONS_ENTREPRISE, {
      headers: { "content-type": "appication/json", authorization: `Bearer ${user?.access_token}` },
    })
      .then((res) => {
        console.log(res);
        setMotifsR(res?.data?.enterprise_refusal_reasons);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //fermer le recrutement

  return (
    <Box display="flex" flexDirection="column" gap="40px">
      <ToastContainer />
      {/* header */}
      <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", gap: "16px" }}>
        {/* left*/}
        <Box display="flex" flexDirection="column" gap="6px">
          <Box>
            <Typography variant="text_xl" fontWeight="700">
              Gestion des candidatures
            </Typography>
          </Box>
          <Box>
            <Typography variant="text_xl" fontWeight="700">
              Candidature #{id}
            </Typography>
          </Box>
        </Box>

        {/* right*/}
        <Box display="flex" gap="10px" alignItems="center">
          {applications?.calculated_status === "OFFER_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(applications?.calculated_status)} />}
          {applications?.calculated_status === "APPLICATION_CANCELED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(applications?.calculated_status)} />}
          {applications?.calculated_status === "MISSION" && <MyChip variant="active_offer" label={chipSelector(applications?.calculated_status)} />}

          {applications?.calculated_status === "APPLICATION_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(applications?.calculated_status)} />}
          {applications?.calculated_status === "CONTRACT_IN_CREATION" && <MyChip variant="skill" label={chipSelector(applications?.calculated_status)} />}
          {applications?.calculated_status === "CREATING_CONTRACT" && <Button variant="mj_primary_md">Signer</Button>}

          {(applications?.calculated_status === "ACCEPTED" || applications?.calculated_status === "DOCUMENTS_PENDING_VALIDATION") && <MyChip icon={ArrowUpGreen} variant="ended_offer" label={chipSelector(applications?.calculated_status)} />}
          {applications?.admin_actions?.map((action) => (
            <Box key={action} flexDirection="row" display="flex" gap="10px" alignItems="center">
              {action === "SIGNED" && (
                <Button variant="mj_primary_md" onClick={() => setOpenAccepter(true)}>
                  Signer
                </Button>
              )}
              {action === "ACCEPTED" && (
                <Button variant="mj_primary_md" onClick={() => setOpenAccept(true)}>
                  Recruter
                </Button>
              )}
              {action === "PENDING" && (
                <Button variant="mj_primary_md" onClick={() => PushEnAttente(id)}>
                  En attente
                </Button>
              )}
              {action === "REFUSED" && <ButtonSquare action="refuse" onClick={() => setOpenRefuser(true)} />}
            </Box>
          ))}
        </Box>
      </Box>

      {/* tabs */}
      <Box display="flex" sx={{ backgroundColor: "#FAFAFB", borderRadius: "6px", padding: "2px", flexDirection: { md: "row", xs: "column" } }} gap="6px" maxWidth="800px">
        {tabs.map((tab, index) => (
          <Button disableRipple fullWidth key={index} variant={selectedTab === tab.id ? "mj_primary_choice_active_lg" : "mj_primary_choice_lg"} onClick={() => setSelectedTab(tab.id)}>
            <Typography fontSize="20px" mr>
              {tab.icon}
            </Typography>
            {tab.name}
          </Button>
        ))}
      </Box>

      <Modal open={openRefuser} onClose={() => setOpenRefuser(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Refuser la candidature
              </Typography>
              <ButtonSquare onClick={() => setOpenRefuser(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                motif: "",
                selected: "Autre",
              }}
              onSubmit={(values) => {
                RejeterCandidature(id, values.selected, values.motif);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Typography variant="text_normal" fontWeight="600" htmlFor="description">
                      Motif du refus
                    </Typography>
                    <FormControl>
                      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={values.selected} onChange={(e) => setFieldValue("selected", e.target.value)}>
                        {motifsR?.map((mot) => (
                          <FormControlLabel value={mot?.id} control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label={mot?.name} />
                        ))}
                        <FormControlLabel value="Autre" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Autre" />
                      </RadioGroup>
                    </FormControl>
                    {values.selected === "Autre" && (
                      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description">
                            Plus de détails
                          </Typography>

                          <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field name="motif" type="input" placeholder="Veuillez donner plus de détails..." id="description" as={MyTextField} multiline fullWidth columns={5} rows={4} autoComplete="off" />
                        <ErrorMessage component={FormikErrorText} name="description" />
                      </Box>
                    )}
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenRefuser(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values.selected === "Autre" && values.motif === "" ? true : false} type="submit" variant="mj_danger_md">
                        Confirmer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openAccepter} onClose={() => setOpenAccepter(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Signer le contrat
              </Typography>
              <ButtonSquare onClick={() => setOpenAccepter(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={(values) => {
                AccepterCandidature(id, values.password);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Typography sx={{ fontSize: "25px", fontWeight: "700", lineHeight: "160%" }}>Signature</Typography>
                    <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                      En introduisant votre mot de passe, vous confirmez avoir lu et accepté le contrat #{applications?.contract?.id}
                    </Typography>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Mot de passe
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder="Saisir votre mot de passe"
                              autoComplete="Off"
                              type={showPasswordDel ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPasswordDel} onMouseDown={handleMouseDownPasswordDel} edge="end">
                                      {showPasswordDel ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                        {errors.password && touched.password ? (
                          <Typography color="red" fontSize="12px">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenAccepter(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.password?.length > 7 ? false : true} type="submit" variant="mj_green_md">
                        Signer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      {/* content */}
      {contentSelector(selectedTab)}
    </Box>
  );
}

export default CandidatureDetail;
