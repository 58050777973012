import { createContext, useState, useReducer, useEffect } from "react";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import MjLabel from "../../components/ui/MjLabel";
import MjErrorText from "../../components/ui/MjErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";

function MonCompte() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [error1, setError1] = useState("");
  const [success1, setSuccess1] = useState("");
  //form config info personnelles
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const initialValues = {
    nom: user?.user?.last_name,
    prenom: user?.user?.first_name,
    email: user?.user?.email,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Format email invalid").required("Ce champ est obligatoire"),
    nom: Yup.string().required("Ce champ est obligatoire"),
    prenom: Yup.string().required("Ce champ est obligatoire"),
  });

  //form config password change
  const initialValues2 = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema2 = Yup.object({
    oldPassword: Yup.string().required("Ce champ est obligatoire"),
    password: Yup.string()
      .required("Ce champ est obligatoire")
      .notOneOf([Yup.ref("oldPassword"), null], "Le nouveau mot de passe doit être différent de l'ancient"),
    confirmPassword: Yup.string()
      .required("Ce champs est obligatoire")
      .oneOf([Yup.ref("password"), null], "Le mot de passe ne correspond pas"),
  });
  const updateMotDePasse = (values) => {
    console.log(values);
    Api.patch(
      Urls.UPDATE_PASSWORD + user?.user?.id + "/password",
      {
        old_password: values.oldPassword,
        new_password: values.password,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setSuccess("Mot de passe changé avec succés");
        setError("");
        setTimeout(() => {
          setSuccess("");
          setError("");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setSuccess("");
        setError(err.response.data.message);
        setTimeout(() => {
          setSuccess("");
          setError("");
        }, 3000);
      });
  };
  const updateUser = (values) => {
    Api.patch(
      Urls.UPDATE_ADMIN + user?.user?.id,
      {
        first_name: values.prenom,
        last_name: values.nom,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setSuccess1("Informations modifiées avec succés");
        setError1("");
        const user = JSON.parse(localStorage.getItem("userAdmin"));
        const access_token = user?.access_token;
        console.log(user, "from USER CONTEXTE");
        let userData = null;

        if (access_token) {
          Api.get(Urls.GET_USER, {
            headers: {
              "Content-type": "application/json",
              authorization: `Bearer ${access_token}`,
            },
          })
            .then((response) => {
              if (response?.data?.status === "success") {
                userData = response?.data?.data?.user;
                console.log("USER CONTEXT USERDATA", userData);

                //get the full user from ls

                const userLS = JSON.parse(localStorage.getItem("userAdmin"));
                console.log("MY USER LOCAL STORAGE", userLS);
                userLS.user = userData;
                console.log("MY FULL USER AFTER APPEND", userLS);
                localStorage.setItem("userAdmin", JSON.stringify(userLS));
              }
            })
            .catch((e) => console.log(e.message));
        }
        setTimeout(() => {
          setSuccess1("");
          setError1("");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setSuccess1("");
        setError1(err.response.data.message);
        setTimeout(() => {
          setSuccess1("");
          setError1("");
        }, 3000);
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" sx={{ marginBottom: "1rem" }}>
          Mon compte
        </Typography>
      </Box>

      {/* Form1  Section */}
      <Box className="info-personelles" sx={{ marginBottom: "2rem" }}>
        <Typography variant="h5" component="h1" mb>
          Informations personnelles
        </Typography>
        <Formik onSubmit={updateUser} initialValues={initialValues} validationSchema={validationSchema}>
          {({ values }) => (
            <Form>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="nom" htmlFor="nom">
                  Nom
                </MjLabel>
                <Field name="nom" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="nom" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="prenom" htmlFor="">
                  Prénom
                </MjLabel>
                <Field name="prenom" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="prenom" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="email" htmlFor="">
                  Email
                </MjLabel>
                <Field disabled name="email" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="email" component={MjErrorText} />
              </Box>
              <Box>
                <Box margin="1rem 0rem" maxWidth="800px">
                  {error1 ? <Alert severity="error">{error1}</Alert> : null}
                  {success1 ? <Alert severity="success">{success1}</Alert> : null}
                </Box>
                <Button type="submit" variant="mj_primary">
                  Mettre à jour
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      {/* end form1 section */}

      {/* Form2  Section */}
      <Box className="info-personelles">
        <Typography variant="h5" component="h1" sx={{ marginBottom: "1rem" }}>
          Mot de passe
        </Typography>
        <Formik onSubmit={updateMotDePasse} initialValues={initialValues2} validationSchema={validationSchema2}>
          {({ values }) => (
            <Form>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="oldPassword" htmlFor="oldPassword">
                  Ancien mot de passe
                </MjLabel>
                <Field type="password" name="oldPassword" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="oldPassword" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="password" htmlFor="password">
                  Mot de passe
                </MjLabel>
                <Field type="password" name="password" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="password" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="confirmPassword" htmlFor="confirmPassword">
                  Confirmer mot de passe
                </MjLabel>
                <Field type="password" name="confirmPassword" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="confirmPassword" component={MjErrorText} />
              </Box>
              <Box>
                <Box margin="1rem 0rem" maxWidth="800px">
                  {error ? <Alert severity="error">{error}</Alert> : null}
                  {success ? <Alert severity="success">{success}</Alert> : null}
                </Box>
                <Button type="submit" variant="mj_primary">
                  Mettre à jour
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      {/* end form2 section */}
    </Box>
  );
}

export default MonCompte;
