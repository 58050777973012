import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Alert, FormControl, Select, MenuItem, Autocomplete } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
import MjErrorText from "../../../components/ui/MjErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function AddAdminAccount({ idAdmin }) {
  const params = useParams();
  // console.log("PARAMS = ", params);
  const location = useLocation();
  // console.log("LOCATION = ", location);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [succes, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [searchedAgency, setSearchedAgency] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchedRole, setSearchedRole] = useState("");

  const onSubmitChangePassword = (values) => {
    console.log("adding admin");
    Api.patch(
      Urls.CHANGE_PASSWORD_ADMIN + `/${idAdmin}/password`,
      {
        new_password: values.password,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log("", res);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log("error object", err);
        toast.error(err.response.data.message);
      });
  };

  const initialValues = {
    password: "",
    confir_password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Ce champ est obligatoire"),
    confirm_password: Yup.string()
      .required("Ce champ est obligatoire")
      .oneOf([Yup.ref("password"), null], "Les mots de passe ne correspondent pas"),
  });

  return (
    <Box>
      <ToastContainer />
      {/* Form Section */}
      <Box className="filter">
        <Formik onSubmit={onSubmitChangePassword} initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true}>
          {({ values }) => (
            <Form>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="password" htmlFor="password">
                  Mot de passe
                </MjLabel>
                <Field name="password" autoComplete="off" as={TextField} fullWidth type="password" />
                <ErrorMessage name="password" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="confirm_password" htmlFor="">
                  Confirmer mot de passe
                </MjLabel>
                <Field name="confirm_password" autoComplete="off" as={TextField} fullWidth type="password" />
                <ErrorMessage name="confirm_password" component={MjErrorText} />
              </Box>

              <Box>
                <Button type="submit" variant="mj_green_md">
                  Mettre à jour
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default AddAdminAccount;
