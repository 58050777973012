export const Urls = {
  //auth urls
  LOGIN_URL: "/admin/auth/login",
  GET_USER: "/get/user",
  GET_USER_RESOURCE: "/auth-user-resource",
  LOGOUT: "/auth/logout",
  //messagerie urls :
  GET_MESSAGES: "/messages",
  // get list of users that have messages
  GET_LIST_USERS_MESSAGES: "/admin/users-with-messages",
  //send messages
  SEND_MESSAGE: "/messages",
  //liste entreprise
  GET_LIST_ENTREPRISE: "/admin/enterprises",
  //get forme juridique
  GET_FORME_JURIDIQUE: "/forme-juridiques",
  //update status of entreprise
  PATCH_STATUS_ENTREPRISE: "/admin/users/verification_data_status",
  // get details entreprise
  GET_DETAILS_ENTREPRISE: "/admin/entreprises/",
  // get offres
  GET_OFFRES: "/admin/offres",
  //patch status offre
  PATCH_STATUS_OFFRE: "/admin/verify-offre",
  //supprimer des offres
  DELETE_OFFERS: "/admin/offres",
  // get motifs
  GET_MOTIFS: "/get/motifs",
  //Create offer
  GET_METIERS_SEARCH: "/metiers",
  GET_HARDSKILLS_SEARCH: "/hardSkills",
  GET_SOFTSKILLS_SEARCH: "/softSkills",
  GET_CERTIFICATIONS_SEARCH: "/certificateDocumentTypes",
  GET_EQUIPMENTS_SEARCH: "/equipment",
  //modify profile v2
  MODIFY_INTERIMAIRE: "/users/",
  MODIFY_PROFILE_PIc: "/users/",
  //get user document by tag v2
  GET_USER_DOCUMENT: "/users/",
  //post use documnets v2
  POST_USER_DOCUMENTS: "/users/",
  //patch profile utilisateur v2
  PATCH_PROFILE_UTILISATEUR: "/profile/",
  //get user candidatures
  GET_LISTE_CANDIDATURES: "/users/",
  //patch use Doccuments v2
  PATCH_USER_DOCUMENTS: "/userDocuments/",
  //delete user documents v2
  DELETE_USER_DOCUMENTS: "/schoolDocuments/",
  //search certificate v2
  SEARCH_CERTIFICATE: "/certificateDocumentTypes",
  //search scolarite v2
  SEARCH_SCHOOLING: "/schoolDocumentTypes",
  //add experience v2
  ADD_EXPERIENCEs: "/users/",
  //liste des experience v2
  LISTE_EXPERIENCE: "/users/",
  //get hqrd skills v2
  GET_HARD_SKILLS: "/hardSkills",
  //get SOFT skills v2
  GET_SOFT_SKILLS: "/softSkills",
  //get soft skills v2
  GET_SKILLS: "/skills",
  //get user hardSkills v2
  GET_USER_HARD_SKILLS: "/users/",
  //put hard skills v2
  PUT_HARD_SKILLS: "/users/",
  //put soft skills v2
  PUT_SKILLS: "/users/",
  //get user softSkills v2
  GET_USER_SKILLS: "/users/",
  // update experience v2
  UPDATE_EXPERIENCE: "/experiences/",
  //delete experience v2
  DELETE_EXPERIENCE: "/experiences/",
  //add user metier v2
  ADD_USER_METIER: "/users/",
  //get metier autocomplete V2
  GET_METIER_AUTO: "/metiers",
  //get user metier v2
  GET_USER_METIER: "/users/",
  //update user metier v2
  UPDATE_USER_METIER: "/users/",
  //delete user metier v2
  DELETE_USER_METIER: "/users/",
  //ajouter un certificat v2
  POST_CERTIFICTAE: "/certificateDocuments",
  //update certificate v2
  UPDATE_CERTIFICATE: "/certificateDocuments/",
  //delete certificate v2
  DELETE_CERTFIIFCATE: "/certificateDocuments/",
  //get user certificates v2
  GET_CERTIFICATE: "/certificateDocuments",
  //create scolariter v2
  POST_SCOLARITE: "/schoolDocuments",
  //UPDATE SCOLARITE v2
  UPDATE_SCOLARITER: "/schoolDocuments/",

  //create offre
  CREATE_OFFRE: "/offers",
  //delete scolariter v2
  DELETE_SCOLARITE: "/schoolDocuments/",
  //GET SCOLARITER v2
  GET_SCOLARITE: "/schoolDocuments",
  //ajouter carte didentite v2
  ADD_IDENTITY_CARD: "/nationalIdentityCards",
  //update carte identite v2
  UPDATE_IDENTITY_CARD: "/nationalIdentityCards/",
  //ajouter passport v2
  ADD_PASSPORT: "/passports",
  //update passport v2
  UPDATE_PASSPORT: "/passports/",
  //ajouter titre de sejour
  ADD_TITRE_SEJOUR: "/residencePermits",
  //update titre de sejour v2
  UPDATE_TITRE_SEJOUR: "/residencePermits/",
  //ajouter res titre de sejour V2
  ADD_RES: "/residencePermitReceipts",
  //update res v2
  UPDATE_RES: "/residencePermitReceipts/",
  //ajouter carte vital
  ADD_VITAL_CARD: "/vitalCards",
  //UPDATE carte vital
  UPDATE_VITAL_CARD: "/vitalCards/",
  //ADD securiter social v2
  ADD_SECURITE_SOCIAL: "/socialSecurityCertificates",
  //update sec social v2
  UPDATE_SEC_SOCIAL: "/socialSecurityCertificates/",
  //add justif dom v2
  ADD_JUSTIF_DOM: "/addressProofs",
  //UPDATE JUSTIF DOM V2
  UPDATE_JUSTIF_DOM: "/addressProofs/",
  //ADD MUTUELLE v2
  ADD_MUTUELLE: "/mutuals",
  //UPDATE MUTUELLE v2
  UPDATE_MUTUELLE: "/mutuals/",
  //ADD MED VISITE v2
  ADD_MEDICAL_VISIT: "/medicalVisits",
  //UPDATE MEDICAL VISIT v2
  UPDATE_MEDICAL_VISIT: "/medicalVisits/",
  //get user V2
  GET_USER_REC: "/admin/users/",
  //ajouter permis
  ADD_PERMIS: "/drivingLicenses",
  //update permis
  UPDATE_PERMIS: "/drivingLicenses/",
  //delete Permis v2
  DELETE_PERMIS: "/drivingLicenses/",
  //modifier mot de passe v2
  UPDATE_PASSWORD_USER: "/users/",
  //update profile entreprise v2
  UPDATE_PROFILE_ENTREPRISE: "/enterprises/",
  //update lgoo entreprise v2
  UPDATE_LOGO_ENTREPRISE: "/enterprises/",
  //get forme juridique v2
  GET_FORME_JURIDIQUE: "/forme-juridiques",
  //sign mandat sepa v2
  SIGN_MADNDAT_SEPA: "/enterprises/",
  //get list offer v2
  GET_OFFRE_LIST: "/offers",
  //get details offre v2
  GET_DETAILS_OFFRE: "/offers/",
  //postuler a une offre
  POSTULER_OFFRE_ID: "/users/",
  //update candidatures v2
  UPDATE_CANDIDATURE: "/applications/",
  //get motif de refus v2
  GET_MOTIF_REFUS: "/interimRefusalReasons",
  //get detail contrat
  GET_DETAIL_CONTRAT: "/contracts/",
  //get missions v2
  GET_LIST_MISSION: "/missions",
  //show mission v2
  SHOW_MISSION: "/missions/",
  //get user documents v2
  //get entreprise by id v2
  GET_ENTREPRISE: "/enterprises/",
  //get contrats v2
  GET_CONTRATS: "/contracts",
  //get profile proposés v2
  PROFILE_PROPOS: "/offers/",
  //suspendre l'offre v2
  SUSPEND_OFFER: "/offers/",
  //get facteur de penibilite V2
  GET_PENIBILITE_FACTORS: "/arduousnessFactors",
  //GET risques pro v2
  GET_RISQUES_PRO: "/professionalRisks",
  //get raison de recrutement v2
  GET_RAISON_RECUTEMENT: "/recruitmentReasons",
  //GET RAISON DE REFUS v2
  GET_REFUSAL_REASONS_ENTREPRISE: "/enterpriseRefusalReasons",
  //get lestes des embauchers v2
  GET_EMBAUCHER: "/offers/",
  // get profile utilisateur v2
  GET_PROFILE_IMTERIM: "/applications/",
  //rate a mission v2
  RATE_INTERIM: "/rate-interim",
  //get details profile embaucher v2
  GET_DETAIL_INTERIM_EMBAUCHER: "/offers/",
  //get la liste des problems v2
  GET_LISTE_PROBS: "/admin/problems",
  //declqrer un probléme v2
  DECLACRE_PROB: "/contracts/",
  // suspendre un contrat v2
  SUSPEND_CONTRAT: "/contracts/",
  //avenant de prolongation v2
  PROLONGATION_VALUE_AVENANT: "/contracts/",
  //get interims for filter v2
  GET_LIST_INTERIMS_ENT: "/enterprises/",
  // modify profile v2
  MODIFY_PROFILE: "/profiles/",
  //get declared hours,
  DECLARED_HOURS: "/contracts/",
  //declarer les heures v2
  DECLARE_HOURS: "/contracts/",
  //get fiche de paie v2
  GET_FICHE_DE_PAIE: "/users/",
  //GET ENTREPRISE FACTUR v2
  GET_ENTREPRISE_FACTURE: "/enterprises/",
  //Signer contrat avec id contrat v2
  SIGN_CONTRAT_ID: "/contracts/",
  //detail contrat declarer les heures v2
  DETAIL_DECLARE_HOURS: "/contracts/",
  // get liste interims by entreprise v2
  GET_LISTE_INTERIMS_BY_ENTREPRISE: "/enterprises/",
  // get contrats to sign v2
  GET_CONTRAT_SIGN: "/users/",
  // get statistics v2
  GET_STATISTICS: "/users/",
  // contrat to declare hours v2
  DECLARE_HOURS_CONTRACTS: "/users/",
  //today work interim v2
  TODAY_WORK_INTERIM: "/users/",
  //send a message V2
  SEND_MESSAGE_POST: "/messages",
  // get messages
  GET_MESSAGES: "/messages",
  //get forme juridique
  // get departement
  GET_DEPARTEMENT: "/get/location/departements",
  // get ville par departement
  GET_VILLE_DEPARTEMENT: "/get/location/villes/departement",
  //GET DETAILS OFFRES
  GET_DETAILS_OFFRES: "/admin/offres/",
  //get secteur d'activiter
  GET_SECTEUR_ACTIVITE: "/get/secteur-activites",
  //get metier
  GET_METIERS: "/get/metiers",
  //GET equipement obligatoire ,
  GET_EQUIPEMENT: "/get/equipement-obligatoires",
  //get risques professionnnels
  GET_RISQUE_PROFESSIONNEL: "/get/risque-professionnelles",
  //get diplome
  GET_DOCUMENTS: "/get/documents/metier-",
  //mise a jour des details dune offre
  PATCH_DETAILS_OFFRE: "/admin/offres/",
  // get listes renard
  GET_LISTE_RENARD: "/admin/interims",
  // update status renard
  UPDATE_STATUS_RENARD: "/admin/users/verification_data_status",
  // get renard infos
  GET_RENARD_INFOS: "/admin/renards/",
  // upload file
  UPLOAD_FILE: "/admin/files/",
  //delete file :
  DELETE_FILE: "/admin/files/",
  //create file
  CREATE_FILE: "/admin/users/",
  //get renard cv
  GET_RENARD_CV: "/admin/renards/",
  //update renard metier
  UPDATE_RENARD_METIER: "/admin/renards/",
  //creer experience
  CREATE_EXP: "/admin/renards/",
  //UPDATE EXPERIENCE
  UPDATE_EXP: "/admin/experiences/",
  //supprimer experience
  DELETE_EXP: "/admin/experiences/",
  // ajouter formation
  CREATE_FORM: "/admin/renards/",
  //supprimer formation
  DELETE_FORM: "/admin/formations/",
  //update formation
  UPDATE_FORM: "/admin/formations/",
  // get liste candidature
  GET_LISTE_CANDIDATURE: "/admin/candidatures",
  // refuser une candidature
  REFUSE_CANDIDATURE: "/admin/refuse-candidatures",
  // get detail candidature
  GET_DETAILS_CANDIDATURE: "/admin/candidatures/",
  // get liste contrat
  GET_LISTE_CONTRAT: "/admin/contrats",
  //signer des contrat
  SIGN_CONTRAT: "/admin/signer-contrats",
  //get contrat et avenants details
  GET_CONTRAT_DETAILS: "/contracts/",
  // get contrat declarer un probleme
  GET_LISTE_CONTRAT_DECLARER_PROBLEMES: "/admin/contrats-to-declare-work",
  //detail contrat declarer probleme
  GET_DETAILS_CONTRAT_DECLARER_PROBLEME: "/admin/contrats/",
  //declarer heures
  DECLARE_WORK: "/admin/contrats/",
  //get liste problemes
  GET_LISTE_PROBLEMS: "/admin/problems",
  //refuser probleme
  REFUSE_PROBLEM: "/admin/refuser-problems",
  //suspendre contrat
  SUSPEND_CONTRAT_PROBLEM: "/admin/suspendre-contrats",
  //details dun probleme
  GET_DETAILS_PROBLEME: "/admin/problems/",
  //listes acompte
  GET_LISTE_ACOMPTE: "/admin/acomptes",
  //accepter acopmte
  ACCEPT_ACOMPTE: "/admin/accepter-acomptes",
  //refuser acopmte
  REFUSE_ACOMPTE: "/admin/refuser-acomptes",
  //detail dun acompte
  GET_ACOMPTE_DETAIL: "/admin/acomptes/",
  //liste fiches de paie
  GET_LISTE_FICHE_DE_PAIE: "/admin/fichedepaies",
  //payer fiche de paie
  PAYER_FICHE_DE_PAIE: "/admin/payer-fichedepaie",
  //get detail fiche de paie
  GET_DETAILS_FICHE_DE_PAIE: "/admin/fichedepaies/",
  //get factures
  GET_LISTE_FACTURE: "/invoices",
  //get liste fiche de paie
  GET_LISTE_FP: "/payslips",
  //payer factures
  PAYER_FACTURE: "/admin/payer-factures",
  //get details facture
  GET_DETAILS_FACTURE: "/admin/factures/",
  //liste des admins
  GET_LISTE_ADMIN: "/admin/admins",
  //delete admin
  DELETE_ADMIN: "/admin/admins",
  //active or suspend admin
  ACTIVE_OR_SUSPEND_ADMIN: "/admin/active-suspend-admins",
  //update admin user
  UPDATE_ADMIN: "/admin/users/",
  //create admin
  CREATE_ADMIN: "/admin/admins",
  //update user password
  UPDATE_PASSWORD: "/admin/users/",
  //telecharger fichier pem
  DOWNLOAD_PEM_FILE: "/admin/download-pem-fichedepaies",
  // Creer un proposition commercial
  CREATE_COM_PROP: "/admin/enterprises/",
  //modify facture
  MODIFY_FACTURE: "/invoices/",
  //modify fiche de paie
  MODIFY_FICHE_DE_PAIE: "/payslips/",
  //create interim potentiel
  CREATE_INTERIMAIRE_POT: "/admin/interims",
  //get liste agence
  GET_AGENCES: "/admin/agencies",
  //delete agencies
  DELETE_AGENCIES: "/admin/agencies",
  //create agence
  CREATE_AGENCE: "/admin/agencies",
  //UPDATE AGENCE
  UPDATE_AGENCE: "/admin/agencies/",
  //update user agency
  UPDATE_USER_AGENCY: "/users/",
  UPDATE_COM_PROP: "/admin/commercialPropositions/",
  //get commercial proposition
  GET_COM_PROPOSITION: "/admin/commercialProposalNotifications",
  //treat demande
  TREATE_DEMANDE: "/admin/commercialProposalNotifications/",
  //sign contrat admin
  SIGNER_CONTRAT_ADMIN: "/admin/contracts/",
  //sign enterprise and interim contrat
  SIGNER_CONTRAT_INT_ENT: "/contracts/",
  //add work certificate
  ADD_WORK_CERTIF: "/admin/users/",
  //get roles admin v2
  GET_ROLES: "/admin/roles",
  //get advances
  GET_ADVANCES: "/advances",
  //get advance refusal reasons
  GET_REFUSAL_REASONS: "/advanceRefusalReasons",
  //update advance
  UPDATE_ADVANCE: "/admin/advances/",
  //suspendre un contrat
  SUSPEND_ADMIN_CONTRAT: "/admin/contracts/",
  //Listes contrat declarer heures
  GET_CONTRAT_ADMIN_DECALRE_HOURS: "/admin/contracts-declared-hours",
  //get interims by enterprise
  GET_INTERIMS_BY_ENTERPRISE: "/enterprises/",
  //Changer mot de pass admin
  CHANGE_PASSWORD_ADMIN: "/users",
  //get list offers admin
  GET_ADMIN_OFFERS: "/admin/offers",
  //verify offer
  VERIFY_OFFER: "/admin/verify-offers",
  //open or close offer
  OPEN_CLOSE_OFFER: "/admin/open-or-close-applications-for-offers",
  ///activate or deactivate offers
  ACTIATE_DEACTIVATE_OFFER: "/admin/activate-or-deactivate-offers",
  //get interims by offer
  GET_INTERIMS_BY_OFFER: "/admin/offers/",
  // get applications
  GET_CANDIATURE: "/admin/applications",
  //get entretiens
  GET_ENTRETIENS: "/admin/interviews/",
  //get contracts
  GET_ADMIN_CONTRACTS: "/admin/contracts",
};
