import React from "react";
import { colors } from "../../theme/Colors";
import { Container, Box, Stack, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";

import IconInformations from "../../imgs/c_infos.svg";
import IconInfoSupp from "../../imgs/c_infosupp.svg";
import IconPoleEmploi from "../../imgs/c_poleemploi.svg";
import IconSecurite from "../../imgs/c_securite.svg";
import IconMonCompteC from "../../imgs/c_moncompte.svg";
import IconMonCompteE from "../../imgs/e_moncompte.svg";
import IconValide from "../../imgs/valide.svg";
import IconMonCV from "../../imgs/c_cv.svg";
import IconCreerOffres from "../../imgs/e_creeroffres.svg";
import IconMesOffres from "../../imgs/e_mesoffres.svg";
import IconAgenda from "../../imgs/c_agenda.svg";
import IconMissions from "../../imgs/missions.svg";
import IconContrat from "../../imgs/c_cv.svg";
import IconInfos from "../../imgs/c_infos.svg";
import iconFaq from "../../imgs/faq.svg";
import IconVilles from "../../imgs/c_location.svg";
import IconEntreprise from "../../imgs/c_infosupp.svg";
import IconPageEntreprise from "../../imgs/entreprise_detail.svg";
import IconPanier from "../../imgs/panier.svg";
import IconDeclarerHeure from "../../imgs/C_dec_heures.svg";
import IconProb from "../../imgs/probleme.svg";
import IconOffresEnCours from "../../imgs/c_offre_en_cours.svg";
import IconOffresSuggeres from "../../imgs/c_offres_suggeres.svg";
import IconCandidatures from "../../imgs/c_candidatures.svg";
import IconFichePaie from "../../imgs/c_fichepaie.svg";
import IconDacompte from "../../imgs/c_acompte.svg";
import IconMessagerie from "../../imgs/c_messagerie.svg";
import IconBell from "../../imgs/C_bell.svg";
const icons = {};
icons["c_candidatures"] = IconCandidatures;
icons["securite"] = IconSecurite;
icons["infosupp"] = IconInfoSupp;
icons["poleemploi"] = IconPoleEmploi;
icons["informations"] = IconInformations;
icons["c_moncompte"] = IconMonCompteC;
icons["e_moncompte"] = IconMonCompteE;
icons["valide"] = IconValide;
icons["c_moncv"] = IconMonCV;
icons["e_creeroffres"] = IconCreerOffres;
icons["e_mesoffres"] = IconMesOffres;
icons["c_agenda"] = IconAgenda;
icons["c_location"] = IconVilles;
icons["missions"] = IconMissions;
icons["c_cv"] = IconContrat;
icons["c_infos"] = IconInfos;
icons["faq"] = iconFaq;
icons["?mark"] = IconEntreprise;
icons["entreprise_detail"] = IconPageEntreprise;
icons["panier"] = IconPanier;
icons["dec_heure"] = IconDeclarerHeure;
icons["probleme"] = IconProb;
icons["c_offres_en_cours"] = IconOffresEnCours;
icons["c_offres_suggeres"] = IconOffresSuggeres;
icons["c_fichepaie"] = IconFichePaie;
icons["c_acompte"] = IconDacompte;
icons["c_messagerie"] = IconMessagerie;
icons["C_bell"] = IconBell;
function MainTitle({ title, size, icon, iconWidth, margin, color, marginIcon, fontWeight }) {
  let mIcon = "15px";
  let fWei = "500";
  if (marginIcon) {
    mIcon = marginIcon;
  }
  if (fontWeight) {
    fWei = fontWeight;
  }
  return (
    <Box sx={{ margin: `${margin}` }} display="flex" flexDirection="row" alignItems="center" marginBottom="1.5rem">
      {icon && <img style={{ width: `${iconWidth}px`, marginRight: `${mIcon}` }} src={icons[`${icon}`]} />}
      <Typography color={color} variant={size} fontWeight={fWei}>
        {title}
      </Typography>
    </Box>
  );
}

export default MainTitle;
