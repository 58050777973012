import React, { useMemo, useState } from "react";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { Avatar, Box, Button, Divider, Modal, Typography, FormControlLabel, Radio, RadioGroup, FormControl, TextField, Checkbox, IconButton, CircularProgress, MenuItem, Select, InputLabel, TableRow, Table, TableContainer, TableHead, TableBody, Alert, OutlinedInput } from "@mui/material";
import { colors } from "../../../theme/Colors";
import { modalStyle, modalStyle2 } from "../../../theme/ModalStyle";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import dropDownIcon from "../../../imgs/imgV2/DropDownIcon.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import styled from "@emotion/styled";

import MyTextField from "../../../components/ui/MyTextField";
import endicapeIcon from "../../../imgs/imgV2/endicapeIcon.svg";
import locationIcon from "../../../imgs/imgV2/LocationIcon.svg";
import EmailIcon from "../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../imgs/imgV2/TelIcon.svg";
import WebSiteIcon from "../../../imgs/imgV2/website.svg";
import JoditEditor from "jodit-react";
import { PDFDownloadLink, Document, Page, Text, View } from "@react-pdf/renderer";
import PersonIcon from "@mui/icons-material/Person";
import BirthIcon from "../../../imgs/imgV2/birthdayIcon.svg";
import MyDatePicker from "../../../components/ui/MyDatePicker";
import MyDP from "../../../components/ui/MyDP";
import checkLinkicon from "../../../imgs/imgV2/checkLinkIcon.svg";
import { useRef } from "react";
import * as Yup from "yup";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { toast, ToastContainer } from "react-toastify";
import Html from "react-pdf-html";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEffect } from "react";
import MjErrorText from "../../../components/ui/MjErrorText";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DEDFE4",
    color: `#0D173A`,
    fontSize: 12,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: `#666D83`,
    fontWeight: "500",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));
//SOME STYLING
const StyledTable = styled(Table)({
  borderRadius: "6px",
});
function PropositionCom({ userFetch, idEnt, userId, change, setChange, modify }) {
  const { user } = useAuthContext();
  const editor = useRef(null);
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [openModifyPropCom, setOpenModifyProp] = useState(false);
  const [openModifyAgenceAffilier, setOpenModifyAgence] = useState(false);
  const [coeff, setCoeff] = useState("");
  const pdfRef = useRef();
  const [TauxH, setTauxH] = useState("");
  const validationSchema = Yup.object().shape({
    coeff: Yup.number().typeError("Valeur invalide").positive("Valeur invalide").required("Ce champ est obligatoire"),
  });

  const [intitialValuesProp, setInValuesProp] = useState({
    coeff: "",
    close: "",
  });
  const [initialvaluesAg, setIntitalValuesAG] = useState({
    agence: "",
  });
  useEffect(() => {
    setInValuesProp({
      coeff: userFetch?.enterprise?.commercial_proposition?.value ? userFetch?.enterprise?.commercial_proposition?.value : "",
      close: userFetch?.enterprise?.commercial_proposition?.clause ? userFetch?.enterprise?.commercial_proposition?.clause : "",
    });
    setIntitalValuesAG({
      agence: userFetch?.enterprise?.agency?.id ? userFetch?.enterprise?.agency?.id : "",
    });
  }, [userFetch]);
  const configFr = useMemo(() => {
    return {
      height: "400px",
      language: "fr",
      maxWidth: "80%",
      readonly: false,
      placeholder: "Clause textuelle...",
      //buttons: ["bold", "italic", "link", "unlink", "underline", "ul", "ol", "font", "fontSize", "paragraph", "hr", "print"],
    };
  }, []);
  const addPropCom = (values) => {
    console.log(idEnt);
    setError("");
    Api.post(
      Urls.CREATE_COM_PROP + idEnt + "/commercialPropositions",
      {
        value: values?.coeff,
        clause: values?.close,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setOpenModifyProp(false);
        setError("");
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        setError(err?.response.data.message);
      });
  };
  const updateProp = (values) => {
    console.log(idEnt);
    setError("");
    Api.patch(
      Urls.UPDATE_COM_PROP + userFetch?.enterprise?.commercial_proposition?.id,
      {
        value: values?.coeff,
        clause: values?.close,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setOpenModifyProp(false);
        setError("");
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        setError(err?.response.data.message);
        toast.error(err?.response?.daat?.message);
      });
  };
  const [agences, setAgence] = useState([]);

  useEffect(() => {
    Api.get(Urls.GET_AGENCES + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setAgence(res?.data?.agencies);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const modifyAgency = (values) => {
    Api.patch(
      Urls.UPDATE_USER_AGENCY + userId,
      {
        agency_id: values.agence,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenModifyAgence(false);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const generatePDF = () => {
    // Create a new PDF document
  };

  return (
    <Box position="relative">
      <ToastContainer />
      <BoxContainer display="flex" flexDirection="column">
        <Box display="flex" marginBottom="20px" alignItems="center" justifyContent="space-between">
          <Typography variant="text_xxl" fontWeight="700">
            Proposition commerciale{" "}
          </Typography>
          {modify && <ButtonSquare action="modify" onClick={() => setOpenModifyProp(true)} />}
        </Box>
        {modify && <></>}
        {/*<>
            {userFetch?.enterprise?.commercial_proposition && (
              <Box marginBottom="20px" display="flex" alignItems="center" justifyContent="space-between">
                <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "22.4px" }}>Télécharger</Typography>
                <PDFDownloadLink
                  document={
                    <Document>
                      <Page>
                        <Text>Entreprise : {userFetch?.enterprise?.name}</Text>
                        <Text>Coefficient : {userFetch?.enterprise?.commercial_proposition?.value}</Text>
                        <Text>Clause : </Text>
                        <Html>{userFetch?.enterprise?.commercial_proposition?.clause}</Html>
                      </Page>
                    </Document>
                  }
                  fileName="proposition-com.pdf"
                >
                  {({ blob, url, loading, error }) => (loading ? "Loading document..." : <ButtonSquare action="download-orange" />)}
                </PDFDownloadLink>
              </Box>
            )}
            <Divider sx={{ marginBottom: "16px" }} />
                </>*/}

        <Box display="flex" flexDirection="column" gap="20px">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography fontSize="14px" fontWeight="500" lineHeight="22.4px">
              Service commercial de l’agence affiliée
            </Typography>
            {modify && <ButtonSquare action="modify" onClick={() => setOpenModifyAgence(true)} />}
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" alignItems="center" gap="4px">
              <img src={locationIcon} alt="location-icon" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.enterprise?.agency?.address ? userFetch?.enterprise?.agency?.address : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <img src={EmailIcon} alt="email-icon" />
              <Typography variant="text_clickable">{userFetch?.enterprise?.agency?.email ? userFetch?.enterprise?.agency?.email : ""}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="4px">
              <img src={TelIcon} alt="tel-icon" />
              <Typography variant="text_clickable">{userFetch?.enterprise?.agency?.phone_number ? userFetch?.enterprise?.agency?.phone_number : ""}</Typography>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
      <Modal open={openModifyAgenceAffilier} onClose={() => setOpenModifyAgence(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Agence affilié
              </Typography>
              <ButtonSquare onClick={() => setOpenModifyAgence(false)} action="close" />
            </Box>
            <Formik
              enableReinitialize={true}
              initialValues={initialvaluesAg}
              onSubmit={(values) => {
                modifyAgency(values);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box marginBottom="6px">
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Agence affilié
                    </Typography>
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                      *
                    </Typography>
                  </Box>

                  <Box>
                    <FormControl sx={{ maxWidth: "170px", minWidth: "170px", margin: "0.3rem 0rem 0rem 0rem" }}>
                      {values.agence ? null : <InputLabel>Agence </InputLabel>}
                      <Select placeholder="Forme juridique" fullWidth value={values.agence} onChange={(e) => setFieldValue("agence", e.target.value)} input={values.agence ? null : <OutlinedInput sx={{ color: "black" }} label="agence ..." />}>
                        <MenuItem key={0} value="">
                          Aucun Filtre
                        </MenuItem>
                        {agences.map((agence) => (
                          <MenuItem key={agence.id} value={agence.id}>
                            {agence.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box marginTop="24px" display="flex" gap="8px" justifyContent="flex-end" alignItems="center">
                    <Button variant="mj_secondary_md" onClick={() => setOpenModifyAgence(false)}>
                      Annuler
                    </Button>
                    <Button type="submit" variant="mj_primary_md" disabled={values?.agence ? false : true}>
                      {" "}
                      Enregistrer
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openModifyPropCom}
        onClose={() => {
          setError("");
          setOpenModifyProp(false);
        }}
      >
        <Box sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Proposition commerciale
              </Typography>
              <ButtonSquare
                onClick={() => {
                  setError("");
                  setOpenModifyProp(false);
                }}
                action="close"
              />
            </Box>
            <Formik
              enableReinitialize={true}
              initialValues={intitialValuesProp}
              onSubmit={(values) => {
                if (userFetch?.enterprise?.commercial_proposition) {
                  updateProp(values);
                } else {
                  addPropCom(values);
                }
              }}
              validationSchema={validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Coefficient
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.coeff} name="coeff" placeholder="Coefficient..." />
                        <ErrorMessage name="coeff" component={MjErrorText} />
                      </Box>
                    </Box>
                    <Box width="100%">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Clause textuelle
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                          *
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="0.5rem">
                        <JoditEditor
                          ref={editor}
                          value={values?.close}
                          config={configFr}
                          tabIndex={1} // tabIndex of textarea
                          onChange={(newContent) => {
                            setFieldValue("close", newContent);
                          }} // preferred to use only this option to update the content for performance reasons
                        />
                      </Box>
                    </Box>
                    <Box>{error && <Alert severity="error">{error}</Alert>}</Box>
                    <Box>
                      <Button disabled={values?.close && values?.coeff ? false : true} type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
            <Divider sx={{ height: "3px", margin: "24px 0px" }} />
            <Box marginBottom="24px" display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
              <Box>
                <Box marginBottom="6px">
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                    Taux horraire
                  </Typography>
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                    *
                  </Typography>
                </Box>
                <TextField onChange={(e) => setTauxH(e.target.value)} value={TauxH} placeholder="Taux horraire..." />
              </Box>
              <Box>
                <Box marginBottom="6px">
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                    Coefficient
                  </Typography>
                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                    *
                  </Typography>
                </Box>
                <TextField onChange={(e) => setCoeff(e.target.value)} value={coeff} placeholder="Coefficient..." />
              </Box>
            </Box>
            <TableContainer sx={{ zIndex: 1, borderRadius: "6px", border: `1px solid #DEDFE4` }}>
              <StyledTable aria-label="customized table">
                <TableHead sx={{ backgroundColor: "#DEDFE4" }}>
                  <TableRow>
                    <StyledTableCell>taux horraire</StyledTableCell>
                    <StyledTableCell>lfm</StyledTableCell>
                    <StyledTableCell width="10%" align="center">
                      cp
                    </StyledTableCell>
                    <StyledTableCell>sommes</StyledTableCell>
                    <StyledTableCell align="center">Charges Dont Net à Patronales filon payer</StyledTableCell>
                    <StyledTableCell align="center">cout total</StyledTableCell>
                    <StyledTableCell align="center">coeff</StyledTableCell>
                    <StyledTableCell align="center">montant facturé</StyledTableCell>
                    <StyledTableCell align="center">montant large</StyledTableCell>
                    <StyledTableCell align="center">taux de marque</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody position="relative">
                  <StyledTableRow>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH ? TauxH + 10 : ""}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH ? TauxH + 20 : ""}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH ? TauxH + 30 : ""}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH ? TauxH + 40 : ""}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH ? TauxH + 50 : ""}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{coeff}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH ? TauxH + 60 : ""}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH ? TauxH + 70 : ""}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography>{TauxH ? TauxH + 80 : ""}</Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </StyledTable>
            </TableContainer>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default PropositionCom;
