import React, { useEffect, useRef, useState } from "react";
import { Box, TextField, Button, Typography, Avatar, CircularProgress, Modal, Divider } from "@mui/material";
import Xmark from "../../../imgs/coface.png";
import ArrowRight from "../../../imgs/ArrowRight.svg";
import { useAuthContext } from "../../../hooks/useAuthContext";
import DoneIcon from "@mui/icons-material/Done";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Api from "../../../api/Api";
import Scolarite from "./Scolarite";
import MetierCV from "./MetierCV";
import Metier from "./Metier";
import InfosPersonnelle from "./InfosPersonnelle";
import InfoAdministrative from "./InfoAdministrative";
import Documents from "./Documents";
import Certification from "./Certification";

import { Urls } from "../../../api/Urls";
import { colors } from "../../../theme/Colors";
import { display } from "@mui/system";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import InterimAgency from "./InterimAgency";
import MyChip from "../../../components/ui/MyChip";

function InterimaireDetails({ modify = true, idInterimaire = null }) {
  const input = useRef();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const location = useLocation();
  const [doneCni, setdoneCni] = useState(false);
  const [donePE, setDonePe] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(0);
  const [cni, setCNI] = useState([]);
  const [poleEmploi, setPoleEmploi] = useState();
  const [loadingCni, setLoadingCni] = useState(false);
  const [loadingPE, setLoadingPE] = useState(false);

  const idInt = idInterimaire ? idInterimaire : location.pathname.split("/")[4];
  const [file, setFile] = useState();
  const [interimaire, setInterimaire] = useState();
  const validerRenard = () => {
    Api.patch(
      Urls.UPDATE_STATUS_RENARD,
      {
        user_ids: [idInt],
        status: "valid",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setSuccess("Validé avec succés");
        setError("");
        setTimeout(() => {
          setOpen(false);
          setSuccess("");
        }, 2000);
      })
      .catch((err) => {
        setSuccess("");
        console.log(err);
        setError("une erreur est survenue");
        setTimeout(() => {
          setOpen(false);
          setError("");
        }, 2000);
      });
  };
  const refuserRenard = () => {
    Api.patch(
      Urls.UPDATE_STATUS_RENARD,
      {
        user_ids: [idInt],
        status: "not_valid",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setSuccess("Refusé avec succés");
        setError("");
        setTimeout(() => {
          setOpen(false);
          setSuccess("");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setError("une erreur est survenue");
        setTimeout(() => {
          setOpen(false);
          setError("");
        }, 2000);
      });
  };
  const [userFetch, setUSerFetch] = useState({});
  useEffect(() => {
    Api.get(Urls.GET_USER_REC + idInt, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res, "user");
        setUSerFetch(res?.data);
      })
      .catch((err) => console.log(err));
  }, [change]);
  const [updateBord, setUpdateBoard] = useState(false);
  return (
    <Box>
      {modify && (
        <Box marginBottom="1rem">
          <Typography variant="h4" component="h1" mb>
            Gestion des intérimaires
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="24px">
        {modify && (
          <Box display="flex" gap="10px" alignItems="center">
            <Typography variant="h5" component="h1">
              Détail intérimaire
            </Typography>
            {userFetch?.user?.is_active === 0 && <MyChip variant="rejected_offer" label="Compte supprimé" />}
          </Box>
        )}
        {modify && (
          <Box>
            <Button
              variant="mj_primary_md"
              onClick={() =>
                navigate(location?.pathname + "/trouver-du-travail", {
                  state: {
                    nom: userFetch?.user?.last_name,
                    prenom: userFetch?.user?.first_name,
                  },
                })
              }
            >
              Trouver du travail
            </Button>
          </Box>
        )}
      </Box>
      <Box display="flex" width="100%" gap="24px" justifyContent="space-between" marginBottom="20px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
        <Box flex="3" display="flex" flexDirection="column" gap="24px">
          <InfosPersonnelle modify={modify} updateBord={updateBord} setUpdateBoard={setUpdateBoard} id={idInt} />
          <InfoAdministrative modify={modify} updateBord={updateBord} setUpdateBoard={setUpdateBoard} id={idInt} />
          <Documents userId={idInt} setChange={setChange} change={change} userFetch={userFetch} modify={modify} />
          <InterimAgency userId={idInt} change={change} setChange={setChange} userFetch={userFetch} modify={true} />
          {modify && (
            <BoxContainer>
              <Box marginBottom="20px">
                <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                  Raccourcis
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="15px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Candidature</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-candidatures", { state: { idinterim: idInt } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Liste contrats</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-contrats", { state: { idinterim: idInt } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Gestion des problèmes</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-problemes", { state: { nom: userFetch?.user?.last_name } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Gestions des suspensions</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-suspensions", { state: { nom: userFetch?.user?.last_name } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Demande d'acompte</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-acomptes", { state: { idinterim: idInt } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Déclarations des heures</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/declarer-les-heures", { state: { idinterim: idInt } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Fiche de paie</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-fiches-de-paie", { state: { nom: userFetch?.user?.last_name } })} action="orange-raccourcis" />
                </Box>
                <Divider />{" "}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Gestions des EPI à fournir</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-epis", { state: { idinterim: idInt } })} action="orange-raccourcis" />
                </Box>
                <Divider />{" "}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Statistiques</Typography>
                  <ButtonSquare action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Historique des missions</Typography>
                  <ButtonSquare
                    onClick={() =>
                      navigate(location.pathname + "/missions", {
                        state: {
                          nom: userFetch?.user?.last_name,
                          prenom: userFetch?.user?.first_name,
                        },
                      })
                    }
                    action="orange-raccourcis"
                  />
                </Box>
              </Box>
            </BoxContainer>
          )}
        </Box>
        <Box flex="6" display="flex" flexDirection="column" gap="24px">
          <Metier modify={modify} updateBord={updateBord} setUpdateBoard={setUpdateBoard} id={idInt} />
          <MetierCV modify={modify} updateBord={updateBord} setUpdateBoard={setUpdateBoard} id={idInt} />
          <Certification modify={modify} updateBord={updateBord} setUpdateBoard={setUpdateBoard} id={idInt} />
          <Scolarite modify={modify} updateBord={updateBord} setUpdateBoard={setUpdateBoard} id={idInt} />
        </Box>
        {/* CONTENT*/}
      </Box>
      {/*<Box sx={{ maxWidth: "800px" }}>
      {/* Header Section 
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" mb>
          Gestion des intérimaires
        </Typography>
        <Typography variant="h5" component="h1">
          Détails de l'intériamire
        </Typography>
      </Box>
      <Box sx={{ margin: "1rem 0rem" }}>
        <Typography variant="detail_orange">Interimaire</Typography>
      </Box>
      {/* Information de l'intérimaire section 
      <Box className="section">
        <Box sx={{ margin: "1rem 0rem" }}>
          <Typography variant="detail_title">Informations de l'intériamire</Typography>
        </Box>
        <Box sx={{ marginLeft: "2rem" }}>
          <Typography>
            Nom de l'intériamire : <Typography variant="detail_value">{interimaire?.last_name ? interimaire.last_name : "-"}</Typography>
          </Typography>
          <Typography>
            Prénom de l'intériamire : <Typography variant="detail_value">{interimaire?.first_name ? interimaire.first_name : "-"}</Typography>
          </Typography>
          <Typography>
            Date de naissance : <Typography variant="detail_value">{interimaire?.birthday ? interimaire?.birthday : "-"}</Typography>
          </Typography>
          <Typography>
            Email de l'intériamire : <Typography variant="detail_value">{interimaire?.email ? interimaire.email : "-"}</Typography>
          </Typography>
          <Typography>
            RIB : <Typography variant="detail_value">{interimaire?.profile?.rib ? interimaire?.profile?.rib : "-"}</Typography>
          </Typography>
        </Box>
      </Box>
      {/* Information supplémentaires section
      <Box className="section">
        <Box sx={{ margin: "1rem 0rem" }}>
          <Typography variant="detail_title">Informations supplémentaires</Typography>
        </Box>
        <Box sx={{ marginLeft: "2rem" }}>
          <Typography>
            Tel : <Typography variant="detail_value">{interimaire?.phone_number ? interimaire.phone_number : "-"}</Typography>
          </Typography>
          <Typography>
            Adresse : <Typography variant="detail_value">{interimaire?.address ? interimaire.address : "-t"}</Typography>
          </Typography>

          {/*<Typography>Mutuelle :</Typography>
          <Box sx={{ marginLeft: "2rem" }}>
            <Typography>
              Nom de l'organisme : <Typography variant="detail_value">Uber</Typography>
            </Typography>
            <Typography>
              Date de validité : <Typography variant="detail_value">10/10/2022</Typography>
            </Typography>
          </Box>
          <Typography>Visite médicale :</Typography>
          <Box sx={{ marginLeft: "2rem" }}>
            <Typography>
              Date de visite médicale : <Typography variant="detail_value">28/08/2022</Typography>
            </Typography>
          </Box>
          <Typography>
            Justification de domicile : <Typography variant="detail_orange_underline">Justification_de_domicile.pdf</Typography>
          </Typography>
        </Box>
      </Box>
      {/* section  
      <Box className="section">
        <Box sx={{ margin: "1rem 0rem" }}>
          <Typography variant="detail_title">Certification</Typography>
        </Box>
        <Box sx={{ marginLeft: "2rem" }}>
          <Typography>
            <Typography variant="detail_orange_underline">
              <li>CV</li>
            </Typography>
            <Typography variant="detail_orange_underline">
              <li>Permis de conduire</li>
            </Typography>
            <Typography variant="detail_orange_underline">
              <li>CACES 389</li>
            </Typography>
          </Typography>
        </Box>
      </Box>
        </Box>
        <Box sx={{ margin: "1rem 0rem" }}>
          <Typography variant="detail_title">Documents</Typography>
        </Box>
        <Box sx={{ marginBottom: "1rem" }} className="section">
          <Box display="flex" flexDirection="row" sx={{ flexWrap: "wrap" }}>
            <Box sx={{ marginRight: "5rem" }}>
              <Box>
                <Typography variant="detail_details">attestation pôle emploi</Typography>
              </Box>
              {poleEmploi?.map((poleEmploi) => (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography onClick={() => window.open(poleEmploi?.url_file, "_blank")} variant="detail_gray_underline">
                    {poleEmploi?.original_name ? poleEmploi.original_name : ""}
                  </Typography>
                  <img onClick={() => handleDeleteFile(poleEmploi?.id)} style={{ marginLeft: "1rem" }} src={Xmark} alt="" />
                </Box>
              ))}
            </Box>
            {poleEmploi?.map((file) => (
              <Box display="flex" alignItems="center" gap="2rem">
                <Button type="file" sx={{ position: "relative", marginTop: "0.5rem" }} variant="mj_primary">
                  <input
                    onChange={(e) => hanldeChangeFileUpload(file?.id, e, file?.document?.name)}
                    id={file?.document?.name}
                    style={{ opacity: "0", position: "absolute", height: "48px", width: "90px", content: "", cursor: "pointer" }}
                    type="file"
                    accept="image/png, image/jpeg  ,image/jpg, application/pdf "
                  />
                  Upload
                </Button>
                {loadingPE === true ? <CircularProgress /> : null}
                {donePE ? <DoneIcon sx={{ fontSize: 40, color: `${colors.main}` }} /> : null}
              </Box>
            ))}
            {poleEmploi?.length === 0 ? (
              <Box display="flex" alignItems="center" gap="2rem">
                <Button type="file" sx={{ position: "relative", marginTop: "0.5rem" }} variant="mj_primary">
                  <input onChange={(e) => hanldeCreateFilePoleEmploi(e)} style={{ opacity: "0", position: "absolute", height: "48px", width: "90px", content: "", cursor: "pointer" }} type="file" accept="image/png, image/jpeg  ,image/jpg, application/pdf " />
                  Upload
                </Button>
                {loadingPE === true ? <CircularProgress /> : null}
                {donePE ? <DoneIcon sx={{ fontSize: 40, color: `${colors.main}` }} /> : null}
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ marginBottom: "1rem" }} className="section">
          <Box display="flex" flexDirection="row" alignItems="center" sx={{ flexWrap: "wrap" }}>
            <Box sx={{ marginRight: "5rem" }}>
              <Box>
                <Typography variant="detail_details">CNI</Typography>
              </Box>
              {cni?.map((cni) => (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography onClick={() => window.open(cni?.url_file, "_blank")} variant="detail_gray_underline">
                    {cni?.original_name ? cni.original_name : ""}
                  </Typography>
                  <img onClick={() => handleDeleteFile(cni?.id)} style={{ marginLeft: "1rem" }} src={Xmark} alt="" />
                </Box>
              ))}
            </Box>
            {cni?.map((file) => (
              <Box display="flex" alignItems="center" gap="2rem">
                <Button type="file" sx={{ position: "relative", marginTop: "0.5rem" }} variant="mj_primary">
                  <input
                    onChange={(e) => hanldeChangeFileUpload(file?.id, e, file?.document?.name)}
                    id={file?.document?.name}
                    style={{ opacity: "0", position: "absolute", height: "48px", width: "90px", content: "", cursor: "pointer" }}
                    type="file"
                    accept="image/png, image/jpeg  ,image/jpg, application/pdf "
                  />
                  Upload
                </Button>
                {loadingPE === true ? <CircularProgress /> : null}
                {donePE ? <DoneIcon sx={{ fontSize: 40, color: `${colors.main}` }} /> : null}
              </Box>
            ))}
            {cni?.length === 0 ? (
              <Box display="flex" alignItems="center" gap="2rem">
                <Button type="file" sx={{ position: "relative", marginTop: "0.5rem" }} variant="mj_primary">
                  <input onChange={(e) => hanldeCreateFileCNI(e)} style={{ opacity: "0", position: "absolute", height: "48px", width: "90px", content: "", cursor: "pointer" }} type="file" accept="image/png, image/jpeg  ,image/jpg, application/pdf " />
                  Upload
                </Button>
                {loadingCni === true ? <CircularProgress /> : null}
                {doneCni ? <DoneIcon sx={{ fontSize: 40, color: `${colors.main}` }} /> : null}
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ cursor: "pointer" }} onClick={() => navigate(`/admin/gestion-des-interimaires/details/${idInt}/CV`)} margin="2rem 0rem" display="flex" alignItems="center" gap="0.2rem">
          <Typography variant="detail_orange">CV intérimaire</Typography>
          <img src={ArrowRight} />
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            onClick={() => {
              setOpen(true);
              setAction("valider");
            }}
            sx={{ marginRight: "1rem" }}
            variant="mj_primary"
          >
            Valider
          </Button>
          <Button
            onClick={() => {
              setOpen(true);
              setAction("refuser");
            }}
            variant="mj_secondary"
          >
            Refuser
          </Button>
        </Box>
        <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={open} onClose={() => setOpen(false)}>
          <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
            <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
              {action} le compte de l'intériamire
            </Box>
            <Box>
              <Typography display="inline-block" margin="1rem" textAlign="center">
                Voulez vous vraiment{" "}
                <Typography display="inline-block" fontWeight="bold">
                  {action} ce comptes
                </Typography>{" "}
              </Typography>
            </Box>
            {success ? (
              <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
                {success}
              </Alert>
            ) : null}
            {error ? <Alert severity="error">{error}</Alert> : null}
            <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
              <Box onClick={() => (action === "valider" ? validerRenard() : refuserRenard())} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                <Button variant="mj_primary">{action}</Button>
              </Box>
              <Box onClick={() => setOpen(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                <Button variant="mj_secondary">Annuler</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
            </Box>*/}
    </Box>
  );
}

export default InterimaireDetails;
