import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Avatar, Chip, Alert, Modal, IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MjLabel from "../../../components/ui/MjLabel";
// import DefaultProfilePic from "../../imgs/user.png";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Form, Formik, ErrorMessage, Field } from "formik";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { colors } from "../../../theme/Colors";
import * as Yup from "yup";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import MjErrorText from "../../../components/ui/MjErrorText";
import { ToastContainer, toast } from "react-toastify";
import PublishRoundedIcon from "@mui/icons-material/PublishRounded";

function EpiDetails({ change, setChange }) {
  // const [change, setChange] = useState(0);
  const params = useParams();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [epi, setEpi] = useState({});
  const [acceptRefuse, setOpenAcceptRefuse] = useState(false);
  const [actionAcceptRefuse, setActionAcceptRefuse] = useState(false);

  const [progressFile, setProgressFile] = useState(0);
  const [file, setFile] = useState();

  const initialValues = {
    file: "",
  };

  const validationSchema = Yup.object().shape({
    file: Yup.object().nullable(),
  });

  useEffect(() => {
    Api.get("/admin/epis/" + params.id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("EPI OBJECT", res.data);
        setEpi(res.data.epi);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);

  var configFile = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressFile(percentCompleted);
    },
  };

  const AcceptRefuseEpi = (values, actionAcceptRefuse) => {
    console.log(values, "this is the values");
    Api.post(
      "/admin/epis/" + epi.id + "/accept-or-refuse",
      {
        status: actionAcceptRefuse ? "ACCEPTED" : "REFUSED",
        purchase_order_file: actionAcceptRefuse ? values.file : null,
      },
      configFile
    )
      .then((res) => {
        console.log(res);
        setProgressFile(0);
        setChange(!change);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressFile(0);
      });
  };

  // const AcceptRefuseSuspension = (values, id, accept) => {
  //   console.log("hi");
  //   Api.post(
  //     "/admin/suspensions/" + id + "/accept-or-refuse",
  //     {
  //       status: accept ? "ACCEPTED" : "REFUSED",
  //       date: values.date,
  //     },
  //     {
  //       headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
  //     }
  //   )
  //     .then((res) => {
  //       console.log(res);
  //       setChange((change) => change + 1);
  //     })

  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <Box>
      {/* Header Section */}

      <Box display="flex" flexDirection="column" sx={{ maxWidth: "700px" }}>
        <ToastContainer />
        <BoxContainer>
          <Box marginBottom="21px" display="flex" flexDirection="row" alignItems="center" gap="10px">
            <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
              Demande #{epi.id}
            </Typography>

            <Chip variant={epi.control_status === "ACCEPTED" ? "offer_ended" : epi.control_status === "REFUSED" ? "rejected_offer" : "active_offer"} label={epi.control_status === "ACCEPTED" ? "Accepté" : epi.control_status === "REFUSED" ? "Refusé" : "En cours"} />
          </Box>

          {/* <pre>{JSON.stringify(epi, null, 2)}</pre> */}
          <Box marginBottom="10px" display="flex" flexDirection="column" gap="10px" justifyContent="center">
            <Typography variant="text_normal" fontSize="14px" fontWeight="500" lineHeight="32px">
              Liste des EPIS
            </Typography>
            {epi?.equipment?.map((epiItem) => (
              <Box>
                <Chip variant="active_offer" label={epiItem.name} />
              </Box>
            ))}
          </Box>
          {epi?.url_file && (
            <Box marginBottom="10px" display="flex" flexDirection="row" gap="10px" alignItems="center">
              <Typography variant="text_normal" fontSize="14px" fontWeight="500" lineHeight="32px">
                Bon de commande
              </Typography>
              <Box>
                <Button variant="mj_secondary_md" href={epi?.url_file} target="_blank">
                  {epi?.name}
                </Button>
              </Box>
            </Box>
          )}
          <Box marginTop="10px" display="flex" flexDirection="row-reverse" gap="10px">
            <Button
              variant="mj_secondary_md"
              onClick={() => {
                setOpenAcceptRefuse(true);
                setActionAcceptRefuse(false);
              }}
            >
              Refuser la demande
            </Button>
            <Button
              variant="mj_green_md"
              onClick={() => {
                setOpenAcceptRefuse(true);
                setActionAcceptRefuse(true);
              }}
            >
              Accepter la demande
            </Button>
          </Box>
        </BoxContainer>

        {epi?.previously_provided_equipment && (
          <BoxContainer marginTop="20px">
            <Box marginBottom="10px" display="flex" flexDirection="column" gap="10px" justifyContent="center">
              <Typography variant="text_normal" fontSize="14px" fontWeight="500" lineHeight="32px">
                Liste des EPIS fournis auparavant
              </Typography>
              {epi?.previously_provided_equipment?.map((epiItem) => (
                <Box>
                  <Chip variant="active_offer" label={epiItem.name} />
                </Box>
              ))}
            </Box>
          </BoxContainer>
        )}

        <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={acceptRefuse} onClose={() => setOpenAcceptRefuse(false)}>
          <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                {actionAcceptRefuse ? "Accepter la demande des EPIs" : "Refuser la demande des EPIs"}
              </Typography>
              <ButtonSquare onClick={() => setOpenAcceptRefuse(false)} action="close" />
            </Box>

            <Formik
              //initial values
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                AcceptRefuseEpi(values, actionAcceptRefuse);
                setOpenAcceptRefuse(false);
                console.log("hello");
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  {/* <Box marginBottom="20px">
                    <Typography variant="text_normal"> Saisissez la date de traitement du problème</Typography>
                  </Box> */}
                  {/* <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                    <Field fullWidth name="date" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" label="Date traitement du problème" size="small" />
                    <ErrorMessage name="date" component={MjErrorText} />
                  </Box> */}

                  {actionAcceptRefuse && (
                    <Box width="100%">
                      <MjLabel>Bon de commande</MjLabel>

                      <TextField
                        setFieldValue={setFieldValue}
                        value={values?.file?.name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <PublishRoundedIcon />
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "80" }}
                                  type="file"
                                  name="file"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    console.log(e);
                                    setFieldValue("file", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        name="file"
                        placeholder="Télécharger..."
                      />
                      <ErrorMessage name="file" component={MjErrorText} />
                    </Box>
                  )}

                  {!actionAcceptRefuse && (
                    <Box>
                      <Typography>Voulez vous vraiment Refuser la demande ?</Typography>
                    </Box>
                  )}

                  <Box display="flex" flexDirection="row-reverse" gap="10px">
                    <Button variant="mj_secondary_md" onClick={() => setOpenAcceptRefuse(false)}>
                      Annuler
                    </Button>
                    <Button variant={actionAcceptRefuse ? "mj_green_md" : "mj_red_md"} type="submit">
                      {actionAcceptRefuse ? "Accepter" : "Refuser"}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default EpiDetails;
