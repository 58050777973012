import React, { useEffect } from "react";
import { Box, TextField, Divider, Button, Typography, Avatar, Alert, Modal } from "@mui/material";
import MjLabel from "../../components/ui/MjLabel";
import DefaultProfilePic from "../../imgs/user.png";
import MjChip from "../../components/ui/MjChip";
import { useState } from "react";
import { colors } from "../../theme/Colors";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
function FactureDetails() {
  const location = useLocation();
  const [change, setChange] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [action, setAction] = useState("");
  const [success, setSuccess] = useState("");
  const idFiche = location.pathname.split("/")[4];
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [facture, setFacture] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_DETAILS_FACTURE + idFiche, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setFacture(res.data.facture);
        console.log(res.data.facture);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);
  //PAYER FICHE DE PAIE
  const payerFiche = () => {
    Api.patch(
      Urls.PAYER_FACTURE,
      {
        facture_ids: [idFiche],
      },
      {
        headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setSuccess("payée avec succés");
        setError("");
        setTimeout(() => {
          setOpen(false);
          setSuccess("");
          setError("");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setError("une erreur est survenue");
        setSuccess("");
        setTimeout(() => {
          setOpen(false);
          setSuccess("");
          setError("");
        }, 2000);
      });
  };

  return (
    <Box sx={{ maxWidth: "800px" }}>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" mb>
          Gestion des factures
        </Typography>
        <Typography variant="h5" component="h1">
          Détails de la facture
        </Typography>
      </Box>
      <Box sx={{ margin: "1rem 0rem" }}>
        <Typography variant="detail_orange">Facture</Typography>
      </Box>
      <Box sx={{ margin: "1rem 0rem" }}>
        <Typography variant="detail_title">Entreprise : {facture?.entreprise_name}</Typography>
      </Box>
      {/* Liste des factures section*/}
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography>
              Date : <Typography variant="detail_value">{facture?.created_at ? facture?.created_at : "-"}</Typography>
            </Typography>
          </Box>
          <Box display="flex">
            <Typography sx={{ marginRight: "0.5rem" }}>Etat :</Typography>
            <MjChip name={facture?.is_paid === 1 ? "paid" : "unpaid"} />
          </Box>
        </Box>
        <Divider sx={{ margin: "1rem 0rem", maxWidth: "800px" }} />
        {facture?.contrats?.map((fact) => (
          <Box key={fact.id}>
            <Box>
              <Typography>
                Mission : <Typography variant="detail_value">{fact.mission_intitule_poste ? fact.mission_intitule_poste : "-"}</Typography>
              </Typography>
              <Typography>
                intérimaire :{" "}
                <Typography variant="detail_value">
                  {fact.first_name ? fact.first_name : ""} {fact.last_name ? fact.last_name : ""}
                </Typography>
              </Typography>
              <Typography>
                Nombre d'heures : <Typography variant="detail_value">{fact?.nbr_hours_declared_by_malinjob ? fact?.nbr_hours_declared_by_malinjob + " Heures" : "-"}</Typography>
              </Typography>
              <Typography>
                Montant : <Typography variant="detail_orange">{fact?.montant_entreprise ? fact?.montant_entreprise + " €" : "-"}</Typography>
              </Typography>
              <Divider sx={{ margin: "1rem 0rem", maxWidth: "800px" }} />
            </Box>
          </Box>
        ))}
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="detail_orange_large">{facture?.total ? "Total :" + facture?.total + "€" : ""}</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ flexWrap: "wrap" }}>
          <Typography>Etat : </Typography>
          {facture?.is_paid === 1 ? null : (
            <Button
              onClick={() => {
                setOpen(true);
                setAction("payer la facture");
              }}
              sx={{ margin: "0.5rem 1rem" }}
              variant="mj_primary"
            >
              Payée
            </Button>
          )}
          <Button onClick={() => window.open(facture?.file_url, "_blank")} sx={{ margin: "0.5rem 1rem" }} variant="mj_primary">
            Télécharger la facture
          </Button>
        </Box>
      </Box>
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={open} onClose={() => setOpen(false)}>
        <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
          <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
            {action}
          </Box>
          <Box>
            <Typography display="inline-block" margin="1rem" textAlign="center">
              Voulez vous vraiment{" "}
              <Typography display="inline-block" fontWeight="bold">
                {action}
              </Typography>{" "}
            </Typography>
          </Box>
          {success ? (
            <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
              {success}
            </Alert>
          ) : null}
          {error ? <Alert severity="error">{error}</Alert> : null}
          <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
            <Box onClick={() => (action === "payer la facture" ? payerFiche() : "")} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
              <Button variant="mj_primary">{action}</Button>
            </Box>
            <Box onClick={() => setOpen(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
              <Button variant="mj_secondary">Annuler</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default FactureDetails;
