import React, { useState } from "react";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { Avatar, Box, Button, Divider, Modal, Typography, FormControlLabel, Radio, RadioGroup, FormControl, TextField, Checkbox, IconButton, CircularProgress } from "@mui/material";
import { colors } from "../../../theme/Colors";
import { modalStyle } from "../../../theme/ModalStyle";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import dropDownIcon from "../../../imgs/imgV2/DropDownIcon.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import MyTextField from "../../../components/ui/MyTextField";
import endicapeIcon from "../../../imgs/imgV2/endicapeIcon.svg";
import locationIcon from "../../../imgs/imgV2/LocationIcon.svg";
import EmailIcon from "../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../imgs/imgV2/TelIcon.svg";
import WebSiteIcon from "../../../imgs/imgV2/website.svg";
import IconSpec from "../../../imgs/imgV2/spec.svg";
import PersonIcon from "@mui/icons-material/Person";
import BirthIcon from "../../../imgs/imgV2/birthdayIcon.svg";
import MyDatePicker from "../../../components/ui/MyDatePicker";
import MyDP from "../../../components/ui/MyDP";
import checkLinkicon from "../../../imgs/imgV2/checkLinkIcon.svg";
import { useRef } from "react";
import * as Yup from "yup";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { toast, ToastContainer } from "react-toastify";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEffect } from "react";
function InfosRecruteurC({ userFetch, userId, idEnt, change, setChange, modify }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  console.log(user, "this is our user");
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [avatarImage, setAvatarImage] = useState(null);
  const [isHover, setIsHover] = useState(null);
  const [error, setError] = useState("");
  const [progressProfilePix, setProgressProfilePic] = useState(0);
  const [image, setImage] = useState();
  const handleAvatarChange = (e) => {
    setAvatarImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
    const file = e.target.files[0];
    console.log(file.size, "size of the file");
    if (file.size > 5 * 1024 * 1024) {
      console.log("file trop volumineux");
      setError("Image trop volumineuse");
    } else {
      setError("");
    }
  };
  //function to handle when click on the modify icon
  const handleModifyClick = (cert) => {
    setOpen(true);
  };

  //handle click icon
  const handleClickIcon = () => {
    const input = document.getElementById("avatar-input");
    input.click();
  };
  //initial values for the infos personnelle form
  const [initialValuesInfoP, setInitialValuesinfoP] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (userFetch) {
      setInitialValuesinfoP({
        recruiter_first_name: userFetch?.enterprise?.recruiter_first_name ? userFetch?.enterprise?.recruiter_first_name : "",
        recruiter_email: userFetch?.enterprise?.recruiter_email ? userFetch?.enterprise?.recruiter_email : "",
        recruiter_last_name: userFetch?.enterprise?.recruiter_last_name ? userFetch?.enterprise?.recruiter_last_name : "",
        phone_number: userFetch?.enterprise?.phone_number ? userFetch?.enterprise?.phone_number : "",
        recruiter_job_position: userFetch?.enterprise?.recruiter_job_position ? userFetch?.enterprise?.recruiter_job_position : "",
      });
    }
  }, [userFetch]);

  //validation schema for the infos personnelle form
  const validationSchemaInfoPers = Yup.object({
    recruiter_first_name: Yup.string().required("Ce champ est obligatoire"),
    recruiter_email: Yup.string().email("Le format de l'email est invalide").required("Ce champ est obligatoire"),
    recruiter_last_name: Yup.string().required("Ce champ est obligatoire"),
    phone_number: Yup.number().positive().required("Ce champ est obligatoire"),
    recruiter_job_position: Yup.string().required("Ce champ est obligatoire"),
  });
  //min date disponibility
  const today = new Date();
  const [userInterim, setUserInterim] = useState();

  // add one day to the date
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  //function to modify the profile
  const ModifyProfile = (values) => {
    console.log(values, "form values");
    Api.patch(
      Urls?.UPDATE_PROFILE_ENTREPRISE + idEnt,
      {
        recruiter_first_name: values.recruiter_first_name,
        recruiter_email: values.recruiter_email,
        recruiter_last_name: values.recruiter_last_name,
        phone_number: values.phone_number,
        recruiter_job_position: values.recruiter_job_position,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setOpen(false);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };

  //config for the image
  var configsProfile = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressProfilePic(percentCompleted);
    },
  };
  //save image profile
  const saveImage = () => {
    Api.post(
      Urls.UPDATE_LOGO_ENTREPRISE + userId + "/profile",
      {
        logo: image,
      },
      configsProfile
    )
      .then((res) => {
        console.log(res);
        setProgressProfilePic(0);
        setImage(null);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        setProgressProfilePic(0);
      });
  };
  return (
    <Box position="relative">
      <ToastContainer />
      {!loading ? (
        <BoxContainer display="flex" flexDirection="column">
          {modify && (
            <Box display="flex" justifyContent="end">
              <ButtonSquare onClick={handleModifyClick} action="modify" />
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap="20px">
            <Box>
              <Box display="flex" alignItems="center" gap="20px">
                <Avatar onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)} sx={{ height: "80px", width: "80px", position: "relative", filter: `${isHover ? "brightness(0.5)" : "none"}`, transition: "0.5s" }}>
                  {avatarImage ? <img src={avatarImage} alt="profil picture" /> : userFetch?.url_avatar ? <img src={userFetch?.url_avatar} alt="profil picture" /> : <PersonIcon fontSize="large" />}
                  <input accept="image/*" style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }} id="avatar-input" type="file" onChange={handleAvatarChange} />
                  {isHover ? <CameraAltIcon onClick={handleClickIcon} fontSize="large" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black", transition: "0.5s" }} /> : null}
                </Avatar>
                <Box display="flex" flexDirection="column">
                  <Typography fontSize="16px" fontWeight="600" lineHeight="25.6px">
                    {userFetch?.enterprise?.recruiter_first_name ? userFetch?.enterprise?.recruiter_first_name : "Prénom"} {userFetch?.enterprise?.recruiter_last_name ? userFetch?.enterprise?.recruiter_last_name : "Nom"}
                  </Typography>
                </Box>
                {image && progressProfilePix === 0 && (
                  <Button onClick={saveImage} disabled={error ? true : false} variant="mj_primary_md">
                    Enregistrer
                  </Button>
                )}
                {progressProfilePix !== 0 && (
                  <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                    <CircularProgress />
                    <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                      {progressProfilePix}%
                    </Typography>
                  </Box>
                )}
              </Box>
              {error ? (
                <Typography color="red" fontSize="12px">
                  {error}
                </Typography>
              ) : null}
            </Box>
            <Box display="flex" flexDirection="column" gap="8px">
              <Box display="flex" alignItems="center" gap="4px">
                <img src={IconSpec} alt="spec-icon" />
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.enterprise?.recruiter_job_position ? userFetch?.enterprise?.recruiter_job_position : ""}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="4px">
                <img src={EmailIcon} alt="email-icon" />
                <Typography variant="text_clickable">{userFetch?.enterprise?.recruiter_email ? userFetch?.enterprise?.recruiter_email : ""}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="4px">
                <img src={TelIcon} alt="tel-icon" />
                <Typography variant="text_clickable">{userFetch?.enterprise?.phone_number ? userFetch?.enterprise?.phone_number : ""}</Typography>
              </Box>
            </Box>
          </Box>
        </BoxContainer>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Informations du recruteur
              </Typography>
              <ButtonSquare onClick={() => setOpen(false)} action="close" />
            </Box>
            <Formik
              innerRef={formikRef}
              initialValues={initialValuesInfoP}
              onSubmit={(values) => {
                ModifyProfile(values);
              }}
              validationSchema={validationSchemaInfoPers}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Nom
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.recruiter_last_name} name="recruiter_last_name" placeholder="Nom..." />
                        {errors.recruiter_last_name && touched.recruiter_last_name ? (
                          <Typography color="red" fontSize="12px">
                            {errors.recruiter_last_name}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Prénom
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.recruiter_first_name} name="recruiter_first_name" placeholder="Prénom..." />
                        {errors.recruiter_first_name && touched.recruiter_first_name ? (
                          <Typography color="red" fontSize="12px">
                            {errors.recruiter_first_name}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Email
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.recruiter_email} name="recruiter_email" placeholder="Email..." />
                        {errors.recruiter_email && touched.recruiter_email ? (
                          <Typography color="red" fontSize="12px">
                            {errors.recruiter_email}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Numéro de téléphone
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.phone_number} name="phone_number" placeholder="Numéro de téléphone..." />
                        {errors.phone_number && touched.phone_number ? (
                          <Typography color="red" fontSize="12px">
                            {errors.phone_number}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Poste
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.recruiter_job_position} name="recruiter_job_position" placeholder="Poste..." />
                        {errors.recruiter_job_position && touched.recruiter_job_position ? (
                          <Typography color="red" fontSize="12px">
                            {errors.recruiter_job_position}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpen(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default InfosRecruteurC;
