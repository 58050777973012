import React, { useState } from "react";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { Avatar, Box, Button, Divider, Modal, Typography, FormControlLabel, Radio, RadioGroup, FormControl, TextField, Checkbox, IconButton, CircularProgress, InputAdornment } from "@mui/material";
import { colors } from "../../../theme/Colors";
import { modalStyle } from "../../../theme/ModalStyle";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import dropDownIcon from "../../../imgs/imgV2/DropDownIcon.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import MyTextField from "../../../components/ui/MyTextField";
import endicapeIcon from "../../../imgs/imgV2/endicapeIcon.svg";
import locationIcon from "../../../imgs/imgV2/LocationIcon.svg";
import EmailIcon from "../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../imgs/imgV2/TelIcon.svg";
import WebSiteIcon from "../../../imgs/imgV2/website.svg";

import PersonIcon from "@mui/icons-material/Person";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import { useRef } from "react";
import * as Yup from "yup";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { toast, ToastContainer } from "react-toastify";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEffect } from "react";
import axios from "axios";
import { debounce } from "lodash";
function InfosEntreprise({ userFetch, userId, idEnt, change, setChange, modify }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  console.log(user, "this is our user");
  const [open, setOpen] = useState(false);
  const [openCodeCoffreo, setOpenCodeCoffreo] = useState(false);
  const formikRef = useRef();
  const [avatarImage, setAvatarImage] = useState(null);
  const [isHover, setIsHover] = useState(null);
  const [error, setError] = useState("");
  const [openAd, setOpenAd] = useState(false);
  const [adressesInfoP, setAdressesInfoP] = useState([]);
  const [adresseInfoP, setAdresseinfoP] = useState("");
  const [progressProfilePix, setProgressProfilePic] = useState(0);
  const [image, setImage] = useState();
  const handleAvatarChange = (e) => {
    setAvatarImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
    const file = e.target.files[0];
    console.log(file.size, "size of the file");
    if (file.size > 5 * 1024 * 1024) {
      console.log("file trop volumineux");
      setError("Image trop volumineuse");
    } else {
      setError("");
    }
  };
  //function to handle when click on the modify icon
  const handleModifyClick = (cert) => {
    setOpen(true);
  };

  //handle click icon
  const handleClickIcon = () => {
    const input = document.getElementById("avatar-input");
    input.click();
  };
  //initial values for the infos personnelle form
  const [initialValuesInfoP, setInitialValuesinfoP] = useState({});
  const [initialValuesCoffreo, setInitialValuesCoffreo] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (userFetch) {
      setAdresseinfoP(userFetch?.enterprise?.address ? userFetch?.enterprise?.address : "");
      setInitialValuesinfoP({
        nom: userFetch.enterprise?.name ? userFetch.enterprise?.name : "",
        email: userFetch.enterprise?.user?.email ? userFetch.enterprise?.user?.email : "",
        email_contact: userFetch.enterprise?.contact_email ? userFetch.enterprise?.contact_email : "",
        email_fact: userFetch.enterprise?.billing_email ? userFetch.enterprise?.billing_email : "",
        address: userFetch.enterprise?.address ? userFetch.enterprise?.address : "",
        website: userFetch.enterprise?.website ? userFetch.enterprise.website : "",
        num_tel: userFetch.enterprise?.phone_number ? userFetch.enterprise.phone_number : "",
      });
      setInitialValuesCoffreo({
        coffreo_code: userFetch?.enterprise?.coffreo_code ? userFetch?.enterprise?.coffreo_code : "",
      });
    }
  }, [userFetch]);
  const [email, setEmail] = useState(null);

  // useEffect(() => {
  //   setLoading(true);
  //   Api.get(Urls.GET_USER_REC + userId, {
  //     headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
  //   })
  //     .then((res) => {
  //       console.log(res, "infos admin");
  //       setEmail(res?.data?.user?.email);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err, "infos admin");
  //     });
  // }, [change]);

  //validation schema for the infos personnelle form
  const validationSchemaInfoPers = Yup.object({
    nom: Yup.string().required("Ce champ est obligatoire"),
    email: Yup.string().email("Le format de l'email est invalide").required("Ce champ est obligatoire"),
    email_contact: Yup.string().email("Le format de l'email est invalide").required("Ce champ est obligatoire"),
    email_fact: Yup.string().email("Le format de l'email est invalide").required("Ce champ est obligatoire"),
    address: Yup.string().required("Ce champ est obligatoire"),
  });
  const validationSchemaCoffreo = Yup.object({
    coffreo_code: Yup.string().required("Ce champ est obligatoire"),
  });
  //min date disponibility
  const today = new Date();

  // add one day to the date
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  //function to modify the profile
  const ModifyProfile = (values) => {
    console.log(values, "form values");
    Api.patch(
      Urls?.UPDATE_PROFILE_ENTREPRISE + idEnt,
      {
        name: values.nom,
        contact_email: values.email_contact,
        billing_email: values.email_fact,
        address: values.address,
        phone_number: values.num_tel,
        website: values.website,
        coffreo_code: values.coffreo_code,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setOpen(false);
        setChange(!change);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
        setOpen(false);
      });
  };

  //function to modify code coffreo
  const ModifyProfileCodeCoffreo = (values) => {
    console.log(values, "form values");
    Api.patch(
      Urls?.UPDATE_PROFILE_ENTREPRISE + idEnt,
      {
        coffreo_code: values.coffreo_code,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setOpen(false);
        setChange(!change);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
        setOpen(false);
      });
  };

  //config for the image
  var configsProfile = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressProfilePic(percentCompleted);
    },
  };
  //save image profile
  const saveImage = () => {
    Api.post(
      Urls.UPDATE_LOGO_ENTREPRISE + idEnt + "/logo",
      {
        logo: image,
      },
      configsProfile
    )
      .then((res) => {
        console.log(res);
        setProgressProfilePic(0);
        setImage(null);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        setProgressProfilePic(0);
      });
  };
  const searchPlacesInfosP = (adresse) => {
    if (adresse !== "") {
      axios
        .get("https://nominatim.openstreetmap.org/search", {
          params: {
            q: adresse,
            format: "json",
            polygon_kml: 0,
            addressdetails: 1,
            limit: 100,
            countrycodes: "fr",
            "accept-language": "fr",
          },
        })
        .then((res) => {
          console.log(res);
          setAdressesInfoP(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const debouncedSearchInfoP = React.useMemo(() => {
    return debounce((adresse) => {
      searchPlacesInfosP(adresse);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearchInfoP(adresseInfoP);
  }, [adresseInfoP]);
  return (
    <Box position="relative">
      <ToastContainer />
      {!loading ? (
        <BoxContainer display="flex" flexDirection="column">
          {modify && (
            <Box display="flex" justifyContent="end">
              <ButtonSquare onClick={handleModifyClick} action="modify" />
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap="20px">
            <Box>
              <Box display="flex" alignItems="center" gap="20px">
                <Avatar onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)} sx={{ height: "80px", width: "80px", position: "relative", filter: `${isHover ? "brightness(0.5)" : "none"}`, transition: "0.5s" }}>
                  {avatarImage ? (
                    <img src={avatarImage} style={{ height: "80px", width: "80px", borderRadius: "50%" }} alt="profil picture" />
                  ) : userFetch?.enterprise?.url_logo ? (
                    <img style={{ height: "80px", width: "80px", borderRadius: "50%" }} src={userFetch?.enterprise?.url_logo} alt="profil picture" />
                  ) : (
                    <PersonIcon fontSize="large" />
                  )}
                  <input accept="image/*" style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }} id="avatar-input" type="file" onChange={handleAvatarChange} />
                  {isHover ? <CameraAltIcon onClick={handleClickIcon} fontSize="large" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black", transition: "0.5s" }} /> : null}
                </Avatar>
                <Box display="flex" flexDirection="column">
                  <Typography fontSize="16px" fontWeight="600" lineHeight="25.6px">
                    {userFetch?.enterprise?.name} #{userFetch?.enterprise?.id}
                  </Typography>
                  <Typography fontSize="12px" fontWeight="400" lineHeight="19.2px" color="#666D83">
                    {userFetch?.enterprise?.user?.verification_data_status === "VALID" ? "Compte valide" : userFetch?.enterprise?.user?.verification_data_status === "NOT_VALID" ? "Non valide" : "En attente de validation"}
                  </Typography>
                </Box>
                {image && progressProfilePix === 0 && (
                  <Button onClick={saveImage} disabled={error ? true : false} variant="mj_primary_md">
                    Enregistrer
                  </Button>
                )}
                {progressProfilePix !== 0 && (
                  <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                    <CircularProgress />
                    <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                      {progressProfilePix}%
                    </Typography>
                  </Box>
                )}
              </Box>
              {error ? (
                <Typography color="red" fontSize="12px">
                  {error}
                </Typography>
              ) : null}
            </Box>
            <Box display="flex" flexDirection="column" gap="8px">
              <Box display="flex" alignItems="center" gap="4px">
                <img src={locationIcon} alt="location-icon" />
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.enterprise?.address ? userFetch?.enterprise?.address : ""}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="4px">
                <img src={EmailIcon} alt="email-icon" />
                <Typography variant="text_clickable">{userFetch?.enterprise?.user?.email ? userFetch.enterprise?.user?.email : ""}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="4px">
                <img src={TelIcon} alt="tel-icon" />
                <Typography variant="text_clickable">{userFetch?.enterprise?.phone_number ? userFetch?.enterprise?.phone_number : ""}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="4px">
                <img src={WebSiteIcon} alt="website-icon" />
                <Typography variant="text_clickable">{userFetch?.enterprise?.website ? userFetch?.enterprise?.website : ""}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="4px">
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22.4PX" }}>Email de contact:</Typography>
                <Typography variant="text_clickable">{userFetch?.enterprise?.contact_email ? userFetch?.enterprise?.contact_email : ""}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="4px">
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>Email de facturation:</Typography>
                <Typography variant="text_clickable">{userFetch?.enterprise?.billing_email ? userFetch?.enterprise?.billing_email : ""}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ margin: "10px 0px" }} />
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ fontSize: "16", fontWeight: "700", lineHeight: "25.6px" }}>code coffreo</Typography>
              {modify && <ButtonSquare onClick={() => setOpenCodeCoffreo(true)} action="modify" />}
            </Box>
            <Box display="flex" gap="5px" alignItems="center">
              <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>code coffreo:</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.enterprise?.coffreo_code ? userFetch?.enterprise?.coffreo_code : ""}</Typography>
            </Box>
          </Box>
        </BoxContainer>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle} maxWidth="700px">
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Informations de l’entreprise
              </Typography>
              <ButtonSquare onClick={() => setOpen(false)} action="close" />
            </Box>
            <Formik
              innerRef={formikRef}
              initialValues={initialValuesInfoP}
              onSubmit={(values) => {
                ModifyProfile(values);
              }}
              validationSchema={validationSchemaInfoPers}
              enableReinitialize={true}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      {/* <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            ID coffréo
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.coffreo_code} name="coffreo_code" placeholder="coffreo code..." />
                        {errors.coffreo_code && touched.coffreo_code ? (
                          <Typography color="red" fontSize="12px">
                            {errors.coffreo_code}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%"></Box> */}
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Nom de l’entreprise
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.nom} name="nom" placeholder="Nom de l’entreprise..." />
                        {errors.nom && touched.nom ? (
                          <Typography color="red" fontSize="12px">
                            {errors.nom}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Email
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField disabled={true} setFieldValue={setFieldValue} value={values.email} name="email" placeholder="Email..." />
                        {errors.email && touched.email ? (
                          <Typography color="red" fontSize="12px">
                            {errors.email}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Email de contact
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.email_contact} name="email_contact" placeholder="Email de contact..." />
                        {errors.email_contact && touched.email_contact ? (
                          <Typography color="red" fontSize="12px">
                            {errors.email_contact}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Email de facturation
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.email_fact} name="email_fact" placeholder="Email de facturation..." />
                        {errors.email_fact && touched.email_fact ? (
                          <Typography color="red" fontSize="12px">
                            {errors.email_fact}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Adresse
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <TextField
                          sx={{ width: "100%" }}
                          value={adresseInfoP}
                          type="text"
                          onClick={() => {
                            setOpenAd(true);
                          }}
                          fullWidth
                          onChange={(e) => {
                            setAdresseinfoP(e.target.value);
                          }}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                            endAdornment: null,
                          }}
                          placeholder="Adresse..."
                        />
                        {openAd && adressesInfoP && (
                          <Box boxShadow="0px 1px 5px 0px black" marginTop="2px" width="100%" maxHeight="300px" sx={{ overflowY: "auto" }}>
                            {adressesInfoP?.map((ad) => (
                              <Box
                                onClick={() => {
                                  setAdresseinfoP(ad?.display_name);
                                  setFieldValue("address", ad?.display_name);
                                  setOpenAd(false);
                                }}
                                padding="5px"
                                sx={{ ":hover": { filter: "brightness(0.95)", backgroundColor: "white" }, cursor: "pointer" }}
                              >
                                <Typography key={ad?.id}>{ad.display_name}</Typography>
                              </Box>
                            ))}
                          </Box>
                        )}{" "}
                        {errors.address && touched.address ? (
                          <Typography color="red" fontSize="12px">
                            {errors.address}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Numéro de téléphone{" "}
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.num_tel} name="num_tel" placeholder="Numéro de téléphone..." />
                        {errors.num_tel && touched.num_tel ? (
                          <Typography color="red" fontSize="12px">
                            {errors.num_tel}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Site web
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.website} name="website" placeholder="Site web..." />
                        {errors.website && touched.website ? (
                          <Typography color="red" fontSize="12px">
                            {errors.website}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpen(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>

      <Modal open={openCodeCoffreo} onClose={() => setOpenCodeCoffreo(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Code coffreo
              </Typography>
              <ButtonSquare onClick={() => setOpenCodeCoffreo(false)} action="close" />
            </Box>
            <Formik
              innerRef={formikRef}
              initialValues={initialValuesCoffreo}
              onSubmit={(values) => {
                ModifyProfileCodeCoffreo(values);
              }}
              validationSchema={validationSchemaCoffreo}
              enableReinitialize={true}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Code coffréo
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.coffreo_code} name="coffreo_code" placeholder="coffreo code..." />
                        {errors.coffreo_code && touched.coffreo_code ? (
                          <Typography color="red" fontSize="12px">
                            {errors.coffreo_code}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" gap="10px" justifyContent="end" alignItems="center" marginTop="25px">
                    <Button onClick={() => setOpenCodeCoffreo(false)} variant="mj_secondary_md">
                      Annuler
                    </Button>
                    <Button type="submit" variant="mj_primary_md">
                      Enregistrer
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default InfosEntreprise;
