import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { colors } from "../../theme/Colors";

const StyledBox = styled(Box)({
  borderRadius: "24px",
  textTransform: "none",
  color: colors.textDark,

  padding: "1rem",
  backgroundColor: `${colors.paperLightGray}`,
  border: `1px solid ${colors.paperLightGrayBorder}`,
});

function ContentBox({ children, ...props }) {
  return <StyledBox {...props}>{children}</StyledBox>;
}

export default ContentBox;
