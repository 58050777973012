import React from "react";
import { Box } from "@mui/material";

function BoxContainerModal({ children, ...props }) {
  return (
    <Box {...props} sx={{ padding: "40px", borderRadius: "6px" }}>
      {children}
    </Box>
  );
}

export default BoxContainerModal;
