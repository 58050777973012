import React, { useEffect, useState } from "react";
import { Box, Modal, TextField, Button, Typography, Autocomplete, IconButton, FormControlLabel, Radio, RadioGroup, FormLabel, FormControl, Alert, Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { modalStyle1, modalStyleOhMyJob1, modalStyleOhMyJob2 } from "../../theme/ModalStyle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ArrowBack, ArrowBackIosOutlined, ArrowForward, ArrowLeft, ArrowRight, CheckBox, ErrorSharp, Flag } from "@mui/icons-material";
import { colors } from "../../theme/Colors";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import * as Yup from "yup";
import ButtonSquare from "../../components/ui/ButtonSquare";
import MjLabel from "../../components/ui/MjLabel";
import MjErrorText from "../../components/ui/MjErrorText";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Formik, Form, Field, useFormik, ErrorMessage } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import Api from "../../api/Api";
import moment from "moment";
import { Urls } from "../../api/Urls";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ButtonDataGridSquare from "../../components/ui/ButtonDataGridSquare";
import MyChip from "../../components/ui/MyChip";

function Comptes() {
  const [rowsPerPage, setRowsPer] = useState(10);

  const columns = [
    { field: "id", headerName: "ID", width: 100, headerClassName: "grid-header" },
    {
      field: "last_name",
      headerName: "Nom",
      width: 100,
    },
    {
      field: "first_name",
      headerName: "Prénom",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "agency_name",
      headerName: "Agence",
      width: 150,
    },
    {
      field: "role",
      headerName: "Roles",
      description: "This column has a value getter and is not sortable.",
      width: 500,
      align: "center",
      renderCell: (params) => {
        return (
          <Box margin="5px 0px" display="flex" gap="2px" flexWrap="wrap">
            {params.row.roles.map((role, index) => (
              <Chip key={index} variant="active_offer" label={role.display_name} />
            ))}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            <ButtonSquare
              action={"delete-red"}
              onClick={() => {
                setId(params.row.id);
                setOpenDeleteSingle(true);
                setAction("supprimer");
              }}
            />
            <ButtonSquare
              action={"consulter"}
              onClick={() => {
                navigate(`/admin/gestion-des-comptes-admin/${params.row.id}`);
              }}
            />
          </Box>
        );
      },
      width: 200,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];
  const { user } = useAuthContext();
  const [id, setId] = useState(null);
  //console.log(user);
  const access_token = user?.access_token;
  const [open, setOpen] = useState(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [loadin, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [actionMultiple, setactionMultiple] = useState("");
  const [openSuspend, setOpenSuspend] = useState(false);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState("");
  const [action, setAction] = useState("");
  const [success, setSuccess] = useState("");
  const [change, setChange] = useState(0);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [data, setData] = useState({});
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openParams, setOpenParams] = React.useState(false);

  //add admin modal stuff
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [searchedAgency, setSearchedAgency] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchedRole, setSearchedRole] = useState("");
  const [openCreateAdmin, setOpenCreateAdmin] = useState(false);
  const [selectedRoleFilter, setSelectedRoleFilter] = useState(null);
  const [searchedRoleFilter, setSearchedRoleFilter] = useState("");

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRolesIDS, setSelectedRolesIDS] = useState([]);
  const [searchedRoles, setSearchedRoles] = useState(null);
  const [selectedRoleValue, setSelectedRoleValue] = useState(null);

  const [didCreateAdmin, setDidCreateAdmin] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteSingle, setOpenDeleteSingle] = useState(false);

  const addAdmin = (values) => {
    console.log("adding admin");
    Api.post(
      Urls.CREATE_ADMIN,
      {
        first_name: values.prenom,
        last_name: values.nom,
        email: values.email,
        agency_id: values.agence,
        role_names: values.role,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log("", res);
        setOpenCreateAdmin(false);
        setDidCreateAdmin(!didCreateAdmin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialValues = {
    nom: "",
    prenom: "",
    email: "",
    role: [],
    agence: "",
  };

  const validationSchema = Yup.object({
    nom: Yup.string().required("Ce champ est obligatoire"),
    prenom: Yup.string().required("Ce champ est obligatoire"),
    email: Yup.string().email("Format email invalid").required("Ce champ est obligatoire"),
    role: Yup.array().required("Ce champ est obligatoire"),
    agence: Yup.string().required("Ce champ est obligatoire"),
  });

  //get agencies
  useEffect(() => {
    Api.get(Urls.GET_AGENCES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {},
    })
      .then((res) => {
        setAgencies(res?.data?.agencies);
        console.log("Agencies :", res.data.agencies);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //get roles
  useEffect(() => {
    Api.get(Urls.GET_ROLES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: { searchKey: "" },
    })
      .then((res) => {
        setRoles(res?.data?.roles);
        console.log("Roles :", res.data.roles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    let pageF = page;
    Api.get(Urls.GET_LISTE_ADMIN + `?perPage=${rowsPerPage}&page=${pageF}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    }).then((res) => {
      console.log("LISTE DES ADMINS : ===> ", res);
      setRows(res?.data?.admins ? res?.data?.admins : []);
      setData(res.data);
      setLoading(false);
      setTotal(res.data?.meta?.total);
    });
  }, [change, page, rowsPerPage, didCreateAdmin]);

  //navigation
  const navigate = useNavigate();

  //navigate functions
  const goToAddAdminAccount = () => {
    navigate("/admin/gestion-des-comptes-admin/add");
  };
  //pagination next
  const handlePaginationNext = () => {
    setLoading(true);
    axios
      .get(data?.links?.next, {
        headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setRows(res.data.users);
        setData(res.data);
        setPage(page + 1);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //handle pagination prev
  const handlePaginationPrev = () => {
    setLoading(true);

    axios
      .get(data?.links?.prev, {
        headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setRows(res.data.users);
        setData(res.data);
        setPage(page - 1);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //custom footer
  const customFooter = () => {
    return (
      <Box margin="0.5rem 1rem 0.5rem 0rem" justifyContent="space-between" gap="0.5rem" display="flex" flexDirection="row" alignItems="center">
        <Box marginLeft="1rem">
          <Typography>{selectedIDs?.length ? selectedIDs.length : 0} lignes selectionnées</Typography>
        </Box>
        <Box gap="0.5rem" display="flex">
          {data?.links?.prev ? <ArrowBackIosOutlined onClick={handlePaginationPrev} sx={{ color: `${colors.main}` }} /> : null}
          <Typography>{page}</Typography>
          {data?.links?.next ? <ArrowForwardIosIcon onClick={handlePaginationNext} sx={{ color: `${colors.main}` }} /> : null}
        </Box>
      </Box>
    );
  };

  //delete admin
  const deleteAdmin = (id) => {
    setLoading(true);
    Api.delete(Urls.DELETE_ADMIN, {
      headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        admin_ids: Array.isArray(id) ? id : [id],
      },
    })
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpenDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //delete admin
  const deleteAdminSingle = (idToDelete) => {
    setLoading(true);
    Api.delete(Urls.DELETE_ADMIN, {
      headers: { "content-tyoe": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        admin_ids: Array.isArray(idToDelete) ? idToDelete : [idToDelete],
      },
    })
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpenDeleteSingle(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialValuesFilter = {
    id_filter: "",
    nom_filter: "",
    prenom_filter: "",
    email_filter: "",
    role_filter: [],
    agence_filter: "",
  };

  const validationSchemaFilter = Yup.object({
    id_filter: Yup.string(),
    nom_filter: Yup.string(),
    prenom_filter: Yup.string(),
    email_filter: Yup.string().email("Format email invalid"),
    role_filter: Yup.string(),
    agence_filter: Yup.string(),
  });

  // eliminate empty data
  // function nonEmptyObject(obj) {
  //   let tempObj = obj;
  //   for (const propName in tempObj) {
  //     if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
  //       delete tempObj[propName];
  //     }
  //   }
  //   return tempObj;
  // }

  const onSubmitFilter = (values) => {
    console.log("bonjour");
    //let realValues = nonEmptyObject(values);
    console.log("VALUES", values);
    // console.log("REAL VALUES", realValues);
    // console.log("at from submit", access_token);

    // setPage(0);
    let pageF = page;
    setLoading(true);
    Api.get(Urls.GET_LISTE_ADMIN + `?perPage=${rowsPerPage}&page=${pageF}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: {
        id: values?.id_filter ? values.id_filter : null,
        firstName: values?.prenom_filter ? values.prenom_filter : null,
        lastName: values?.nom_filter ? values.nom_filter : null,
        email: values?.email_filter ? values.email_filter : null,
        agencyId: values?.agence_filter ? values.agence_filter : null,
        roleName: values?.role_filter ? values.role_filter : null,
      },
    })
      .then((response) => {
        console.log("response filter", response);
        setLoading(false);
        setRows(response.data.admins);
        setData(response.data);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" fontWeight="600">
          Gestion des comptes admin
        </Typography>
      </Box>
      {/* Form Section */}
      <Box className="filter"></Box>
      {/* Actions Section */}
      <Box className="actions">
        <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
          <Box display="flex" gap="1rem">
            <Button onClick={() => setOpenCreateAdmin(true)} variant="mj_green_md">
              Ajouter un admin
            </Button>
          </Box>
        </Box>

        {/* filter fields */}

        <Formik initialValues={initialValuesFilter} onSubmit={onSubmitFilter}>
          {({ values }) => (
            <Form>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: "6px", padding: "20px 0px" }}>
                <Box sx={{ marginBottom: "1rem", width: "200px" }} maxWidth="500px">
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="id_filter" type="input" as={TextField} label="Id" size="small" />
                    <ErrorMessage name="id_filter" component={MjErrorText} />
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "1rem", width: "200px" }} maxWidth="500px">
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="nom_filter" type="input" as={TextField} label="Nom" size="small" />
                    <ErrorMessage name="nom_filter" component={MjErrorText} />
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "1rem", width: "200px" }} maxWidth="500px">
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="prenom_filter" type="input" as={TextField} label="Prénom" size="small" />
                    <ErrorMessage name="prenom_filter" component={MjErrorText} />
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "1rem", width: "200px" }}>
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="email_filter" type="input" as={TextField} label="Email" size="small" />
                    <ErrorMessage name="email_filter" component={MjErrorText} />
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "1rem", width: "200px" }} maxWidth="500px">
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field name="agence_filter">
                      {({ field, form }) => (
                        <Autocomplete
                          {...field}
                          fullWidth
                          value={selectedAgency}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, value) => {
                            if (value) {
                              setSelectedAgency(value);
                              form.setFieldValue("agence_filter", value.id);
                              console.log("selected agence_filter is ::", value);
                            } else {
                              setSelectedAgency(null);
                              form.setFieldValue("agence_filter", "");
                            }
                          }}
                          onInputChange={(event, value) => {
                            setSearchedAgency(event?.target?.value);
                            console.log("searching for :", event?.target?.value);
                            console.log(value);
                            if (event?.target?.value === "") {
                              form.setFieldValue("agence_filter", "");
                            }
                          }}
                          options={agencies}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField value={selectedAgency} {...params} sx={{ width: "200px" }} label="Agence" size="small" />}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="agence_filter" component={MjErrorText} />
                  </Box>
                </Box>

                {/* role filter */}
                <Box sx={{ marginBottom: "1rem", width: "200px" }} maxWidth="500px">
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field name="role_filter">
                      {({ field, form }) => (
                        <Autocomplete
                          {...field}
                          fullWidth
                          value={selectedRoleFilter}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, value) => {
                            if (value) {
                              setSelectedRoleFilter(value);
                              form.setFieldValue("role_filter", value.name);
                              console.log("selected role_filter is ::", value);
                            } else {
                              setSelectedRoleFilter(null);
                              form.setFieldValue("role_filter", "");
                            }
                          }}
                          onInputChange={(event, value) => {
                            setSearchedRoleFilter(event?.target?.value);
                            console.log("searching for :", event?.target?.value);
                            console.log(value);
                            if (event?.target?.value === "") {
                              form.setFieldValue("role_filter", "");
                            }
                          }}
                          options={roles}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField value={selectedRoleFilter} {...params} sx={{ width: "200px" }} label="Role" size="small" />}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="role_filter" component={MjErrorText} />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, gap: "10px", alignItems: { md: "center", xs: "none" } }}>
                <Button type="submit" variant="mj_green_md">
                  Filtrer
                </Button>

                <Button
                  onClick={() => {
                    if (selectedIDs.length > 0) {
                      setOpenDelete(true);
                      setactionMultiple("supprimer");
                    }
                  }}
                  variant="mj_red_md"
                >
                  Supprimer les comptes
                </Button>
              </Box>
            </Form>
          )}
        </Formik>

        {/* <Box width="150px">
            <TextField label="id" size="small" />
          </Box>
          <Box width="150px">
            <TextField label="Nom" size="small" />
          </Box>
          <Box width="150px">
            <TextField label="Prénom" size="small" />
          </Box>
          <Box width="200px">
            <TextField label="email" size="small" />
          </Box> */}
      </Box>

      {/* modal delete admin */}
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openDelete} onClose={() => setOpenDelete(false)}>
        <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
          <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="text_xxl" fontWeight="700">
              Supression de comptes admin
            </Typography>
            <ButtonSquare onClick={() => setOpenDelete(false)} action="close" />
          </Box>

          <Box marginBottom="20px">
            <Typography variant="text_normal"> Voulez vous vraiment supprimer ce(s) compte(s) ?</Typography>
          </Box>

          <Box display="flex" flexDirection="row-reverse" gap="10px">
            <Button variant="mj_secondary_md">Annuler</Button>
            <Button variant="mj_red_md" onClick={() => deleteAdmin(selectedIDs)}>
              Supprimer
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* modal delete signle admin */}
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openDeleteSingle} onClose={() => setOpenDeleteSingle(false)}>
        <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
          <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="text_xxl" fontWeight="700">
              Supression de comptes admin
            </Typography>
            <ButtonSquare onClick={() => setOpenDeleteSingle(false)} action="close" />
          </Box>

          <Box marginBottom="20px">
            <Typography variant="text_normal"> Voulez vous vraiment supprimer ce compte ?</Typography>
          </Box>

          <Box display="flex" flexDirection="row-reverse" gap="10px">
            <Button variant="mj_secondary_md">Annuler</Button>
            <Button variant="mj_red_md" onClick={() => deleteAdminSingle(id)}>
              Supprimer
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* modal add admin */}
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openCreateAdmin} onClose={() => setOpenCreateAdmin(false)}>
        <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
          <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="text_xxl" fontWeight="700">
              Création compte admin
            </Typography>
            <ButtonSquare onClick={() => setOpenCreateAdmin(false)} action="close" />
          </Box>
          <Formik onSubmit={addAdmin} initialValues={initialValues} validationSchema={validationSchema}>
            {({ values }) => (
              <Form>
                <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                  <MjLabel fontWeight="500" name="nom" htmlFor="nom">
                    Nom
                  </MjLabel>
                  <Field name="nom" autoComplete="off" as={TextField} fullWidth />
                  <ErrorMessage name="nom" component={MjErrorText} />
                </Box>
                <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                  <MjLabel fontWeight="500" name="prenom" htmlFor="">
                    Prénom
                  </MjLabel>
                  <Field name="prenom" autoComplete="off" as={TextField} fullWidth />
                  <ErrorMessage name="prenom" component={MjErrorText} />
                </Box>
                <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                  <MjLabel fontWeight="500" name="email" htmlFor="">
                    Email
                  </MjLabel>
                  <Field name="email" autoComplete="off" as={TextField} fullWidth />
                  <ErrorMessage name="email" component={MjErrorText} />
                </Box>

                <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                  <MjLabel fontWeight="500" name="agence" htmlFor="">
                    Agence
                  </MjLabel>
                  <Field name="agence">
                    {({ field, form }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        value={selectedAgency}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (value) {
                            setSelectedAgency(value);
                            form.setFieldValue("agence", value.id);
                            console.log("selected agence is ::", value);
                          } else {
                            setSelectedAgency(null);
                            form.setFieldValue("agence", "");
                          }
                        }}
                        onInputChange={(event, value) => {
                          setSearchedAgency(event?.target?.value);
                          console.log("searching for :", event?.target?.value);
                          console.log(value);
                          if (event?.target?.value === "") {
                            form.setFieldValue("agence", "");
                          }
                        }}
                        options={agencies}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField fullWidth value={selectedAgency} {...params} placeholder="Rechercher une agence..." />}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="agence" component={MjErrorText} />
                </Box>

                <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                  <MjLabel fontWeight="500" name="role" htmlFor="">
                    role
                  </MjLabel>
                  {/* <Field name="role">
                    {({ field, form }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        value={selectedRole}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (value) {
                            setSelectedRole(value);
                            form.setFieldValue("role", value.name);
                            console.log("selected role is ::", [value.name]);
                          } else {
                            setSelectedRole(null);
                            form.setFieldValue("role", "");
                          }
                        }}
                        onInputChange={(event, value) => {
                          setSearchedRole(event?.target?.value);
                          console.log("searching for :", event?.target?.value);
                          console.log(value);
                          if (event?.target?.value === "") {
                            form.setFieldValue("role", "");
                          }
                        }}
                        options={roles}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField fullWidth value={selectedRole} {...params} placeholder="Rechercher une role..." />}
                      />
                    )}
                  </Field> */}
                  <Field name="role">
                    {({ field, form }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        value={null}
                        blurOnSelect={true}
                        noOptionsText={"Aucune option"}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (value && !selectedRoles.find((item) => item.id === value.id)) {
                            setSelectedRoleValue(value);
                            setSelectedRoles(selectedRoles.concat([value]));
                            setSelectedRolesIDS(selectedRolesIDS.concat([value.name]));
                            form.setFieldValue("role", selectedRolesIDS.concat([value.name]));
                          } else {
                            setSelectedRoleValue(null);
                          }
                        }}
                        onInputChange={(event, value) => {
                          setSearchedRoles(event?.target?.value);
                          console.log("searching for :", event?.target?.value);
                          console.log(value);
                        }}
                        options={roles}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            value={selectedRoles}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            placeholder="Rechercher des roles..."
                          />
                        )}
                      />
                    )}
                  </Field>
                  <Field name="role">
                    {({ field, form }) => (
                      <Box flexWrap="wrap" display={selectedRolesIDS.length === 0 ? "none" : "flex"} gap="10px" sx={{ paddingTop: "10px" }}>
                        {selectedRoles?.map((item, index) => (
                          <MyChip
                            key={index}
                            variant="skill"
                            label={item.name}
                            onDelete={() => {
                              setSelectedRoles(selectedRoles.filter((sel) => sel.id !== item.id));
                              setSelectedRolesIDS(selectedRolesIDS.filter((sel) => sel !== item.name));
                              form.setFieldValue(
                                "role",
                                selectedRolesIDS.filter((sel) => sel !== item.name)
                              );
                              console.log("selected roles after deleting the selected value", selectedRoles);
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  </Field>
                  <ErrorMessage name="role" component={MjErrorText} />
                </Box>

                <Box>
                  <Button type="submit" variant="mj_green_md">
                    Créer le compte
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      {/*  end modal create admin */}

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "910px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          checkboxSelection
          loading={loadin}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
            footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} lignes selectionnées` : `${count.toLocaleString()} ligne selectionnée`),
          }}
          paginationMode="server"
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
        />
      </Box>
    </Box>
  );
}

export default Comptes;
