import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Avatar, Divider, Modal, Alert, CircularProgress, Autocomplete } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
import DefaultProfilePic from "../../../imgs/user.png";
import { useLocation } from "react-router-dom";
import docgrayIcon from "../../../imgs/imgV2/DocGray.svg";

import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import MjChip from "../../../components/ui/MjChip";
import dollarIcon from "../../../imgs/imgV2/dollarIcon.svg";
import plusIconBleu from "../../../imgs/imgV2/plusIconBlue.svg";
import { ContrastRounded } from "@mui/icons-material";
import { colors } from "../../../theme/Colors";
import InterimaireDetails from "../details-Interimaire/InterimaireDetails";
import EntrepriseDetails from "../detailEntreprise/EntrepriseDetails";
import DetailContrat from "../DetailContrat";
import MyChip from "../../../components/ui/MyChip";
import { ToastContainer, toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import { modalStyle } from "../../../theme/ModalStyle";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import MyTextField from "../../../components/ui/MyTextField";
import BoxContainer from "../../../components/ui/BoxContainer";
import moment from "moment";
import ReleverDheures from "./ReleverDheures";

function DeclarerHeureDetails() {
  const location = useLocation();
  const idContrat = location.pathname.split("/")[4];
  const { user } = useAuthContext();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [action, setAction] = useState("");
  const [openSuspend, setOpenSuspend] = useState(false);

  const [open, setOpen] = useState(false);
  const [contrat, setContrat] = useState({});
  const [change, setChange] = useState(0);
  const [contratD, setContratD] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const access_token = user?.access_token;
  const signerEntreprise = () => {
    Api.patch(
      Urls.SIGNER_CONTRAT_ADMIN + idContrat,
      {
        signed_by_enterprise_at: moment(new Date()).format(),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const signerInteriamire = () => {
    Api.patch(
      Urls.SIGNER_CONTRAT_ADMIN + idContrat,
      {
        signed_by_interim_at: moment(new Date()).format(),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const etats = {
    CURRENT: { label: "En cours", variant: "active_offer" },
    PENDING: { label: "En attente", variant: "pending_contract" },
    CREATING: { label: "En cours de création", variant: "skill" },
    FINISHED: { label: "Terminé", variant: "ended_offer" },
    CANCELED: { label: "Annulé", variant: "rejected_offer" },
    UPCOMING: { label: "À venir", variant: "skill" },
    SUSPENDED: { label: "Suspendu", variant: "rejected_offer" },
  };
  const [tabs, setTabs] = useState([
    {
      id: 1,
      name: "Contrat #" + idContrat,
      icon: "📋",
    },
    {
      id: 2,
      name: "Intérimaire",
    },
    {
      id: 3,
      name: "Entreprise",
    },
    {
      id: 4,
      name: "Relevé d’heure",
    },
  ]);
  const [initialValues, setInitialValues] = useState();
  const contentSelector = (contentId) => {
    switch (contentId) {
      case 1:
        return <DetailContrat contract={contratD} id={idContrat} />;
      case 2:
        return <InterimaireDetails modify={false} idInterimaire={contratD?.user?.id} />;
      case 3:
        return <EntrepriseDetails modify={false} idEntreprise={contratD?.enterprise?.id} />;
      case 4:
        return <ReleverDheures contratD={contratD} id={idContrat} />;
      default:
        return <DetailContrat contract={contratD} id={idContrat} />;
    }
  };
  useEffect(() => {
    Api.get(Urls.GET_CONTRAT_DETAILS + idContrat, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res?.data?.contract);
        setContratD(res?.data?.contract);
        setInitialValues({
          oid_ent: res?.data?.contract?.enterprise_coffreo_oid ? res?.data?.contract?.enterprise_coffreo_oid : "",
          oid_int: res?.data?.contract?.interim_coffreo_oid ? res?.data?.contract?.interim_coffreo_oid : "",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setInitialValues({
          oid_ent: "",
          oid_int: "",
        });
      });
  }, [change]);

  //signer contrat
  return (
    <Box>
      {/* Header Section */}
      <ToastContainer />
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" mb>
          Gestion des déclarations d'heures
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box marginBottom="1rem" display="flex" gap="10px" alignItems="center">
            <Box display="flex" alignItems="center" gap="5px">
              <img src={contratD?.contract_type === "INIT" ? docgrayIcon : contratD?.contract_type === "PROLONGATION" ? plusIconBleu : dollarIcon} alt="doc-icon" />
              <Typography variant="text_clickable">{idContrat}</Typography>
            </Box>
            <Box>
              {contratD?.status === "CURRENT" && <MyChip variant="active_offer" label="En cours" />}
              {contratD?.status === "CANCELED" && <MyChip variant="rejected_offer" label="Annulé" />}
              {contratD?.status === "SUSPENDED" && <MyChip variant="rejected_offer" label={"Suspendu le " + moment(idContrat?.suspended_at).format("DD MMMM YYYY")} />}
              {contratD?.status === "UPCOMING" && <MyChip variant="skill" label="À venir" />}
              {contratD?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
              {contratD?.status === "CREATING" && <MyChip variant="skill" label="En cours de création" />}
              {contratD?.status === "PENDING" && <MyChip variant="pending_contract" label="En attente" />}
            </Box>
          </Box>
        </Box>
      </Box>
      {!loading && (
        <Box>
          {/* tabs */}
          <Box marginBottom="24px" display="flex" width="100%" sx={{ backgroundColor: "#FAFAFB", borderRadius: "6px", padding: "2px", flexDirection: { md: "row", xs: "column" } }} gap="6px">
            {tabs.map((tab, index) => (
              <Button disableRipple fullWidth key={index} variant={selectedTab === tab.id ? "mj_primary_choice_active_lg" : "mj_primary_choice_lg"} onClick={() => setSelectedTab(tab.id)}>
                <Typography fontSize="20px" mr>
                  {tab.icon}
                </Typography>
                {tab.name}
              </Button>
            ))}
          </Box>
          {/* content */}
          {contentSelector(selectedTab)}
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}

      {/* Details section */}
    </Box>
  );
}

export default DeclarerHeureDetails;
