import React from "react";
import { Box } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { colors } from "../../theme/Colors";

function CheckedCircle({ checked, width, height, bgcolor }) {
  switch (checked) {
    case true:
      return (
        <Box sx={{ width: `${width ? width : "20px"}`, height: `${height ? height : "20px"}`, borderRadius: "50px", backgroundColor: `${bgcolor ? bgcolor : "#49E59F"}`, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CheckRoundedIcon sx={{ padding: "2px" }} fontSize="10px" htmlColor="white" />
        </Box>
      );
    case false:
      return (
        <Box sx={{ width: `${width ? width : "20px"}`, height: `${height ? height : "20px"}`, borderRadius: "50px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #DEDFE4" }}>
          <CheckRoundedIcon sx={{ padding: "2px" }} fontSize="10px" htmlColor="#DEDFE4" />
        </Box>
      );
    case "PENDING":
      return (
        <Box sx={{ width: `${width ? width : "20px"}`, height: `${height ? height : "20px"}`, borderRadius: "50px", backgroundColor: `${bgcolor ? bgcolor : colors.main}`, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CheckRoundedIcon sx={{ padding: "2px" }} fontSize="10px" htmlColor="white" />
        </Box>
      );
    case "VALID":
      return (
        <Box sx={{ width: `${width ? width : "20px"}`, height: `${height ? height : "20px"}`, borderRadius: "50px", backgroundColor: `${bgcolor ? bgcolor : "#49E59F"}`, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CheckRoundedIcon sx={{ padding: "2px" }} fontSize="10px" htmlColor="white" />
        </Box>
      );

    case "NOT_VALID":
      return (
        <Box sx={{ width: `${width ? width : "20px"}`, height: `${height ? height : "20px"}`, borderRadius: "50px", backgroundColor: "red", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #DEDFE4" }}>
          <CheckRoundedIcon sx={{ padding: "2px" }} fontSize="10px" htmlColor="white" />
        </Box>
      );
  }
}

export default CheckedCircle;
