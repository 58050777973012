import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
Api.interceptors.response.use(
  (response) => {
    // Traitez la réponse réussie ici
    return response;
  },
  (error) => {
    // Gérez les erreurs ici
    console.error("Erreur Axios :", error);
    if (error?.response?.status === 401 && error?.response?.data?.message !== "The provided password is incorrect.") {
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
    // Vous pouvez rejeter l'erreur pour la traiter plus tard
    return Promise.reject(error);
  }
);
export default Api;
