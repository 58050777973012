import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Checkbox, IconButton, Modal, Alert, Autocomplete, InputAdornment } from "@mui/material";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import { FormControlLabel } from "@mui/material";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import * as yup from "yup";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import RechercheIcon from "../../imgs/imgV2/RechercheIcon.svg";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { colors } from "../../theme/Colors";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";

import ButtonSquare from "../../components/ui/ButtonSquare";

function Interimaires() {
  const [change, setChange] = useState(0);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [id, setId] = useState(null);
  const validationSchema = yup.object().shape({
    id_interimaire: yup.string(),
    telephone: yup.number().typeError("entrer un nombre valide").positive("entrer un numero valid").min(111111111, "entrer un numero valid").max(99999999999, "entrer un numero valid"),
    rib: yup.number("ce champ doit comporter un nombre").typeError("ce champ doit comporter un nombre").max(999999999999999999999999999, "un maximum de 27 chiffres"),
  });
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: "grid-header" },
    {
      field: "first_name",
      headerName: "Prénom",
      width: 150,
      renderCell: (params) => {
        if (params.row.first_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.first_name;
        }
      },
    },
    {
      field: "last_name",
      headerName: "Nom",
      width: 150,
      renderCell: (params) => {
        if (params.row.last_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.last_name;
        }
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 170,
      renderCell: (params) => {
        if (params.row.email === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.email;
        }
      },
    },
    {
      field: "birthday",
      headerName: "Date de naissance",
      width: 100,
      renderCell: (params) => {
        if (params.row.birthday === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.birthday;
        }
      },
    },
    {
      field: "phone_number",
      headerName: "Numéro de tel",
      width: 120,
      renderCell: (params) => {
        if (params.row.phone_number === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.phone_number;
        }
      },
    },
    {
      field: "rib",
      headerName: "RIB",
      width: 150,
      renderCell: (params) => {
        if (params.row.rib === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.rib;
        }
      },
    },
    {
      field: "agency_name",
      headerName: "Agence",
      width: 150,
      renderCell: (params) => {
        if (params.row.agency_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.agency_name;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            {/*
            <ButtonDataGrid
              action={"accept"}
              onClick={() => {
                setAction("valider");
                setOpen(true);
                setId(params.row);
              }}
            />
            <ButtonDataGrid
              action={"refuse"}
              onClick={() => {
                setAction("refuser");
                setOpen(true);
                setId(params.row);
              }}
            />
            */}
            <ArrowCircleRightIcon action={"details"} sx={{ cursor: "pointer", color: `${colors.mjGreenOutline}`, fontSize: 40 }} onClick={() => navigate(`/admin/gestion-des-interimaires/details/${params.row.id}`, { state: { status: params.row.status, type: "non potentiel" } })} />
          </Box>
        );
      },
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];

  const { user } = useAuthContext();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState("");
  const [actionMultiple, setactionMultiple] = useState("");
  const [open, setOpen] = useState(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const navigate = useNavigate();
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [statusH, setStatusH] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIDs, setSelectedIDs] = useState(null);
  const [status, setStatus] = useState([]);
  const [openParams, setOpenParams] = React.useState(false);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState();

  const formik = useFormik({
    initialValues: {
      id_interimaire: "",
      prenom: "",
      nom: "",
      date_min: "",
      date_max: "",
      agence: "",
      rib: "",
      telephone: "",
      checkedAtt: false,
      checkedValid: false,
      checkedPot: false,
      checkedV: false,
      checkedRef: false,
      metier: { id: "", name: "" },
      certification: { id: "", name: "" },
      cv: "",
    },
    onSubmit: () => {
      console.log(formik.values, status);
      setChange(change + 1);
    },
    validationSchema: validationSchema,
  });
  useEffect(() => {
    if (rowsPerPage && page) {
      setLoading(true);
      Api.get(Urls.GET_LISTE_RENARD + `?perPage=${25}`, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          ...(formik.values.email !== "" && { email: formik.values.email }),
          ...(formik.values.rib !== "" && { rib: formik.values.rib }),
          ...(formik.values.telephone !== "" && { phoneNumber: formik.values.telephone }),
          ...(formik.values.id_interimaire !== "" && { id: formik.values.id_interimaire }),
          ...(formik.values.date_max !== "" && formik.values.date_max !== null && { birthdayMax: formik.values.date_max }),
          ...(formik.values.date_min !== "" && formik.values.date_min !== null && { birthdayMin: formik.values.date_min }),
          ...(formik.values.nom !== "" && { lastName: formik.values.nom }),
          ...(formik.values.prenom !== "" && { firstName: formik.values.prenom }),
          ...(formik.values.agence !== "" && { agencyId: formik.values.agence }),
          ...(status.length !== 0 && { verificationDataStatus: status }),
          ...(formik.values.certification?.id !== "" && { certificateDocumentTypeIds: [formik.values.certification?.id] }),
          ...(formik.values.metier?.id !== "" && { metierIds: [formik.values.metier?.id] }),
          ...(formik.values.checkedhandicap === true && { isHandicapped: 1 }),
          ...(formik.values.checkedPrendTt === true && { isHandicapped: 0 }),
          ...(formik.values.cv !== "" && { cvContent: formik.values.cv }),
        },
      })
        .then((res) => {
          setRows(res.data.interims);
          setData(res.data);
          setLoading(false);
          setTotal(res?.data?.meta?.total);
          console.log("response el gharbi", res.data);
        })
        .catch((err) => {
          console.log(err);
          setRows([]);
        });
    }
  }, [formik.values, change]);
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };
  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.interims);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };

  const validerRenard = (id) => {
    setLoading(true);
    Api.patch(
      Urls.UPDATE_STATUS_RENARD,
      {
        user_ids: [id],
        verification_data_status: "VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };
  const refuserRenard = (id) => {
    Api.patch(
      Urls.UPDATE_STATUS_RENARD,
      {
        user_ids: [id],
        verification_data_status: "NOT_VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };
  const refuserMultipleRenard = (id) => {
    setLoading(true);
    Api.patch(
      Urls.UPDATE_STATUS_RENARD,
      {
        user_ids: selectedIDs,
        verification_data_status: "NOT_VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setLoading(false);
        setOpenMultiple(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenMultiple(false);
      });
  };
  const [agencies, setAgencies] = useState([]);
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_AGENCES + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setAgencies(res.data.agencies);
      })
      .catch((err) => {
        console.log(err);
        setAgencies([]);
      });
  }, []);
  const validerMultipleRenard = (id) => {
    setLoading(true);
    Api.patch(
      Urls.UPDATE_STATUS_RENARD,
      {
        user_ids: selectedIDs,
        verification_data_status: "VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setLoading(false);
        setOpenMultiple(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenMultiple(false);
      });
  };
  const customFooter = () => {
    return <Box></Box>;
  };
  const [certifications, setCertification] = useState([]);
  //rechercher des certificat pour le autocomplete
  const SearchCertif = (value) => {
    Api.get(Urls.SEARCH_CERTIFICATE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        console.log(res);
        setCertification(res?.data?.certificate_document_types);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //search metier
  const [metierListe, setMetierListe] = useState([]);
  const searchMetier = (value) => {
    Api.get(Urls.GET_METIER_AUTO, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        console.log(res, "search resposne");
        setMetierListe(res?.data?.metiers);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des intérimaires
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box className="filter" display="flex" flexDirection="column" gap="1rem">
          <Box display="flex" sx={{ flexDirection: { md: "column", xs: "column" } }}>
            <Box>
              <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
                Filtre :
              </Typography>
            </Box>

            {/*Search fields  */}
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="id_interimaire">
                ID intériamire
              </label>
              <TextField onChange={formik.handleChange} type="input" name="id_interimaire" placeholder="ID intérimaire" />
            </Box>
            {formik.errors.id_interimaire ? <Typography color="red">{formik.errors.id_interimaire}</Typography> : null}
          </Box>

          <Typography fontWeight="bold">Informations supplémentaires : </Typography>
          <Box marginBottom="1rem" gap="1rem" display="flex" flexWrap="wrap">
            <Box alignItems="center" gap="0.3rem" display="flex" flexDirection="column">
              <Box alignItems="center" gap="0.3rem" display="flex">
                <TextField type="text" error={formik?.errors?.nom ? true : false} placeholder="Nom de l'intérimaire" name="nom" onChange={formik.handleChange} />
              </Box>
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <TextField placeholder="Prénom de l'intérimaire" name="prenom" onChange={formik.handleChange} />
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <TextField placeholder="Email" type="input" name="email" onChange={formik.handleChange} />
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <TextField placeholder="Téléphone" type="input" name="telephone" onChange={formik.handleChange} />
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <TextField placeholder="RIB" type="input" name="rib" onChange={formik.handleChange} />
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex"></Box>
            <Box display="flex" flexWrap="wrap" alignItems="center" gap="0.3rem">
              <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarMonthIcon }}
                  value={formik.values.date_min}
                  onChange={(value) => formik.setFieldValue("date_min", value)}
                  maxDateTime={formik.values.date_max}
                  maxDate={formik.values.date_max ? formik.values.date_max : new Date()}
                  InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        type="date"
                        {...params}
                        name="date_min"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Date min de naissance",
                          disabled: "true",
                        }}
                      />
                    );
                  }}
                ></DatePicker>
              </LocalizationProvider>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("date_min", null)} />

              <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                <DatePicker
                  components={{ OpenPickerIcon: CalendarMonthIcon }}
                  value={formik.values.date_max}
                  onChange={(value) => formik.setFieldValue("date_max", value)}
                  minDateTime={formik.values.date_min}
                  minDate={formik.values.date_min ? formik.values.date_min : null}
                  InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        type="date"
                        {...params}
                        name="dateMax"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Date max de naissance",
                          disabled: "true",
                        }}
                      />
                    );
                  }}
                ></DatePicker>
              </LocalizationProvider>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("date_max", null)} />
            </Box>
            <Box gap="0.3rem" display="flex" alignItems="center">
              <Box>
                <Autocomplete
                  sx={{ minWidth: "200px" }}
                  fullWidth
                  options={agencies}
                  //options={candidaturesStates}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(e) => e.name}
                  onChange={(event, value) => {
                    if (value !== null) {
                      console.log("selecting candidature state value", value);
                      console.log("selecting candidature state value", value.id);
                      formik.setFieldValue("agence", value.id);
                      console.log(value);
                      console.log("selectedCandidatureState");
                    } else {
                      formik.setFieldValue("agence", "");
                      console.log(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Agence" />}
                />
              </Box>
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <Autocomplete
                sx={{ minWidth: "300px" }}
                disablePortal
                fullWidth
                id="combo-box-demo"
                value={formik?.values?.metier}
                onChange={(e, value) => {
                  console.log(value);
                  if (value) {
                    formik.setFieldValue("metier", value);
                  }
                }}
                options={metierListe}
                getOptionLabel={(Option) => Option.name}
                renderInput={(params) => (
                  <TextField
                    sx={{ maxWidth: "100%" }}
                    {...params}
                    variant="outlined"
                    value={formik.values?.metier}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        searchMetier(e.target.value);
                      } else {
                        setMetierListe([]);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={RechercheIcon} alt="end" />
                        </InputAdornment>
                      ),
                      endAdornment: null,
                    }}
                    placeholder="Métier..."
                  />
                )}
              />{" "}
              <ClearIcon sx={{ color: "black" }} onClick={() => formik.setFieldValue("metier", { id: "", name: "" })} />
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <Autocomplete
                sx={{ minWidth: "300px", maxWidth: "500px" }}
                disablePortal
                ListboxProps={{ style: { maxHeight: "180px" } }}
                fullWidth
                id="combo-box-demo"
                value={formik?.values?.certification}
                onChange={(e, value) => {
                  if (value) {
                    formik.setFieldValue("certification", value);
                  }
                }}
                options={certifications}
                getOptionLabel={(Option) => Option.name}
                renderInput={(params) => (
                  <TextField
                    sx={{ maxWidth: "100%" }}
                    value={formik.values.certification}
                    onChange={(e) => {
                      if (e.target.value) {
                        if (e.target.value?.length > 0) {
                          SearchCertif(e?.target?.value);
                        } else {
                          setCertification([]);
                        }
                      }
                    }}
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={RechercheIcon} alt="end" />
                        </InputAdornment>
                      ),
                      endAdornment: null,
                    }}
                    placeholder="Certification..."
                  />
                )}
              />{" "}
              <ClearIcon sx={{ color: "black" }} onClick={() => formik.setFieldValue("certification", { id: "", name: "" })} />
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <TextField placeholder="Contenu CV" type="input" name="cv" onChange={formik.handleChange} />
            </Box>

            <FormControlLabel
              label="Handicapé"
              control={
                <Checkbox
                  checked={formik.values.checkedhandicap}
                  onChange={(e) => {
                    formik.setFieldValue("checkedhandicap", e.target.checked);
                    if (formik.values.checkedPrendTt && e.target.checked) {
                      formik.setFieldValue("checkedPrendTt", false);
                    }
                  }}
                />
              }
            />
            <FormControlLabel
              label="Non handicapé"
              control={
                <Checkbox
                  checked={formik.values.checkedPrendTt}
                  onChange={(e) => {
                    formik.setFieldValue("checkedPrendTt", e.target.checked);
                    if (formik.values.checkedhandicap && e.target.checked) {
                      formik.setFieldValue("checkedhandicap", false);
                    }
                  }}
                />
              }
            />
          </Box>
        </Box>
        {/* End form section */}
        <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={open} onClose={() => setOpen(false)}>
          <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
            <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
              {action} le compte de/des interimaire
            </Box>
            <Box>
              <Typography display="inline-block" margin="1rem" textAlign="center">
                Voulez vous vraiment{" "}
                <Typography display="inline-block" fontWeight="bold">
                  {action} ce/ces comptes
                </Typography>{" "}
              </Typography>
            </Box>
            {success ? (
              <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
                {success}
              </Alert>
            ) : null}
            {error ? <Alert severity="error">{error}</Alert> : null}
            <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
              <Box onClick={() => (action === "valider" ? validerRenard(id?.id) : refuserRenard(id?.id))} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                <Button variant="mj_primary">{action}</Button>
              </Box>
              <Box onClick={() => setOpen(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                <Button variant="mj_secondary">Annuler</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openMultiple} onClose={() => setOpenMultiple(false)}>
          <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
            <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
              {actionMultiple} le compte de/des interimaire
            </Box>
            <Box>
              <Typography display="inline-block" margin="1rem" textAlign="center">
                Voulez vous vraiment{" "}
                <Typography display="inline-block" fontWeight="bold">
                  {actionMultiple} ce/ces comptes
                </Typography>{" "}
              </Typography>
            </Box>
            {success ? (
              <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
                {success}
              </Alert>
            ) : null}
            {error ? <Alert severity="error">{error}</Alert> : null}
            <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
              <Box onClick={() => (actionMultiple === "valider" ? validerMultipleRenard() : refuserMultipleRenard())} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                <Button variant="mj_primary">{actionMultiple}</Button>
              </Box>
              <Box onClick={() => setOpenMultiple(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                <Button variant="mj_secondary">Annuler</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* Actions Section */}
        <Box className="actions">
          <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
            {/*
            <Box display="flex" gap="1rem">
              <Button
                onClick={() => {
                  setOpenMultiple(true);
                  setactionMultiple("valider");
                }}
                variant="mj_primary_md"
              >
                Valider les comptes
              </Button>
            </Box>
            <Box display="flex" gap="1rem">
              <Button
                onClick={() => {
                  setOpenMultiple(true);
                  setactionMultiple("refuser");
                }}
                variant="mj_secondary_md"
              >
                Refuser
              </Button>
            </Box>
              */}
          </Box>
        </Box>
      </form>

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box marginBottom="24px" sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1255px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows ? rows : []}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          page={page - 1}
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          checkboxSelection
          loading={loading}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
          }}
          disableSelectionOnClick
          paginationMode="server"
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Interimaires;
