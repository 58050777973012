import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Avatar, Chip, Alert, Modal } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
// import DefaultProfilePic from "../../imgs/user.png";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Form, Formik, ErrorMessage, Field } from "formik";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { colors } from "../../../theme/Colors";
import * as Yup from "yup";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import MjErrorText from "../../../components/ui/MjErrorText";
import { ToastContainer, toast } from "react-toastify";

function SuspensionDetails({ change, setChange }) {
  // const [change, setChange] = useState(0);
  const params = useParams();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [suspension, setSuspension] = useState({});
  const [acceptRefuse, setOpenAcceptRefuse] = useState(false);
  const [actionAcceptRefuse, setActionAcceptRefuse] = useState(false);

  const initialValues = {
    date: "",
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Ce champs est obligatoire"),
  });

  useEffect(() => {
    Api.get("/admin/suspensions/" + params.id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("SUSPENSION OBJECT", res.data);
        setSuspension(res.data.suspension);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);

  const AcceptRefuseSuspension = (values, id, accept) => {
    console.log("hi");
    Api.post(
      "/admin/suspensions/" + id + "/accept-or-refuse",
      {
        status: accept ? "ACCEPTED" : "REFUSED",
        date: values.date,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange((change) => change + 1);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      {/* Header Section */}

      <Box display="flex" flexDirection="column" sx={{ maxWidth: "700px" }}>
        <ToastContainer />
        <BoxContainer>
          <Box marginBottom="21px">
            <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
              #{suspension.id}
            </Typography>
          </Box>

          {/* <pre>{JSON.stringify(suspension, null, 2)}</pre> */}
          <Box marginBottom="10px" display="flex" flexDirection="row" gap="10px" alignItems="center">
            <Typography variant="text_normal" fontSize="14px" fontWeight="500" lineHeight="32px">
              Déclaré par
            </Typography>
            <Chip variant="active_offer" label={suspension.declared_by} />
          </Box>
          <Box marginBottom="10px" display="flex" flexDirection="row" gap="10px" alignItems="center">
            <Typography variant="text_normal" fontSize="14px" fontWeight="500" lineHeight="32px">
              Motif du problème
            </Typography>
            <Chip variant="rejected_offer" label={suspension?.interim_problem_reason ? suspension?.interim_problem_reason?.name : suspension?.enterprise_problem_reason?.name} />
          </Box>
          <Box marginBottom="10px" display="flex" flexDirection="row" gap="10px" alignItems="top">
            <Typography variant="text_normal" fontSize="14px" fontWeight="500" lineHeight="32px">
              Détail du problème
            </Typography>
            <Typography variant="text_normal" fontSize="14px" fontWeight="600" lineHeight="32px" maxWidth="500px">
              {suspension.detail}
            </Typography>
          </Box>
          <Box marginTop="20px" display="flex" flexDirection="row-reverse" gap="10px" alignItems="top">
            <Typography variant="text_normal" fontSize="14px" fontWeight="600" lineHeight="32px" maxWidth="500px">
              {suspension.created_at}
            </Typography>
            <Typography variant="text_normal" fontSize="14px" fontWeight="500" lineHeight="32px">
              Déclaré le
            </Typography>
          </Box>
        </BoxContainer>
        <Box marginTop="10px" display="flex" flexDirection="row-reverse" gap="10px">
          <Button
            variant="mj_secondary_md"
            onClick={() => {
              setOpenAcceptRefuse(true);
              setActionAcceptRefuse(false);
            }}
          >
            Refuser la demande
          </Button>
          <Button
            variant="mj_red_md"
            onClick={() => {
              setOpenAcceptRefuse(true);
              setActionAcceptRefuse(true);
            }}
          >
            Accepter la demande
          </Button>
        </Box>

        <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={acceptRefuse} onClose={() => setOpenAcceptRefuse(false)}>
          <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                {actionAcceptRefuse ? "Accepter la demande de suspension" : "Refuser la demande de suspension"}
              </Typography>
              <ButtonSquare onClick={() => setOpenAcceptRefuse(false)} action="close" />
            </Box>

            <Formik
              //initial values
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                AcceptRefuseSuspension(values, suspension.id, actionAcceptRefuse);
                setOpenAcceptRefuse(false);
                console.log("hello");
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  {/* <Box marginBottom="20px">
                    <Typography variant="text_normal"> Saisissez la date de traitement du problème</Typography>
                  </Box> */}
                  <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                    <Field fullWidth name="date" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" label="Date traitement du problème" size="small" />
                    <ErrorMessage name="date" component={MjErrorText} />
                  </Box>

                  <Box display="flex" flexDirection="row-reverse" gap="10px">
                    <Button variant="mj_secondary_md" onClick={() => setOpenAcceptRefuse(false)}>
                      Annuler
                    </Button>
                    <Button variant="mj_primary_md" type="submit">
                      Traiter
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default SuspensionDetails;
