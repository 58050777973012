import React, { useEffect, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast } from "react-toastify";

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from "@mui/material";
import styled from "@emotion/styled";
import moment from "moment";
import MyChip from "../../components/ui/MyChip";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DEDFE4",
    color: `#0D173A`,
    fontSize: 12,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: `#666D83`,
    fontWeight: "500",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));
//SOME STYLING
const StyledTable = styled(Table)({
  borderRadius: "6px",
});
function ListeEntretiens({ applicationId }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [entretiens, setEntretiens] = useState([]);
  useEffect(() => {
    Api.get(Urls.GET_ENTRETIENS, {
      params: {
        applicationId: applicationId,
      },
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setEntretiens(res?.data?.interviews);
        console.log(res);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
      });
  }, []);
  return (
    <Box>
      {entretiens?.length > 0 && (
        <TableContainer sx={{ zIndex: 1, borderRadius: "6px", border: `1px solid #DEDFE4`, maxWidth: "1000px" }}>
          <StyledTable aria-label="customized table">
            <TableHead sx={{ backgroundColor: "#DEDFE4" }}>
              <TableRow>
                <StyledTableCell>Id entretien</StyledTableCell>
                <StyledTableCell>Date et heure</StyledTableCell>
                <StyledTableCell>Adresse</StyledTableCell>
                <StyledTableCell>Etat de l'entretien</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody position="relative">
              {entretiens?.map((entretien) => (
                <StyledTableRow>
                  <StyledTableCell>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography>{entretien?.id}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography>
                        {moment(entretien?.date_time?.split(" ")[0]).format("DD MMMM YYYY")} {entretien?.date_time?.split(" ")[1].split(":")[0] + "h" + entretien?.date_time?.split(" ")[1].split(":")[1]}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography>{entretien?.address}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" alignItems="center" gap="10px">
                      {entretien?.status === "PENDING" && <MyChip variant="skill" label="En attente" />}
                      {entretien?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}
      {entretiens?.length === 0 && (
        <Typography textAlign="center" sx={{ fontSize: "15px", fontWeight: "600" }}>
          Aucun entretien
        </Typography>
      )}
    </Box>
  );
}

export default ListeEntretiens;
