import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Divider, Modal, Alert } from "@mui/material";
import MjChip from "../../components/ui/MjChip";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { colors } from "../../theme/Colors";

function AcompteDetail() {
  const navigate = useNavigate();
  const problem = {};
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [change, setChange] = useState(0);
  const idAc = location.pathname.split("/")[4];
  const [acompte, setAcompte] = useState({});

  useEffect(() => {
    Api.get(Urls.GET_ACOMPTE_DETAIL + idAc, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setAcompte(res.data.acompte);
        console.log(res.data.acompte);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);
  //accept acompte
  const AccepterAcompte = () => {
    Api.patch(
      Urls.ACCEPT_ACOMPTE,
      {
        acompte_ids: [idAc],
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setSuccess("acompte payée");
        setError("");
        setTimeout(() => {
          setSuccess("");
          setError("");
          setOpen(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setError("une erreur est survenue");
        setError("");
        setTimeout(() => {
          setSuccess("");
          setError("");
          setOpen(false);
        }, 2000);
      });
  };
  //refuser acompte
  const refuseAcompte = () => {
    Api.patch(
      Urls.REFUSE_ACOMPTE,
      {
        acompte_ids: [idAc],
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        navigate("/admin/gestion-des-acomptes");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" mb>
          Gestion des acomtpes
        </Typography>
        <Typography variant="h5" component="h1">
          Détail contrat
        </Typography>
      </Box>

      {/* Probleme SECTION */}
      <Box sx={{ margin: "1rem 0rem" }}>
        <Typography marginBottom="0.7rem">
          Intérimaire :{" "}
          <Typography variant="detail_orange" sx={{ textDecoration: "underline" }}>
            {acompte?.first_name ? acompte?.first_name : ""} {acompte?.last_name ? acompte?.last_name : ""}
          </Typography>
        </Typography>
        <Box width="fit-content">
          <Box width="fit-content" display="flex" sx={{ gap: { xs: "1rem", sm: "3rem", md: "10rem", lg: "15rem" } }}>
            <Typography marginBottom="0.7rem">
              Date :{" "}
              <Typography variant="detail_gray_underline" sx={{ textDecoration: "underline" }}>
                {acompte?.created_at ? acompte?.created_at?.split("T")[0].split("-")[1] + "/" + acompte?.created_at?.split("T")[0].split("-")[0] : "-"}
              </Typography>
            </Typography>
            <Box sx={{ marginBottom: "0.3rem" }} display="flex" alignItems="center" gap="0.3rem">
              <Typography>Etat : </Typography>
              <MjChip name="paid" />
            </Box>
          </Box>
          <Divider />
        </Box>
        <Typography marginBottom="0.7rem">
          Mission : <Typography variant="detail_gray_underline">{acompte?.intitule_poste ? acompte.intitule_poste : "-"}</Typography>
        </Typography>
        <Typography marginBottom="0.7rem">
          Numéro du contrat :{" "}
          <Typography variant="detail_orange" sx={{ textDecoration: "underline" }}>
            {" "}
            {acompte?.contrat_id ? acompte?.contrat_id : "-"}
          </Typography>
        </Typography>
        <Typography marginBottom="0.7rem">
          Entreprise :{" "}
          <Typography variant="detail_orange" sx={{ textDecoration: "underline" }}>
            {acompte?.entreprise_name ? acompte?.entreprise_name : "-"}
          </Typography>
        </Typography>
        <Typography>
          Nombre d'heures : <Typography variant="detail_gray_underline">{acompte?.nbr_hours_declared_by_malinjob ? acompte?.nbr_hours_declared_by_malinjob + " heures" : "-"}</Typography>
        </Typography>
        <Typography marginBottom="0.7rem">
          Montant :{" "}
          <Typography variant="detail_orange" sx={{ textDecoration: "underline" }}>
            {acompte?.montant ? acompte?.montant + " €" : "-"}
          </Typography>
        </Typography>
      </Box>

      {/* actions section */}
      <Box sx={{ margin: "2rem 0rem", display: "flex", gap: "1rem" }}>
        {acompte?.is_paid === 1 ? null : (
          <Button
            onClick={() => {
              setOpen(true);
              setAction("payer l'acompte");
            }}
            variant="mj_primary"
          >
            Payer
          </Button>
        )}
        <Button
          onClick={() => {
            setOpen(true);
            setAction("Annuler la demande d'acopmte");
          }}
          variant="mj_secondary"
        >
          Annuler la demande
        </Button>
        <Button variant="mj_primary">Télécharger (PEM)</Button>
      </Box>
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={open} onClose={() => setOpen(false)}>
        <Box width="400px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
          <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
            {action}
          </Box>
          <Box>
            <Typography display="inline-block" margin="1rem" textAlign="center">
              Voulez vous vraiment{" "}
              <Typography display="inline-block" fontWeight="bold">
                {action}
              </Typography>{" "}
            </Typography>
          </Box>
          {success ? (
            <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
              {success}
            </Alert>
          ) : null}
          {error ? <Alert severity="error">{error}</Alert> : null}
          <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
            <Box onClick={() => (action === "payer l'acompte" ? AccepterAcompte() : refuseAcompte())} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
              <Button variant="mj_primary">{action}</Button>
            </Box>
            <Box onClick={() => setOpen(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
              <Button variant="mj_secondary">Annuler</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default AcompteDetail;
