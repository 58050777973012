import React, { useState, useEffect } from "react";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { Box, Button, Divider, Modal, Typography, InputAdornment, TextField, Autocomplete, FormControlLabel, Checkbox, Stepper, Step, StepButton, StepContent, StepLabel } from "@mui/material";
import { colors } from "../../../theme/Colors";
import { modalStyle } from "../../../theme/ModalStyle";
import LensIcon from "@mui/icons-material/Lens";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import dropDownIcon from "../../../imgs/imgV2/DropDownIcon.svg";
import MyTextField from "../../../components/ui/MyTextField";
import MyDatePicker from "../../../components/ui/MyDatePicker";
import MyDP from "../../../components/ui/MyDP";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import checkLinkicon from "../../../imgs/imgV2/checkLinkIcon.svg";
import { useRef } from "react";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import * as Yup from "yup";
import plusIcon from "../../../imgs/imgV2/plusIcon.svg";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import MyChip from "../../../components/ui/MyChip";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import { useAuthContext } from "../../../hooks/useAuthContext";
import moment from "moment";
import { create } from "yup/lib/Reference";
function MetierCV({ id, updateBord, setUpdateBoard, modify }) {
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [value, setValue] = useState("");
  const [change, setChange] = useState(false);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [update, setUpdate] = useState("first");
  const [userHardSkills, setUserHardSkills] = useState([]);
  const [userSoftSkills, setUserSoftSkills] = useState([]);
  const [file, setFile] = useState();
  const [idExp, setIDExp] = useState();
  const [selected, setSelected] = useState([]);
  const [selectedSoft, setSelectedSoft] = useState([]);
  const [disabledDateFin, setDisabledDateFin] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [hardSkillsListe, setHardSkillsListe] = useState([]);
  const [initialValuesExp, setInitialValuesExp] = useState({
    job_title: "",
    nom_ent: "",
    date_debut: "",
    date_fin: "",
    exp: "",
    checked: false,
  });
  //validation schema fot the exp form
  const validationSchemaExp = Yup.object().shape({
    job_title: Yup.string().required("Ce champ est obligatoire"),
    nom_ent: Yup.string().required("Ce champ est obligatoire"),
    date_debut: Yup.date().typeError("Entrer une date valide").required("Ce champ est obligatoire"),
    date_fin: Yup.date().typeError("Entrer une date valide").required("Ce champ est obligatoire"),
    exp: Yup.string().required("Ce champ est obligatoire"),
  });
  //validation schema fot the exp form
  const validationSchemaWithoutDate = Yup.object().shape({
    job_title: Yup.string().required("Ce champ est obligatoire"),
    nom_ent: Yup.string().required("Ce champ est obligatoire"),
    date_debut: Yup.date().typeError("Entrer une date valide").required("Ce champ est obligatoire"),
    exp: Yup.string().required("Ce champ est obligatoire"),
  });
  //validationSchema for the first form
  const validationSchema = Yup.object().shape({
    titre: Yup.string().required("Ce champ est obligatoire"),
  });
  const formExp = useFormik({
    initialValues: initialValuesExp,
    onSubmit: (values) => {
      const obj = {
        id: expToCompare.length + 1,
        job_title: values.job_title,
        start_date: values.date_debut,
        end_date: values.date_fin,
        detail: values.exp,
        societe_name: values.nom_ent,
      };
      setNewExperiences(newExperiences.concat([obj]));
      formExp.setFieldValue("job_title", "");
      formExp.setFieldValue("exp", "");
      formExp.setFieldValue("date_debut", null);
      formExp.setFieldValue("date_fin", null);
      formExp.setFieldValue("nom_ent", "");
      setOpenAdd(false);
    },
    validationSchema: disabledDateFin ? validationSchemaWithoutDate : validationSchemaExp,
  });
  const formExpUpdate = useFormik({
    initialValues: initialValuesExp,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const [exp, setExp] = useState([]);
  const [softSkillsListe, setSoftSkillsListe] = useState([]);
  //fsunction to handle when click on the modify icon
  const handleModifyClick = (cert) => {
    setOpen(true);
  };

  const [initialValues, setInitialValues] = useState({
    titre: "",
  });
  //handle delite exp
  const [isDelete, setIsDelete] = useState({ state: false, value: null });
  const handleDeliteExp = (expe) => {
    setExperienceToDelete(experienceTodelete.concat([expe]));
    const filteredListe = expToCompare.filter((exp) => exp.id !== expe);
    const filteredListe2 = expCopy.filter((exp) => exp.id !== expe);
    setExpToCompare(filteredListe);
    setExpCofy(filteredListe2);
  };
  const handleDelitenewExp = (expe) => {
    const filteredListe = newExperiences.filter((exp) => exp.id !== expe);
    setNewExperiences(filteredListe);
  };

  const stepperStyle = {
    ".MuiSvgIcon-root:not(.Mui-completed)": {
      color: colors.main,
    },
  };
  //state list experience
  const [expToCompare, setExpToCompare] = useState([]);
  const [newExperiences, setNewExperiences] = useState([]);
  const [experienceTodelete, setExperienceToDelete] = useState([]);
  const [expToShow, setExpToshow] = useState([]);
  const [data, setData] = useState();
  const [dataModal, setDataModal] = useState();
  const [expCopy, setExpCofy] = useState([]);
  useEffect(() => {
    Api.get(Urls.LISTE_EXPERIENCE + id + "/experiences", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 10,
        userId: id,
      },
    })
      .then((res) => {
        console.log(res, "liste des experiences");
        setExp(res?.data?.experiences);
        setExpCofy(res?.data?.experiences);
        setExpToCompare(res?.data?.experiences);
        setData(res?.data);
        setDataModal(res?.data);
      })
      .catch((err) => {});
  }, [change]);
  //create experience
  const createExperience = (values) => {
    console.log(values);
    Api.post(
      Urls.ADD_EXPERIENCEs + id + "/experiences",
      {
        job_title: values.job_title,
        societe_name: values.societe_name,
        start_date: values.start_date,
        end_date: disabledDateFin ? null : values.end_date,
        detail: values.detail,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setDisabledDateFin(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  //update experience
  const UpdateExperience = (values) => {
    console.log(values);
    Api.patch(
      Urls.UPDATE_EXPERIENCE + values.id,
      {
        ...(values?.job_title !== "" && { job_title: values?.job_title }),
        ...(values?.societe_name !== "" && { societe_name: values.societe_name }),
        ...(values?.start_date !== "" && { start_date: values.start_date }),
        ...(values?.end_date !== "" && { end_date: values.end_date }),
        ...(values?.exp !== "" && { detail: values.detail }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  //search hard skills
  const searchHardSkills = (value) => {
    Api.get(Urls.GET_HARD_SKILLS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        console.log(res);
        setHardSkillsListe(res?.data?.hard_skills);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //search soft skills
  const searchSoftSKills = (value) => {
    Api.get(Urls.GET_SKILLS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        console.log(res);
        setSoftSkillsListe(res?.data?.skills);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //get user soft skills
  const [lastSelected, setLastselected] = useState({});
  useEffect(() => {
    Api.get(Urls.GET_USER_SKILLS + id + "/skills", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res.data, "competences");
        setSelectedSoft(res?.data?.skills);
        setUserSoftSkills(res?.data?.skills);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);
  //save hard skills
  const saveHardSkills = () => {
    let ListeOfSelection = [];
    for (let index = 0; index < selected.length; index++) {
      const element = selected[index];
      ListeOfSelection.push(element.id);
    }
    console.log(ListeOfSelection);
    Api.put(
      Urls.PUT_SKILLS + id + "/skills",
      {
        ids: ListeOfSelection,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  //save soft skills
  const saveSoftSkills = () => {
    let ListeOfSelection = [];
    for (let index = 0; index < selectedSoft.length; index++) {
      const element = selectedSoft[index];
      ListeOfSelection.push(element.id);
    }
    console.log(ListeOfSelection);
    Api.put(
      Urls.PUT_SKILLS + id + "/skills",
      {
        ids: ListeOfSelection,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  //delete experience
  const deleteExp = (id) => {
    Api.delete(Urls.DELETE_EXPERIENCE + id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setIsDelete({
          state: false,
          value: null,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  //handle pagination
  const handlePagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 10,
      },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setExp(exp.concat(res?.data?.experiences));
        setData(res.data);
      }
      if (err) {
        toast.error(err?.response?.data?.message);
      }
    });
  };
  //handle pagination modal
  const handlePaginationModal = () => {
    Api.get(`${data.links.next}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 10,
      },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setExpToCompare(expToCompare.concat(res?.data?.experiences));
        setExp(exp.concat(res?.data?.experiences));
        setDataModal(res.data);
      }
      if (err) {
        toast.error(err?.response?.data?.message);
      }
    });
  };
  const valuesToEdit = () => {};
  const valuesToDelite = () => {};
  const valuesToAdd = () => {};
  const executeSave = () => {
    let listToUpdate = [];
    for (let i = 0; i < expToCompare.length; i++) {
      if (
        expCopy[i].id === expToCompare[i]?.id &&
        (expCopy[i]?.start_date !== expToCompare[i]?.start_date || expCopy[i]?.end_date !== expToCompare[i]?.end_date || expCopy[i]?.societe_name !== expToCompare[i]?.societe_name || expCopy[i]?.detail !== expToCompare[i]?.detail || expCopy[i]?.job_title !== expToCompare[i]?.job_title)
      ) {
        listToUpdate.push(expToCompare[i]);
      }
    }
    let pass = false;
    let executeUpdate = false;
    if (listToUpdate.length === 0) {
      pass = true;
      executeUpdate = false;
    }
    for (let index = 0; index < listToUpdate?.length; index++) {
      if (listToUpdate[index]?.detail === "" || listToUpdate[index]?.societe_name === "" || listToUpdate[index]?.job_title === "") {
        toast.error("Un des champs entré est vide");
      } else {
        pass = true;
        executeUpdate = true;
      }
    }
    for (let index = 0; index < newExperiences?.length; index++) {
      if (newExperiences[index]?.detail === "" || newExperiences[index]?.societe_name === "" || newExperiences[index]?.job_title === "") {
        toast.error("Un des champs entré est vide");
        pass = false;
      } else {
        pass = pass && true;
        executeUpdate = false;
      }
    }
    if (pass === true) {
      if (executeUpdate) {
        for (let index = 0; index < listToUpdate.length; index++) {
          UpdateExperience(listToUpdate[index]);
        }
      }
      saveSoftSkills();
      for (let index = 0; index < newExperiences.length; index++) {
        createExperience(newExperiences[index]);
      }
      for (let index = 0; index < experienceTodelete.length; index++) {
        deleteExp(experienceTodelete[index]);
      }
      setTimeout(() => {
        setChange(!change);
      }, 1000);
    }
    if (pass) {
      setOpen(false);
      setNewExperiences([]);
      setExperienceToDelete([]);
      setExpToCompare([]);
    }
    setChange(!change);
    setUpdateBoard(!updateBord);
  };
  console.log(newExperiences, "new experiences");
  console.log(expToCompare, "la liste des exp to compare");
  console.log(experienceTodelete, "exp to delete");
  return (
    <Box position="relative">
      <BoxContainer display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap="2px">
            <Typography variant="text_xxl" fontWeight="700">
              Mon CV
            </Typography>
          </Box>
          {modify && <ButtonSquare onClick={handleModifyClick} action="modify" />}
        </Box>
        <Divider sx={{ margin: "15px 0px" }} />
        <Box display="flex" flexDirection="column" gap="20px">
          <Box>
            <Typography fontSize="14px" fontWeight="600" lineHeight="22.4px">
              Expériences professionnelles
            </Typography>
          </Box>
          {exp?.length > 0 ? (
            <Stepper nonLinear orientation="vertical" sx={stepperStyle}>
              {exp.map((step, index) => (
                <Step active={true}>
                  <StepButton icon={<LensIcon sx={{ marginLeft: "5px" }} fontSize="10" />} color={step.end_date ? "#000B30" : colors.main} disableRipple>
                    <StepLabel sx={{ paddingTop: "0rem" }}>
                      <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
                        <Box>
                          <Typography sx={{ display: "inline", fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>{step?.job_title} à </Typography>
                          <Typography sx={{ display: "inline", fontSize: "14px", fontWeight: "700", lineHeight: "22.4px" }}>{step.societe_name}</Typography>
                        </Box>
                        <Typography fontSize="12px" fontWeight="400" lineHeight="19.2px" color="#666D83">
                          | {moment(step.start_date).format("MMMM YYYY")} - {step.end_date ? moment(step.end_date).format("MMMM YYYY") : "jusqu'à maintenant"}
                        </Typography>
                      </Box>
                    </StepLabel>
                  </StepButton>
                  <StepContent>
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="22.4px" color="#2E3755">
                        {step.detail}
                      </Typography>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          ) : (
            <Box>
              <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "600" }}>
                Contenu vide
              </Typography>
            </Box>
          )}
          {data?.links?.next && (
            <Box sx={{ cursor: "pointer" }} display="flex" justifyContent="center" alignItems="center">
              <ControlPointRoundedIcon fontSize="large" sx={{ color: `${colors.main}` }} onClick={handlePagination} />
            </Box>
          )}
          <Box display="flex" gap="8px" flexDirection="column">
            <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "22.4px" }}>Compétences</Typography>
            {userSoftSkills?.length > 0 ? (
              <Box flexWrap="wrap" display="flex" alignItems="center" gap="10px">
                {userSoftSkills?.map((soft) => (
                  <MyChip variant="skill" label={soft.name} />
                ))}
              </Box>
            ) : (
              <Box>
                <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Contenu vide
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </BoxContainer>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Mon CV
              </Typography>
              <Box>
                <Button variant="mj_primary_md">Importer votre CV</Button>
                <ButtonSquare onClick={() => setOpen(false)} action="close" />
              </Box>
            </Box>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={(values) => {
                createExperience(values);
              }}
              validationSchema={validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Expériences profesionnelles</Typography>
                      <Button onClick={() => setOpenAdd(true)} variant="mj_secondary_md" sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <img src={plusIcon} alt="add-icon" />
                        Ajouter
                      </Button>
                    </Box>
                    {openAdd && (
                      <form>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Intitulé du poste
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <TextField fullWidth onChange={formExp.handleChange} value={formExp.values.job_title} name="job_title" placeholder="Intitulé du poste..." />
                                {formExp.errors.job_title && formExp.touched.job_title ? (
                                  <Typography color="red" fontSize="12px">
                                    {formExp.errors.job_title}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Nom de l’entreprise
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <TextField fullWidth onChange={formExp.handleChange} value={formExp.values.nom_ent} name="nom_ent" placeholder="Nom de l’entreprise..." />
                                {formExp.errors.nom_ent && formExp.touched.nom_ent ? (
                                  <Typography color="red" fontSize="12px">
                                    {formExp.errors.nom_ent}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Date de début
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  views={["month", "year"]}
                                  components={{ OpenPickerIcon: CalendarMonthIcon }}
                                  name="date_debut"
                                  maxDate={formExp.values?.date_fin ? formExp.values?.date_fin : new Date().toISOString().slice(0, 10)}
                                  value={formExp.values?.date_debut}
                                  inputFormat="MM-yyyy"
                                  onChange={(value) => {
                                    formExp.setFieldValue("date_debut", moment(value).format("yyyy-MM-DD"));
                                  }}
                                  InputProps={{ sx: { "& .MuiSvgIcon-root": { height: "14px", width: "14px" } } }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        type="time"
                                        fullWidth
                                        error={false}
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "Mois/Année",
                                        }}
                                      />
                                    );
                                  }}
                                ></DesktopDatePicker>
                              </LocalizationProvider>
                              {formExp.errors.date_debut && formExp.touched.date_debut ? (
                                <Typography color="red" fontSize="12px">
                                  {formExp.errors.date_debut}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Date de fin
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box gap="16px" display="flex" alignItems="center">
                                <Box width="100%">
                                  <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                      views={["month", "year"]}
                                      disabled={disabledDateFin}
                                      components={{ OpenPickerIcon: CalendarMonthIcon }}
                                      name="date_fin"
                                      maxDate={new Date()}
                                      minDate={formExp.values?.date_debut ? formExp.values?.date_debut : null}
                                      value={formExp?.values?.date_fin}
                                      inputFormat="MM-yyyy"
                                      onChange={(value) => {
                                        formExp.setFieldValue("date_fin", moment(value).format("yyyy-MM-DD"));
                                      }}
                                      InputProps={{ sx: { "& .MuiSvgIcon-root": { height: "14px", width: "14px" } } }}
                                      renderInput={(params) => {
                                        return (
                                          <TextField
                                            type="time"
                                            fullWidth
                                            {...params}
                                            disabled={disabledDateFin}
                                            error={false}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "Mois/Année",
                                            }}
                                          />
                                        );
                                      }}
                                    ></DesktopDatePicker>
                                  </LocalizationProvider>
                                  {formExp.errors.date_fin && formExp.touched.date_fin ? (
                                    <Typography color="red" fontSize="12px">
                                      {formExp.errors.date_fin}
                                    </Typography>
                                  ) : null}
                                </Box>
                                <Box width="100%">
                                  <FormControlLabel
                                    onChange={(e) => {
                                      e.target.checked ? formExp.setFieldValue("date_fin", null) && setDisabledDateFin(true) : formExp.setFieldValue("date_fin", new Date().toISOString().slice(0, 10)) && setDisabledDateFin(false);
                                    }}
                                    control={<Checkbox checked={disabledDateFin} />}
                                    label="J’y suis toujours"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Décrivez votre expérience
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <TextField
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    formExp.setFieldValue("exp", e.target.value);
                                  }}
                                  value={formExp?.values?.exp}
                                  placeholder="Décrivez votre expérience..."
                                  name="exp"
                                  type="input"
                                  id="description"
                                  multiline
                                  fullWidth
                                  columns={5}
                                  rows={5}
                                  autoComplete="off"
                                />
                                {formExp.errors.exp && formExp.touched.exp ? (
                                  <Typography color="red" fontSize="12px">
                                    {formExp.errors.exp}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box marginTop="16px" display="flex" flexDirection="row" gap="16px" justifyContent="end">
                          <Button onClick={() => setOpenAdd(false)} variant="mj_secondary_sm">
                            Annuler
                          </Button>
                          <Button onClick={formExp.handleSubmit} variant="mj_primary_md">
                            Enregistrer
                          </Button>
                        </Box>
                        <Divider sx={{ margin: "15px 0px", opacity: "0" }} />
                      </form>
                    )}
                    {newExperiences && (
                      <Box>
                        {newExperiences?.map((exp, index) => (
                          <Box display="flex" flexDirection="column" gap="16px">
                            <Box display="flex" alignItems="center" gap="2px">
                              <Typography sx={{ cursor: "pointer" }}>#{index + 1}</Typography>
                              <ButtonSquare onClick={() => handleDelitenewExp(exp?.id)} action="delete" />
                            </Box>
                            <form>
                              <Box display="flex" flexDirection="column" gap="20px">
                                <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Intitulé du poste
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        onChange={(e) => {
                                          const updatedObject = { ...newExperiences[index], job_title: e.target.value };
                                          const updatedList = [...newExperiences.slice(0, index), updatedObject, ...newExperiences.slice(index + 1)];
                                          setNewExperiences(updatedList);
                                        }}
                                        value={exp.job_title}
                                        name="nom_ent"
                                        placeholder="Intitulé du poste*..."
                                      />
                                      {exp?.job_title === "" ? (
                                        <Typography color="red" fontSize="12px">
                                          Ce champ est obligatoire
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Nom de l’entreprise
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        onChange={(e) => {
                                          const updatedObject = { ...newExperiences[index], societe_name: e.target.value };
                                          const updatedList = [...newExperiences.slice(0, index), updatedObject, ...newExperiences.slice(index + 1)];
                                          setNewExperiences(updatedList);
                                        }}
                                        value={exp.societe_name}
                                        name="nom_ent"
                                        placeholder="Nom de l’entreprise..."
                                      />
                                      {exp?.societe_name === "" ? (
                                        <Typography color="red" fontSize="12px">
                                          Ce champ est obligatoire
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Date de début
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                                      <DesktopDatePicker
                                        views={["month", "year"]}
                                        components={{ OpenPickerIcon: CalendarMonthIcon }}
                                        name="end_date"
                                        maxDate={exp?.end_date ? exp?.end_date : new Date().toISOString().slice(0, 10)}
                                        value={exp?.start_date}
                                        inputFormat="MM-yyyy"
                                        onChange={(value) => {
                                          const updatedObject = { ...newExperiences[index], start_date: moment(value).format("yyyy-MM-DD") };
                                          const updatedList = [...newExperiences.slice(0, index), updatedObject, ...newExperiences.slice(index + 1)];
                                          setNewExperiences(updatedList);
                                        }}
                                        InputProps={{ sx: { "& .MuiSvgIcon-root": { height: "14px", width: "14px" } } }}
                                        renderInput={(params) => {
                                          return (
                                            <TextField
                                              type="time"
                                              fullWidth
                                              error={false}
                                              {...params}
                                              inputProps={{
                                                ...params.inputProps,
                                                placeholder: "Mois/Année",
                                              }}
                                            />
                                          );
                                        }}
                                      ></DesktopDatePicker>
                                    </LocalizationProvider>
                                  </Box>
                                </Box>
                                <Box display="flex" gap="16px" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Date de fin
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box gap="16px" display="flex" alignItems="center">
                                      <Box width="100%">
                                        <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                                          <DesktopDatePicker
                                            views={["month", "year"]}
                                            disabled={exp?.end_date ? false : true}
                                            components={{ OpenPickerIcon: CalendarMonthIcon }}
                                            name="end_date"
                                            maxDate={new Date()}
                                            minDate={exp?.start_date ? exp?.start_date : null}
                                            value={exp?.end_date}
                                            inputFormat="MM-yyyy"
                                            onChange={(value) => {
                                              const updatedObject = { ...newExperiences[index], end_date: moment(value).format("yyyy-MM-DD") };
                                              const updatedList = [...newExperiences.slice(0, index), updatedObject, ...newExperiences.slice(index + 1)];
                                              setNewExperiences(updatedList);
                                            }}
                                            InputProps={{ sx: { "& .MuiSvgIcon-root": { height: "14px", width: "14px" } } }}
                                            renderInput={(params) => {
                                              return (
                                                <TextField
                                                  type="time"
                                                  fullWidth
                                                  {...params}
                                                  disabled={exp?.end_date ? false : true}
                                                  error={false}
                                                  inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Mois/Année",
                                                  }}
                                                />
                                              );
                                            }}
                                          ></DesktopDatePicker>
                                        </LocalizationProvider>
                                        {formExpUpdate.errors.date_fin && formExpUpdate.touched.date_fin ? (
                                          <Typography color="red" fontSize="12px">
                                            {formExpUpdate.errors.date_fin}
                                          </Typography>
                                        ) : null}
                                      </Box>
                                      <Box width="100%">
                                        <FormControlLabel
                                          onChange={(e) => {
                                            const updatedObject = { ...newExperiences[index], end_date: e.target.checked ? null : new Date().toISOString().slice(0, 10) };
                                            const updatedList = [...newExperiences.slice(0, index), updatedObject, ...newExperiences.slice(index + 1)];
                                            setNewExperiences(updatedList);
                                          }}
                                          control={<Checkbox checked={exp?.end_date ? false : true} />}
                                          label="J’y suis toujours"
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box display="flex" gap="16px" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Décrivez votre expérience
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        onChange={(e) => {
                                          const updatedObject = { ...newExperiences[index], detail: e.target.value };
                                          const updatedList = [...newExperiences.slice(0, index), updatedObject, ...newExperiences.slice(index + 1)];
                                          setNewExperiences(updatedList);
                                        }}
                                        value={exp.detail}
                                        placeholder="Décrivez votre expérience..."
                                        name="exp"
                                        type="input"
                                        id="description"
                                        multiline
                                        fullWidth
                                        columns={5}
                                        rows={5}
                                        autoComplete="off"
                                      />
                                      {exp?.detail === "" ? (
                                        <Typography color="red" fontSize="12px">
                                          Ce champ est obligatoire
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Divider sx={{ margin: "15px 0px", opacity: "0" }} />
                            </form>
                          </Box>
                        ))}
                        {dataModal?.links?.next && (
                          <Box sx={{ cursor: "pointer" }} display="flex" justifyContent="center" alignItems="center">
                            <ControlPointRoundedIcon fontSize="small" sx={{ color: `${colors.main}` }} onClick={handlePaginationModal} />
                          </Box>
                        )}
                      </Box>
                    )}
                    {expToCompare && (
                      <Box>
                        {expToCompare?.map((exp, index) => (
                          <Box display="flex" flexDirection="column" gap="16px">
                            <Box display="flex" alignItems="center" gap="2px">
                              <Typography sx={{ cursor: "pointer" }}>#{index + 1}</Typography>
                              <ButtonSquare onClick={() => handleDeliteExp(exp?.id)} action="delete" />
                            </Box>
                            <form>
                              <Box display="flex" flexDirection="column" gap="20px">
                                <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Intitulé du poste
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        onChange={(e) => {
                                          const updatedObject = { ...expToCompare[index], job_title: e.target.value };
                                          const updatedList = [...expToCompare.slice(0, index), updatedObject, ...expToCompare.slice(index + 1)];
                                          setExpToCompare(updatedList);
                                        }}
                                        value={exp.job_title}
                                        name="nom_ent"
                                        placeholder="Intitulé du poste*..."
                                      />
                                      {exp?.job_title === "" ? (
                                        <Typography color="red" fontSize="12px">
                                          Ce champ est obligatoire
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Nom de l’entreprise
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        fullWidth
                                        onChange={(e) => {
                                          const updatedObject = { ...expToCompare[index], societe_name: e.target.value };
                                          const updatedList = [...expToCompare.slice(0, index), updatedObject, ...expToCompare.slice(index + 1)];
                                          setExpToCompare(updatedList);
                                        }}
                                        value={exp.societe_name}
                                        name="nom_ent"
                                        placeholder="Nom de l’entreprise..."
                                      />
                                      {exp?.societe_name === "" ? (
                                        <Typography color="red" fontSize="12px">
                                          Ce champ est obligatoire
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Date de début
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                                      <DesktopDatePicker
                                        views={["month", "year"]}
                                        components={{ OpenPickerIcon: CalendarMonthIcon }}
                                        name="end_date"
                                        maxDate={exp?.end_date ? exp?.end_date : new Date().toISOString().slice(0, 10)}
                                        value={exp?.start_date}
                                        inputFormat="MM-yyyy"
                                        onChange={(value) => {
                                          const updatedObject = { ...expToCompare[index], start_date: moment(value).format("yyyy-MM-DD") };
                                          const updatedList = [...expToCompare.slice(0, index), updatedObject, ...expToCompare.slice(index + 1)];
                                          setExpToCompare(updatedList);
                                        }}
                                        InputProps={{ sx: { "& .MuiSvgIcon-root": { height: "14px", width: "14px" } } }}
                                        renderInput={(params) => {
                                          return (
                                            <TextField
                                              type="time"
                                              fullWidth
                                              error={false}
                                              {...params}
                                              inputProps={{
                                                ...params.inputProps,
                                                placeholder: "Mois/Année",
                                              }}
                                            />
                                          );
                                        }}
                                      ></DesktopDatePicker>
                                    </LocalizationProvider>
                                  </Box>
                                </Box>
                                <Box display="flex" gap="16px" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Date de fin
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box gap="16px" display="flex" alignItems="center">
                                      <Box width="100%">
                                        <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                                          <DesktopDatePicker
                                            views={["month", "year"]}
                                            disabled={exp?.end_date ? false : true}
                                            components={{ OpenPickerIcon: CalendarMonthIcon }}
                                            name="end_date"
                                            maxDate={new Date()}
                                            minDate={exp?.start_date ? exp?.start_date : null}
                                            value={exp?.end_date}
                                            inputFormat="MM-yyyy"
                                            onChange={(value) => {
                                              const updatedObject = { ...expToCompare[index], end_date: moment(value).format("yyyy-MM-DD") };
                                              const updatedList = [...expToCompare.slice(0, index), updatedObject, ...expToCompare.slice(index + 1)];
                                              setExpToCompare(updatedList);
                                            }}
                                            InputProps={{ sx: { "& .MuiSvgIcon-root": { height: "14px", width: "14px" } } }}
                                            renderInput={(params) => {
                                              return (
                                                <TextField
                                                  type="time"
                                                  fullWidth
                                                  {...params}
                                                  disabled={exp?.end_date ? false : true}
                                                  error={false}
                                                  inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Mois/Année",
                                                  }}
                                                />
                                              );
                                            }}
                                          ></DesktopDatePicker>
                                        </LocalizationProvider>
                                        {formExpUpdate.errors.date_fin && formExpUpdate.touched.date_fin ? (
                                          <Typography color="red" fontSize="12px">
                                            {formExpUpdate.errors.date_fin}
                                          </Typography>
                                        ) : null}
                                      </Box>
                                      <Box width="100%">
                                        <FormControlLabel
                                          onChange={(e) => {
                                            const updatedObject = { ...expToCompare[index], end_date: e.target.checked ? null : new Date().toISOString().slice(0, 10) };
                                            const updatedList = [...expToCompare.slice(0, index), updatedObject, ...expToCompare.slice(index + 1)];
                                            setExpToCompare(updatedList);
                                          }}
                                          control={<Checkbox checked={exp?.end_date ? false : true} />}
                                          label="J’y suis toujours"
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box display="flex" gap="16px" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                                  <Box width="100%">
                                    <Box marginBottom="6px">
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                        Décrivez votre expérience
                                      </Typography>
                                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                        *
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <TextField
                                        onChange={(e) => {
                                          const updatedObject = { ...expToCompare[index], detail: e.target.value };
                                          const updatedList = [...expToCompare.slice(0, index), updatedObject, ...expToCompare.slice(index + 1)];
                                          setExpToCompare(updatedList);
                                        }}
                                        value={exp.detail}
                                        placeholder="Décrivez votre expérience..."
                                        name="exp"
                                        type="input"
                                        id="description"
                                        multiline
                                        fullWidth
                                        columns={5}
                                        rows={5}
                                        autoComplete="off"
                                      />
                                      {exp?.detail === "" ? (
                                        <Typography color="red" fontSize="12px">
                                          Ce champ est obligatoire
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Divider sx={{ margin: "15px 0px", opacity: "0" }} />
                            </form>
                          </Box>
                        ))}
                        {dataModal?.links?.next && (
                          <Box sx={{ cursor: "pointer" }} display="flex" justifyContent="center" alignItems="center">
                            <ControlPointRoundedIcon fontSize="small" sx={{ color: `${colors.main}` }} onClick={handlePaginationModal} />
                          </Box>
                        )}
                      </Box>
                    )}
                    <Divider sx={{ margin: "10px 0px" }} />
                    <Box>
                      <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Compétences</Typography>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Rechercher
                          </Typography>
                        </Box>
                        <Box>
                          <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            value={null}
                            blurOnSelect={true}
                            onChange={(e, value) => {
                              console.log(value);
                              const exist = selectedSoft?.filter((select) => select.id === value.id);
                              if (!exist[0]) {
                                setSelectedSoft(selectedSoft.concat([value]));
                              }
                              setSoftSkillsListe([]);
                            }}
                            options={softSkillsListe}
                            getOptionLabel={(Option) => Option.name}
                            renderInput={(params) => (
                              <TextField
                                sx={{ maxWidth: "100%" }}
                                value={value}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  if (e.target.value.length > 0) {
                                    searchSoftSKills(e.target.value);
                                  }
                                }}
                                {...params}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={RechercheIcon} alt="end" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: null,
                                }}
                                placeholder="Compétences..."
                              />
                            )}
                          />
                          <Box flexWrap="wrap" display="flex" gap="10px" margin="10px 0px">
                            {selectedSoft?.map((select) => (
                              <MyChip
                                variant="skill"
                                label={select.name}
                                onDelete={() => {
                                  setSelectedSoft(selectedSoft.filter((sel) => sel.id !== select.id));
                                  console.log("hard skills after deleting a selected value", softSkillsListe);
                                }}
                              />
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpen(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button onClick={executeSave} variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default MetierCV;
