import React from 'react'
import {Box} from "@mui/material"


function BoxContainer({children, ...props}) {
  return (
    <Box {...props} sx={{border:"1px solid #DEDFE4", padding: "24px", borderRadius:"6px"}}>{children}</Box>
  )
}

export default BoxContainer