import React, { useState } from "react";
import BoxContainer from "../../../components/ui/BoxContainer";
import { Box, CircularProgress, Divider, Input, Typography } from "@mui/material";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { useEffect } from "react";

function Documents({ modify, userFetch, setChange, change }) {
  const [loading, setLoading] = useState(false);
  const [loadingWork, setLoadingWork] = useState(false);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const formRefPole = useRef();
  const formRefWork = useRef();
  const [workCertifs, setWorkCertifs] = useState({});
  const [initialValues, setInitialValues] = useState({
    file: "",
    type: "",
  });
  useEffect(() => {
    let WORK_CERTFI = null;
    let pole_emploi = null;
    for (let index = 0; index < userFetch?.user?.work_certificates?.length; index++) {
      const element = userFetch?.user?.work_certificates[index];
      if (element.type === "WORK_CERTIFICATE") {
        WORK_CERTFI = element;
      } else {
        pole_emploi = element;
      }
    }
    console.log(WORK_CERTFI, pole_emploi, "work and pole in useEffect");
    setWorkCertifs({
      POLE_EMPLOI: pole_emploi,
      work_certificate: WORK_CERTFI,
    });
  }, [userFetch]);
  const add_work_cetfs = (values) => {
    if (values.type === "WORK_CERTIFICATE") setLoadingWork(true);
    else setLoading(true);
    Api.post(
      Urls.ADD_WORK_CERTIF + userFetch?.user?.id + "/workCertificates",
      {
        type: values.type,
        file: values.file,
      },
      {
        headers: { "Content-type": "multipart/form-data", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        setLoading(false);
        setLoadingWork(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
        setLoading(false);
        setLoadingWork(false);
      });
  };
  return (
    <>
      <BoxContainer backgroundColor="#000B30">
        <Box display="flex" flexDirection="column" gap="15px">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "22.4px", color: "white" }}>Attestation pole emploi</Typography>
            <Box display="flex" gap="4px">
              <Formik
                enableReinitialize={true}
                innerRef={formRefPole}
                initialValues={initialValues}
                onSubmit={(values) => {
                  add_work_cetfs(values);
                }}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    {modify && (
                      <ButtonSquare
                        onClick={() => {
                          const element = document.getElementById("file-pole");
                          element.click();
                        }}
                        action="download-green"
                      />
                    )}
                    <input
                      hidden
                      style={{ height: "100%", width: "100%", opacity: "0" }}
                      name="file"
                      id="file-pole"
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png, .svg, .webp"
                      onChange={(e) => {
                        setFieldValue("file", e.target?.files[0]);
                        setFieldValue("type", "POLE_EMPLOI");
                        formRefPole.current.handleSubmit();
                      }}
                    />
                  </Form>
                )}
              </Formik>
              {loading && <CircularProgress />}
              {workCertifs?.POLE_EMPLOI && <ButtonSquare onClick={() => window.open(workCertifs?.POLE_EMPLOI?.url_certificate, "_blank")} action="download-orange" />}
            </Box>
          </Box>
          <Divider />

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "22.4px", color: "white" }}>Attestation de travail</Typography>
            <Box display="flex" gap="4px">
              <Formik
                enableReinitialize={true}
                innerRef={formRefWork}
                initialValues={initialValues}
                onSubmit={(values) => {
                  add_work_cetfs(values);
                }}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    {modify && (
                      <ButtonSquare
                        onClick={() => {
                          const element = document.getElementById("file-work");
                          element.click();
                        }}
                        action="download-green"
                      />
                    )}{" "}
                    <input
                      hidden
                      style={{ height: "100%", width: "100%", opacity: "0" }}
                      name="file"
                      id="file-work"
                      accept=".pdf, .jpg, .jpeg, .png, .svg, .webp"
                      type="file"
                      onChange={(e) => {
                        setFieldValue("file", e.target?.files[0]);
                        setFieldValue("type", "WORK_CERTIFICATE");
                        formRefWork.current.handleSubmit();
                      }}
                    />
                  </Form>
                )}
              </Formik>
              {loadingWork && <CircularProgress />}

              {workCertifs?.work_certificate && <ButtonSquare onClick={() => window.open(workCertifs?.work_certificate?.url_certificate, "_blank")} action="download-orange" />}
            </Box>
          </Box>
        </Box>
      </BoxContainer>
    </>
  );
}

export default Documents;
