import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MjLabel from "../../components/ui/MjLabel";
import MyTextField from "../../components/ui/MyTextField";
import MjErrorText from "../../components/ui/MjErrorText";
import * as Yup from "yup";
import { useState } from "react";
import { useEffect } from "react";
import { Urls } from "../../api/Urls";
import "react-toastify/dist/ReactToastify.css";

import Api from "../../api/Api";
import { ToastContainer, toast } from "react-toastify";
import { colors } from "../../theme/Colors";
function DetailAgence() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split("/")[4];
  const act = location?.state?.action;
  const [openDelete, setOpenDelete] = useState(false);
  const [initialValues, setInitialValues] = useState({
    nom: "",
    telephone: "",
    adresse: "",
    email: "",
  });
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("Ce champ est obligatoire"),
    adresse: Yup.string().required("Ce champ est obligatoire"),
    email: Yup.string().email("entrer une adresse email valide").required("Ce champ est obligatoire"),
    telephone: Yup.number().typeError("Valeur invalide").positive("Valeur invalide").required("Ce champ est obligatoire"),
  });
  useEffect(() => {
    if (id) {
      Api.get(Urls.GET_AGENCES, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          id: id,
        },
      })
        .then((res) => {
          setInitialValues({
            nom: res?.data?.agencies[0].name,
            adresse: res?.data?.agencies[0].address,
            telephone: res?.data?.agencies[0].phone_number,
            email: res?.data?.agencies[0].email,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const AjouterAgence = (values) => {
    Api.post(
      Urls.CREATE_AGENCE,
      {
        name: values.nom,
        address: values.adresse,
        phone_number: values.telephone,
        email: values.email,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const UpdateAgence = (values) => {
    Api.patch(
      Urls.UPDATE_AGENCE + id,
      {
        ...(initialValues.nom !== values?.nom ? { name: values.nom } : null),
        ...(initialValues.adresse !== values?.adresse ? { address: values.adresse } : null),
        ...(initialValues.telephone !== values?.telephone ? { phone_number: values.telephone } : null),
        ...(initialValues.email !== values?.email ? { email: values.email } : null),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const supAgence = () => {
    Api.delete(Urls.DELETE_AGENCIES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        agency_ids: [id],
      },
    })
      .then((res) => {
        console.log(res);
        setOpenDelete(false);
        toast.success(res?.data?.message);
        navigate("/admin/gestion-des-agences/");
      })
      .catch((err) => {
        console.log(err);
        setOpenDelete(false);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box sx={{ maxWidth: "800px" }}>
      <ToastContainer />
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" mb>
          Gestion des agences
        </Typography>
        {act === "details" && (
          <Typography variant="h5" component="h1">
            Agence #{id}
          </Typography>
        )}
        {act === "create" && (
          <Typography variant="h5" component="h1">
            Ajouter une agence
          </Typography>
        )}
      </Box>
      <Box marginTop="24px">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (act === "details") {
              UpdateAgence(values);
            } else {
              AjouterAgence(values);
            }
          }}
          enableReinitialize={true}
        >
          {({ values }) => (
            <Form>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="nom" htmlFor="nom">
                  Nom
                </MjLabel>
                <Field name="nom" autoComplete="off" as={MyTextField} placeholder="Nom" fullWidth />
                <ErrorMessage name="nom" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="email" htmlFor="">
                  Email
                </MjLabel>
                <Field name="email" autoComplete="off" as={MyTextField} placeholder="Email" fullWidth />
                <ErrorMessage name="email" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="Téléphone" htmlFor="">
                  Téléphone
                </MjLabel>
                <Field name="telephone" autoComplete="off" as={MyTextField} placeholder="Téléphone" fullWidth />
                <ErrorMessage name="telephone" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="adresse" htmlFor="">
                  Adresse
                </MjLabel>
                <Field name="adresse" autoComplete="off" as={MyTextField} placeholder="Adresse" fullWidth />
                <ErrorMessage name="adresse" component={MjErrorText} />
              </Box>
              <Box display="flex" alignItems="center" gap="8px">
                {act === "details" && (
                  <Box>
                    <Button type="submit" variant="mj_primary_md">
                      Mettre à jour
                    </Button>
                  </Box>
                )}
                {act === "details" && (
                  <Box>
                    <Button onClick={() => setOpenDelete(true)} variant="mj_secondary_md">
                      Supprimer l'agence
                    </Button>
                  </Box>
                )}
                {act === "create" && (
                  <Box>
                    <Button type="submit" variant="mj_primary_md">
                      Ajouter
                    </Button>
                  </Box>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openDelete} onClose={() => setOpenDelete(false)}>
        <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
          <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
            Supprimer une agence
          </Box>
          <Box>
            <Typography display="inline-block" margin="1rem" textAlign="center">
              Voulez vous vraiment supprimer cette agence
            </Typography>
          </Box>

          <Box onClick={() => console.log("test")} margin="1rem 0rem" gap="8px" display="flex" alignItems="center" justifyContent="center">
            <Button variant="mj_secondary_md" onClick={() => supAgence(id?.id)}>
              Supprimer
            </Button>
            <Button variant="mj_primary_md" onClick={() => setOpenDelete(false)}>
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default DetailAgence;
