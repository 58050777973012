import React from "react";
import { Chip } from "@mui/material";
import { colors } from "../../theme/Colors";

function MjChip({ name }) {
  switch (name) {
    case "pre-selected":
      return (
        <Chip
          size="small"
          label="Préselectionné"
          variant="filled"
          sx={{
            backgroundColor: `${colors.mj_preselected}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case 2:
      return (
        <Chip
          size="small"
          label="2/3"
          variant="filled"
          sx={{
            backgroundColor: `${colors.mj_preselected}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "confirmed":
      return (
        <Chip
          size="small"
          label="Confirmé"
          variant="filled"
          sx={{
            backgroundColor: `${colors.main_mj}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );

    case "accepted":
      return <Chip size="small" label="Accepté" variant="filled" color="success" />;
    case "ACCEPTED":
      return <Chip size="small" label="Payé" variant="filled" color="success" />;

    case "paid":
      return <Chip size="small" label="Payée" variant="filled" color="success" />;
    case "paidF":
      return <Chip size="small" label="Encaissée" variant="filled" color="success" />;
    case "Npotentiel":
      return <Chip size="small" label="Vrai" variant="filled" color="success" />;

    case "active":
      return <Chip size="small" label="Actif" variant="filled" color="success" />;

    case "declared":
      return <Chip size="small" label="Déclaré" variant="filled" color="success" />;

    case 3:
      return <Chip size="small" label="3/3" variant="filled" color="success" />;

    case "VALID":
      return <Chip size="small" label="Validé" variant="filled" color="success" />;
    case 1:
      return <Chip size="small" label="Visible" variant="filled" color="success" />;
    case "verified":
      return <Chip size="small" label="verifié" variant="filled" color="success" />;

    case "signed":
      return <Chip size="small" label="Signé" color="success" variant="outlined" sx={{ border: 2 }} />;

    case "PENDING":
      return (
        <Chip
          size="small"
          label="En attente"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_pending}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_pending}`,
            },
          }}
        />
      );

    case "unpaid":
      return (
        <Chip
          size="small"
          label="Impayée"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_pending}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_pending}`,
            },
          }}
        />
      );
    case "potentiel":
      return (
        <Chip
          size="small"
          label="potentiel"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_pending}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_pending}`,
            },
          }}
        />
      );
    case "unpaidF":
      return (
        <Chip
          size="small"
          label="Non encaissée"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_pending}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_pending}`,
            },
          }}
        />
      );

    case "undeclared":
      return (
        <Chip
          size="small"
          label="Non déclaré"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_pending}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_pending}`,
            },
          }}
        />
      );

    case "NOT_VALID":
      return (
        <Chip
          size="small"
          label="Refusée"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_red}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_red}`,
            },
          }}
        />
      );

    case "not_active":
      return (
        <Chip
          size="small"
          label="inactif"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_red}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_red}`,
            },
          }}
        />
      );
    case "refused":
      return (
        <Chip
          size="small"
          label="Refusée"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_red}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_red}`,
            },
          }}
        />
      );
    case "REFUSED":
      return (
        <Chip
          size="small"
          label="Refusée"
          variant="outlined"
          sx={{
            border: 2,
            color: "white",
            borderColor: `${colors.mj_red}`,
            backgroundColor: "#D30000",
            "& .MuiChip-label": {
              color: `white`,
            },
          }}
        />
      );
    case 0:
      return (
        <Chip
          size="small"
          label="Non visible"
          variant="outlined"
          sx={{
            border: 2,
            borderColor: `${colors.mj_red}`,
            backgroundColor: "white",
            "& .MuiChip-label": {
              color: `${colors.mj_red}`,
            },
          }}
        />
      );
    case "suspended":
      return (
        <Chip
          size="small"
          label="Suspendu"
          variant="filled"
          sx={{
            borderColor: `${colors.mj_red}`,
            backgroundColor: `${colors.mj_red}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
    case "not_verified":
      return (
        <Chip
          size="small"
          label="Non verifié"
          variant="filled"
          sx={{
            borderColor: `${colors.mj_red}`,
            backgroundColor: `${colors.mj_red}`,
            "& .MuiChip-label": {
              color: "white",
            },
          }}
        />
      );
  }
}

export default MjChip;
