import React, { useState } from "react";
import BoxContainer from "./BoxContainer";
import iconPoint from "../../imgs/imgV2/point.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import { Avatar, Box, Button, Checkbox, FormControlLabel, Modal, Typography } from "@mui/material";
import logoEntreprise from "../../imgs/imgV2/entrepriseLogo.png";
import MyChip from "./MyChip";
import alertIcon from "../../imgs/imgV2/alertIcon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import { useAuthContext } from "../../hooks/useAuthContext";
import { modalStyle, modalStyle2 } from "../../theme/ModalStyle";
import { CheckBox } from "@mui/icons-material";
import ButtonSquare from "./ButtonSquare";
import { ToastContainer, toast } from "react-toastify";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import DetailsProfileInterim from "../../pages/details/offres/DetailsProfileInterim";
function InterimComponent({ interim, id, postulerIDS, setPostulerIDS }) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [checkedPostuler, setCheckedPostuler] = useState(false);
  const access_token = user?.access_token;
  const [idUser, setIdUser] = useState(null);
  const [openPostuler, setOpenPostuler] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const translateMatchig = {
    suitable: "matching_satisfaisant",
    perfect: "matching_parfait",
  };
  const handlePostuler = () => {
    Api.post(Urls.POSTULER_OFFRE_ID + idUser + "/offers/" + id + "/apply", null, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setPostulerIDS(postulerIDS.concat(idUser));
        setOpenPostuler(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
      });
  };
  return (
    <BoxContainer>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Box
          onClick={() => {
            setIdUser(interim?.id);
            setOpenProfile(true);
          }}
          flex={6}
          display="flex"
          alignItems="center"
          gap="30px"
          sx={{ cursor: "pointer" }}
        >
          <Avatar variant="rounded" sx={{ height: "100px", width: "100px", borderRadius: "50%" }}>
            {interim?.url_avatar ? <img src={interim?.url_avatar} alt="logo-avatar" style={{ height: "100px", width: "100px", borderRadius: "50%" }} /> : <PersonIcon sx={{ fontSize: "60px" }} />}
          </Avatar>
          <Box display="flex" flexDirection="column">
            {interim?.matching_level != "weak" && <MyChip variant={translateMatchig[`${interim?.matching_level}`]} label={interim?.matching_level === "suitable" ? "👌 Matching satisfaisant" : "🥇 Matching parfait"} />}

            <Typography sx={{ lineHeight: "28.8px", fontSize: "18px", fontWeight: "600", cursor: "pointer" }}>
              {interim?.first_name} {interim?.last_name}
            </Typography>
          </Box>
        </Box>
        <Box flex={1}>
          {postulerIDS?.includes(interim?.id) && <MyChip variant="skill" label="Vous avez postulé" />}
          {!postulerIDS?.includes(interim?.id) && (
            <Button
              variant="mj_primary_md"
              onClick={() => {
                setIdUser(interim?.id);
                setOpenPostuler(true);
              }}
            >
              Postuler
            </Button>
          )}
        </Box>
      </Box>
      <Modal
        open={openPostuler}
        onClose={() => {
          setOpenPostuler(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box display="flex" justifyContent="end" alignItems="end">
              <ButtonSquare onClick={() => setOpenPostuler(false)} action="close" />
            </Box>
            <Typography marginBottom="16px" variant="text_xxl" fontWeight="700">
              Voulez-vous postuler pour ce poste ?
            </Typography>
            <Box display="flex" flexDirection="column" gap="22px" marginBottom="46px">
              <Box marginBottom="10px" display="flex" justifyContent="center">
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "160%", textAlign: "center" }}>Êtes-vous sûr(e) de vouloir postuler pour ce poste ?</Typography>
              </Box>
            </Box>
            <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
              <Button
                onClick={() => {
                  setOpenPostuler(false);
                }}
                variant="mj_secondary_md"
              >
                Annuler
              </Button>
              <Button onClick={() => handlePostuler()} variant="mj_primary_md">
                Envoyer
              </Button>{" "}
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openProfile} onClose={() => setOpenProfile(false)}>
        <Box sx={modalStyle2}>
          <Box sx={{ padding: "24px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpenProfile(false)} action="close" />
                <Typography variant="text_xxl" fontWeight="700">
                  Profile #{idUser}
                </Typography>
              </Box>
            </Box>
            <DetailsProfileInterim interimId={idUser} type={"detailsProfile"} id={id} />
          </Box>
        </Box>
      </Modal>
    </BoxContainer>
  );
}

export default InterimComponent;
