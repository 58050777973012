import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField, Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { colors } from "../../theme/Colors";
import { DatePicker } from "@mui/x-date-pickers";
import { setFieldValue } from "formik";
import { useState } from "react";
import { useRef } from "react";

const OmjDatePicker = ({ children, form, field, color, backgroundColor, fullWidth, hasAsap, ...props }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const inputRef = useRef();

  const [asap, setAsap] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [dateValue, setDateValue] = React.useState();

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-");
  }

  const handleChange = (newValue) => {
    let dateChanged = "";
    setDateValue(newValue);
    dateChanged = newValue;
    setFieldValue(name, formatDate(dateChanged));
  };

  return (
    <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
      <DatePicker
        disablePast
        ref={inputRef}
        disabled={props.disabled || isDisabled}
        components={{ OpenPickerIcon: CalendarMonthIcon }}
        maxDate={props.maxDate}
        value={value}
        minDate={props.minDate}
        onChange={handleChange}
        name={name}
        InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${color}` } } }}
        renderInput={(params) => {
          return (
            <TextField
              type="time"
              {...params}
              fullWidth={fullWidth ? fullWidth : false}
              error={false}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
              }}
            />
          );
        }}
      >
        {children}
      </DatePicker>
      {hasAsap && (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: "6px", borderRadius: "6px", "&:hover": { backgroundColor: "#f5f5f5" }, padding: "0px 8px" }}>
          <FormControlLabel
            control={
              <Box sx={{ display: "inline-block" }}>
                <Checkbox
                  disableRipple
                  checked={asap}
                  onChange={() => {
                    setAsap(!asap);
                    if (asap === false) {
                      form.setFieldValue(name, formatDate(new Date()));
                      setIsDisabled(true);
                    } else {
                      setIsDisabled(false);
                    }
                  }}
                />
              </Box>
            }
            label={<Typography variant="text_normal"> Dès que possible</Typography>}
            sx={{
              display: "table",
              alignItems: "flex-start",
              "& .MuiFormControlLabel-label": { fontSize: 14 }, // set the font size to 16px
            }}
          />
        </Box>
      )}
    </LocalizationProvider>
  );
};

export default OmjDatePicker;
