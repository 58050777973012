import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Alert, FormControl, Select, MenuItem, Chip } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
import MjErrorText from "../../../components/ui/MjErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLocation, useParams } from "react-router-dom";

// import SuspensionDetails from "./SuspensionDetails";
import InterimaireDetails from "../details-Interimaire/InterimaireDetails";
import EntrepriseDetails from "../detailEntreprise/EntrepriseDetails";
import DetailOffreFinale from "../offres/DetailOffreFinale";
import EntretienDetails from "./EntretienDetails";
// import DetailContrat from "../detail-contrat/DetailContrat";

function ContentSelectorEntretien() {
  const params = useParams();
  // console.log("PARAMS = ", params);
  const location = useLocation();
  // console.log("LOCATION = ", location);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [succes, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [searchedAgency, setSearchedAgency] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchedRole, setSearchedRole] = useState("");

  const [interview, setInterview] = useState({});

  const [selectedTab, setSelectedTab] = useState(1);

  const [change, setChange] = useState(0);

  const tabs = [
    {
      id: 1,
      name: "Entretien",
      icon: "",
    },
    {
      id: 2,
      name: "Interimaire",
      icon: "",
    },
    {
      id: 3,
      name: "Entreprise",
      icon: "",
    },
    {
      id: 4,
      name: "Candidature",
      icon: "",
    },
  ];

  // //get interview
  useEffect(() => {
    Api.get("/admin/interviews" + `/${params?.id}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setInterview(res?.data?.interview);
        console.log("interview ::::::", res.data.interview);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);

  const chipSelector = (status) => {
    switch (status) {
      case "FINISHED":
        return <Chip variant="ended_offer" label={"Terminé"} />;
      case "PENDING":
        return <Chip variant="active_offer" label={"En cours"} />;
    }
  };

  const contentSelector = (contentId = 1) => {
    switch (contentId) {
      case 1:
        // return <SuspensionDetails change={change} setChange={setChange} />;
        return <EntretienDetails change={change} setChange={setChange} />;
      case 2:
        return <InterimaireDetails modify={false} idInterimaire={interview.interim_id} />;
      case 3:
        return <EntrepriseDetails modify={false} idEntreprise={interview.enterprise_id} />;
      case 4:
        return <DetailOffreFinale id={interview.offer_id} type="offre" />;
      default:
        return <div>entretien</div>;
    }
  };

  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" fontWeight="600" mb>
          Gestion des Entretiens
        </Typography>

        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography variant="h5" component="h1" fontWeight="600">
            {"CANDIDATURE #" + interview.application_id}
          </Typography>
          <>{chipSelector(interview.status)}</>
        </Box>
      </Box>

      {/* tabs */}
      <Box display="flex" sx={{ maxWidth: "700px", backgroundColor: "#FAFAFB", borderRadius: "6px", padding: "2px", flexDirection: { md: "row", xs: "column" } }} gap="6px" margin="40px 0px">
        {tabs.map((tab, index) => (
          <Button disableRipple fullWidth key={index} variant={selectedTab === tab.id ? "mj_primary_choice_active_lg" : "mj_primary_choice_lg"} onClick={() => setSelectedTab(tab.id)}>
            <Typography fontSize="20px" mr>
              {tab.icon}
            </Typography>
            {tab.name}
          </Button>
        ))}
      </Box>

      {/* content */}
      {contentSelector(selectedTab)}
    </Box>
  );
}

export default ContentSelectorEntretien;
