import React from "react";
import { Button } from "@mui/material";
import { colors } from "../../theme/Colors";

function ButtonDataGridSquare({ action, ...props }) {
  switch (action) {
    //download button
    case "download":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "1.8rem", color: "white", textTransform: "none", backgroundColor: `${colors.mj_green}`, "&:hover": { backgroundColor: `${colors.mj_green_light}` } }}>
          Télécharger
        </Button>
      );
    case "downloadPEM":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "1.8rem", color: "white", textTransform: "none", backgroundColor: `${colors.mj_green}`, "&:hover": { backgroundColor: `${colors.mj_green_light}` } }}>
          Télécharger PEM
        </Button>
      );

    //pay button
    case "pay":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "1.8rem", color: "white", textTransform: "none", backgroundColor: `${colors.mj_green}`, "&:hover": { backgroundColor: `${colors.mj_green_light}` } }}>
          Payer
        </Button>
      );

    //declare hours button
    case "declare_hours":
      return (
        <Button {...props} onClick={props.onClick} sx={{ height: "1.8rem", color: "white", textTransform: "none", backgroundColor: `${colors.mj_green}`, "&:hover": { backgroundColor: `${colors.mj_green_light}` } }}>
          Déclarer les heures
        </Button>
      );
  }
}

export default ButtonDataGridSquare;
