import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import endicapeIcon from "../../../imgs/imgV2/endicapeIcon.svg";
import locationIcon from "../../../imgs/imgV2/LocationIcon.svg";
import EmailIcon from "../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../imgs/imgV2/TelIcon.svg";
import WebSiteIcon from "../../../imgs/imgV2/website.svg";
import MyTextField from "../../../components/ui/MyTextField";
import BoxContainer from "../../../components/ui/BoxContainer";
import { ToastContainer, toast } from "react-toastify";
import { colors } from "../../../theme/Colors";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { modalStyle } from "../../../theme/ModalStyle";

function InterimAgency({ userId, change, setChange, modify, userFetch }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [agences, setAgence] = useState([]);
  const [openModifyAgenceAffilier, setOpenModifyAgence] = useState(false);
  const [initialvaluesAg, setIntitalValuesAG] = useState({
    agence: "",
  });

  useEffect(() => {
    setIntitalValuesAG({
      agence: userFetch?.user?.agency?.id ? userFetch?.user?.agency?.id : "",
    });
  }, [userFetch]);

  useEffect(() => {
    Api.get(Urls.GET_AGENCES + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setAgence(res?.data?.agencies);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const modifyAgency = (values) => {
    Api.patch(
      Urls.UPDATE_USER_AGENCY + userId,
      {
        agency_id: values.agence,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenModifyAgence(false);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box>
      <ToastContainer />
      <BoxContainer>
        <Box display="flex" flexDirection="column" gap="20px">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography fontSize="14px" fontWeight="500" lineHeight="22.4px">
              Service commercial de l’agence affiliée
            </Typography>
            {modify && <ButtonSquare action="modify" onClick={() => setOpenModifyAgence(true)} />}
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" alignItems="center" gap="4px">
              <img src={locationIcon} alt="location-icon" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.user?.agency?.address ? userFetch?.user?.agency?.address : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <img src={EmailIcon} alt="email-icon" />
              <Typography variant="text_clickable">{userFetch?.user?.agency?.email ? userFetch?.user?.agency?.email : ""}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="4px">
              <img src={TelIcon} alt="tel-icon" />
              <Typography variant="text_clickable">{userFetch?.user?.agency?.phone_number ? userFetch?.user?.agency?.phone_number : ""}</Typography>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
      <Modal open={openModifyAgenceAffilier} onClose={() => setOpenModifyAgence(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Agence affilié
              </Typography>
              <ButtonSquare onClick={() => setOpenModifyAgence(false)} action="close" />
            </Box>
            <Formik
              enableReinitialize={true}
              initialValues={initialvaluesAg}
              onSubmit={(values) => {
                modifyAgency(values);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box marginBottom="6px">
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Agence affilié
                    </Typography>
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                      *
                    </Typography>
                  </Box>

                  <Box>
                    <FormControl sx={{ maxWidth: "170px", minWidth: "170px", margin: "0.3rem 0rem 0rem 0rem" }}>
                      {values.agence ? null : <InputLabel>Agence </InputLabel>}
                      <Select placeholder="Forme juridique" fullWidth value={values.agence} onChange={(e) => setFieldValue("agence", e.target.value)} input={values.agence ? null : <OutlinedInput sx={{ color: "black" }} label="agence ..." />}>
                        <MenuItem key={0} value="">
                          Aucun Filtre
                        </MenuItem>
                        {agences.map((agence) => (
                          <MenuItem key={agence.id} value={agence.id}>
                            {agence.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box marginTop="24px" display="flex" gap="8px" justifyContent="flex-end" alignItems="center">
                    <Button variant="mj_secondary_md" onClick={() => setOpenModifyAgence(false)}>
                      Annuler
                    </Button>
                    <Button type="submit" variant="mj_primary_md" disabled={values?.agence ? false : true}>
                      {" "}
                      Enregistrer
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default InterimAgency;
