import React from "react";
import BoxContainer from "./BoxContainer";
import iconPoint from "../../imgs/imgV2/point.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import { Box, Typography } from "@mui/material";
import logoEntreprise from "../../imgs/imgV2/entrepriseLogo.png";
import MyChip from "./MyChip";
import alertIcon from "../../imgs/imgV2/alertIcon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
function MissionComponent({ mission }) {
  const navigate = useNavigate();
  return (
    <BoxContainer>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Box display="flex" alignItems="center" gap="40px">
          <img src={mission?.enterprise?.url_logo} alt="logo-entreprise" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
          <Box>
            <Typography sx={{ lineHeight: "28.8px", fontSize: "18px", fontWeight: "600", cursor: "pointer" }}>{mission?.enterprise?.name}</Typography>
            <Typography sx={{ lineHeight: "22.4px", fontSize: "14px", fontWeight: "500" }}>{mission?.job_title}</Typography>
            {mission?.some_hours_are_not_declared && (
              <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
                <img src={alertIcon} width="14px" alt="" />
                <Typography variant="text_normal" color="#CC0000" fontWeight="500">
                  Certaines heures ne sont pas déclarées
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <MyChip variant={mission?.status === "ACTIVE" ? "active_offer" : "ended_offer"} label={mission?.status === "ACTIVE" ? "Active" : "Terminée"} />
        </Box>
      </Box>
      <Box display="flex" gap="40px">
        <Box width="100px"></Box>
        <Box marginBottom="20px" display="flex" sx={{ flexDirection: { xs: "column", sm: "column", lg: "row", md: "row" }, alignItems: { sm: "inherit", xs: "inherit", md: "center", lg: "center" } }} gap="8px">
          <Box>
            <Typography>💸️ {mission?.total_amount}€</Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }}>
            <img src={iconPoint} alt="icon-point" />
          </Box>
          <Box>
            <Typography>📍 {mission?.location_address}</Typography>
          </Box>
          <Box>
            <Typography>⏰️ {mission?.total_hours} heures</Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }}>
            <img src={iconPoint} alt="icon-point" />
          </Box>
          <Box display="flex" gap="8px">
            <Box>
              <Typography>🗓 {moment(mission.start_date).format("DD MMMM YYYY")}</Typography>
            </Box>
            <Box>
              <img src={arrowRightIcon} alt="icon-point" />
            </Box>
            <Box>
              <Typography>{moment(mission.end_date).format("DD MMMM YYYY")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </BoxContainer>
  );
}

export default MissionComponent;
