import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate, useLocation } from "react-router-dom";

export const useLogin = (email, password) => {
  const [errorLogin, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { dispatch } = useAuthContext();
  const { user } = useAuthContext();

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);

    //remove const response = await
    const response = await Api.post(
      Urls.LOGIN_URL,
      { email: email, password: password },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        console.log("reponse el gharbi", response.status);
        console.log(response.data);
        console.log(response.data.status);
        console.log("Login success", response.data);
        // save the user to local storage
        localStorage.setItem("userAdmin", JSON.stringify(response.data));

        // update the auth context
        dispatch({ type: "LOGIN", payload: response.data });

        // update loading state
        setIsLoading(false);

        //get the user to decide where to redirect
        //const { user } = JSON.parse(localStorage.getItem("user"));
        console.log(user);

        console.log("is_email_verified", user?.data?.user?.is_email_verified);

        //navigate admin dashboard if email is verified
        console.log("user access token", response?.data?.access_token);
        if (response?.data?.access_token) {
          console.log("dkhelna");
          navigate("/Admin", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setError(err.response.data.message);
      });
  };

  return { login, isLoading, errorLogin };
};
