import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { replace } from "formik";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../theme/Colors";
import IconFox from "../../imgs/imgV2/FoxLogoAdmin.svg";
import MenuIcon from "@mui/icons-material/Menu";
import DefaultProfilePic from "../../imgs/user.png";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useUserContext } from "../../context/useUserContext";

const drawerWidth = 240;

function SharedLayoutAdmin(props) {
  const location = useLocation();
  const adresse = location.pathname.split("/")[0] + "/" + location.pathname.split("/")[1] + "/" + location.pathname.split("/")[2];
  const [selected, setSelected] = React.useState("");
  const navigate = useNavigate();
  const { userData } = useUserContext();
  const { user } = useAuthContext();
  const [navigation, setNavigation] = React.useState([]);

  React.useEffect(() => {
    let liste_of_access = [];
    if (user?.user?.account_type === "SUPER_ADMIN") {
      setNavigation([
        "Gestion des comptes admin",
        "Gestion des entreprises",
        "Gestion des offres",
        "Gestion des intérimaires",
        "Gestion des intérimaires potentiel",
        "Gestion des agences",
        "Gestion des candidatures",
        "Gestion des contrats et avenants",
        "Gestion des factures",
        "Gestion des fiches de paie",
        "Gestion des problèmes",
        "Gestion des suspension",
        "Déclaration des heures",
        "Messagerie",
        "Gestion des acomptes",
        "Gestion des demandes de propositions commerciales",
        "Gestion des entretiens",
        "Gestion des EPIS",
      ]);
    } else {
      const roles_list = user?.user?.roles;
      for (let index = 0; index < roles_list.length; index++) {
        const element = roles_list[index];
        liste_of_access.push(element.display_name);
      }
      setNavigation(liste_of_access);
    }
  }, []);

  const access_token = user?.access_token;
  const userType = user?.user?.user_type;
  console.log(user, "c'est lutilisateur");

  console.log(adresse);
  const imageSrc = null;

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigateMonCompte = () => {
    navigate("/admin/mon-compte");
  };

  const handleNavigateLogin = () => {
    navigate("/login");
  };
  const handleNavigation = (index) => {
    switch (index) {
      case "Gestion des comptes admin":
        navigate("/admin/gestion-des-comptes-admin");
        break;
      case "Gestion des entreprises":
        navigate("/admin/gestion-des-entreprises");
        break;
      case "Gestion des offres":
        navigate("/admin/gestion-des-offres");
        break;
      case "Gestion des intérimaires":
        navigate("/admin/gestion-des-interimaires");
        break;
      case "Gestion des intérimaires potentiel":
        navigate("/admin/gestion-des-interimaires-potentiel");
        break;
      case "Gestion des agences":
        navigate("/admin/gestion-des-agences");
        break;
      case "Gestion des candidatures":
        navigate("/admin/gestion-des-candidatures");
        break;
      case "Gestion des contrats et avenants":
        navigate("/admin/gestion-des-contrats");
        break;
      case "Gestion des factures":
        navigate("/admin/gestion-des-factures");
        break;
      case "Gestion des fiches de paie":
        navigate("/admin/gestion-des-fiches-de-paie");
        break;
      case "Gestion des problèmes":
        navigate("/admin/gestion-des-problemes");
        break;
      case "Gestion des suspension":
        navigate("/admin/gestion-des-suspensions");
        break;
      case "Déclaration des heures":
        navigate("/admin/declarer-les-heures");
        break;
      case "Messagerie":
        navigate("/admin/messagerie");
        break;
      case "Gestion des acomptes":
        navigate("/admin/gestion-des-acomptes");
        break;
      case "Gestion des demandes de propositions commerciales":
        navigate("/admin/gestion-des-propositions");
        break;
      case "Gestion des entretiens":
        navigate("/admin/gestion-des-entretiens");
        break;
      case "Gestion des EPIS":
        navigate("/admin/gestion-des-epis");
        break;
    }
  };
  React.useEffect(() => {
    if (adresse.includes("/admin/gestion-des-comptes-admin")) {
      setSelected("Gestion des comptes admin");
    }
    if (adresse.includes("/admin/gestion-des-entreprises")) {
      setSelected("Gestion des entreprises");
    }
    if (adresse.includes("/admin/gestion-des-offres")) {
      setSelected("Gestion des offres");
    }
    if (adresse.includes("/admin/gestion-des-interimaires")) {
      setSelected("Gestion des intérimaires");
    }
    if (adresse.includes("/admin/gestion-des-agences")) {
      setSelected("Gestion des agences");
    }
    if (adresse.includes("/admin/gestion-des-interimaires-potentiel")) {
      setSelected("Gestion des intérimaires potentiel");
    }
    if (adresse.includes("/admin/gestion-des-candidatures")) {
      setSelected("Gestion des candidatures");
    }
    if (adresse.includes("/admin/gestion-des-contrats")) {
      setSelected("Gestion des contrats et avenants");
    }
    if (adresse.includes("/admin/gestion-des-factures")) {
      setSelected("Gestion des factures");
    }
    if (adresse.includes("/admin/gestion-des-fiches-de-paie")) {
      setSelected("Gestion des fiches de paie");
    }
    if (adresse.includes("/admin/gestion-des-problemes")) {
      setSelected("Gestion des problèmes");
    }
    if (adresse.includes("/admin/declarer-les-heures")) {
      setSelected("Déclaration des heures");
    }
    if (adresse.includes("/admin/messagerie")) {
      setSelected("Messagerie");
    }
    if (adresse.includes("/admin/gestion-des-acomptes")) {
      setSelected("Gestion des acomptes");
    }
    if (adresse.includes("/admin/gestion-des-entretiens")) {
      setSelected("Gestion des entretiens");
    }
    if (adresse.includes("/admin/gestion-des-epis")) {
      setSelected("Gestion des EPIS");
    }
    if (adresse.includes("/admin/gestion-des-suspensions")) {
      setSelected("Gestion des suspension");
    }
  }, [selected, location.pathname]);

  const drawer = (
    <Box>
      <Toolbar>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "2.5rem 0rem" }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <img src={IconFox} height={50} alt="malin job logo" style={{ marginRight: "1rem" }} />
              <Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography fontFamily="Montserrat" fontSize="1.5rem" fontWeight="500">
                    OhMyJob
                  </Typography>
                </Box>
                <Typography fontFamily="Montserrat" fontSize="1rem" fontWeight="500">
                  Administrateur
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Toolbar>

      <List>
        {navigation?.map((text, index) => (
          <ListItem sx={{ backgroundColor: `${selected === text || adresse === text ? `${colors.main}` : ""}`, display: `${userType === "admin" && text === "Gestion des comptes admin" ? "none" : ""}` }} key={text} disablePadding>
            <ListItemButton
              onClick={() => {
                handleNavigation(text);
                setSelected(text);
              }}
            >
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  //logout admin
  const logout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT, null, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("ResponseLogout", response);
        localStorage.removeItem("userAdmin");
        navigate("/login", replace);
        navigate(0, replace);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ backgroundColor: `${colors.mj_navbar}`, color: "white", display: "flex", justifyContent: "space-between" }}>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon></MenuIcon>
          </IconButton>
          <Box></Box>
          <Box display="flex" gap="1.5rem">
            <Box textAlign="right">
              <Typography noWrap component="div">
                {user?.user?.first_name}
              </Typography>
              <Typography noWrap component="div">
                {user?.user?.last_name}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ width: "3rem", height: "3rem" }}>{imageSrc === null ? <img style={{ width: "3rem" }} src={DefaultProfilePic} alt="" /> : <img style={{ width: "3rem" }} id="imgNavbar" src={imageSrc} alt="" />}</Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleNavigateMonCompte}>
                  <Typography textAlign="center">Mon compte</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography onClick={logout} textAlign="center">
                    Se déconnecter
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

SharedLayoutAdmin.propTypes = {
  window: PropTypes.func,
};

export default SharedLayoutAdmin;
