import "./App.css";
import { Routes, Route, Navigate, Outlet, redirect, useNavigate } from "react-router-dom";
import Login from "./pages/authentification/Login";
import SharedLayoutAdmin from "./pages/admin/SharedLayoutAdmin";
import Comptes from "./pages/admin/Comptes";
import Entreprises from "./pages/admin/Entreprises";
import Offres from "./pages/admin/Offres";
import Interimaires from "./pages/admin/Interimaires";
import ContratsAvenants from "./pages/admin/ContratsAvenants";
import Factures from "./pages/admin/Factures";
import FichesDePaie from "./pages/admin/FichesDePaie";
import DeclarerHeures from "./pages/admin/DeclarerHeures";
import Messagerie from "./pages/admin/Messagerie";
import "react-toastify/dist/ReactToastify.css";
import Problemes from "./pages/admin/Problemes";
import Suspensions from "./pages/admin/Suspensions";
import UpdateAdminAccount from "./pages/details/detail-admin/UpdateAdminAccount";
import Welcome from "./pages/admin/Welcome";
import MonCompte from "./pages/details/MonCompte";
import EntrepriseDetails from "./pages/details/detailEntreprise/EntrepriseDetails";
import ContratsAvenantsDetails from "./pages/details/ContratsAvenantsDetails";
import ProblemesDetails from "./pages/details/detail-problem/ProblemesDetails";
import InterimaireDetails from "./pages/details/details-Interimaire/InterimaireDetails";
import FactureDetails from "./pages/details/FactureDetails";
import FicheDePaieDetails from "./pages/details/FicheDePaieDetails";
import InterimairePot from "./pages/admin/InterimairePot";
import InterimaireCV from "./pages/details/InterimaireCV";
import CandidatureDetail from "./pages/details/CandidatureDetail";
import OffreDetails from "./pages/details/OffreDetails";
import DeclarerHeureDetails from "./pages/details/detail-declarerHeure/DeclarerHeureDetails";
import { useAuthContext } from "./hooks/useAuthContext";
import TrouverDuTravail from "./pages/details/details-Interimaire/TrouverDuTravail";
import Acompte from "./pages/admin/Acompte";
import AcompteDetail from "./pages/details/AcompteDetail";
import ListeMissionInterim from "./pages/details/details-Interimaire/ListeMissionInterim";
import SuccessCreateOffer from "./pages/details/offres/SuccessCreateOffer";
import CreateOfferSelect from "./pages/details/offres/CreateOfferSelect";
import FailureCreateOffer from "./pages/details/offres/FailureCreateOffer";
import CreerOffres from "./pages/details/offres/CreerOffres";
import Agences from "./pages/admin/Agences";
import DetailAgence from "./pages/details/DetailAgence";
import PropositionCommercial from "./pages/admin/PropositionCommercial";
import ContentSelector from "./pages/details/detail-admin/ContentSelector";
import OfferDetails from "./pages/details/offres/OfferDetails";
import GestionCandidatures from "./pages/admin/GestionCandidatures";
import ContentSelectorProblems from "./pages/details/detail-problem/ContentSelectorProblems";
import ContentSelectorSuspensions from "./pages/details/detail-suspension/ContentSelectorSuspensions";
import ContentSelectorEntretien from "./pages/details/detail-entretien/ContentSelectorEntretien";
import ContentSelectorEpi from "./pages/details/detail-epi/ContentSelectorEpi";
import Entretiens from "./pages/admin/Entretiens";
import Epis from "./pages/admin/Epis";
import { useEffect, useState } from "react";
import HistoriqueInterim from "./pages/details/detailEntreprise/HistoriqueInterim";

function App() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const roles = user?.user?.roles;
  const [loading, setLoading] = useState(true);

  const [list_of_roles, set_list_of_roles] = useState([]);
  let list_roles = [];
  useEffect(() => {
    if (user?.user?.roles) {
      for (let index = 0; index < user?.user?.roles.length; index++) {
        const element = user?.user?.roles[index];
        list_roles.push(element.display_name);
      }
      set_list_of_roles(list_roles);
    }
    setLoading(false);
  }, [user]);
  console.log(user, "from app.js");

  return (
    <>
      <Routes>
        <Route path="" element={<Navigate to="login" />} />
        <Route path="/" element={<Outlet />} />
        <Route path="/login" element={user?.access_token ? <Navigate to="/admin" /> : <Login />} />
        {!loading && (
          <Route path="/admin" element={user?.access_token ? <SharedLayoutAdmin /> : <div>Unauthorized</div>}>
            <Route index element={<Welcome />} />
            <Route path="mon-compte" element={<MonCompte />} />
            {/* gestion des compte admins */}
            <Route path="gestion-des-comptes-admin">
              <Route index path="/admin/gestion-des-comptes-admin" element={list_of_roles.includes("Gestion des comptes admin") || user?.user?.account_type === "SUPER_ADMIN" ? <Comptes /> : <div></div>} />
              <Route path="/admin/gestion-des-comptes-admin/add" element={list_of_roles.includes("Gestion des comptes admin") || user?.user?.account_type === "SUPER_ADMIN" ? <UpdateAdminAccount /> : <div></div>} />
              <Route path="/admin/gestion-des-comptes-admin/:id" element={list_of_roles.includes("Gestion des comptes admin") || user?.user?.account_type === "SUPER_ADMIN" ? <ContentSelector /> : <div></div>} />
            </Route>

            <Route path="gestion-des-acomptes">
              <Route index path="/admin/gestion-des-acomptes" element={list_of_roles.includes("Gestion des acomptes") || user?.user?.account_type === "SUPER_ADMIN" ? <Acompte /> : <div></div>} />
              <Route path="/admin/gestion-des-acomptes/details/:id" element={list_of_roles.includes("Gestion des acomptes") || user?.user?.account_type === "SUPER_ADMIN" ? <AcompteDetail /> : <div></div>} />
            </Route>

            {/* gestion des entreprises */}
            <Route path="gestion-des-entreprises">
              <Route index path="/admin/gestion-des-entreprises" element={list_of_roles.includes("Gestion des entreprises") || user?.user?.account_type === "SUPER_ADMIN" ? <Entreprises /> : <div></div>} />
              <Route path="/admin/gestion-des-entreprises/details/:id" element={list_of_roles.includes("Gestion des entreprises") || user?.user?.account_type === "SUPER_ADMIN" ? <EntrepriseDetails /> : <div></div>} />
              <Route path="/admin/gestion-des-entreprises/details/:id/offre/creer-offre/offre-cree" element={list_of_roles.includes("Gestion des entreprises") || user?.user?.account_type === "SUPER_ADMIN" ? <SuccessCreateOffer /> : <div></div>} />
              <Route path="/admin/gestion-des-entreprises/details/:id/offre/creer-offre/erreur-creation" element={list_of_roles.includes("Gestion des entreprises") || user?.user?.account_type === "SUPER_ADMIN" ? <FailureCreateOffer /> : <div></div>} />
              <Route path="/admin/gestion-des-entreprises/details/:id/offre/option-create-offer" element={list_of_roles.includes("Gestion des entreprises") || user?.user?.account_type === "SUPER_ADMIN" ? <CreateOfferSelect /> : <div></div>} />
              <Route path="/admin/gestion-des-entreprises/details/:id/offre/creer-offre" element={list_of_roles.includes("Gestion des entreprises") || user?.user?.account_type === "SUPER_ADMIN" ? <CreerOffres /> : <div></div>} />
              <Route path="/admin/gestion-des-entreprises/details/:id/historique-des-interimaires" element={list_of_roles.includes("Gestion des entreprises") || user?.user?.account_type === "SUPER_ADMIN" ? <HistoriqueInterim /> : <div></div>} />
            </Route>

            <Route path="gestion-des-offres">
              <Route index path="/admin/gestion-des-offres" element={list_of_roles.includes("Gestion des offres") || user?.user?.account_type === "SUPER_ADMIN" ? <Offres /> : <div></div>} />
              <Route path="/admin/gestion-des-offres/details/:id" element={list_of_roles.includes("Gestion des offres") || user?.user?.account_type === "SUPER_ADMIN" ? <OfferDetails /> : <div></div>} />
              <Route path="/admin/gestion-des-offres/nouvelle-offre/offre-cree" element={list_of_roles.includes("Gestion des offres") || user?.user?.account_type === "SUPER_ADMIN" ? <SuccessCreateOffer /> : <div></div>} />
              <Route path="/admin/gestion-des-offres/nouvelle-offre/erreur-creation" element={list_of_roles.includes("Gestion des offres") || user?.user?.account_type === "SUPER_ADMIN" ? <CreerOffres /> : <div></div>} />
              <Route path="/admin/gestion-des-offres/nouvelle-offre" element={list_of_roles.includes("Gestion des offres") || user?.user?.account_type === "SUPER_ADMIN" ? <CreerOffres /> : <div></div>} />
            </Route>
            <Route path="gestion-des-candidatures">
              <Route index path="/admin/gestion-des-candidatures" element={list_of_roles.includes("Gestion des candidatures") || user?.user?.account_type === "SUPER_ADMIN" ? <GestionCandidatures /> : <div></div>} />
              <Route index path="/admin/gestion-des-candidatures/details/:id" element={list_of_roles.includes("Gestion des candidatures") || user?.user?.account_type === "SUPER_ADMIN" ? <CandidatureDetail /> : <div></div>} />
            </Route>

            <Route path="gestion-des-agences">
              <Route path="/admin/gestion-des-agences" element={list_of_roles.includes("Gestion des agences") || user?.user?.account_type === "SUPER_ADMIN" ? <Agences /> : <div></div>} />
              <Route path="/admin/gestion-des-agences/details/:id" element={list_of_roles.includes("Gestion des agences") || user?.user?.account_type === "SUPER_ADMIN" ? <DetailAgence /> : <div></div>} />
              <Route path="/admin/gestion-des-agences/create" element={list_of_roles.includes("Gestion des agences") || user?.user?.account_type === "SUPER_ADMIN" ? <DetailAgence /> : <div></div>} />
            </Route>
            <Route path="gestion-des-interimaires-potentiel">
              <Route index path="/admin/gestion-des-interimaires-potentiel" element={list_of_roles.includes("Gestion des intérimaires potentiel") || user?.user?.account_type === "SUPER_ADMIN" ? <InterimairePot /> : <div></div>} />
              <Route path="/admin/gestion-des-interimaires-potentiel/details/:id" element={list_of_roles.includes("Gestion des intérimaires potentiel") || user?.user?.account_type === "SUPER_ADMIN" ? <InterimaireDetails /> : <div></div>} />
              <Route path="/admin/gestion-des-interimaires-potentiel/details/:id/trouver-du-travail" element={list_of_roles.includes("Gestion des intérimaires potentiel") || user?.user?.account_type === "SUPER_ADMIN" ? <TrouverDuTravail /> : <div></div>} />
              <Route path="/admin/gestion-des-interimaires-potentiel/details/:id/missions" element={list_of_roles.includes("Gestion des intérimaires potentiel") || user?.user?.account_type === "SUPER_ADMIN" ? <ListeMissionInterim /> : <div></div>} />
            </Route>
            <Route path="gestion-des-interimaires">
              <Route index path="/admin/gestion-des-interimaires" element={list_of_roles.includes("Gestion des intérimaires") || user?.user?.account_type === "SUPER_ADMIN" ? <Interimaires /> : <div></div>} />
              <Route path="/admin/gestion-des-interimaires/details/:id" element={list_of_roles.includes("Gestion des intérimaires") || user?.user?.account_type === "SUPER_ADMIN" ? <InterimaireDetails /> : <div></div>} />
              <Route path="/admin/gestion-des-interimaires/details/:id/trouver-du-travail" element={list_of_roles.includes("Gestion des intérimaires") || user?.user?.account_type === "SUPER_ADMIN" ? <TrouverDuTravail /> : <div></div>} />
              <Route path="/admin/gestion-des-interimaires/details/:id/missions" element={list_of_roles.includes("Gestion des intérimaires") || user?.user?.account_type === "SUPER_ADMIN" ? <ListeMissionInterim /> : <div></div>} />

              <Route path="/admin/gestion-des-interimaires/details/:id/CV" element={list_of_roles.includes("Gestion des intérimaires") || user?.user?.account_type === "SUPER_ADMIN" ? <InterimaireCV /> : <div></div>} />
            </Route>

            <Route path="gestion-des-propositions">
              <Route index path="/admin/gestion-des-propositions" element={list_of_roles.includes("Gestion des demandes de propositions commerciales") || user?.user?.account_type === "SUPER_ADMIN" ? <PropositionCommercial /> : <div></div>} />
            </Route>
            <Route path="gestion-des-contrats">
              <Route index path="/admin/gestion-des-contrats" element={list_of_roles.includes("Gestion des contrats et avenants") || user?.user?.account_type === "SUPER_ADMIN" ? <ContratsAvenants /> : <div></div>} />
              <Route path="/admin/gestion-des-contrats/details/:id" element={list_of_roles.includes("Gestion des contrats et avenants") || user?.user?.account_type === "SUPER_ADMIN" ? <ContratsAvenantsDetails /> : <div></div>} />
            </Route>

            <Route path="gestion-des-factures">
              <Route index path="/admin/gestion-des-factures/" element={list_of_roles.includes("Gestion des factures") || user?.user?.account_type === "SUPER_ADMIN" ? <Factures /> : <div></div>} />
              <Route path="/admin/gestion-des-factures/details/:id" element={list_of_roles.includes("Gestion des factures") || user?.user?.account_type === "SUPER_ADMIN" ? <FactureDetails /> : <div></div>} />
            </Route>

            <Route path="gestion-des-fiches-de-paie">
              <Route index path="/admin/gestion-des-fiches-de-paie" element={list_of_roles.includes("Gestion des fiches de paie") || user?.user?.account_type === "SUPER_ADMIN" ? <FichesDePaie /> : <div></div>} />
              <Route path="/admin/gestion-des-fiches-de-paie/details/:id" element={list_of_roles.includes("Gestion des fiches de paie") || user?.user?.account_type === "SUPER_ADMIN" ? <FicheDePaieDetails /> : <div></div>} />
            </Route>

            <Route path="gestion-des-problemes">
              <Route index path="/admin/gestion-des-problemes" element={list_of_roles.includes("Gestion des problèmes") || user?.user?.account_type === "SUPER_ADMIN" ? <Problemes /> : <div></div>} />
              {/* <Route path="/admin/gestion-des-problemes/details/:id" element={<ProblemesDetails />: <div></div>} /> */}
              <Route path="/admin/gestion-des-problemes/details/:id" element={list_of_roles.includes("Gestion des problèmes") || user?.user?.account_type === "SUPER_ADMIN" ? <ContentSelectorProblems /> : <div></div>} />
            </Route>

            <Route path="gestion-des-suspensions">
              <Route index path="/admin/gestion-des-suspensions" element={list_of_roles.includes("Gestion des suspension") || user?.user?.account_type === "SUPER_ADMIN" ? <Suspensions /> : <div></div>} />
              <Route path="/admin/gestion-des-suspensions/details/:id" element={list_of_roles.includes("Gestion des suspension") || user?.user?.account_type === "SUPER_ADMIN" ? <ContentSelectorSuspensions /> : <div></div>} />
            </Route>

            <Route path="declarer-les-heures">
              <Route index path="/admin/declarer-les-heures" element={list_of_roles.includes("Déclaration des heures") || user?.user?.account_type === "SUPER_ADMIN" ? <DeclarerHeures /> : <div></div>} />
              <Route index path="/admin/declarer-les-heures/details/:id" element={list_of_roles.includes("Déclaration des heures") || user?.user?.account_type === "SUPER_ADMIN" ? <DeclarerHeureDetails /> : <div></div>} />
            </Route>

            <Route path="gestion-des-entretiens">
              <Route index path="/admin/gestion-des-entretiens" element={list_of_roles.includes("Gestion des entretiens") || user?.user?.account_type === "SUPER_ADMIN" ? <Entretiens /> : <div></div>} />
              <Route path="/admin/gestion-des-entretiens/details/:id" element={list_of_roles.includes("Gestion des entretiens") || user?.user?.account_type === "SUPER_ADMIN" ? <ContentSelectorEntretien /> : <div></div>} />{" "}
            </Route>

            <Route path="gestion-des-epis">
              <Route index path="/admin/gestion-des-epis" element={list_of_roles.includes("Gestion des EPIS") || user?.user?.account_type === "SUPER_ADMIN" ? <Epis /> : <div></div>} />
              <Route path="/admin/gestion-des-epis/details/:id" element={list_of_roles.includes("Gestion des EPIS") || user?.user?.account_type === "SUPER_ADMIN" ? <ContentSelectorEpi /> : <div></div>} />
            </Route>

            <Route path="messagerie" element={list_of_roles.includes("Messagerie") || user?.user?.account_type === "SUPER_ADMIN" ? <Messagerie /> : <div></div>} />
          </Route>
        )}
      </Routes>
    </>
  );
}

export default App;
