import React from "react";
import { InputLabel, styled } from "@mui/material";
import { colors } from "../../theme/Colors";

const ErrorText = styled(InputLabel)({
  color: `${colors.mj_error}`,
  fontSize: "0.875rem",
});

function MjErrorText({ children }) {
  return <ErrorText>{children}</ErrorText>;
}

export default MjErrorText;
