import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Avatar, Chip, Alert, Modal, Autocomplete } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
// import DefaultProfilePic from "../../imgs/user.png";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Form, Formik, ErrorMessage, Field } from "formik";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { colors } from "../../../theme/Colors";
import * as Yup from "yup";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import MjErrorText from "../../../components/ui/MjErrorText";
import TimeInput from "../../../components/ui/TimeInput";
import { ToastContainer, toast } from "react-toastify";

function EntretienDetails({ change, setChange }) {
  // const [change, setChange] = useState(0);
  const params = useParams();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [interview, setInterview] = useState({});
  const [acceptRefuse, setOpenAcceptRefuse] = useState(false);
  const [actionAcceptRefuse, setActionAcceptRefuse] = useState(false);
  const [openUpdateEntretien, setOpenUpdateEntretien] = useState(false);

  const optionsStatus = [
    { id: "PENDING", name: "En cours" },
    { id: "FINISHED", name: "Terminé" },
  ];

  const [openPlanifier, setOpenPlanifier] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [statusEntretien, setStatusEntretien] = useState("");

  const [searchedOffer, setSearchedOffer] = useState("");
  const [selectedOffer, setSelectedOffer] = useState({});
  const [offers, setOffers] = useState([]);

  const [searchedCandidature, setSearchedCandidature] = useState("");
  const [selectedCandidature, setSelectedCandidature] = useState({});
  const [candidatures, setCandidatures] = useState([]);

  const initialValuesPlanifier = {
    offre: "",
    candidature: interview?.application_id,
    date: "",
    heure: "",
    address: "",
  };

  const validationSchemaPlanier = Yup.object().shape({
    offre: Yup.string(),
    candidature: Yup.string().required("Ce champs est obligatoire"),
    date: Yup.string().required("Ce champs est obligatoire"),
    address: Yup.string().required("Ce champs est obligatoire"),
    heure: Yup.string().required("Ce champs est obligatoire"),
  });

  const initialValues = {
    status: null,
  };

  const validationSchema = Yup.object().shape({
    status: Yup.object().nullable().required("Ce champs est obligatoire"),
  });

  useEffect(() => {
    Api.get("/admin/interviews/" + params.id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("interview OBJECT", res.data);
        setInterview(res.data.interview);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);

  const updateStatusEntretien = (values, id) => {
    console.log("hi");
    Api.post(
      "/admin/interviews/" + id + "/status",
      {
        status: values.status.id,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange((change) => change + 1);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  //get offers SEARCH
  useEffect(() => {
    console.log("GETTIN offres SEARCH ///////////:::::::::::");
    if (searchedOffer) {
      console.log("GETTIN offres PAR ID ///////////:::::::::::");
      console.log("searched offer ///////////////", searchedOffer);
      Api.get("/admin/search/offers" + `?search=${searchedOffer}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          console.log("RESPONSE SEARCH offer ::::::::::::::", response);
          setOffers(response?.data?.offers);
        }
      });
    }
  }, [searchedOffer]);

  //get candidatures SEARCH
  useEffect(() => {
    console.log("GETTIN candidatures SEARCH ///////////:::::::::::");
    if (searchedCandidature && selectedOffer?.id) {
      console.log("GETTIN candidatures PAR ID ///////////:::::::::::");
      console.log("searched candidature ///////////////", searchedCandidature);
      Api.get(`/admin/search/offers/${selectedOffer?.id}/applications` + `?search=${searchedCandidature}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          console.log("RESPONSE SEARCH candidature ::::::::::::::", response);
          setCandidatures(response?.data?.applications);
        }
      });
    }
  }, [searchedCandidature]);

  const createInterview = (values) => {
    console.log("hi");
    Api.post(
      "/admin/interviews",
      {
        application_id: values.candidature,
        address: values.address,
        date_time: values.date + " " + values.heure + ":00",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log("CREATE INTERVIEW RESULT", res);
        toast.success(res.data.message);
        setChange((change) => change + 1);
      })

      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
      });
  };

  return (
    <Box>
      {/* Header Section */}

      <Box display="flex" flexDirection="column" sx={{ maxWidth: "700px" }}>
        <ToastContainer />
        <BoxContainer>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box marginBottom="21px" display="flex" flexDirection="row" gap="10px" alignItems="center">
              <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                Entretien #{interview?.id}
              </Typography>
              <Chip variant={interview?.status === "FINISHED" ? "ended_offer" : "active_offer"} label={interview?.status === "FINISHED" ? "Terminé" : "En cours"} />
            </Box>
            <Box>
              <ButtonSquare action="modify" onClick={() => setOpenUpdateEntretien(true)} />
            </Box>
          </Box>
          {interview?.date_time && (
            <Box marginBottom="21px" display="flex" flexDirection="row" gap="10px" alignItems="center">
              <Typography variant="text_clickable" fontWeight="500" lineHeight="32px">
                {interview?.date_time.slice(0, -3).replace(" ", " à ")}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
            <Typography variant="text_normal" fontWeight="500" lineHeight="32px">
              {interview?.address}
            </Typography>
          </Box>
        </BoxContainer>

        <Box display="flex" flexDirection="row-reverse" marginTop="20px">
          <Button onClick={() => setOpenPlanifier(true)} type="submit" variant="mj_green_md">
            Planifier un second entretien
          </Button>
        </Box>

        <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openUpdateEntretien} onClose={() => setOpenUpdateEntretien(false)}>
          <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Traitement entretien
              </Typography>
              <ButtonSquare onClick={() => setOpenUpdateEntretien(false)} action="close" />
            </Box>

            <Formik
              //initial values
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                updateStatusEntretien(values, interview.id);
                setOpenUpdateEntretien(false);
                console.log("hello");
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                  <MjLabel>Status de l'entretien</MjLabel>

                  <Field
                    name="status"
                    as={Autocomplete}
                    options={optionsStatus}
                    noOptionsText={"Aucune option"}
                    onChange={(event, value) => {
                      if (value) {
                        setFieldValue("status", value);
                        setStatusEntretien(value);
                        console.log("selected métier is ::", value);
                      } else {
                        setStatusEntretien(null);
                        setFieldValue("status", null);
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
                    renderInput={(params) => <TextField fullWidth value={statusEntretien} {...params} placeholder="Rechercher un métier..." />}
                  />
                  <ErrorMessage name="status" component={MjErrorText} />

                  <Box display="flex" flexDirection="row-reverse" gap="10px" marginTop="20px">
                    <Button variant="mj_secondary_md" onClick={() => setOpenUpdateEntretien(false)}>
                      Annuler
                    </Button>
                    <Button variant="mj_primary_md" type="submit">
                      Traiter
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>

        <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openPlanifier} onClose={() => setOpenPlanifier(false)}>
          <Box padding="40px" sx={{ bgcolor: "white", borderRadius: "6px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Planifier un entretien
              </Typography>
              <ButtonSquare onClick={() => setOpenPlanifier(false)} action="close" />
            </Box>

            <Formik
              //initial values
              initialValues={initialValuesPlanifier}
              validationSchema={validationSchemaPlanier}
              onSubmit={(values) => {
                createInterview(values);
                setOpenPlanifier(false);
                console.log("hello");
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                  {/* <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                  <Field fullWidth name="date" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" label="Date traitement du problème" size="small" />
                  <ErrorMessage name="date" component={MjErrorText} />
                </Box> */}
                  {/* <Box sx={{ marginBottom: "10px" }}>
                    <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                      Offre
                    </MjLabel>
                    <Field name="offre">
                      {({ field, form }) => (
                        <Autocomplete
                          {...field}
                          fullWidth
                          value={selectedOffer}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, value) => {
                            if (value) {
                              form.setFieldValue("offre", value.id);
                              setSelectedOffer(value);
                              setCandidatures([]);
                              form.setFieldValue("candidature", "");
                              console.log("selected métier is ::", value);
                            } else {
                              setSelectedOffer(null);
                              setSelectedCandidature(null);
                              form.setFieldValue("offre", "");
                              form.setFieldValue("candidature", "");
                            }
                          }}
                          onInputChange={(event, value) => {
                            setSearchedOffer(event?.target?.value);
                            console.log("searching for :", event?.target?.value);
                            console.log(value);
                            if (event?.target?.value === "") {
                              form.setFieldValue("offre", "");
                            }
                          }}
                          options={offers}
                          getOptionLabel={(option) => "#" + option.id + " " + option.job_title}
                          renderInput={(params) => <TextField fullWidth value={selectedOffer} {...params} placeholder="Rechercher un métier..." />}
                        />
                      )}
                    </Field>
                  </Box>

                  <Box sx={{ marginBottom: "10px" }}>
                    <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                      Candidature
                    </MjLabel>
                    <Field name="candidature">
                      {({ field, form }) => (
                        <Autocomplete
                          disabled={selectedOffer ? false : true}
                          {...field}
                          fullWidth
                          value={selectedCandidature}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, value) => {
                            if (value) {
                              form.setFieldValue("candidature", value.id);
                              setSelectedCandidature(value);
                              console.log("selected candidature is ::", value);
                            } else {
                              setSelectedCandidature(null);
                              form.setFieldValue("candidature", "");
                            }
                          }}
                          onInputChange={(event, value) => {
                            setSearchedCandidature(event?.target?.value);
                            console.log("searching for :", event?.target?.value);
                            console.log(value);
                            if (event?.target?.value === "") {
                              form.setFieldValue("candidature", "");
                            }
                          }}
                          options={candidatures}
                          getOptionLabel={(option) => "#" + option.id + " " + "entreprise : " + option.enterprise_name + " | intérimaire : " + option.user_last_name + " " + option.user_first_name}
                          renderInput={(params) => <TextField fullWidth value={selectedOffer} {...params} placeholder="Rechercher une candidature..." />}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="candidature" component={MjErrorText} />
                  </Box> */}

                  <Box sx={{ marginBottom: "10px" }}>
                    <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                      Date de l'entretien
                    </MjLabel>
                    <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                      <Field fullWidth name="date" as={MyDPFullDate} setFieldValue={setFieldValue} variant="outlined" size="small" />
                      <ErrorMessage name="date" component={MjErrorText} />
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "10px" }}>
                    <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                      Heure de l'entretien
                    </MjLabel>

                    <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                      <Field name="heure" width="100%" component={TimeInput} autoComplete="off" />
                      <ErrorMessage name="heure" component={MjErrorText} />
                    </Box>
                  </Box>

                  <Box sx={{ marginBottom: "10px" }}>
                    <MjLabel fontWeight="600" name="nom" htmlFor="nom" color="black">
                      Adresse de l'entretien
                    </MjLabel>

                    <Box flex={1} alignItems="center" marginRight="1rem" marginBottom="20px">
                      <Field fullWidth name="address" as={TextField} variant="outlined" size="small" />
                      <ErrorMessage name="address" component={MjErrorText} />
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="row-reverse" gap="10px">
                    <Button variant="mj_secondary_md" onClick={() => setOpenPlanifier(false)}>
                      Annuler
                    </Button>
                    <Button variant="mj_primary_md" type="submit">
                      Planifier
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>

        {/* <pre>{JSON.stringify(suspension, null, 2)}</pre> */}
      </Box>
    </Box>
  );
}

export default EntretienDetails;
