import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";

function Welcome() {
  {
    /* const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie_fox,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }; */
  }

  const val = "Bienvenue sur votre espace \nAdministrateur";

  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ height: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="h2" component="h1" align="center" style={{ wordWrap: "break-word" }}>
          {val}
        </Typography>
        {/* <Lottie options={defaultOptions} height={400} width={400} /> */}
      </Box>

      {/* Lottie section */}
      <Box className="actions"></Box>
    </Box>
  );
}

export default Welcome;
