import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Checkbox, IconButton, Modal, Alert } from "@mui/material";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import { FormControlLabel } from "@mui/material";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import * as yup from "yup";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { colors } from "../../theme/Colors";
import { useNavigate } from "react-router-dom";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { ToastContainer, toast } from "react-toastify";
import MyTextField from "../../components/ui/MyTextField";
import * as Yup from "yup";
import { modalStyle } from "../../theme/ModalStyle";
import BoxContainer from "../../components/ui/BoxContainer";

function Agences() {
  const [change, setChange] = useState(0);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [id, setId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const validationSchema = yup.object().shape({
    id_interimaire: yup.string(),
    telephone: yup.number().typeError("entrer un nombre valide").positive("entrer un numero valid").min(111111111, "entrer un numero valid").max(99999999999, "entrer un numero valid"),
    rib: yup.number("ce champ doit comporter un nombre").typeError("ce champ doit comporter un nombre").max(999999999999999999999999999, "un maximum de 27 chiffres"),
  });
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: "grid-header" },
    {
      field: "name",
      headerName: "Nom",
      width: 150,
      renderCell: (params) => {
        if (params.row.name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.name;
        }
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      renderCell: (params) => {
        if (params.row.email === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.email;
        }
      },
    },
    {
      field: "phone_number",
      headerName: "Téléphone",
      width: 170,
      renderCell: (params) => {
        if (params.row.phone_number === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.phone_number;
        }
      },
    },
    {
      field: "address",
      headerName: "Adresse",
      width: 100,
      renderCell: (params) => {
        if (params.row.address === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.address;
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            <ButtonDataGrid
              action={"delete"}
              onClick={() => {
                setOpenDelete(true);
                setId(params.row);
              }}
            />
            <ArrowCircleRightIcon action={"details"} sx={{ cursor: "pointer", color: `${colors.mjGreenOutline}`, fontSize: 40 }} onClick={() => navigate(`/admin/gestion-des-agences/details/${params.row.id}`, { state: { action: "details" } })} />
          </Box>
        );
      },
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];

  const { user } = useAuthContext();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState("");
  const [actionMultiple, setactionMultiple] = useState("");
  const [open, setOpen] = useState(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const navigate = useNavigate();
  const access_token = user?.access_token;
  const [error, setError] = useState("");
  const [statusH, setStatusH] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIDs, setSelectedIDs] = useState(null);
  const [status, setStatus] = useState([]);
  const [openParams, setOpenParams] = React.useState(false);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState();

  const formik = useFormik({
    initialValues: {
      id_interimaire: "",
      metier: "",
      certification: "",
      cv: "",
      checkedhandicap: false,
      checkedPrendTt: false,
      checkedAtt: false,
      checkedValid: false,
      checkedRef: false,
    },
    onSubmit: () => {
      console.log(formik.values, status);
      setChange(change + 1);
    },
    validationSchema: validationSchema,
  });
  const [initialValues1, setInitialValues1] = useState({
    nom: "",
    telephone: "",
    adresse: "",
    email: "",
  });
  const validationSchema1 = Yup.object().shape({
    nom: Yup.string().required("Ce champ est obligatoire"),
    adresse: Yup.string().required("Ce champ est obligatoire"),
    email: Yup.string().email("entrer une adresse email valide").required("Ce champ est obligatoire"),
    telephone: Yup.number().typeError("Valeur invalide").positive("Valeur invalide").required("Ce champ est obligatoire"),
  });
  useEffect(() => {
    if (rowsPerPage && page) {
      setLoading(true);
      Api.get(Urls.GET_AGENCES + `?perPage=${25}`, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          is_potential: 0,
          ...(formik.values.email !== "" && { email: formik.values.email }),
          ...(formik.values.telephone !== "" && { phone_number: formik.values.telephone }),
          ...(formik.values.nom !== "" && { name: formik.values.nom }),
          ...(formik.values.adresse !== "" && { address: formik.values.adresse }),
          ...(formik.values.id !== "" && { id: formik.values.id }),
        },
      })
        .then((res) => {
          setRows(res.data.agencies);
          setData(res.data);
          setLoading(false);
          setTotal(res?.data?.meta?.total);
          console.log("response el gharbi", res.data);
        })
        .catch((err) => {
          console.log(err);
          setRows([]);
        });
    }
  }, [change, page, rowsPerPage, formik.values]);
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };
  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.agencies);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };

  const customFooter = () => {
    return <Box></Box>;
  };
  const supAgence = (id) => {
    Api.delete(Urls.DELETE_AGENCIES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        agency_ids: [id],
      },
    })
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpenDelete(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setOpenDelete(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const SupMultipleAgence = (id) => {
    Api.delete(Urls.DELETE_AGENCIES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        agency_ids: [id],
      },
    })
      .then((res) => {
        console.log(res);
        setChange(change + 1);
        setOpenMultiple(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setOpenMultiple(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const AjouterAgence = (values) => {
    Api.post(
      Urls.CREATE_AGENCE,
      {
        name: values.nom,
        address: values.adresse,
        phone_number: values.telephone,
        email: values.email,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAdd(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box>
      <ToastContainer />
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des agences
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box className="filter" display="flex" flexDirection="column" gap="1rem">
          <Box>
            <Button onClick={() => setOpenAdd(true)} variant="mj_primary_md">
              Ajouter une agence
            </Button>
          </Box>
          <Box display="flex" gap="16px" sx={{ flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" } }}>
            <Box>
              <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
                Filtre :
              </Typography>
            </Box>

            {/*Search fields  */}
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <TextField error={formik.errors.id ? true : false} onChange={formik.handleChange} type="input" name="id" placeholder="id" />
            </Box>
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <TextField error={formik.errors.nom ? true : false} onChange={formik.handleChange} type="input" name="nom" placeholder="Nom" />
            </Box>
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <TextField error={formik.errors.id_interimaire ? true : false} onChange={formik.handleChange} type="input" name="email" placeholder="Email" />
            </Box>
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <TextField error={formik.errors.id_interimaire ? true : false} onChange={formik.handleChange} type="input" name="telephone" placeholder="Téléphone" />
            </Box>
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <TextField error={formik.errors.id_interimaire ? true : false} onChange={formik.handleChange} type="input" name="adresse" placeholder="Adresse" />
            </Box>
          </Box>
        </Box>

        {/* Checkboxes */}

        {/* Actions Section */}
        <Box className="actions">
          <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
              Actions :
            </Typography>

            <Box display="flex" gap="1rem">
              <Button
                disabled={selectedIDs?.length > 0 ? false : true}
                onClick={() => {
                  setOpenMultiple(true);
                }}
                variant="mj_secondary_md"
              >
                Supprimer les agences
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box marginBottom="24px" sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1255px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows ? rows : []}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          page={page - 1}
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          checkboxSelection
          loading={loading}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
          }}
          disableSelectionOnClick
          paginationMode="server"
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openDelete} onClose={() => setOpenDelete(false)}>
        <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
          <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
            Supprimer une agence
          </Box>
          <Box>
            <Typography display="inline-block" margin="1rem" textAlign="center">
              Voulez vous vraiment supprimer cette agence
            </Typography>
          </Box>
          {success ? (
            <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
              {success}
            </Alert>
          ) : null}
          {error ? <Alert severity="error">{error}</Alert> : null}
          <Box onClick={() => console.log("test")} margin="1rem 0rem" gap="8px" display="flex" alignItems="center" justifyContent="center">
            <Button variant="mj_secondary_md" onClick={() => supAgence(id?.id)}>
              Supprimer
            </Button>
            <Button variant="mj_primary_md" onClick={() => setOpenDelete(false)}>
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openMultiple} onClose={() => setOpenMultiple(false)}>
        <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
          <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
            Supprimer des agences
          </Box>
          <Box>
            <Typography display="inline-block" margin="1rem" textAlign="center">
              Voulez vous vraiment supprimer ces agences
            </Typography>
          </Box>
          {success ? (
            <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
              {success}
            </Alert>
          ) : null}
          {error ? <Alert severity="error">{error}</Alert> : null}
          <Box onClick={() => console.log("test")} margin="1rem 0rem" gap="8px" display="flex" alignItems="center" justifyContent="center">
            <Button variant="mj_secondary_md" onClick={() => SupMultipleAgence()}>
              Supprimer
            </Button>
            <Button variant="mj_primary_md" onClick={() => setOpenMultiple(false)}>
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openAdd} onClose={() => setOpenAdd(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Création d’une agence
              </Typography>
              <ButtonSquare onClick={() => setOpenAdd(false)} action="close" />
            </Box>
            <Formik
              initialValues={initialValues1}
              onSubmit={(values) => {
                AjouterAgence(values);
              }}
              validationSchema={validationSchema1}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Nom
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.nom} name="nom" placeholder="Nom de l’entreprise..." />
                        {errors.nom && touched.nom ? (
                          <Typography color="red" fontSize="12px">
                            {errors.nom}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Email
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.email} name="email" placeholder="Email..." />
                        {errors.email && touched.email ? (
                          <Typography color="red" fontSize="12px">
                            {errors.email}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Téléphone
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.telephone} name="telephone" placeholder="Téléphone..." />
                        {errors.telephone && touched.telephone ? (
                          <Typography color="red" fontSize="12px">
                            {errors.telephone}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Adresse
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.adresse} name="adresse" placeholder="Adresse..." />
                        {errors.adresse && touched.adresse ? (
                          <Typography color="red" fontSize="12px">
                            {errors.adresse}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenAdd(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default Agences;
