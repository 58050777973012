import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Alert, FormControl, Select, MenuItem, Autocomplete } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
import MjErrorText from "../../../components/ui/MjErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLocation, useParams } from "react-router-dom";
import { useRef } from "react";
import MyChip from "../../../components/ui/MyChip";
import { ToastContainer, toast } from "react-toastify";

function UpdateAdminAccount() {
  const params = useParams();
  // console.log("PARAMS = ", params);
  const location = useLocation();
  // console.log("LOCATION = ", location);
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  const formRef = useRef();
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [searchedAgency, setSearchedAgency] = useState("");
  const [roles, setRoles] = useState([]);
  // const [selectedRole, setSelectedRole] = useState(null);
  // const [searchedRole, setSearchedRole] = useState("");

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRolesIDS, setSelectedRolesIDS] = useState([]);
  const [searchedRoles, setSearchedRoles] = useState(null);
  const [selectedRoleValue, setSelectedRoleValue] = useState(null);

  const [admin, setAdmin] = useState({});

  const [selectedNames, setSelectedNames] = useState([]);

  const onSubmitUpdateAdmin = (values) => {
    console.log("updating admin");
    console.log("updating admin with values =====> ", values);
    Api.patch(
      Urls.CREATE_ADMIN + `/${admin.id}`,
      {
        first_name: values.prenom,
        last_name: values.nom,
        email: values.email,
        agency_id: values.agence,
        role_names: values.role,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  //get admin
  useEffect(() => {
    Api.get(Urls.GET_LISTE_ADMIN + `/${params.id}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setAdmin(res?.data?.admin);
        setSelectedAgency(res?.data?.admin?.agency);
        formRef?.current?.setFieldValue("agence", res?.data?.admin?.agency?.id);
        console.log("ADMIN :", res.data.admin);
        setSelectedRoles(res?.data?.admin?.roles);
        setSelectedRolesIDS(res?.data?.admin?.roles.map((r) => r.name));
        formRef?.current?.setFieldValue(
          "role",
          res?.data?.admin?.roles.map((r) => r.name)
        );
        //setSelectedRolesIDS(...selectedRolesIDS.concat([res?.data?.admin?.roles?.id]));
        //formRef?.current?.setFieldValue("role", ...selectedRolesIDS.concat([res?.data?.admin?.roles?.id]));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const initialValues = {
    nom: admin ? admin.last_name : "",
    prenom: admin ? admin.first_name : "",
    email: admin ? admin.email : "",
    role: admin ? admin.role_names : "",
    agence: admin ? admin.agency_name : "",
  };

  const validationSchema = Yup.object({
    nom: Yup.string().required("Ce champ est obligatoire"),
    prenom: Yup.string().required("Ce champ est obligatoire"),
    email: Yup.string().email("Format email invalid").required("Ce champ est obligatoire"),
    role: Yup.string().required("Ce champ est obligatoire"),
    agence: Yup.string().required("Ce champ est obligatoire"),
  });

  //get agencies
  useEffect(() => {
    Api.get(Urls.GET_AGENCES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {},
    })
      .then((res) => {
        setAgencies(res?.data?.agencies);
        console.log("Agencies :", res.data.agencies);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //get roles
  useEffect(() => {
    Api.get(Urls.GET_ROLES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: { searchKey: "" },
    })
      .then((res) => {
        setRoles(res?.data?.roles);
        console.log("Roles :", res.data.roles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box>
      {/* Form Section */}
      <Box className="filter">
        <ToastContainer />
        <Formik onSubmit={onSubmitUpdateAdmin} initialValues={initialValues} enableReinitialize={true} innerRef={formRef}>
          {({ values }) => (
            <Form>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="nom" htmlFor="nom">
                  Nom
                </MjLabel>
                <Field name="nom" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="nom" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="prenom" htmlFor="">
                  Prénom
                </MjLabel>
                <Field name="prenom" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="prenom" component={MjErrorText} />
              </Box>
              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="email" htmlFor="">
                  Email
                </MjLabel>
                <Field name="email" autoComplete="off" as={TextField} fullWidth />
                <ErrorMessage name="email" component={MjErrorText} />
              </Box>

              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="agence" htmlFor="">
                  Agence
                </MjLabel>
                <Field name="agence">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={selectedAgency}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value) {
                          setSelectedAgency(value);
                          form.setFieldValue("agence", value.id);
                          console.log("selected agence is ::", value);
                        } else {
                          setSelectedAgency(null);
                          form.setFieldValue("agence", "");
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedAgency(event?.target?.value);
                        console.log("searching for :", event?.target?.value);
                        console.log(value);
                        if (event?.target?.value === "") {
                          form.setFieldValue("agence", "");
                        }
                      }}
                      options={agencies}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField fullWidth value={selectedAgency} {...params} placeholder="Rechercher une agence..." />}
                    />
                  )}
                </Field>
                <ErrorMessage name="agence" component={MjErrorText} />
              </Box>

              <Box sx={{ marginBottom: "1rem" }} maxWidth="500px">
                <MjLabel name="role" htmlFor="">
                  role
                </MjLabel>
                {/* <Field name="role">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={selectedRole}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value) {
                          setSelectedRole(value);
                          form.setFieldValue("role", value.name);
                          console.log("selected role is ::", [value.name]);
                        } else {
                          setSelectedRole(null);
                          form.setFieldValue("role", "");
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedRole(event?.target?.value);
                        console.log("searching for :", event?.target?.value);
                        console.log(value);
                        if (event?.target?.value === "") {
                          form.setFieldValue("role", "");
                        }
                      }}
                      options={roles}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField fullWidth value={selectedRole} {...params} placeholder="Rechercher une role..." />}
                    />
                  )}
                </Field>
                <ErrorMessage name="role" component={MjErrorText} /> */}

                <Field name="role">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={null}
                      blurOnSelect={true}
                      noOptionsText={"Aucune option"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value && !selectedRoles.find((item) => item.id === value.id)) {
                          setSelectedRoleValue(value);
                          setSelectedRoles(selectedRoles.concat([value]));
                          setSelectedRolesIDS(selectedRolesIDS.concat([value.name]));
                          form.setFieldValue("role", selectedRolesIDS.concat([value.name]));
                        } else {
                          setSelectedRoleValue(null);
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedRoles(event?.target?.value);
                        console.log("searching for :", event?.target?.value);
                        console.log(value);
                      }}
                      options={roles}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          value={selectedRoles}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                          placeholder="Rechercher des roles..."
                        />
                      )}
                    />
                  )}
                </Field>
                <Field name="role">
                  {({ field, form }) => (
                    <Box flexWrap="wrap" display={selectedRolesIDS.length === 0 ? "none" : "flex"} gap="10px" sx={{ paddingTop: "10px" }}>
                      {selectedRoles?.map((item, index) => (
                        <MyChip
                          key={index}
                          variant="skill"
                          label={item.name}
                          onDelete={() => {
                            setSelectedRoles(selectedRoles.filter((sel) => sel.id !== item.id));
                            setSelectedRolesIDS(selectedRolesIDS.filter((sel) => sel !== item.name));
                            form.setFieldValue(
                              "role",
                              selectedRolesIDS.filter((sel) => sel !== item.name)
                            );
                            console.log("selected roles after deleting the selected value", selectedRoles);
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Field>
              </Box>

              <Box>
                <Button type="submit" variant="mj_green_md">
                  Mettre à jour
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default UpdateAdminAccount;
