import React, { useState } from "react";
import { Autocomplete, Box, Button, Divider, Grid, InputAdornment, Modal, Typography, TextField, Radio, FormControl, RadioGroup, FormControlLabel, IconButton } from "@mui/material";
import BoxContainer from "./BoxContainer";
import MyChip from "./MyChip";
import pointIcon from "../../imgs/imgV2/point.svg";
import Xredicon from "../../imgs/imgV2/XredIcon.svg";

import ButtonSquare from "./ButtonSquare";
import moment from "moment";
import { modalStyle, modalStyle2 } from "../../theme/ModalStyle";
import TimeInput from "./TimeInput";
import RightArrowTime from "../../imgs/imgV2/RightArrowTime.svg";
import FormStep from "./FormStep";
import AddIcon from "../../imgs/imgV2/plusIcon.svg";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import RechercheIcon from "../../imgs/imgV2/RechercheIcon.svg";
import ArrowUpGreen from "../../imgs/imgV2/ArrowUpGreen.svg";
import { ErrorMessage, Field, Form, Formik, validateYupSchema } from "formik";
import FormikErrorText from "./FormikErrorText";
import { colors } from "../../theme/Colors";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import BoxContainerModal from "./BoxContainerModal";
import FormStepper from "./FormStepper";
import OmjDatePicker from "./OmjDatePicker";
import MySwitch from "./MySwitch/MySwitch";
import styled from "@emotion/styled";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import DetailsProfileInterim from "../../pages/details/offres/DetailsProfileInterim";
import DetailOffreFinale from "../../pages/details/offres/DetailOffreFinale";

function ProfilePropComponent({ idUser, contractID, etat, motifsR, setChange, change, id, start_date, nombre_de_mission, titre, matching, actions }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [openDetailsProfile, setOpenDetailProfile] = useState(false);
  const MyTextField = styled(TextField)({
    width: "100%",
  });
  const [showPasswordDel, setShowPasswordDel] = useState(false);

  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  const [openAccepter, setOpenAccepter] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [openRefuser, setOpenRefuser] = useState(false);
  const [idContrat, setIdContrat] = useState(null);
  const [openOffre, setOpenOffre] = useState(false);

  //etat selector
  const chipSelector = (etat) => {
    switch (etat) {
      case "APPLIED":
        return "En attente";
        break;
      case "PENDING":
        return "En attente";
        break;
      case "OFFER_REJECTED":
        return "Proposition refusée";
        break;
      case "APPLICATION_REJECTED":
        return "Refusée";
        break;
      case "APPLICATION_CANCELED":
        return "Candidature Annulée";
        break;
      case "ACCEPTED":
        return "Offre envoyée";
        break;
      case "DOCUMENTS_PENDING_VALIDATION":
        return "Offre envoyée";
        break;
      case "CREATING_CONTRACT":
        return "Contrat en cours de création";
        break;
    }
  };

  function afficherDifferenceDate(date) {
    const now = moment();

    const diff = moment(date);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");

    if (minutes === 1) {
      return "Proposé il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Proposé il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Proposé il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Proposé il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Proposé il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Proposé il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Proposé il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Proposé il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Proposé il y a 1 mois";
    } else {
      return `Proposé il y a ${mois} mois`;
    }
  }
  const translateMatchig = {
    suitable: "matching_satisfaisant",
    perfect: "matching_parfait",
  };

  const RejeterCandidature = (id, selected, motif) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        admin_status: "REFUSED",
        ...(selected !== "Autre" ? null : { refusal_reason: motif }),
        refusal_reason_id: selected === "Autre" ? null : selected,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const AccepterCandidature = (id, password) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        admin_status: "SIGNED",
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAccepter(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const PushEnAttente = (id) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        admin_status: "PENDING",
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAccepter(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  console.log(actions, "action admin");

  return (
    <Box sx={{ cursor: "pointer" }}>
      <ToastContainer />
      <BoxContainer sx={{ cursor: "pointer" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box flex={5} onClick={() => setOpenDetailProfile(true)} display="flex" flexDirection="column" gap="20px">
            <Box>
              {matching != "weak" && <MyChip onClick={() => setOpenDetailProfile(true)} variant={translateMatchig[`${matching}`]} label={matching === "suitable" ? "👌 Matching satisfaisant" : "🥇 Matching parfait"} />}
              <Typography onClick={() => setOpenDetailProfile(true)} fontSize="18px" fontWeight="500" lineHeight="28.8px">
                {titre?.name ? titre?.name : null}
              </Typography>
              <Typography onClick={() => setOpenDetailProfile(true)} fontSize="12px" fontWeight="400" lineHeight="19.2px" color="#4A526C">
                {afficherDifferenceDate(start_date)}
              </Typography>
            </Box>
            <Box onClick={() => setOpenDetailProfile(true)} display="flex" alignItems="center" gap="8px">
              {titre?.experience_years && (
                <Box display="flex" alignItems="center" gap="8px">
                  {" "}
                  <Typography fontSize="14px" fontWeight="500" lineHeight="22.4px">
                    🛠️ 3 ans d’expérience
                  </Typography>
                  <img src={pointIcon} alt="point-icon" />
                </Box>
              )}
              <Typography fontSize="14px" fontWeight="500" lineHeight="22.4px">
                💼️ {nombre_de_mission} missions travaillées
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            {etat === "OFFER_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
            {etat === "APPLICATION_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
            {etat === "CREATING_CONTRACT" && <MyChip icon={ArrowUpGreen} variant="skill" label={chipSelector(etat)} />}
            {etat === "CREATING_CONTRACT" && <Button variant="mj_primary_md">Signer</Button>}

            {(etat === "ACCEPTED" || etat === "DOCUMENTS_PENDING_VALIDATION") && <MyChip icon={ArrowUpGreen} variant="ended_offer" label={chipSelector(etat)} />}
            {actions?.map((action) => (
              <Box key={action} flexDirection="row" display="flex" gap="10px" alignItems="center">
                {action === "SIGNED" && (
                  <Button variant="mj_primary_md" onClick={() => setOpenAccepter(true)}>
                    Signer
                  </Button>
                )}
                {action === "ACCEPTED" && (
                  <Button variant="mj_primary_md" onClick={() => setOpenAccept(true)}>
                    Recruter
                  </Button>
                )}
                {action === "PENDING" && (
                  <Button variant="mj_primary_md" onClick={() => PushEnAttente(id)}>
                    En attente
                  </Button>
                )}
                {action === "REFUSED" && <ButtonSquare action="refuse" onClick={() => setOpenRefuser(true)} />}
              </Box>
            ))}
          </Box>
        </Box>
      </BoxContainer>
      <Modal open={openDetailsProfile} onClose={() => setOpenDetailProfile(false)}>
        <Box sx={modalStyle2}>
          <Box sx={{ padding: "24px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpenDetailProfile(false)} action="close" />
                <Typography variant="text_xxl" fontWeight="700">
                  Profile #{idUser}
                </Typography>
              </Box>
              <Box display="flex" gap="10px" alignItems="center">
                {idContrat && (
                  <Button variant="mj_secondary_md" onClick={() => setOpenOffre(true)}>
                    Voir votre offre
                  </Button>
                )}
                {etat === "OFFER_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
                {etat === "APPLICATION_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
                {(etat === "ACCEPTED" || etat === "DOCUMENTS_PENDING_VALIDATION") && <MyChip icon={ArrowUpGreen} variant="ended_offer" label={chipSelector(etat)} />}
                {actions?.map((action) => (
                  <Box key={action} flexDirection="row" display="flex" gap="10px" alignItems="center">
                    {action === "SIGNED" && (
                      <Button variant="mj_primary_md" onClick={() => setOpenAccepter(true)}>
                        Signer
                      </Button>
                    )}
                    {action === "ACCEPTED" && (
                      <Button variant="mj_primary_md" onClick={() => setOpenAccept(true)}>
                        Recruter
                      </Button>
                    )}
                    {action === "PENDING" && (
                      <Button variant="mj_primary_md" onClick={() => PushEnAttente(id)}>
                        En attente
                      </Button>
                    )}
                    {action === "REFUSED" && <ButtonSquare action="refuse" onClick={() => setOpenRefuser(true)} />}
                  </Box>
                ))}
              </Box>
            </Box>
            <DetailsProfileInterim interimId={idUser} type={"detailsProfile"} id={id} />
          </Box>
        </Box>
      </Modal>
      <Modal open={openOffre} onClose={() => setOpenOffre(false)}>
        <Box sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpenOffre(false)} action="close" />

                <Typography variant="text_xxl" fontWeight="700">
                  Offre finale #{idContrat}
                </Typography>
              </Box>
            </Box>
            <DetailOffreFinale filter="finalOffer" id={idContrat} type={"contrat"} />
          </BoxContainer>
        </Box>
      </Modal>

      <Modal open={openRefuser} onClose={() => setOpenRefuser(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Refuser la candidature
              </Typography>
              <ButtonSquare onClick={() => setOpenRefuser(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                motif: "",
                selected: "Autre",
              }}
              onSubmit={(values) => {
                RejeterCandidature(id, values.selected, values.motif);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Typography variant="text_normal" fontWeight="600" htmlFor="description">
                      Motif du refus
                    </Typography>
                    <FormControl>
                      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={values.selected} onChange={(e) => setFieldValue("selected", e.target.value)}>
                        {motifsR?.map((mot) => (
                          <FormControlLabel value={mot?.id} control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label={mot?.name} />
                        ))}
                        <FormControlLabel value="Autre" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Autre" />
                      </RadioGroup>
                    </FormControl>
                    {values.selected === "Autre" && (
                      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description">
                            Plus de détails
                          </Typography>

                          <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field name="motif" type="input" placeholder="Veuillez donner plus de détails..." id="description" as={MyTextField} multiline fullWidth columns={5} rows={4} autoComplete="off" />
                        <ErrorMessage component={FormikErrorText} name="description" />
                      </Box>
                    )}
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenRefuser(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values.selected === "Autre" && values.motif === "" ? true : false} type="submit" variant="mj_danger_md">
                        Confirmer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openAccepter} onClose={() => setOpenAccepter(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Signer le contrat
              </Typography>
              <ButtonSquare onClick={() => setOpenAccepter(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={(values) => {
                AccepterCandidature(id, values.password);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Typography sx={{ fontSize: "25px", fontWeight: "700", lineHeight: "160%" }}>Signature</Typography>
                    <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                      En introduisant votre mot de passe, vous confirmez avoir lu et accepté le contrat #{contractID}
                    </Typography>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Mot de passe
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder="Saisir votre mot de passe"
                              autoComplete="Off"
                              type={showPasswordDel ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPasswordDel} onMouseDown={handleMouseDownPasswordDel} edge="end">
                                      {showPasswordDel ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                        {errors.password && touched.password ? (
                          <Typography color="red" fontSize="12px">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenAccepter(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.password?.length > 7 ? false : true} type="submit" variant="mj_green_md">
                        Signer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default ProfilePropComponent;
