import { createTheme } from "@mui/material/styles";
import { colors } from "./Colors";
import { frFR } from "@mui/material/locale";

//MUI CUSTOM THEME
export const theme = createTheme(
  {
    palette: {
      primary: {
        main: colors.main,
        light: colors.light,
        dark: colors.dark,
        white: "#FFFFF",
        preselected: colors.mj_preselected,
        confirmed: colors.main_mj,
        refused: colors.mj_error,
        accepted: colors.mj_green,
        pending: colors.mj_pending,
      },
      neutral: {
        main: "#FFFFF",
      },
    },

    typography: {
      fontFamily: ["Inter", "Montserrat"].join(","),
    },

    components: {
      MuiChip: {
        variants: [
          {
            props: {
              variant: "active_offer",
            },
            style: {
              color: "#003E9C",
              fontWeight: "600",
              background: "#E6F0FF",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "2px 4px",
              },
            },
          },
          {
            props: {
              variant: "ended_offer",
            },
            style: {
              color: "#2D8C61",
              fontWeight: "600",
              background: "#EDFCF5",
              borderRadius: "6px",
              padding: "6px 8px 6px 8px",

              height: "22px",
              "& .MuiChip-label": {
                padding: "2px 4px",
                lineHeight: "19.2px",
              },
            },
          },
          {
            props: {
              variant: "rejected_offer",
            },
            style: {
              color: "#CC0000",
              fontWeight: "600",
              background: "#FAE6E6",
              padding: "6px 8px 6px 8px",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "2px 4px",
              },
            },
          },
          {
            props: {
              variant: "pending_contract",
            },
            style: {
              color: "rgba(234, 91, 12, 1)",
              fontWeight: "600",
              background: "rgba(253, 239, 231, 1)",
              padding: "6px 8px 6px 8px",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "2px 4px",
              },
            },
          },
          {
            props: {
              variant: "contrat_en_cours",
            },
            style: {
              color: "#0065FF",
              fontWeight: "600",
              background: "#E6F0FF",
              padding: "6px 8px 6px 8px",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "2px 4px",
              },
            },
          },
          {
            props: {
              variant: "id",
            },
            style: {
              color: "#666D83",
              fontWeight: "600",
              background: "#F5F5F7",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "2px 4px",
              },
            },
          },
          {
            props: {
              variant: "matching_parfait",
            },
            style: {
              color: "white",
              fontWeight: "600",
              background: "#0065FF",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "2px 4px",
              },
            },
          },
          {
            props: {
              variant: "matching_satisfaisant",
            },
            style: {
              color: "#2E3755",
              fontWeight: "600",
              background: "#F5F5F7",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "2px 4px",
              },
            },
          },
          {
            props: {
              variant: "skill",
            },
            style: {
              color: "#2E3755",
              fontWeight: "500",
              background: "#F5F5F7",
              borderRadius: "6px",
              padding: "4px 10px",
              lineHeight: "19.2px",
              "& .MuiChip-label": {
                padding: "2px 12px 2px 4px",
              },
              "& .MuiChip-deleteIcon": {
                color: "#2E3755",
              },
              "& .MuiChip-deleteIcon:hover": {
                color: "#2E3755",
              },
            },
          },
        ],
      },
      //drawer
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: `${colors.mj_drawer}`,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            color: colors.tFieldGray,
            "& .MuiInputBase-root": {
              height: "42px",
              padding: "0px 10px",
            },
            "& .MuiAutocomplete-input": {
              padding: "0px 10px !important",
            },
            "& .css-gqvsnj-MuiAutocomplete-root": {
              padding: "0px 10px",
            },
            "& .css-wq7oss-MuiAutocomplete-root": {
              padding: "0px 10px",
            },
          },
        },
      },
      //navbar
      AppBar: {
        variants: [
          {
            props: {
              variant: "mj_navbar",
            },
            style: {
              background: `${colors.mj_navbar}`,
              color: "white",
            },
          },
        ],
      },

      //Input textfield
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            fontSize: "0.875rem",
            backgroundColor: "white",
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderColor: "#DEDFE4",
              borderRadius: "6px",
              fontSize: "14px",
              fontWeight: "400",
              padding: "0px 10px",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: colors.tFieldGray,
            "& .MuiOutlinedInput-root": {
              padding: "0px 10px",
            },
            "& .MuiInputBase-root": {
              minHeight: "42px",
              padding: "0px 10px",
            },
            "& .MuiInputBase-input": {
              minHeight: "42px",
              padding: "0px 10px",
            },
            "& .MuiInputBase-inputMultiline": {
              borderRadius: "6px",
              fontSize: "14px",
              lineHeight: "22.4px",
              fontWeight: "400",
              padding: "10px 10px",
            },
            "& .MuiOutlinedInput-root": {
              minHeight: "42px",
              borderRadius: "6px",
              fontSize: "14px",
              lineHeight: "22.4px",
              fontWeight: "400",
              padding: "0px 10px",
              "& fieldset": {
                borderColor: "#DEDFE4",
                borderRadius: "6px",
                fontSize: "14px",
                lineHeight: "22.4px",
                fontWeight: "400",
                padding: "0px 10px",
              },
              "&:placeholder fieldset": {
                color: "#DEDFE4",
              },
              "&:hover fieldset": {
                border: `2px solid ${colors.main}`,
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${colors.main}`,
              },
            },
          },
        },
      },

      //TEXT
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "mini_title",
            },
            style: {
              fontSize: "1.3rem",
            },
          },
          {
            props: {
              variant: "text",
            },
            style: {
              fontSize: "0.875rem",
              color: colors.textDark,
              fontWeight: 500,
            },
          },
          {
            props: {
              variant: "text_gray",
            },
            style: {
              fontSize: "0.875rem",
              color: colors.gray,
              fontWeight: 500,
            },
          },
          {
            props: {
              variant: "text_gray_footer",
            },
            style: {
              cursor: "pointer",
              fontSize: "0.875rem",
              color: colors.gray,
              fontWeight: 500,
              "&:hover": {
                textDecoration: "underline",
                transition: "width 1s",
              },
            },
          },
          {
            props: {
              variant: "text_gray_description",
            },
            style: {
              fontSize: "0.875rem",
              color: colors.gray,
              fontWeight: 500,
              maxWidth: "620px",
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
            },
          },
          {
            props: {
              variant: "text_normal",
            },
            style: {
              fontSize: "14px",
              color: "black",
              fontWeight: 400,
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              lineHeight: "22.4px",
            },
          },
          //XS
          {
            props: {
              variant: "text_xs",
            },
            style: {
              fontSize: "12px",
              color: "black",
              fontWeight: 400,
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              lineHeight: "22.4px",
            },
          },
          //SM
          {
            props: {
              variant: "text_sm",
            },
            style: {
              fontSize: "14px",
              color: "black",
              fontWeight: 400,
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              lineHeight: "22.4px",
            },
          },
          //MD
          {
            props: {
              variant: "text_md",
            },
            style: {
              fontSize: "16px",
              color: "black",
              fontWeight: 400,
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              lineHeight: "25.6px",
            },
          },
          //LG
          {
            props: {
              variant: "text_lg",
            },
            style: {
              fontSize: "20px",
              color: "black",
              fontWeight: 400,
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              lineHeight: "32px",
            },
          },
          //XM
          {
            props: {
              variant: "text_xl",
            },
            style: {
              fontSize: "28px",
              color: "black",
              fontWeight: 400,
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              lineHeight: "44.8px",
            },
          },
          //XXL
          {
            props: {
              variant: "text_xxl",
            },
            style: {
              fontSize: "32px",
              color: "black",
              fontWeight: 400,
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              lineHeight: "51.2px",
            },
          },
          //CLICKABLE
          {
            props: {
              variant: "text_clickable",
            },
            style: {
              fontSize: "14px",
              color: colors.text_clickable,
              fontWeight: 400,
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              cursor: "pointer",
              lineHeight: "22.4px",
            },
          },
          {
            props: {
              variant: "detail_value",
            },
            style: {
              fontWeight: "600",
            },
          },

          {
            props: {
              variant: "detail_title",
            },
            style: {
              fontWeight: "600",
            },
          },

          {
            props: {
              variant: "detail_orange",
            },
            style: {
              color: `${colors.main_mj}`,
              fontWeight: "600",
            },
          },
          {
            props: {
              variant: "detail_orange_underline",
            },
            style: {
              color: `${colors.main_mj}`,
              fontWeight: "400",
              textDecoration: "underline",
              cursor: "pointer",
            },
          },
          {
            props: {
              variant: "detail_gray_underline",
            },
            style: {
              color: `${colors.gray}`,
              fontWeight: "400",
              textDecoration: "underline",
              cursor: "pointer",
            },
          },
          {
            props: {
              variant: "detail_orange_large",
            },
            style: {
              color: `${colors.main_mj}`,
              fontWeight: "600",
              fontSize: "1.5rem",
            },
          },
        ],
      },

      MuiButton: {
        //BUTTONS VARIANTS
        variants: [
          //MJ_BUTTON_PRIMARY
          {
            props: { variant: "mj_primary_sm" },
            style: {
              padding: "6px 8px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              backgroundColor: colors.main,
              borderRadius: "6px",
              color: "white",
              border: `2px solid ${colors.main}`,
              "&:hover": {
                backgroundColor: colors.main,
                filter: "brightness(0.7)",
                color: "white",
                border: `2px solid ${colors.main}`,
              },
              textTransform: "none",
            },
          },

          //MJ_BUTTON_PRIMARY
          {
            props: { variant: "mj_red_sm" },
            style: {
              padding: "6px 8px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              backgroundColor: "#CC0000",
              borderRadius: "6px",
              color: "white",
              border: `2px solid #CC0000`,
              "&:hover": {
                backgroundColor: "#CC0000",
                filter: "brightness(0.7)",
                color: "white",
                border: `2px solid #CC0000`,
              },
              textTransform: "none",
            },
          },

          //MJ_BUTTON_PRIMARY
          {
            props: { variant: "mj_red_md" },
            style: {
              padding: "6px 12px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              backgroundColor: "#CC0000",
              borderRadius: "6px",
              color: "white",
              border: `2px solid #CC0000`,
              "&:hover": {
                backgroundColor: "#CC0000",
                filter: "brightness(0.7)",
                color: "white",
                border: `2px solid #CC0000}`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_green_md" },
            style: {
              padding: "6px 12px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              backgroundColor: "#49E59F",
              borderRadius: "6px",
              color: "white",
              border: `2px solid #49E59F`,
              "&:hover": {
                backgroundColor: "#49E59F",
                filter: "brightness(0.7)",
                color: "white",
                border: `2px solid #49E59F`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_blue_md" },
            style: {
              padding: "6px 12px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              backgroundColor: "#003E9C",
              borderRadius: "6px",
              color: "white",
              border: `2px solid #003E9C`,
              "&:hover": {
                backgroundColor: "#003E9C",
                filter: "brightness(0.7)",
                color: "white",
                border: `2px solid #003E9C`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_primary_md" },
            style: {
              padding: "6px 12px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              backgroundColor: colors.main,
              borderRadius: "6px",
              color: "white",
              border: `2px solid ${colors.main}`,
              "&:hover": {
                backgroundColor: colors.main,
                filter: "brightness(0.7)",
                color: "white",
                border: `2px solid ${colors.main}`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_primary_choice" },
            style: {
              padding: "10px 25px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19.2px",
              backgroundColor: "#FAFAFB",
              borderRadius: "6px",
              color: "black",
              border: `0`,
              "&:hover": {
                backgroundColor: "#FAFAFB",
                color: "black",
                border: `0`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_primary_choice_lg" },
            style: {
              padding: "16px 25px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19.2px",
              backgroundColor: "#FAFAFB",
              borderRadius: "6px",
              color: "black",
              border: `0`,
              "&:hover": {
                backgroundColor: "#FAFAFB",
                color: "black",
                border: `0`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_primary_choice_active" },
            style: {
              padding: "10px 25px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19.2px",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.12)",
              color: "black",
              border: "1px solid #DEDFE4",
              border: `0`,
              "&:hover": {
                backgroundColor: "#FAFAFB",
                border: "1px solid #DEDFE4",
                color: "black",
                border: `0`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_green_md" },
            style: {
              padding: "6px 12px",
              transition: "ease all 0.5s",
              fontSize: "14px",
              backgroundColor: "#EDFCF5",
              borderRadius: "6px",
              color: "#2D8C61",
              border: `2px solid #EDFCF5`,
              "&:hover": {
                backgroundColor: "#EDFCF5",
                filter: "brightness(0.7)",
                color: "#2D8C61",
                border: `2px solid #EDFCF5`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_green_sm" },
            style: {
              padding: "6px 8px",
              transition: "ease all 0.5s",
              fontSize: "14px",
              backgroundColor: "#EDFCF5",
              borderRadius: "6px",
              color: "#2D8C61",
              border: `2px solid #EDFCF5`,
              "&:hover": {
                backgroundColor: "#EDFCF5",
                filter: "brightness(0.7)",
                color: "#2D8C61",
                border: `2px solid #EDFCF5`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_primary_md" },
            style: {
              padding: "6px 12px",
              transition: "ease all 0.5s",
              fontSize: "14px",
              backgroundColor: colors.main,
              borderRadius: "6px",
              color: "white",
              border: `2px solid ${colors.main}`,
              "&:hover": {
                backgroundColor: colors.main,
                filter: "brightness(0.7)",
                color: "white",
                border: `2px solid ${colors.main}`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_primary_choice_active_lg" },
            style: {
              padding: "16px 25px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19.2px",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.12)",
              color: "black",
              border: "2px solid #DEDFE4",
              border: `0`,
              "&:hover": {
                backgroundColor: "#FAFAFB",
                border: "1px solid #DEDFE4",
                color: "black",
                border: `0`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_danger_sm" },
            style: {
              padding: "6px 8px",
              transition: "ease all 0.5s",
              fontSize: "12px",
              backgroundColor: "#CC0000",
              borderRadius: "6px",
              color: "white",
              border: `2px solid #CC0000`,
              "&:hover": {
                backgroundColor: "#CC0000",
                filter: "brightness(0.7)",
                color: "white",
                border: `2px solid #CC0000`,
              },
              textTransform: "none",
            },
          },
          //MJ_BUTTON_PRIMARY SMALL
          {
            props: { variant: "mj_danger_md" },
            style: {
              padding: "6px 12px",
              transition: "ease all 0.5s",
              backgroundColor: "#CC0000",
              borderRadius: "6px",
              fontSize: "14px",
              color: "white",
              border: `2px solid ${"#CC0000"}`,
              "&:hover": {
                backgroundColor: "#CC0000",
                color: "white",
                border: `2px solid ${"#CC0000"}`,
              },
              textTransform: "none",
            },
          },
          //MJ_BUTTON_SECONDARY
          {
            props: { variant: "mj_secondary_sm" },
            style: {
              padding: "6px 8px",
              transition: "ease all 0.5s",
              backgroundColor: "white",
              borderRadius: "6px",
              color: "#000B30",
              border: `1px solid #818592`,

              "&:hover": {
                backgroundColor: "white",
                filter: "brightness(0.8)",
                color: "#000B30",
                border: `1px solid #818592`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_secondary_drop" },
            style: {
              padding: "6px 8px",
              transition: "ease all 0.5s",
              backgroundColor: "white",
              borderRadius: "6px",
              color: "#000B30",
              border: `1px solid #818592`,

              "&:hover": {
                backgroundColor: "white",
                color: "#000B30",
                border: `1px solid #818592`,
              },
              textTransform: "none",
            },
          },
          {
            props: { variant: "mj_secondary_md" },
            style: {
              padding: "6px 12px",
              transition: "ease all 0.5s",
              backgroundColor: "white",
              borderRadius: "6px",
              fontSize: "14px",
              color: "#000B30",
              border: `1px solid #818592`,

              "&:hover": {
                backgroundColor: "white",
                filter: "brightness(0.8)",
                color: "#000B30",
                border: `1px solid #818592`,
              },
              textTransform: "none",
            },
          },
          //MJ_BUTTON_LINK
          {
            props: { variant: "mj_link" },
            style: {
              fontWeight: "500",
              backgroundColor: "white",

              color: colors.main,
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "white",
                color: colors.main,
              },
            },
          },
          //MJ BUTTON CONFIRMED STATE
          {
            props: { variant: "confirmed" },
            style: {
              backgroundColor: `${colors.main}`,
              color: "white",
              textTransform: "none",
              height: "1.6rem",
              fontSize: "0.875rem",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: `${colors.main}`,
                color: "white",
              },
            },
          },
          //MJ BUTTON PRE-SELECTED STATE
          {
            props: { variant: "pre-selected" },
            style: {
              padding: "7px",
              backgroundColor: "#d3c400",
              color: "white",
              fontSize: "0.875rem",
              height: "1.6rem",
              textTransform: "none",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#d3c400",
                color: "white",
              },
            },
          },
          //MJ BUTTON PENDING STATE
          {
            props: { variant: "pending" },
            style: {
              backgroundColor: "white",
              minWidth: "120px",
              fontSize: "0.875rem",
              padding: "0px",
              color: `${colors.gray}`,
              textTransform: "none",
              height: "1.6rem",
              border: `2px solid ${colors.gray}`,
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "white",
                color: `${colors.gray}`,
              },
            },
          },
          //MJ BUTTON ACCEPTED STATE
          {
            props: { variant: "accepted" },
            style: {
              backgroundColor: "#4e9200",
              color: "white",
              fontSize: "0.875rem",
              textTransform: "none",
              height: "1.6rem",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#4e9200",
                color: "white",
              },
            },
          },
          //SIGNED STate
          {
            props: { variant: "signed" },
            style: {
              backgroundColor: "white",
              color: "green",
              fontSize: "0.875rem",
              textTransform: "none",
              height: "1.6rem",
              borderRadius: "20px",
              border: "2px solid green",
              "&:hover": {
                backgroundColor: "white",
                color: "green",
              },
            },
          },
          //MJ BUTTON REFUSED STATE
          {
            props: { variant: "refused" },
            style: {
              backgroundColor: "#7c7c7c",
              color: "white",
              textTransform: "none",
              fontSize: "0.875rem",
              height: "1.6rem",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#7c7c7c",
                color: "white",
              },
            },
          },
          //MJ BUTTON CANCELED STATE
          {
            props: { variant: "canceled" },
            style: {
              backgroundColor: "lightred",
              color: "white",
              textTransform: "none",
              fontSize: "0.875rem",
              height: "1.6rem",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "lightred",
                color: "white",
              },
            },
          },
          //MJ BUTTON MISSION STATE
          {
            props: { variant: "mission" },
            style: {
              fontSize: "16px",
              backgroundColor: "darkred",
              color: "white",
              textTransform: "none",
              height: "1.6rem",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "darkred",
                color: "white",
              },
            },
          },
          //MJ PRIMARY BUTTON REFACTORED
          {
            props: { variant: "mj_primary_refact" },
            style: {
              backgroundColor: `${colors.main}`,
              color: "white",
              textTransform: "none",
              borderRadius: "6px",
              border: `2px solid ${colors.main}`,
              "&:hover": {
                backgroundColor: `${colors.main}`,
                color: "white",
              },
            },
          },
          //MJ BUTTON SECONDARY OUTLINE
          {
            props: { variant: "mj_secondary_outlined" },
            style: {
              padding: "5px 12px",
              transition: "ease all 0.5s",
              backgroundColor: "white",
              borderRadius: "8px",
              color: colors.mj_red,
              fontSize: "1rem",
              border: `2px solid ${colors.mj_red}`,
              textTransform: "none",
            },
          },
          //MJ BUTTON ORANGE (LOGIN/MASQUER OFFRE)
          {
            props: { variant: "mj_orange" },
            style: {
              padding: "5px 12px",
              transition: "ease all 0.5s",
              backgroundColor: colors.main_mj,
              borderRadius: "8px",
              color: "white",
              fontSize: "1rem",
              border: `2px solid ${colors.main_mj}`,

              "&:hover": {
                backgroundColor: colors.light_mj,
                color: "white",
              },
              textTransform: "none",
            },
          },
        ],
      },
    },
  },
  frFR
);
