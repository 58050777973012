import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { modalStyle } from "../../../theme/ModalStyle";
import { colors } from "../../../theme/Colors";
import { Box, Button, Typography, Modal, TextField, InputAdornment, Autocomplete, Divider, Grid } from "@mui/material";
import BoxContainerModal from "../../../components/ui/BoxContainerModal";
import CircularProgress from "@mui/material/CircularProgress";
import jobIcon from "../../../imgs/imgV2/spec.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import RightArrowTimeOrange from "../../../imgs/imgV2/RightArrowTimeOrange.svg";
import calendarIcon from "../../../imgs/imgV2/calendarIcon.svg";
import XIconTime from "../../../imgs/imgV2/XIconTime.svg";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import locationIcon from "../../../imgs/imgV2/LocationIcon.svg";
import BoxContainer from "../../../components/ui/BoxContainer";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import MyChip from "../../../components/ui/MyChip";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { Formik, Field, Form, ErrorMessage } from "formik";
import styled from "@emotion/styled";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import AddIcon from "../../../imgs/imgV2/plusIcon.svg";
import TimeInput from "../../../components/ui/TimeInput";
import MySwitch from "../../../components/ui/MySwitch/MySwitch";
import RightArrowTime from "../../../imgs/imgV2/RightArrowTime.svg";
import MapComponent from "../../../components/mapComponent/MapComponent";

const BlackContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "#000B30", padding: "24px", borderRadius: "6px" }}>
      {children}
    </Box>
  );
};

const GrayContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "#F5F5F7", padding: "4px 4px 1px 4px", borderRadius: "6px" }}>
      {children}
    </Box>
  );
};

const ItemContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "white", border: "1px solid #DEDFE4", padding: "9px 16px", borderRadius: "6px", marginBottom: "4px", boxShadow: "0px 2px 3px rgba(0,0,0,0.06)" }}>
      {children}
    </Box>
  );
};

const ItemContainerAgenda = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "white", border: "1px solid #DEDFE4", padding: "18px 16px", borderRadius: "6px", marginBottom: "4px", boxShadow: "0px 2px 3px rgba(0,0,0,0.06)" }}>
      {children}
    </Box>
  );
};

const MyTextField = styled(TextField)({
  width: "100%",
});

function OfferDetailsContent({ id }) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  const [detailOffre, setDetailOffre] = useState({});
  const [agenda, setAgenda] = useState([]);
  const [panier, setPanier] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openInformations, setOpenInformations] = useState(false);
  const [openTaches, setOpenTaches] = useState(false);
  const [openTaux, setOpenTaux] = useState(false);
  const [openEquipementFournis, setOpenEquipementFournis] = useState(false);
  const [openEquipementAFournir, setOpenEquipementAFournir] = useState(false);
  const [openAgenda, setOpenAgenda] = useState(false);
  const [openPanier, setOpenPanier] = useState(false);

  const [openAddPanier, setOpenAddPanier] = useState(false);
  const [errorPanier, setErrorPanier] = useState("");

  const [didSubmitInformations, setDidSubmitInformations] = useState(false);
  const [didSubmitTaux, setDidSubmitTaux] = useState(false);
  const [didSubmitPanier, setDidSubmitPanier] = useState(false);
  const [didSubmitEquipementsFournis, setDidSubmitEquipementsFournis] = useState(false);
  const [didSubmitTaches, setDidSubmitTaches] = useState(false);

  const [selectedMetier, setSelectedMetier] = useState(detailOffre?.metier);
  const [searchedMetier, setSearchedMetier] = useState();
  const [metiers, setMetiers] = useState([]);
  const [skills, setSkills] = useState([]);
  const [equipementsFournis, setEquipementsFournis] = useState([]);
  const [equipementsAFournir, setEquipementsAFournir] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchedSkills, setSearchedSkills] = useState();
  const [searchedEquipementsFournis, setSearchedEquipementsFournis] = useState();
  const [searchedEquipementsAFournir, setSearchedEquipementsAFournir] = useState();
  const [skillsToSubmit, setSkillsToSubmit] = useState([]);
  const [equipementsFournisToSubmit, setEquipementsFournisToSubmit] = useState([]);
  const [equipementsAFournirToSubmit, setEquipementsAFournirToSubmit] = useState([]);

  const [openModifyPanierItem, setOpenModifyPanierItem] = useState(false);
  const [panierItemToModify, setPanierItemToModify] = useState(null);

  const initialValuesInformations = {
    intitulePoste: detailOffre?.job_title ? detailOffre?.job_title : "",
    metier: detailOffre?.metier ? detailOffre?.metier : "",
    experienceSouhaite: detailOffre?.experience_years_wanted ? detailOffre?.experience_years_wanted : "",
  };

  const validationSChemaInformations = Yup.object({
    intitulePoste: Yup.string().required("Ce champs est obligatoire"),
    metier: Yup.object()
      .shape({
        id: Yup.string().required("Ce champs est obligatoire"),
      })
      .required("Ce champ est obligatoire")
      .nullable(),
    experienceSouhaite: Yup.string().required("Ce champs est obligatoire"),
  });

  const initialValuesTaux = {
    tauxDeBase: detailOffre?.basic_hourly_rate ? detailOffre?.basic_hourly_rate : "",
    tauxIntemperie: detailOffre?.severe_weather_hourly_rate ? detailOffre?.severe_weather_hourly_rate : "",
    tauxNuit: detailOffre?.night_hourly_rate ? detailOffre?.night_hourly_rate : "",
    tauxWeekend: detailOffre?.weekend_hourly_rate ? detailOffre?.weekend_hourly_rate : "",
  };

  const validationSChemaTaux = Yup.object({
    tauxDeBase: Yup.string().required("Ce champs est obligatoire"),
    tauxIntemperie: Yup.string().required("Ce champs est obligatoire"),
    tauxNuit: Yup.string().required("Ce champs est obligatoire"),
    tauxWeekend: Yup.string().required("Ce champs est obligatoire"),
  });

  const initialValuesPanier = {
    panier: detailOffre?.baskets ? detailOffre?.baskets : "",
  };

  const initialValuesBasket = {
    id: "",
    name: "",
    value: "",
  };

  const basketSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required("Ce champs est obligatoire"),
    value: Yup.number("Ce champs doit être numérique").required("Ce champs est obligatoire"),
  });

  const validationSchemaPanier = Yup.object().shape({
    panier: Yup.array()
      .of(basketSchema)
      .test("unique-names", "Le nom du panier doit être unique", function (value) {
        const basketNames = value.map((basket) => basket.name.toLowerCase());
        const uniqueBasketNames = new Set(basketNames);

        return basketNames.length === uniqueBasketNames.size;
      })
      .required("Panier is required"),
  });

  //deletepanier item
  const deletePanier = (form, panier) => {
    console.log("FORM DELETE PANIER", form);
    console.log("PANIER DELETE PANIER NAME", panier.name);

    form?.setFieldValue(
      "panier",
      form?.values?.panier?.filter((pform) => pform.name !== panier.name)
    );
  };

  const updateObjectById = (id, newObject, array) => {
    const updatedArray = array?.map((obj) => {
      if (obj.id === id) {
        return newObject;
      }
      return obj;
    });

    return updatedArray;
  };

  //deletepanier item
  const modifyPanierItem = (target, name, value) => {
    console.log("************ target is:", target);

    const newPanier = formPanier?.current?.values?.panier.map((obj) => {
      if (obj.name === target.name) {
        return { id: "", name, value };
      }
      return obj;
    });

    formPanier?.current.setFieldValue("panier", newPanier);

    // form?.setFieldValue("panier", updateObjectById(panier.name, { id: "", name: name, value: value }, form?.values?.panier));
  };

  const formPanier = useRef();
  // console.log("formREF PANIER :::::::::::::::", formPanier);

  const handleSubmitAddPanierItem = (values) => {
    if (formPanier) {
      formPanier.current.values.panier.push({ id: "", name: values.name, value: values.value });
      setOpenAddPanier(false);
    }
  };

  const initialValuesTaches = {
    tasksToDo: detailOffre?.tasks_to_do ? detailOffre?.tasks_to_do : "",
    skills: detailOffre?.skills ? detailOffre?.skills : [],
  };

  const validationSChemaTaches = Yup.object({
    tasksToDo: Yup.string().required("Ce champs est obligatoire"),
    skills: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required("ID is required"),
        name: Yup.string().required("Name is required"),
      })
    ),
  });

  const initialValuesEquipementsFournis = {
    equipementsFournis: detailOffre?.equipment_provided ? detailOffre?.equipment_provided : [],
  };

  const validationSChemaEquipementsFournis = Yup.object({
    equipementsFournis: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required("ID is required"),
        name: Yup.string().required("Name is required"),
      })
    ),
  });

  const initialValuesEquipementsAFournir = {
    equipementsAFournir: detailOffre?.equipment_to_be_provided ? detailOffre?.equipment_to_be_provided : [],
  };

  const validationSChemaEquipementsAFournir = Yup.object({
    equipementsAFournir: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required("ID is required"),
        name: Yup.string().required("Name is required"),
      })
    ),
  });

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const formAgenda = useRef();

  const translationDay = {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
  };

  //switchs
  const [checkedLundi, setCheckedLundi] = useState(false);
  const [hasShiftLundi, setHasShiftLundi] = useState(false);

  const [checkedMardi, setCheckedMardi] = useState(false);
  const [hasShiftMardi, setHasShiftMardi] = useState(false);

  const [checkedMercredi, setCheckedMercredi] = useState(false);
  const [hasShiftMercredi, setHasShiftMercredi] = useState(false);

  const [checkedJeudi, setCheckedJeudi] = useState(false);
  const [hasShiftJeudi, setHasShiftJeudi] = useState(false);

  const [checkedVendredi, setCheckedVendredi] = useState(false);
  const [hasShiftVendredi, setHasShiftVendredi] = useState(false);

  const [checkedSamedi, setCheckedSamedi] = useState(false);
  const [hasShiftSamedi, setHasShiftSamedi] = useState(false);

  const [checkedDimanche, setCheckedDimanche] = useState(false);
  const [hasShiftDimanche, setHasShiftDimanche] = useState(false);

  const initialValuesAgenda = {
    // weeklyPlanner: detailOffre?.weekly_planner ? detailOffre?.weekly_planner : [],

    start_time_lundi: "",
    end_time_lundi: "",
    start_time_lundi_2: "",
    end_time_lundi_2: "",

    start_time_mardi: "",
    end_time_mardi: "",
    start_time_mardi_2: "",
    end_time_mardi_2: "",

    start_time_mercredi: "",
    end_time_mercredi: "",
    start_time_mercredi_2: "",
    end_time_mercredi_2: "",

    start_time_jeudi: "",
    end_time_jeudi: "",
    start_time_jeudi_2: "",
    end_time_jeudi_2: "",

    start_time_vendredi: "",
    end_time_vendredi: "",
    start_time_vendredi_2: "",
    end_time_vendredi_2: "",

    start_time_samedi: "",
    end_time_samedi: "",
    start_time_samedi_2: "",
    end_time_samedi_2: "",

    start_time_dimanche: "",
    end_time_dimanche: "",
    start_time_dimanche_2: "",
    end_time_dimanche_2: "",

    //ensure time stays in the form when pressing next/previous
    is_samedi: checkedSamedi,
    is_dimanche: checkedDimanche,
    is_lundi: checkedLundi,
    is_mardi: checkedMardi,
    is_mercredi: checkedMercredi,
    is_jeudi: checkedJeudi,
    is_vendredi: checkedVendredi,
  };

  // validation method for start_time end_time
  const isStartTimeBeforeEndTime = (start, end) => {
    // skip validation if fields are empty
    if (!start || !end) return true;

    const startTime = start.split(":");
    const endTime = end.split(":");

    const startHour = parseInt(startTime[0]);
    const startMinute = parseInt(startTime[1]);

    const endHour = parseInt(endTime[0]);
    const endMinute = parseInt(endTime[1]);

    if (startHour < endHour) return true;
    if (startHour === endHour && startMinute < endMinute) return true;

    return false;
  };

  const validationSchemaAgenda = Yup.object({
    // checked
    is_samedi: Yup.boolean(),
    is_dimanche: Yup.boolean(),
    is_lundi: Yup.boolean(),
    is_mardi: Yup.boolean(),
    is_mercredi: Yup.boolean(),
    is_jeudi: Yup.boolean(),
    is_vendredi: Yup.boolean(),

    //validate heure début heure de fin matiné
    start_time_samedi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_samedi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_samedi);
    }),

    start_time_dimanche: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_dimanche } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_dimanche);
    }),

    start_time_lundi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_lundi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_lundi);
    }),

    start_time_mardi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mardi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mardi);
    }),

    start_time_mercredi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mercredi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mercredi);
    }),

    start_time_jeudi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_jeudi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_jeudi);
    }),

    start_time_vendredi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_vendredi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_vendredi);
    }),

    //validate heure début heure de fin soir

    start_time_samedi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_samedi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_samedi_2);
    }),
    end_time_samedi_2: Yup.string(),

    start_time_dimanche_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_dimanche_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_dimanche_2);
    }),
    end_time_dimanche_2: Yup.string(),

    start_time_lundi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_lundi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_lundi_2);
    }),
    end_time_lundi_2: Yup.string(),

    start_time_mardi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mardi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mardi_2);
    }),
    end_time_mardi_2: Yup.string(),

    start_time_mercredi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mercredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mercredi_2);
    }),
    end_time_mercredi_2: Yup.string(),

    start_time_jeudi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_jeudi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_jeudi_2);
    }),
    end_time_jeudi_2: Yup.string(),

    start_time_vendredi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_vendredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_vendredi_2);
    }),
    end_time_vendredi_2: Yup.string(),

    //validate in between
    end_time_samedi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_samedi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_samedi_2);
    }),
    end_time_dimanche: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_dimanche_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_dimanche_2);
    }),
    end_time_lundi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_lundi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_lundi_2);
    }),
    end_time_mardi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_mardi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_mardi_2);
    }),
    end_time_mercredi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_mercredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_mercredi_2);
    }),
    end_time_jeudi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_jeudi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_jeudi_2);
    }),
    end_time_vendredi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_vendredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_vendredi_2);
    }),
  });

  const checked_hasShift_helper = (wp) => {
    let countSamedi = 0;
    let countDimanche = 0;
    let countLundi = 0;
    let countMardi = 0;
    let countMercredi = 0;
    let countJeudi = 0;
    let countVendredi = 0;

    console.log("agenda rearranged", agenda);

    wp.map((item, index) => {
      console.log("FORMAGENDA", formAgenda?.current);
      console.log("LOOOOOPING");

      if (item.day_name === "saturday") {
        setCheckedSamedi(true);
        countSamedi++;

        if (countSamedi === 1) {
          formAgenda.current.initialValues.is_samedi = true;
          formAgenda.current.initialValues.start_time_samedi = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_samedi = item.end_time.slice(0, -3);
        }
        if (countSamedi === 2) {
          setHasShiftSamedi(true);
          formAgenda.current.initialValues.start_time_samedi_2 = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_samedi_2 = item.end_time.slice(0, -3);
        }
      }

      if (item.day_name === "sunday") {
        setCheckedDimanche(true);
        countDimanche++;

        if (countDimanche === 1) {
          formAgenda.current.initialValues.is_dimanche = true;
          formAgenda.current.initialValues.start_time_dimanche = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_dimanche = item.end_time.slice(0, -3);
        }
        if (countDimanche === 2) {
          setHasShiftDimanche(true);
          formAgenda.current.initialValues.start_time_dimanche_2 = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_dimanche_2 = item.end_time.slice(0, -3);
        }
      }

      if (item.day_name === "monday") {
        setCheckedLundi(true);
        countLundi++;

        if (countLundi === 1) {
          formAgenda.current.initialValues.is_lundi = true;
          formAgenda.current.initialValues.start_time_lundi = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_lundi = item.end_time.slice(0, -3);
        }
        if (countLundi === 2) {
          setHasShiftLundi(true);
          formAgenda.current.initialValues.start_time_lundi_2 = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_lundi_2 = item.end_time.slice(0, -3);
        }
      }

      if (item.day_name === "tuesday") {
        setCheckedMardi(true);
        countMardi++;

        if (countMardi === 1) {
          formAgenda.current.initialValues.is_mardi = true;
          formAgenda.current.initialValues.start_time_mardi = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_mardi = item.end_time.slice(0, -3);
        }
        if (countMardi === 2) {
          setHasShiftMardi(true);
          formAgenda.current.initialValues.start_time_mardi_2 = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_mardi_2 = item.end_time.slice(0, -3);
        }
      }

      if (item.day_name === "wednesday") {
        setCheckedMercredi(true);
        countMercredi++;

        if (countMercredi === 1) {
          formAgenda.current.initialValues.is_mercredi = true;
          formAgenda.current.initialValues.start_time_mercredi = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_mercredi = item.end_time.slice(0, -3);
        }
        if (countMercredi === 2) {
          setHasShiftMercredi(true);
          formAgenda.current.initialValues.start_time_mercredi_2 = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_mercredi_2 = item.end_time.slice(0, -3);
        }
      }

      if (item.day_name === "thursday") {
        setCheckedJeudi(true);
        countJeudi++;

        if (countJeudi === 1) {
          formAgenda.current.initialValues.is_jeudi = true;
          formAgenda.current.initialValues.start_time_jeudi = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_jeudi = item.end_time.slice(0, -3);
        }
        if (countJeudi === 2) {
          setHasShiftJeudi(true);
          formAgenda.current.initialValues.start_time_jeudi_2 = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_jeudi_2 = item.end_time.slice(0, -3);
        }
      }

      if (item.day_name === "friday") {
        setCheckedVendredi(true);
        countVendredi++;

        if (countVendredi === 1) {
          formAgenda.current.initialValues.is_vendredi = true;
          formAgenda.current.initialValues.start_time_vendredi = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_vendredi = item.end_time.slice(0, -3);
        }
        if (countVendredi === 2) {
          setHasShiftVendredi(true);
          formAgenda.current.initialValues.start_time_vendredi_2 = item.start_time.slice(0, -3);
          formAgenda.current.initialValues.end_time_vendredi_2 = item.end_time.slice(0, -3);
        }
      }
    });
  };

  //agenda mapper
  const agendaMapper = [
    {
      id: 1,
      name_en: "saturday",
      name: "samedi",
      checked: checkedSamedi,
      setChecked: setCheckedSamedi,
      hasShift: hasShiftSamedi,
      setHasShift: setHasShiftSamedi,
    },
    {
      id: 2,
      name_en: "sunday",
      name: "dimanche",
      checked: checkedDimanche,
      setChecked: setCheckedDimanche,
      hasShift: hasShiftDimanche,
      setHasShift: setHasShiftDimanche,
    },
    {
      id: 3,
      name_en: "monday",
      name: "lundi",
      checked: checkedLundi,
      setChecked: setCheckedLundi,
      hasShift: hasShiftLundi,
      setHasShift: setHasShiftLundi,
    },
    {
      id: 4,
      name_en: "tuesday",
      name: "mardi",
      checked: checkedMardi,
      setChecked: setCheckedMardi,
      hasShift: hasShiftMardi,
      setHasShift: setHasShiftMardi,
    },
    {
      id: 5,
      name_en: "wednesday",
      name: "mercredi",
      checked: checkedMercredi,
      setChecked: setCheckedMercredi,
      hasShift: hasShiftMercredi,
      setHasShift: setHasShiftMercredi,
    },
    {
      id: 6,
      name_en: "thursday",
      name: "jeudi",
      checked: checkedJeudi,
      setChecked: setCheckedJeudi,
      hasShift: hasShiftJeudi,
      setHasShift: setHasShiftJeudi,
    },
    {
      id: 7,
      name_en: "friday",
      name: "vendredi",
      checked: checkedVendredi,
      setChecked: setCheckedVendredi,
      hasShift: hasShiftVendredi,
      setHasShift: setHasShiftVendredi,
    },
  ];

  //planner maker
  const weeklyPlannerMaker = (values) => {
    let weeklyPlanner = [];

    agendaMapper.map((day) => {
      if (values[`start_time_${day.name}`] && values[`end_time_${day.name}`]) {
        weeklyPlanner.push({
          day_name: day.name_en,
          start_time: values[`start_time_${day.name}`],
          end_time: values[`end_time_${day.name}`],
        });
        if (values[`start_time_${day.name}_2`] && values[`end_time_${day.name}_2`]) {
          weeklyPlanner.push({
            day_name: day.name_en,
            start_time: values[`start_time_${day.name}_2`],
            end_time: values[`end_time_${day.name}_2`],
          });
        }
      }
    });

    console.log("my weekly planner before sending is :::::::::::::::::", weeklyPlanner);
    return weeklyPlanner;
  };

  // get detail offer by ID AND PEFECT CANDIDATURES
  const [position, setPosition] = useState([48.864716, 2.349014]);
  const [selectedAdresse, setSelectedAdresse] = useState({});
  const [zoom_level, setZoom_level] = useState(13);
  useEffect(() => {
    Api.get(`${Urls.GET_DETAILS_OFFRE}${id}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res, "data from el gharbi");
        setDetailOffre(res?.data?.offer);
        setPosition([res?.data?.offer?.location_latitude, res?.data?.offer?.location_longitude]);
        setSelectedAdresse({
          id: "",
          display_name: res?.data?.offer?.location_address,
        });
        setSkillsToSubmit(res?.data?.offer?.skills);
        setEquipementsFournisToSubmit(res?.data?.offer?.equipment_provided);
        setEquipementsAFournirToSubmit(res?.data?.offer?.equipment_to_be_provided);
        setPanier(res?.data?.offer?.baskets);
        setAgenda(arangeCalendar(res?.data?.offer?.weekly_planner));
        checked_hasShift_helper(res?.data?.offer?.weekly_planner);
        setLoading(false);
        console.log(arangeCalendar(res?.data?.offer?.weekly_planner), "agenda");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [openInformations, openTaux, openPanier, openTaches, openEquipementFournis, openEquipementAFournir, openAgenda]);

  //arange dates
  const arangeCalendar = (calendarOBJ) => {
    let days = [
      { id: 1, day_name: "monday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 2, day_name: "tuesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 3, day_name: "wednesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 4, day_name: "thursday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 5, day_name: "friday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 6, day_name: "saturday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 7, day_name: "sunday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
    ];
    let daysToUse = [
      { id: 1, day_name: "monday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 2, day_name: "tuesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 3, day_name: "wednesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 4, day_name: "thursday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 5, day_name: "friday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 6, day_name: "saturday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 7, day_name: "sunday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
    ];
    for (let index = 0; index < days.length; index++) {
      for (let i = 0; i < calendarOBJ.length; i++) {
        if (calendarOBJ[i]?.day_name === days[index]?.day_name && calendarOBJ[i]?.day_name === calendarOBJ[i + 1]?.day_name) {
          daysToUse[index] = { id: calendarOBJ[i]?.id, day_name: days[index]?.day_name, start_time1: calendarOBJ[i]?.start_time, end_time1: calendarOBJ[i]?.end_time, start_time2: calendarOBJ[i + 1]?.start_time, end_time2: calendarOBJ[i + 1]?.end_time };
          i++;
          index++;
        }
        if (calendarOBJ[i]?.day_name === days[index]?.day_name && calendarOBJ[i]?.day_name !== calendarOBJ[i + 1]?.day_name) {
          daysToUse[index] = { id: calendarOBJ[i]?.id, day_name: days[index]?.day_name, start_time1: calendarOBJ[i]?.start_time, end_time1: calendarOBJ[i]?.end_time, start_time2: "", end_time2: "" };
        }
      }
    }
    return daysToUse;
  };

  //get Metiers SEARCH
  useEffect(() => {
    console.log("GETTIN METIERS SEARCH ///////////:::::::::::");
    if (searchedMetier) {
      console.log("GETTIN METIERS PAR ID ///////////:::::::::::");
      console.log("searched metier ///////////////", searchedMetier);
      Api.get(Urls.GET_METIERS_SEARCH + `?search=${searchedMetier}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          console.log("RESPONSE SEARCH METIER ::::::::::::::", response);
          setMetiers(response?.data?.metiers);
        }
      });
    }
  }, [searchedMetier]);

  //Update offer informations
  const onSubmitInformation = (values) => {
    Api.patch(
      Urls.UPDATE_OFFRE + `/${id}`,
      {
        job_title: values.intitulePoste,
        metier_id: values.metier.id,
        experience_years_wanted: values.experienceSouhaite,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 200) {
          console.log("RESPONES GHERBI", response);
          toast.success(response?.data?.message);
          setOpenInformations(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  //Update offer taux
  const onSubmitTaux = (values) => {
    Api.patch(
      Urls.UPDATE_OFFRE + `/${id}`,
      {
        basic_hourly_rate: values.tauxDeBase,
        severe_weather_hourly_rate: values.tauxIntemperie,
        weekend_hourly_rate: values.tauxWeekend,
        night_hourly_rate: values.tauxNuit,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 200) {
          console.log("RESPONES GHERBI", response);
          toast.success(response?.data?.message);
          setOpenTaux(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  //Update baskets
  const onSubmitBasket = (values) => {
    Api.patch(
      Urls.UPDATE_OFFRE + `/${id}`,
      {
        baskets: values.panier,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 200) {
          console.log("RESPONES GHERBI", response);
          toast.success(response?.data?.message);
          setOpenPanier(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  //Update taches
  const onSubmitTaches = (values) => {
    Api.patch(
      Urls.UPDATE_OFFRE + `/${id}`,
      {
        tasks_to_do: values.tasksToDo,
        skill_ids: values.skills.map((obj) => obj.id),
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 200) {
          console.log("RESPONES GHERBI", response);
          toast.success(response?.data?.message);
          setOpenTaches(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  //Update equipements fournis
  const onSubmitEquipementsFournis = (values) => {
    Api.patch(
      Urls.UPDATE_OFFRE + `/${id}`,
      {
        equipment_provided_ids: values.equipementsFournis.map((obj) => obj.id),
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 200) {
          console.log("RESPONES GHERBI", response);
          toast.success(response?.data?.message);
          setOpenEquipementFournis(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  //Update equipements à fournir
  const onSubmitEquipementsAFournir = (values) => {
    Api.patch(
      Urls.UPDATE_OFFRE + `/${id}`,
      {
        equipment_to_be_provided_ids: values.equipementsAFournir.map((obj) => obj.id),
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 200) {
          console.log("RESPONES GHERBI", response);
          toast.success(response?.data?.message);
          setOpenEquipementAFournir(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  //get skills
  useEffect(() => {
    console.log("GETTIN compétences SEARCH ///////////:::::::::::");
    if (searchedSkills) {
      console.log("search skill ///////////////", searchedSkills);
      Api.get(Urls.GET_SKILLS + `?search=${searchedSkills}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          console.log("RESPONSE SEARCH Compétences ::::::::::::::", response);
          setSkills(response?.data?.skills);
        }
      });
    }
  }, [searchedSkills]);

  //get equipments fournis
  useEffect(() => {
    console.log("GETTIN equipments SEARCH ///////////:::::::::::");
    if (searchedEquipementsFournis) {
      console.log("search EquipmentsToProvide ///////////////", searchedEquipementsFournis);
      Api.get(Urls.GET_EQUIPMENTS_SEARCH + `?search=${searchedEquipementsFournis}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          console.log("RESPONSE SEARCH Equipments To Provide ::::::::::::::", response);
          setEquipementsFournis(response?.data?.equipment);
        }
      });
    }
  }, [searchedEquipementsFournis]);

  //get equipements à fournir
  useEffect(() => {
    console.log("GETTIN compétences SEARCH ///////////:::::::::::");
    if (searchedEquipementsAFournir) {
      console.log("search skill ///////////////", searchedEquipementsAFournir);
      Api.get(Urls.GET_EQUIPMENTS_SEARCH + `?search=${searchedEquipementsAFournir}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          console.log("RESPONSE SEARCH Compétences ::::::::::::::", response);
          setEquipementsAFournir(response?.data?.equipment);
        }
      });
    }
  }, [searchedEquipementsAFournir]);

  //onsubmit agenda
  //Update taches
  const onSubmitAgenda = (values) => {
    Api.patch(
      Urls.UPDATE_OFFRE + `/${id}`,
      {
        weekly_planner: weeklyPlannerMaker(values),
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 200) {
          console.log("RESPONES GHERBI", response);
          toast.success(response?.data?.message);
          setOpenAgenda(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  return (
    <Box marginBottom="24px">
      <ToastContainer />
      {!loading && (
        <Box display="flex" gap="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
          <Box display="flex" flexDirection="column" gap="24px" flex={2}>
            <BoxContainer>
              <Box marginBottom="20px" display="flex" justifyContent="space-between">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  📊 Informations
                </Typography>
                <ButtonSquare action="modify" onClick={() => setOpenInformations(true)} />
              </Box>

              <Box display="flex" flexDirection="column" gap="10px">
                <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                  {detailOffre?.job_title}
                </Typography>
                <Box display="flex" flexDirection="row" gap="8px" alignItems="start">
                  <img src={jobIcon} style={{ marginTop: "3px" }} alt="job-icon" />
                  <Box>
                    <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                      {detailOffre?.metier?.name}
                    </Typography>
                    <Typography fontSize="12px" fontWeight="400" lineHeight="160%" color="#4A526C">
                      {detailOffre?.experience_years_wanted} ans d’expérience
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" gap="8px" alignItems="start">
                  <img src={calendarIcon} style={{ marginTop: "3px" }} alt="job-icon" />
                  <Box display="flex" gap="8px">
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        {moment(detailOffre?.start_date).format("DD MMMM YYYY")}
                      </Typography>
                    </Box>
                    <Box>
                      <img src={arrowRightIcon} alt="icon-point" />
                    </Box>
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        {moment(detailOffre?.end_date).format("DD MMMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" gap="8px" alignItems="start">
                  <img src={locationIcon} style={{ marginTop: "3px" }} alt="job-icon" />
                  <Box display="flex" gap="8px">
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        {detailOffre?.location_address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </BoxContainer>
            {/* Taux horaire */}
            <BoxContainer>
              <Box marginBottom="20px" display="flex" justifyContent="space-between">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  💸 Taux horaire
                </Typography>
                <ButtonSquare action="modify" onClick={() => setOpenTaux(true)} />
              </Box>
              <Box display="flex" flexDirection="column" gap="10px">
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="text_normal" color="#4A526C">
                    Brut
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600">
                    {detailOffre?.basic_hourly_rate}€
                  </Typography>
                </Box>
                <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="text_normal" color="#4A526C">
                    Weekend
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600">
                    {detailOffre?.weekend_hourly_rate}€
                  </Typography>
                </Box>
                <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="text_normal" color="#4A526C">
                    Nuit
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600">
                    {detailOffre?.night_hourly_rate}€
                  </Typography>
                </Box>
                <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="text_normal" color="#4A526C">
                    Heures intempéries
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600">
                    {detailOffre?.severe_weather_hourly_rate}€
                  </Typography>
                </Box>
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="20px" display="flex" justifyContent="space-between">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  🛒 Panier par jour
                </Typography>
                <ButtonSquare action="modify" onClick={() => setOpenPanier(true)} />
              </Box>
              <Box display="flex" flexDirection="column" gap="10px">
                {panier?.map((itemPanier, index) => (
                  <Box key={index}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography variant="text_normal" color="#4A526C">
                        {itemPanier.name}
                      </Typography>
                      <Typography variant="text_normal" fontWeight="600">
                        {itemPanier.value}€
                      </Typography>
                    </Box>
                    {index !== panier?.length - 1 && <Divider sx={{ backgroundColor: "#EBEBEE" }} />}
                  </Box>
                ))}
              </Box>
            </BoxContainer>
            <Box display="flex" flexDirection="column" gap="2px">
              <BlackContainer>
                <Box sx={{ marginBottom: "20px" }} display="flex" justifyContent="space-between">
                  <Typography variant="text_normal" fontSize="20px" fontWeight="700" color="white" lineHeight="32px">
                    🗓️ Agenda
                  </Typography>
                  <ButtonSquare action="modify" onClick={() => setOpenAgenda(true)} />
                </Box>

                <Box display="flex" flexDirection="column" justifyContent="space-between" gap="16px">
                  {agenda.map((day, index) => (
                    <>
                      <Box key={day.index} display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography fontWeight="400" sx={{ fontSize: { xs: "12px", md: "12px", sm: "12px", lg: "14px" }, width: { lg: "80px", xs: "70px", sm: "70px", md: "70px" } }} color={day?.start_time1 || day?.start_time2 ? "white" : "#4A526C"}>
                          {translationDay[`${day?.day_name}`]}
                        </Typography>

                        {day?.start_time1 ? (
                          <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", lg: "row", md: "column" }, width: "100px", alignItems: { xs: "center", lg: "inherit", md: "center", sm: "center" } }} gap="5px">
                            <Typography variant="text_xs" fontWeight="400" color="white">
                              {day?.start_time1.slice(0, -3)}
                            </Typography>
                            <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "inherit" } }}>
                              <img src={RightArrowTimeOrange} width="12.5px" alt="" />
                            </Box>
                            <Typography variant="text_xs" fontWeight="400" color="white">
                              {day?.end_time1.slice(0, -3)}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ width: "100px" }} display="flex" justifyContent="center">
                            <img src={XIconTime} height="20px" alt="" />
                          </Box>
                        )}
                        {day?.start_time2 ? (
                          <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", lg: "row", md: "column" }, width: "100px", alignItems: { xs: "center", lg: "inherit", md: "center", sm: "center" } }} gap="5px">
                            <Typography variant="text_xs" fontWeight="400" color="white">
                              {day?.start_time2?.slice(0, -3)}
                            </Typography>
                            <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "inherit" } }}>
                              <img src={RightArrowTimeOrange} width="12.5px" alt="" />
                            </Box>{" "}
                            <Typography variant="text_xs" fontWeight="400" color="white">
                              {day?.end_time2?.slice(0, -3)}
                            </Typography>
                          </Box>
                        ) : (
                          <Box display="flex" sx={{ width: "100px" }} justifyContent="center">
                            <img src={XIconTime} height="20px" alt="" />
                          </Box>
                        )}
                      </Box>
                      {index !== agenda.length - 1 && <Divider sx={{ backgroundColor: "#1C2647" }} />}
                    </>
                  ))}
                </Box>
              </BlackContainer>
            </Box>{" "}
          </Box>
          <Box display="flex" flexDirection="column" gap="24px" flex={5}>
            <BoxContainer>
              <Box marginBottom="20px" display="flex" justifyContent="space-between">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  ☑️ Tâches à réaliser
                </Typography>
                <ButtonSquare action="modify" onClick={() => setOpenTaches(true)} />
              </Box>
              <Divider sx={{ marginBottom: "20px" }} />
              <Box marginBottom="24px">
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "160%" }}>{detailOffre?.tasks_to_do}</Typography>
              </Box>
              <Box marginBottom="8px">
                <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                  Compétences
                </Typography>
              </Box>
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.skills?.map((skill, index) => (
                  <Box key={index}>
                    <Box sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}>
                      <MyChip variant="skill" label={skill?.name} key={skill?.id} />
                    </Box>
                    <Box sx={{ display: { xs: "inherit", lg: "none", md: "none", sm: "none" } }}>
                      <MyChip variant="skill" label={skill?.name?.slice(0, 30) + "..."} key={skill?.id} />
                    </Box>
                  </Box>
                ))}
                {detailOffre?.skills?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Aucune Compétence requise</Typography>}
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="20px">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  📚 Diplômes requis
                </Typography>
              </Box>

              <Divider sx={{ marginBottom: "20px" }} />
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.school_document_types?.map((cert) => (
                  <MyChip variant="skill" label={cert?.name} key={cert?.id} />
                ))}
                {detailOffre?.school_document_types?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Aucun diplôme requis</Typography>}
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="20px">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  🏆 Certifications / Habilitations obligatoires
                </Typography>
              </Box>
              <Divider sx={{ marginBottom: "20px" }} />
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.certificate_document_types?.map((cert) => (
                  <MyChip variant="skill" label={cert?.name} key={cert?.id} />
                ))}
                {detailOffre?.certificate_document_types?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Aucune Certification requise</Typography>}
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="20px" display="flex" justifyContent="space-between">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  🛠 Équipements fournis
                </Typography>
                <ButtonSquare action="modify" onClick={() => setOpenEquipementFournis(true)} />
              </Box>
              <Divider sx={{ marginBottom: "20px" }} />
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.equipment_provided?.map((eq) => (
                  <MyChip variant="skill" label={eq?.name} key={eq?.id} />
                ))}
                {detailOffre?.equipment_provided?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Pas d'équipements founis</Typography>}
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="20px" display="flex" justifyContent="space-between">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  🧰 Équipements à fournir
                </Typography>
                <ButtonSquare action="modify" onClick={() => setOpenEquipementAFournir(true)} />
              </Box>
              <Divider sx={{ marginBottom: "20px" }} />
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.equipment_to_be_provided?.map((eq) => (
                  <MyChip variant="skill" label={eq?.name} key={eq?.id} />
                ))}
                {detailOffre?.equipment_to_be_provided?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Pas d'équipements founis</Typography>}
              </Box>
            </BoxContainer>
          </Box>

          {/* modal informations */}
          <Modal open={openInformations} onClose={() => setOpenInformations(false)}>
            <Box sx={modalStyle}>
              <BoxContainerModal>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Informations
                  </Typography>
                  <ButtonSquare onClick={() => setOpenInformations(false)} action="close" />
                </Box>
                <Formik initialValues={initialValuesInformations} validationSchema={validationSChemaInformations} onSubmit={onSubmitInformation}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Box>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: { md: "16px" } }}>
                          {/* ============intitulé poste============ */}
                          <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px", width: "100%" }}>
                            <Box sx={{ marginBottom: "6px" }}>
                              <Typography variant="text_normal" fontWeight="600" htmlFor="intitulePoste" name="intitulePoste">
                                Intitulé du poste
                              </Typography>
                              <Typography variant="text_normal" fontWeight="600" htmlFor="intitulePoste" name="intitulePoste" style={{ color: colors.main }}>
                                *
                              </Typography>
                            </Box>
                            <Field name="intitulePoste" type="input" placeholder="Saisir l’intitulé du poste..." id="intitulePoste" as={MyTextField} autoComplete="off" />
                            <ErrorMessage component={FormikErrorText} name="intitulePoste" />
                          </Box>
                          {/* =============== metier ============= */}
                          <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px", width: "100%" }}>
                            <Box sx={{ marginBottom: "6px" }}>
                              <Typography variant="text_normal" fontWeight="600" htmlFor="metier" name="metier">
                                Métier
                              </Typography>
                              <Typography variant="text_normal" fontWeight="600" htmlFor="metier" name="metier" style={{ color: colors.main }}>
                                *
                              </Typography>
                            </Box>
                            <Field
                              name="metier"
                              as={Autocomplete}
                              options={metiers}
                              noOptionsText={"Aucune option"}
                              onChange={(event, value) => {
                                if (value) {
                                  setFieldValue("metier", value);
                                  setSelectedMetier(value);
                                  console.log("selected métier is ::", value);
                                } else {
                                  setSelectedMetier(null);
                                  setFieldValue("metier", null);
                                }
                              }}
                              onInputChange={(event, value) => {
                                setSearchedMetier(event?.target?.value);
                                console.log("searching for :", event?.target?.value);
                                console.log(value);
                                if (event?.target?.value === "") {
                                  setFieldValue("metier", "");
                                }
                              }}
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              renderInput={(params) => (
                                <MyTextField
                                  fullWidth
                                  value={selectedMetier}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img src={RechercheIcon} alt="end" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  placeholder="Rechercher un métier..."
                                />
                              )}
                            />
                            <ErrorMessage component={FormikErrorText} name="metier" />
                          </Box>{" "}
                        </Box>
                        {/* ========experience souhaite========== */}
                        <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                          <Box sx={{ marginBottom: "6px" }}>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="experienceSouhaite" name="experienceSouhaite">
                              Expérience souhaitée
                            </Typography>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="experienceSouhaite" name="experienceSouhaite" style={{ color: colors.main }}>
                              *
                            </Typography>
                          </Box>
                          <Field sx={{ width: "100px" }} name="experienceSouhaite" type="input" placeholder="Saisir l’intitulé du poste..." id="experienceSouhaite" as={TextField} autoComplete="off" />
                          <ErrorMessage component={FormikErrorText} name="experienceSouhaite" />
                        </Box>
                        {/* =========== lieu ============== */}
                        <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                          <Box sx={{ marginBottom: "6px" }}>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="experienceSouhaite" name="experienceSouhaite">
                              Expérience souhaitée
                            </Typography>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="experienceSouhaite" name="experienceSouhaite" style={{ color: colors.main }}>
                              *
                            </Typography>
                          </Box>
                          <TextField disabled value={detailOffre?.location_address}></TextField>
                        </Box>
                        <Box sx={{ overflow: "hidden" }}>
                          <MapComponent is_radius={false} position={position} zoom_level={zoom_level} setZoom_level={setZoom_level} selectedAdresse={selectedAdresse} />
                        </Box>
                        <Box display="flex" flexDirection="row-reverse" gap="10px" paddingTop="40px">
                          <Box>
                            <Button variant="mj_primary_md" type="submit">
                              Enregistrer
                            </Button>
                          </Box>
                          <Box>
                            <Button variant="mj_secondary_md" onClick={() => setOpenInformations(false)}>
                              Annuler
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainerModal>
            </Box>
          </Modal>

          {/* modal taux horaires */}
          <Modal open={openTaux} onClose={() => setOpenTaux(false)}>
            <Box sx={modalStyle}>
              <BoxContainerModal>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Taux horaires
                  </Typography>
                  <ButtonSquare onClick={() => setOpenTaux(false)} action="close" />
                </Box>
                <Formik initialValues={initialValuesTaux} validationSchema={validationSChemaTaux} onSubmit={onSubmitTaux}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      {/* <pre>{JSON.stringify(values)}</pre> */}
                      <Box>
                        <Grid container>
                          {/* ============taux  de base============ */}
                          <Grid item xs={12} sm={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px", width: "100%" }}>
                              <Box sx={{ marginBottom: "6px" }}>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tauxDeBase" name="tauxDeBase">
                                  Brut
                                </Typography>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tauxDeBase" name="tauxDeBase" style={{ color: colors.main }}>
                                  *
                                </Typography>
                              </Box>
                              <Box sx={{ width: "100px" }}>
                                <Field name="tauxDeBase" type="input" placeholder="Saisir l’intitulé du poste..." id="tauxDeBase" as={MyTextField} autoComplete="off" />
                              </Box>
                              <ErrorMessage component={FormikErrorText} name="tauxDeBase" />
                            </Box>
                          </Grid>
                          {/* ============taux weekend poste============ */}
                          <Grid item xs={12} sm={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px", width: "100%" }}>
                              <Box sx={{ marginBottom: "6px" }}>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tauxWeekend" name="tauxWeekend">
                                  Weekend
                                </Typography>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tauxWeekend" name="tauxWeekend" style={{ color: colors.main }}>
                                  *
                                </Typography>
                              </Box>
                              <Box sx={{ width: "100px" }}>
                                <Field name="tauxWeekend" type="input" placeholder="Saisir l’intitulé du poste..." id="tauxWeekend" as={MyTextField} autoComplete="off" />
                              </Box>
                              <ErrorMessage component={FormikErrorText} name="tauxWeekend" />
                            </Box>
                          </Grid>
                          {/* ============taux de nuit============ */}
                          <Grid item xs={12} sm={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px", width: "100%" }}>
                              <Box sx={{ marginBottom: "6px" }}>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tauxNuit" name="tauxNuit">
                                  Nuit
                                </Typography>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tauxNuit" name="tauxNuit" style={{ color: colors.main }}>
                                  *
                                </Typography>
                              </Box>
                              <Box sx={{ width: "100px" }}>
                                <Field name="tauxNuit" type="input" placeholder="Saisir l’intitulé du poste..." id="tauxNuit" as={MyTextField} autoComplete="off" />
                              </Box>
                              <ErrorMessage component={FormikErrorText} name="tauxNuit" />
                            </Box>
                          </Grid>
                          {/* ===========Heures intemperie============ */}
                          <Grid item xs={12} sm={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px", width: "100%" }}>
                              <Box sx={{ marginBottom: "6px" }}>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                                  Intitulé du poste
                                </Typography>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie" style={{ color: colors.main }}>
                                  *
                                </Typography>
                              </Box>
                              <Box sx={{ width: "100px" }}>
                                <Field name="tauxIntemperie" type="input" placeholder="Saisir l’intitulé du poste..." id="tauxIntemperie" as={MyTextField} autoComplete="off" />
                              </Box>
                              <ErrorMessage component={FormikErrorText} name="tauxIntemperie" />
                            </Box>
                          </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row-reverse" gap="10px" paddingTop="40px">
                          <Box>
                            <Button variant="mj_primary_md" type="submit">
                              Enregistrer
                            </Button>
                          </Box>
                          <Box>
                            <Button variant="mj_secondary_md" onClick={() => setOpenInformations(false)}>
                              Annuler
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainerModal>
            </Box>
          </Modal>

          {/* modal panier */}
          <Modal open={openPanier} onClose={() => setOpenPanier(false)}>
            <Box sx={modalStyle}>
              <BoxContainerModal>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Panier par jour
                  </Typography>
                  <ButtonSquare onClick={() => setOpenPanier(false)} action="close" />{" "}
                </Box>

                <Formik innerRef={formPanier} initialValues={initialValuesPanier} validationSchema={validationSchemaPanier} onSubmit={onSubmitBasket}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Box className="panier" sx={{ marginBottom: "32px" }}>
                        <GrayContainer>
                          <Field>
                            {({ form }) =>
                              values?.panier?.map((p, index) => {
                                return (
                                  <ItemContainer key={index} sx={{ margin: "1rem 0.5rem" }} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="text_normal">{p.name}</Typography>

                                    <Box display="flex" gap="16px" alignItems="center">
                                      <Typography variant="text_normal" fontWeight="700">
                                        {p.value} €
                                      </Typography>
                                      <Box>
                                        <ButtonSquare
                                          action="modify"
                                          onClick={() => {
                                            setOpenModifyPanierItem(true);
                                            setPanierItemToModify(p);
                                          }}
                                        />
                                        <ButtonSquare action="delete" onClick={() => deletePanier(form, p)} />
                                      </Box>
                                    </Box>
                                  </ItemContainer>
                                );
                              })
                            }
                          </Field>
                          <Button onClick={() => setOpenAddPanier(true)} fullWidth sx={{ marginBottom: "4px", padding: "14px 14px", color: "#000B30", "&:hover": { backgroundColor: "transparent" }, textTransform: "none" }}>
                            <Box mr>
                              <img src={AddIcon} width="12px" alt="add-icon" />
                            </Box>
                            Ajouter
                          </Button>
                        </GrayContainer>
                        <ErrorMessage name="panier" component={FormikErrorText} />
                      </Box>

                      {/* modify panier item */}
                      <Modal open={openModifyPanierItem} onClose={() => setOpenModifyPanierItem(false)}>
                        <Box sx={modalStyle}>
                          <BoxContainerModal>
                            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                              <Typography variant="text_xxl" fontWeight="700">
                                Ajouter un panier
                              </Typography>
                              <ButtonSquare onClick={() => setOpenModifyPanierItem(false)} action="close" />{" "}
                            </Box>

                            <Formik initialValues={{ id: "", name: panierItemToModify?.name, value: panierItemToModify?.value }} validationSchema={basketSchema}>
                              {({ values, errors }) => (
                                <Form>
                                  <Box>
                                    <Typography>modify panier : {panierItemToModify?.name}</Typography>
                                  </Box>
                                  <pre>{JSON.stringify(values, null, 2)}</pre>
                                  <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                                    <Box sx={{ marginBottom: "6px" }}>
                                      <Typography variant="text_normal" fontWeight="600" htmlFor="name" name="name">
                                        Titre
                                      </Typography>
                                      <Typography variant="text_normal" fontWeight="600" htmlFor="name" name="name" style={{ color: colors.main }}>
                                        *
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Field name="name" type="input" placeholder="Saisir le nom du panier" id="name" as={MyTextField} autoComplete="off" />
                                    </Box>
                                    <ErrorMessage component={FormikErrorText} name="name" />
                                  </Box>

                                  <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                                    <Box sx={{ marginBottom: "6px" }}>
                                      <Typography variant="text_normal" fontWeight="600" htmlFor="value" name="value">
                                        Montant
                                      </Typography>
                                      <Typography variant="text_normal" fontWeight="600" htmlFor="value" name="value" style={{ color: colors.main }}>
                                        *
                                      </Typography>
                                    </Box>
                                    <Box sx={{ width: "80px" }}>
                                      <Field name="value" type="input" placeholder="0" id="value" as={MyTextField} autoComplete="off" />
                                    </Box>
                                    <ErrorMessage component={FormikErrorText} name="value" />
                                  </Box>
                                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                                    <Box display="flex" flexDirection="row" justifyContent="center" gap="12px">
                                      <Button
                                        variant="mj_secondary_md"
                                        onClick={() => {
                                          setOpenModifyPanierItem(false);
                                        }}
                                      >
                                        Annuler
                                      </Button>
                                      <Button
                                        variant="mj_primary_md"
                                        onClick={() => {
                                          if (isEmptyObject(errors)) {
                                            console.log("empty object condition verifié");
                                            modifyPanierItem(panierItemToModify, values.name, values.value);
                                            setOpenModifyPanierItem(false);
                                          }
                                        }}
                                      >
                                        Enregistrer
                                      </Button>
                                    </Box>
                                  </Box>
                                </Form>
                              )}
                            </Formik>
                          </BoxContainerModal>
                        </Box>
                      </Modal>
                      {/* end modify panier item */}

                      <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Box display="flex" flexDirection="row" justifyContent="center" gap="12px">
                          <Button
                            variant="mj_secondary_md"
                            onClick={() => {
                              setOpenPanier(false);
                            }}
                          >
                            Annuler
                          </Button>
                          <Button variant="mj_primary_md" type="submit">
                            Enregistrer
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
                {/* Modal add panier */}
                <Modal open={openAddPanier} onClose={() => setOpenAddPanier(false)}>
                  <Box sx={modalStyle}>
                    <BoxContainerModal>
                      <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="text_xxl" fontWeight="700">
                          Ajouter un panier
                        </Typography>
                        <ButtonSquare onClick={() => setOpenAddPanier(false)} action="close" />
                      </Box>

                      <Formik initialValues={initialValuesBasket} validationSchema={basketSchema} onSubmit={handleSubmitAddPanierItem}>
                        {({ values }) => (
                          <Form>
                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                              <Box sx={{ marginBottom: "6px" }}>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="name" name="name">
                                  Titre
                                </Typography>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="name" name="name" style={{ color: colors.main }}>
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <Field name="name" type="input" placeholder="Saisir le nom du panier" id="name" as={MyTextField} autoComplete="off" />
                              </Box>
                              <ErrorMessage component={FormikErrorText} name="name" />
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                              <Box sx={{ marginBottom: "6px" }}>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="value" name="value">
                                  Montant
                                </Typography>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="value" name="value" style={{ color: colors.main }}>
                                  *
                                </Typography>
                              </Box>
                              <Box sx={{ width: "80px" }}>
                                <Field name="value" type="input" placeholder="0" id="value" as={MyTextField} autoComplete="off" />
                              </Box>
                              <ErrorMessage component={FormikErrorText} name="value" />
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                              <Box display="flex" flexDirection="row" justifyContent="center" gap="12px">
                                <Button
                                  variant="mj_secondary_md"
                                  onClick={() => {
                                    setOpenAddPanier(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                                <Button variant="mj_primary_md" type="submit">
                                  Enregistrer
                                </Button>
                              </Box>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    </BoxContainerModal>
                  </Box>
                </Modal>
              </BoxContainerModal>
            </Box>
          </Modal>

          {/* Modal taches */}
          <Modal open={openTaches} onClose={() => setOpenTaches(false)}>
            <Box sx={modalStyle}>
              <BoxContainerModal>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Taches à réaliser
                  </Typography>
                  <ButtonSquare onClick={() => setOpenTaches(false)} action="close" />
                </Box>
                <Formik initialValues={initialValuesTaches} validationSchema={validationSChemaTaches} onSubmit={onSubmitTaches}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Box>
                        <Grid container>
                          {/* <pre>{JSON.stringify(skillsToSubmit, null, 2)}</pre> */}
                          {/* ============taches à réaliser ============ */}
                          <Grid item xs={12}>
                            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px", width: "100%" }}>
                              <Box sx={{ marginBottom: "6px" }}>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tasksToDo" name="tasksToDo">
                                  Taches
                                </Typography>
                                <Typography variant="text_normal" fontWeight="600" htmlFor="tasksToDo" name="tasksToDo" style={{ color: colors.main }}>
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <Field fullWidth name="tasksToDo" type="input" placeholder="Décrire les tâches à réaliser..." id="tasksToDo" as={MyTextField} multiline columns={5} rows={4} autoComplete="off" />
                              </Box>
                              <ErrorMessage component={FormikErrorText} name="tasksToDo" />
                            </Box>
                          </Grid>

                          {/*============= skills ============= */}
                          <Grid item xs={12}>
                            <Box sx={{ marginBottom: "6px" }}>
                              <Typography variant="text_normal" fontWeight="600" htmlFor="experienceSouhaite" name="experienceSouhaite">
                                Compétences
                              </Typography>
                            </Box>

                            <Field name="skills">
                              {({ field, form }) => (
                                <Autocomplete
                                  {...field}
                                  fullWidth
                                  value={null}
                                  blurOnSelect={true}
                                  noOptionsText={"Aucune option"}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(event, value) => {
                                    if (value && !skillsToSubmit.find((item) => item.id === value.id)) {
                                      setSkillsToSubmit(skillsToSubmit.concat([value]));
                                      form.setFieldValue("skills", skillsToSubmit.concat([value]));
                                    }
                                  }}
                                  onInputChange={(event, value) => {
                                    setSearchedSkills(event?.target?.value);
                                    console.log("searching for :", event?.target?.value);
                                    console.log(value);
                                  }}
                                  options={skills}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <MyTextField
                                      fullWidth
                                      value={selectedSkills}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <img src={RechercheIcon} alt="end" />
                                          </InputAdornment>
                                        ),
                                      }}
                                      placeholder="Rechercher des skills..."
                                    />
                                  )}
                                />
                              )}
                            </Field>

                            <Box display="flex" gap="10px" flexWrap="wrap" paddingTop="10px">
                              {values?.skills?.map((skill, index) => (
                                <Box key={index}>
                                  <Box sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}>
                                    <MyChip
                                      variant="skill"
                                      label={skill?.name}
                                      key={skill?.id}
                                      onDelete={() => {
                                        setSkillsToSubmit(skillsToSubmit.filter((sel) => sel.id !== skill.id));
                                        setFieldValue(
                                          "skills",
                                          skillsToSubmit.filter((sel) => sel.id !== skill.id)
                                        );
                                      }}
                                    />
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row-reverse" gap="10px" paddingTop="40px">
                          <Box>
                            <Button variant="mj_primary_md" type="submit">
                              Enregistrer
                            </Button>
                          </Box>
                          <Box>
                            <Button variant="mj_secondary_md" onClick={() => setOpenInformations(false)}>
                              Annuler
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainerModal>
            </Box>
          </Modal>

          {/* Modal equipements fournis */}
          <Modal open={openEquipementFournis} onClose={() => setOpenEquipementFournis(false)}>
            <Box sx={modalStyle}>
              <BoxContainerModal>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Equipements fournis
                  </Typography>
                  <ButtonSquare onClick={() => setOpenEquipementFournis(false)} action="close" />
                </Box>
                <Formik initialValues={initialValuesEquipementsFournis} validationSchema={validationSChemaEquipementsFournis} onSubmit={onSubmitEquipementsFournis}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Box>
                        {/*============= skills ============= */}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600">
                            Equipements fournis
                          </Typography>
                        </Box>

                        <Field name="euquipementsFournis">
                          {({ field, form }) => (
                            <Autocomplete
                              {...field}
                              fullWidth
                              value={null}
                              blurOnSelect={true}
                              noOptionsText={"Aucune option"}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionDisabled={(option) => {
                                return detailOffre.equipment_to_be_provided.some((disabledOption) => disabledOption.id === option.id);
                              }}
                              onChange={(event, value) => {
                                if (value && !equipementsFournisToSubmit.find((item) => item.id === value.id)) {
                                  setEquipementsFournisToSubmit(equipementsFournisToSubmit.concat([value]));
                                  form.setFieldValue("equipementsFournis", equipementsFournisToSubmit.concat([value]));
                                }
                              }}
                              onInputChange={(event, value) => {
                                setSearchedEquipementsFournis(event?.target?.value);
                                console.log("searching for :", event?.target?.value);
                                console.log(value);
                              }}
                              options={equipementsFournis}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <MyTextField
                                  fullWidth
                                  value={selectedSkills}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img src={RechercheIcon} alt="end" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  placeholder="Rechercher des equipements..."
                                />
                              )}
                            />
                          )}
                        </Field>

                        <Box display="flex" gap="10px" flexWrap="wrap" paddingTop="10px">
                          {values?.equipementsFournis?.map((eq, index) => (
                            <Box key={index}>
                              <Box sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}>
                                <MyChip
                                  variant="skill"
                                  label={eq?.name}
                                  key={eq?.id}
                                  onDelete={() => {
                                    setEquipementsFournisToSubmit(skillsToSubmit.filter((sel) => sel.id !== eq.id));
                                    setFieldValue(
                                      "equipementsFournis",
                                      equipementsFournisToSubmit.filter((sel) => sel.id !== eq.id)
                                    );
                                  }}
                                />
                              </Box>
                            </Box>
                          ))}
                        </Box>

                        <Box display="flex" flexDirection="row-reverse" gap="10px" paddingTop="40px">
                          <Box>
                            <Button variant="mj_primary_md" type="submit">
                              Enregistrer
                            </Button>
                          </Box>
                          <Box>
                            <Button variant="mj_secondary_md" onClick={() => setOpenInformations(false)}>
                              Annuler
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainerModal>
            </Box>
          </Modal>

          {/* Modal equipements à fournir */}
          <Modal open={openEquipementAFournir} onClose={() => setOpenEquipementAFournir(false)}>
            <Box sx={modalStyle}>
              <BoxContainerModal>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Equipements à fournir
                  </Typography>
                  <ButtonSquare onClick={() => setOpenEquipementAFournir(false)} action="close" />
                </Box>
                <Formik initialValues={initialValuesEquipementsAFournir} validationSchema={validationSChemaEquipementsAFournir} onSubmit={onSubmitEquipementsAFournir}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Box>
                        {/*============= equipements ============= */}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600">
                            Equipements à fournir
                          </Typography>
                        </Box>

                        <Field name="equipementsAFournir">
                          {({ field, form }) => (
                            <Autocomplete
                              {...field}
                              fullWidth
                              value={null}
                              blurOnSelect={true}
                              noOptionsText={"Aucune option"}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionDisabled={(option) => {
                                return detailOffre.equipment_provided.some((disabledOption) => disabledOption.id === option.id);
                              }}
                              onChange={(event, value) => {
                                if (value && !equipementsAFournirToSubmit.find((item) => item.id === value.id)) {
                                  setEquipementsAFournirToSubmit(equipementsAFournirToSubmit.concat([value]));
                                  form.setFieldValue("equipementsAFournir", equipementsAFournirToSubmit.concat([value]));
                                }
                              }}
                              onInputChange={(event, value) => {
                                setSearchedEquipementsAFournir(event?.target?.value);
                                console.log("searching for :", event?.target?.value);
                                console.log(value);
                              }}
                              options={equipementsAFournir}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <MyTextField
                                  fullWidth
                                  value={selectedSkills}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img src={RechercheIcon} alt="end" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  placeholder="Rechercher des equipements..."
                                />
                              )}
                            />
                          )}
                        </Field>

                        <Box display="flex" gap="10px" flexWrap="wrap" paddingTop="10px">
                          {values?.equipementsAFournir?.map((eq, index) => (
                            <Box key={index}>
                              <Box sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}>
                                <MyChip
                                  variant="skill"
                                  label={eq?.name}
                                  key={eq?.id}
                                  onDelete={() => {
                                    setEquipementsAFournirToSubmit(equipementsAFournirToSubmit.filter((sel) => sel.id !== eq.id));
                                    setFieldValue(
                                      "equipementsAFournir",
                                      equipementsAFournirToSubmit.filter((sel) => sel.id !== eq.id)
                                    );
                                  }}
                                />
                              </Box>
                            </Box>
                          ))}
                        </Box>

                        <Box display="flex" flexDirection="row-reverse" gap="10px" paddingTop="40px">
                          <Box>
                            <Button variant="mj_primary_md" type="submit">
                              Enregistrer
                            </Button>
                          </Box>
                          <Box>
                            <Button variant="mj_secondary_md" onClick={() => setOpenInformations(false)}>
                              Annuler
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainerModal>
            </Box>
          </Modal>

          {/* Modal AGENDA */}
          <Modal open={openAgenda} onClose={() => setOpenAgenda(false)}>
            <Box sx={modalStyle}>
              <BoxContainerModal>
                <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Agenda
                  </Typography>
                  <ButtonSquare onClick={() => setOpenAgenda(false)} action="close" />
                </Box>
                <Formik innerRef={formAgenda} initialValues={initialValuesAgenda} validationSchema={validationSchemaAgenda} onSubmit={onSubmitAgenda}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Box>
                        {/*============= equipements ============= */}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                        <pre>has shift Samedi : {JSON.stringify(hasShiftSamedi)}</pre>
                        <pre>has shift Dimanche : {JSON.stringify(hasShiftDimanche)}</pre>
                        <pre>has shift Lundi : {JSON.stringify(hasShiftLundi)}</pre>
                        <pre>has shift Mardi : {JSON.stringify(hasShiftMardi)}</pre>
                        <pre>has shift Mercredi : {JSON.stringify(hasShiftMercredi)}</pre>
                        <pre>has shift Jeudi : {JSON.stringify(hasShiftJeudi)}</pre>
                        <pre>has shift Vendredi : {JSON.stringify(hasShiftVendredi)}</pre> */}
                        {/* <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600">
                            Agenda
                          </Typography>
                        </Box> */}

                        <Field>
                          {({ form }) => (
                            <Box className="Agenda" sx={{ marginBottom: "32px" }}>
                              <GrayContainer>
                                {agendaMapper.map((day) => (
                                  <ItemContainerAgenda display="flex" flexDirection="column">
                                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                                      <Box display="flex" flexDirection="row" justifyContent="center" gap="10px">
                                        <Box sx={{ paddingTop: "2px" }}>
                                          <MySwitch
                                            isOn={form.values[`is_${day.name}`]}
                                            onChange={() => {
                                              day.setChecked(!day.checked);
                                              form.setFieldValue(`is_${day.name}`, !day.checked);
                                              form.setFieldValue(`start_time_${day.name}`, "");
                                              form.setFieldValue(`end_time_${day.name}`, "");
                                              form.setFieldValue(`start_time_${day.name}_2`, "");
                                              form.setFieldValue(`end_time_${day.name}_2`, "");
                                              if (form.values[`is_${day.name}`] === true) {
                                                form.setFieldValue(`start_time_${day.name}`, "");
                                                form.setFieldValue(`end_time_${day.name}`, "");
                                              }
                                            }}
                                          />
                                        </Box>
                                        <Box>
                                          <Typography variant="text_normal">{day.name}</Typography>
                                        </Box>
                                      </Box>
                                      {form.values[`is_${day.name}`] && (
                                        <Box display="flex" flexDirection="column" gap="10px">
                                          {/* Crénau matiné */}
                                          <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                                            <Box width="40px" />
                                            <Field name={`start_time_${day.name}`} width="60px" component={TimeInput} autoComplete="off" />
                                            <Box width="15px">
                                              <img src={RightArrowTime} width="15px" alt="" />
                                            </Box>
                                            <Field name={`end_time_${day.name}`} width="60px" component={TimeInput} autoComplete="off" />
                                          </Box>
                                          {/* ajouter */}
                                          {day.hasShift === false ? (
                                            <Box display="flex" flexDirection="row-reverse">
                                              <Button variant="mj_secondary_md" sx={{ width: "146px" }} onClick={() => day.setHasShift(true)}>
                                                Ajouter
                                              </Button>
                                            </Box>
                                          ) : (
                                            ""
                                          )}
                                          {/* Crénau soir */}
                                          {day.hasShift && (
                                            <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
                                              <ButtonSquare
                                                sx={{ height: "32px" }}
                                                action="close"
                                                onClick={() => {
                                                  day.setHasShift(false);
                                                  form.setFieldValue(`start_time_${day.name}_2`, "");
                                                  form.setFieldValue(`end_time_${day.name}_2`, "");
                                                }}
                                              />
                                              <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                                                <Field name={`start_time_${day.name}_2`} width="60px" component={TimeInput} autoComplete="off" />
                                                <Box width="15px">
                                                  <img src={RightArrowTime} width="15px" alt="" />
                                                </Box>
                                                <Field name={`end_time_${day.name}_2`} width="60px" component={TimeInput} autoComplete="off" />
                                              </Box>
                                            </Box>
                                          )}
                                        </Box>
                                      )}
                                    </Box>
                                    {form.errors[`start_time_${day.name}`] && (
                                      <Box sx={{ paddingTop: "16px" }}>
                                        <ErrorMessage name={`start_time_${day.name}`} component={FormikErrorText} />
                                      </Box>
                                    )}
                                    {form.errors[`start_time_${day.name}_2`] && (
                                      <Box sx={{ paddingTop: "16px" }}>
                                        <ErrorMessage name={`start_time_${day.name}_2`} component={FormikErrorText} />
                                      </Box>
                                    )}
                                    {form.errors[`end_time_${day.name}`] && (
                                      <Box sx={{ paddingTop: "16px" }}>
                                        <ErrorMessage name={`end_time_${day.name}`} component={FormikErrorText} />
                                      </Box>
                                    )}
                                  </ItemContainerAgenda>
                                ))}
                              </GrayContainer>
                            </Box>
                          )}
                        </Field>

                        <Box display="flex" flexDirection="row-reverse" gap="10px" paddingTop="40px">
                          <Box>
                            <Button variant="mj_primary_md" type="submit">
                              Enregistrer
                            </Button>
                          </Box>
                          <Box>
                            <Button variant="mj_secondary_md" onClick={() => setOpenInformations(false)}>
                              Annuler
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </BoxContainerModal>
            </Box>
          </Modal>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
export default OfferDetailsContent;
