import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { colors } from "../../theme/Colors";

const StyledBox = styled(Box)({
  borderRadius: "20px",
  textTransform: "none",
  color: colors.textDark,
  padding: "1.2rem 1.2rem",
  backgroundColor: `${colors.paperLightOrange}`,
});

function LightOrangeBox({ children, ...props }) {
  return <StyledBox {...props}>{children}</StyledBox>;
}

export default LightOrangeBox;
