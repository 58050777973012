import React, { useEffect, useState } from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { chipSelector } from "../../utils/chipSelector";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { Box, TextField, Button, Typography, Checkbox, IconButton, Select, MenuItem, FormControl, OutlinedInput, InputLabel, FormControlLabel, Alert, Modal, Autocomplete, InputAdornment } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../theme/Colors";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import CloseIcon from "@mui/icons-material/Close";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik, ErrorMessage } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import RechercheIcon from "../../imgs/imgV2/RechercheIcon.svg";

import axios from "axios";
import { Urls } from "../../api/Urls";
import { id } from "date-fns/locale";
import * as yup from "yup";
import { json, useLocation, useNavigate } from "react-router-dom";
import { ArrowBack, ArrowBackIosOutlined, ArrowForward, ArrowLeft, ArrowRight, CheckBox, ErrorSharp, Flag } from "@mui/icons-material";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { ToastContainer, toast } from "react-toastify";
import { modalStyle } from "../../theme/ModalStyle";
import BoxContainer from "../../components/ui/BoxContainer";

function Acompte() {
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "ID acompte", width: 90, headerClassName: "grid-header" },
    {
      field: "userId",
      headerName: "ID intérimaire",
      width: 150,
      renderCell: (params) => {
        if (params.row.user.d === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.user.id;
        }
      },
    },
    {
      field: "lastName",
      headerName: "Nom",
      width: 180,
      renderCell: (params) => {
        if (params.row.user.last_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.user.last_name;
        }
      },
    },
    {
      field: "firstName",
      headerName: "Prénom",
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (params.row.user.first_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.user.first_name;
        }
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (params.row.created_at === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.created_at;
        }
      },
    },
    {
      field: "amoubt",
      headerName: "Montant",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (params.row.amount === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.amount;
        }
      },
    },
    {
      field: "status",
      headerName: "Etat",
      description: "This column has a value getter and is not sortable.",
      width: 110,
      align: "center",
      renderCell: (params) => {
        if (params.row.control_status !== null) return chipSelector(params.row.control_status);
        else return <Typography>-</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "start",
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="8px" alignItems="center">
            <Button
              onClick={() => {
                setId(params.row.id);
                setOpenAccept(true);
              }}
              variant="mj_green_md"
            >
              accepter
            </Button>
            <Button
              variant="mj_red_md"
              onClick={() => {
                setId(params.row.id);
                setOpenRefuse(true);
              }}
            >
              Refuser
            </Button>
          </Box>
        );
      },
      width: 220,
    },
  ];

  const [finalValues, setFinalValues] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [id, setId] = useState(null);
  const { user } = useAuthContext();
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(0);
  const [openAccept, setOpenAccept] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const [multipleActions, setMultipleActions] = useState("");
  const access_token = user?.access_token;
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIDs, setSelectedIDs] = useState(null);
  const [openParams, setOpenParams] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [status, setStatus] = useState([]);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [total, seTotal] = useState(0);
  const [formes, setFormes] = useState([]);
  const location = useLocation();
  const idInt = location?.state?.idinterim ? location?.state?.idinterim : "";
  const [initialValues, setInitialValues] = useState({
    id_demande: "",
    user_id: idInt,
    firstName: "",
    lastName: "",
    dateMin: "",
    dateMax: "",
    amount: "",
    checkedAtt: false,
    checkedValid: false,
    checkedRef: false,
  });
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      setPage(1);
      setFinalValues(formik.values);
    },
    enableReinitialize: true,
  });

  //get forme juridique
  useEffect(() => {
    Api.get(Urls.GET_FORME_JURIDIQUE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res.data.data, "forme jur");
        setFormes(res?.data?.forme_juridiques);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (rowsPerPage && page) {
      setLoading(true);
      Api.get(Urls.GET_ADVANCES + `?perPage=${25}`, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          ...(formik.values.id_demande !== "" && { id: formik.values.id_demande }),
          ...(formik.values.user_id !== "" && { userId: formik.values.user_id }),
          ...(formik.values.firstName !== "" && { lastName: formik.values.firstName }),
          ...(formik.values.lastName !== "" && { firstName: formik.values.lastName }),
          ...(formik.values.amount !== "" && { amount: formik.values.amount }),
          ...(formik.values.dateMin !== "" && formik.values.dateMin !== null && { dateMin: formik.values.dateMin }),
          ...(formik.values.dateMax !== "" && formik.values.dateMax !== null && { dateMax: formik.values.dateMax }),
          ...(status.length !== 0 && { status: status }),
        },
      })
        .then((res) => {
          console.log(res.data);
          setRows(res?.data?.advances);
          setData(res.data);
          setLoading(false);
          seTotal(res?.data?.meta?.total);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [formik.values, change]);
  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.advances);
        setData(res.data);
        setLoading(false);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };
  const customFooter = () => {
    return <Box></Box>;
  };
  const [refusalReasons, setRefusalReasons] = useState([]);
  const [search, setSearch] = useState("");
  const SearchRefusal = (name) => {
    Api.get(Urls.GET_REFUSAL_REASONS, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(name !== "" ? { search: name } : null),
      },
    })
      .then((res) => {
        setRefusalReasons(res?.data?.advance_refusal_reasons);
      })
      .catch((err) => {
        console.log(err);
        setRefusalReasons([]);
      });
  };
  const RefuserAcompte = (values) => {
    Api.patch(
      Urls.UPDATE_ADVANCE + id,
      {
        control_status: "REFUSED",
        advance_refusal_reason_id: values.reason,
      },
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenRefuse(false);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const AccepterAcompte = (values) => {
    Api.patch(
      Urls.UPDATE_ADVANCE + id,
      {
        control_status: "ACCEPTED",
      },
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenAccept(false);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box>
      <ToastContainer />
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des acomptes{" "}
        </Typography>
      </Box>

      {/* Form Section */}
      <form onSubmit={formik.handleSubmit}>
        <Box className="filter" display="flex" flexDirection="column" gap="1rem">
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Box>
              <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
                Filtre :
              </Typography>
            </Box>

            {/*Search fields  */}
            <Box alignItems="center" marginBottom="1rem" gap="1rem" display="flex" flexWrap="wrap">
              <Box alignItems="center" gap="0.3rem" display="flex" flexDirection="column">
                <Box alignItems="center" gap="0.3rem" display="flex">
                  <TextField placeholder="Id acompte" name="id_demande" onChange={formik.handleChange} />
                </Box>
              </Box>
              <Box alignItems="center" gap="0.3rem" display="flex">
                <TextField value={formik.values?.user_id} placeholder="Id intérimaire" name="user_id" onChange={formik.handleChange} />
              </Box>

              <Box gap="0.3rem" display="flex" flexDirection="column">
                <Box alignItems="center" gap="0.3rem" display="flex">
                  <TextField placeholder="Nom" name="firstName" onChange={formik.handleChange} />
                </Box>
              </Box>
              <Box alignItems="center" gap="0.3rem" display="flex" flexDirection="column">
                <Box alignItems="center" gap="0.3rem" display="flex">
                  <TextField placeholder="Prénom" name="lastName" onChange={formik.handleChange} />
                </Box>
              </Box>
              <Box display="flex" flexWrap="wrap" alignItems="center" gap="0.3rem">
                <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                  <DatePicker
                    components={{ OpenPickerIcon: CalendarMonthIcon }}
                    value={formik.values.dateMin}
                    onChange={(value) => formik.setFieldValue("dateMin", value)}
                    maxDateTime={formik.values.createdAtMax}
                    InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          type="date"
                          {...params}
                          name="dateMin"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "jour/mois/année min",
                            disabled: "true",
                          }}
                        />
                      );
                    }}
                  ></DatePicker>
                </LocalizationProvider>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("dateMin", null)} />

                <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                  <DatePicker
                    components={{ OpenPickerIcon: CalendarMonthIcon }}
                    value={formik.values.dateMax}
                    onChange={(value) => formik.setFieldValue("dateMax", value)}
                    minDateTime={formik.values.createdAtMin}
                    InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          type="date"
                          {...params}
                          name="dateMax"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "jour/mois/année max",
                            disabled: "true",
                          }}
                        />
                      );
                    }}
                  ></DatePicker>
                </LocalizationProvider>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("dateMax", null)} />
              </Box>
              <Box alignItems="center" gap="0.3rem" display="flex" flexDirection="column">
                <Box alignItems="center" gap="0.3rem" display="flex">
                  <TextField placeholder="Montant" name="amount" onChange={formik.handleChange} />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Checkboxes */}
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: "0.5rem", marginBottom: "0.5rem" }}>
            <Box display="flex" alignItems="center">
              <label htmlFor="state">Etat : </label>
            </Box>

            <FormControlLabel
              label="En attente"
              control={
                <Checkbox
                  checked={formik.values.checkedAtt}
                  onChange={(e) => {
                    formik.setFieldValue("checkedAtt", e.target.checked);
                    e.target.checked === true ? setStatus((status) => status.concat(["PENDING"])) : setStatus(status.filter((status) => status !== "PENDING"));
                  }}
                />
              }
            />
            <FormControlLabel
              label="Acceptée"
              control={
                <Checkbox
                  checked={formik.values.checkedValid}
                  onChange={(e) => {
                    formik.setFieldValue("checkedValid", e.target.checked);
                    e.target.checked === true ? setStatus((status) => status.concat(["ACCEPTED"])) : setStatus(status.filter((status) => status !== "ACCEPTED"));
                  }}
                />
              }
            />
            <FormControlLabel
              label="Refusée"
              control={
                <Checkbox
                  checked={formik.values.checkedRef}
                  onChange={(e) => {
                    formik.setFieldValue("checkedRef", e.target.checked);
                    e.target.checked === true ? setStatus((status) => status.concat(["REFUSED"])) : setStatus(status.filter((status) => status !== "REFUSED"));
                  }}
                />
              }
            />
          </Box>
        </Box>
        {/* End form section */}

        {/* Actions Section */}
        {/*<Box className="actions">
          <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
              Actions :
            </Typography>

            <Box display="flex" gap="1rem">
              <Button
                onClick={() => {
                  setOpenAccept(true);
                }}
                variant="mj_green_md"
              >
                Accepter les acomptes{" "}
              </Button>
            </Box>

            <Box display="flex" gap="1rem">
              <Button
                onClick={() => {
                  setOpenRefuse(true);
                }}
                variant="mj_red_md"
              >
                Refuser les acomptes{" "}
              </Button>
            </Box>
          </Box>
              </Box>*/}
      </form>

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box marginBottom="24px" sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1300px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          onCellClick={(e) => {
            console.log(e.colDef);
            if (e.field === "action") {
              setTimeout(() => {
                setFinalValues([""]);
              }, 500);
            }
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          checkboxSelection
          rowCount={total}
          loading={loading}
          page={page - 1}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
          }}
          disableSelectionOnClick
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          paginationMode="server"
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal open={openRefuse} onClose={() => setOpenRefuse(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Refuser l’acompte
              </Typography>
              <ButtonSquare onClick={() => setOpenRefuse(false)} action="close" />
            </Box>
            <Formik
              enableReinitialize={true}
              initialValues={{
                reason: "",
              }}
              onSubmit={(values) => {
                RefuserAcompte(values);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box marginBottom="6px">
                    <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                      Motif du refus{" "}
                    </Typography>
                    {/*<Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                      *
              </Typography>*/}
                  </Box>

                  <Box>
                    <Autocomplete
                      disablePortal
                      ListboxProps={{ style: { maxHeight: "180px" } }}
                      fullWidth
                      id="combo-box-demo"
                      value={values?.titreObj}
                      onChange={(e, value) => {
                        if (value) {
                          setFieldValue("reason", value.id);
                        }
                      }}
                      options={refusalReasons}
                      getOptionLabel={(Option) => Option.name}
                      renderInput={(params) => (
                        <TextField
                          sx={{ maxWidth: "100%" }}
                          value={values.titre}
                          onChange={(e) => {
                            if (e.target.value) {
                              if (e.target.value?.length > 0) {
                                SearchRefusal(e?.target?.value);
                              } else {
                                setRefusalReasons([]);
                              }
                            }
                          }}
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                            endAdornment: null,
                          }}
                          placeholder="Motif de refus..."
                        />
                      )}
                    />
                  </Box>
                  <Box marginTop="24px" display="flex" gap="8px" justifyContent="flex-end" alignItems="center">
                    <Button variant="mj_secondary_md" onClick={() => setOpenRefuse(false)}>
                      Annuler
                    </Button>
                    <Button type="submit" variant="mj_primary_md" disabled={values?.reason ? false : true}>
                      {" "}
                      Enregistrer
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openAccept} onClose={() => setOpenAccept(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Accepter l’acompte
              </Typography>
              <ButtonSquare onClick={() => setOpenAccept(false)} action="close" />
            </Box>
            <Typography sx={{ fontSize: "15px", marginBottom: "24px", fontWeight: "600", textAlign: "center" }}>Êtes-vous sûr(e) de vouloir accepter cet acompte ?</Typography>
            <Box marginTop="24px" display="flex" gap="8px" justifyContent="flex-end" alignItems="center">
              <Button variant="mj_secondary_md" onClick={() => setOpenAccept(false)}>
                Annuler
              </Button>
              <Button onClick={() => AccepterAcompte()} variant="mj_primary_md">
                {" "}
                Enregistrer
              </Button>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default Acompte;
