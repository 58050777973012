import React from "react";

import { modalStyle } from "../../../theme/ModalStyle";
import { Box, Button, Typography, SvgIcon, Modal, CircularProgress, Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";

import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";

import MyChip from "../../../components/ui/MyChip";
import ButtonSquare from "../../../components/ui/ButtonSquare";

import OfferDetailsContent from "./OfferDetailsContent";
import ProfileProp from "./ProfileProp";

import BoxContainer from "../../../components/ui/BoxContainer";
import { toast } from "react-toastify";
import moment from "moment";
import PropositionMission from "./PropositionMission";

const chipSelector = (status) => {
  switch (status) {
    case 1:
      return { variant: "active_offer", label: "Active" };
    case 0:
      return { variant: "ended_offer", label: "Terminée" };
    default:
      return {};
  }
};

function OfferDetails({ idO }) {
  const location = useLocation();
  const navigate = useNavigate();
  const id = idO ? idO : location.pathname.split("/")[4];
  console.log(id, "id pffer");
  const jobTitle = location?.state?.title;
  const [status, setStatus] = useState(location?.state?.status);
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);

  const [applicationOpen, setApplicationOpen] = useState();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [selectedTab, setSelectedTab] = useState(1);
  function afficherDifferenceDate(date) {
    const now = moment();
    const diff = moment(date);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");

    if (minutes === 1) {
      return "Postée il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Postée il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Postée il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Postée il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Postée il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Postée il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Postée il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Postée il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Postée il y a 1 mois";
    } else {
      return `Postée il y a ${mois} mois`;
    }
  }
  const tabs = [
    {
      id: 1,
      name: "Détails de l’offre",
      icon: "📋",
    },
    {
      id: 2,
      name: "Profils proposés",
      icon: "📨",
    },
    {
      id: 3,
      name: "Proposition de mission",
      icon: "📬",
    },
  ];
  const [detailOffre, setDetailOffre] = useState({});
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  // get detail offer by ID AND PEFECT CANDIDATURES
  useEffect(() => {
    Api.get(`${Urls.GET_DETAILS_OFFRE}${id}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res, "data from el gharbi");
        setDetailOffre(res?.data?.offer);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [change]);

  const contentSelector = (contentId = 1) => {
    switch (contentId) {
      case 1:
        return <OfferDetailsContent id={id} />;
      case 2:
        return <ProfileProp id={id} />;
      case 3:
        return detailOffre?.is_active === 1 ? <PropositionMission id={id} /> : <Typography textAlign="center">Offre cloturée</Typography>;
      default:
        return <OfferDetailsContent id={id} />;
    }
  };
  const [openfermer, setOpenFermer] = useState(false);
  const [openouvrir, setOpenOuvrir] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  //fermer le recrutement
  const FermerRecrutement = (etat) => {
    Api.patch(
      Urls.SUSPEND_OFFER + id + "/is_application_open",
      {
        is_application_open: etat,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenFermer(false);
        setOpenOuvrir(false);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.resposne?.data?.message);
      });
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const navigateRepOffre = () => {
    navigate("/entreprise/offres/republier-offre", { state: { id: id } });
  };
  //delete offer
  const VerifyOffer = (status) => {
    Api.post(
      Urls.VERIFY_OFFER,
      {
        offer_ids: [id],
        is_verified: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  //publier offre
  const publishOffers = (status) => {
    Api.post(
      Urls.OPEN_CLOSE_OFFER,
      {
        offer_ids: [id],
        is_application_open: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  //accept offer
  const activateOffer = (status) => {
    Api.post(
      Urls.ACTIATE_DEACTIVATE_OFFER,
      {
        offer_ids: [id],
        is_active: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  const fuctionSelector = (selection) => {
    console.log(selection, "selection");
    switch (selection) {
      case "ne pas vérifier":
        return VerifyOffer(0);
        break;
      case "vérifier":
        return VerifyOffer(1);
        break;
      case "activer":
        return activateOffer(1);
        break;
      case "terminer":
        return activateOffer(0);
        break;
      case "publier":
        return publishOffers(1);
        break;
      case "ne pas publier":
        return publishOffers(0);
        break;
    }
  };
  return (
    <Box display="flex" flexDirection="column" gap="40px">
      {/* header */}
      <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", gap: "16px" }}>
        {/* left*/}
        <Box display="flex" flexDirection="column" gap="6px">
          <Box>
            <Typography variant="text_xl" fontWeight="700">
              {jobTitle} #{id}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap="10px">
            <MyChip variant={chipSelector(status).variant} label={chipSelector(status).label} />
            <Typography variant="text_normal" color="#4A526C">
              {afficherDifferenceDate(detailOffre?.created_at)}
            </Typography>
          </Box>
        </Box>

        {/* right*/}
        <Box display="flex" flexDirection="row" gap="10px">
          {loading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Box display="flex" gap="10px" alignItems="center">
              <Box>
                {detailOffre?.is_application_open === 1 ? (
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setAction("ne pas publier");
                    }}
                    variant="mj_red_md"
                    sx={{ height: "42px", display: "flex", gap: "6px" }}
                  >
                    Fermer le recrutement
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setAction("publier");
                    }}
                    variant="mj_green_md"
                    sx={{ height: "42px", display: "flex", gap: "6px" }}
                  >
                    ouvrir le recrutement
                  </Button>
                )}
              </Box>

              <Box>
                {detailOffre?.is_active === 1 ? (
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setAction("terminer");
                    }}
                    variant="mj_secondary_md"
                    sx={{ height: "42px" }}
                  >
                    Clôturer la demande
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setAction("activer");
                    }}
                    variant="mj_secondary_md"
                    sx={{ height: "42px" }}
                  >
                    Activer la demande
                  </Button>
                )}
              </Box>
              <Box>
                {detailOffre?.is_verified ? (
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setAction("vérifier");
                    }}
                    variant="mj_red_md"
                    sx={{ height: "42px" }}
                  >
                    Ne pas vérifier la demande
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setAction("ne pas vérifier");
                    }}
                    variant="mj_green_md"
                    sx={{ height: "42px" }}
                  >
                    Vérifier la demande
                  </Button>
                )}
              </Box>
            </Box>
          )}

          <Menu
            sx={{ mt: "50px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }} onClick={() => navigateRepOffre()}>
              <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                Réutiliser l’offre
              </Typography>
            </MenuItem>
            <MenuItem sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}>
              <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400", color: "lightgray" }}>
                Supprimer l’offre
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* tabs */}
      <Box display="flex" sx={{ backgroundColor: "#FAFAFB", borderRadius: "6px", padding: "2px", flexDirection: { md: "row", xs: "column" } }} gap="6px" maxWidth="800px">
        {tabs.map((tab, index) => (
          <Button disableRipple fullWidth key={index} variant={selectedTab === tab.id ? "mj_primary_choice_active_lg" : "mj_primary_choice_lg"} onClick={() => setSelectedTab(tab.id)}>
            <Typography fontSize="20px" mr>
              {tab.icon}
            </Typography>
            {tab.name}
          </Button>
        ))}
      </Box>

      {/* content */}
      {contentSelector(selectedTab)}
      <Modal
        open={openfermer}
        onClose={() => {
          setOpenFermer(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Fermer le recrutement
              </Typography>
              <ButtonSquare onClick={() => setOpenFermer(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir fermer le recrutement</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenFermer(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => FermerRecrutement(false)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Modification de la demande
              </Typography>
              <ButtonSquare onClick={() => setOpen(false)} action="close" />
            </Box>
            <Typography sx={{ fontSize: "15px", marginBottom: "24px", fontWeight: "600", textAlign: "center" }}>Êtes-vous sûr(e) de vouloir {action} cette offre ?</Typography>
            <Box marginTop="24px" display="flex" gap="8px" justifyContent="flex-end" alignItems="center">
              <Button variant="mj_secondary_md" onClick={() => setOpen(false)}>
                Annuler
              </Button>
              <Button onClick={() => fuctionSelector(action)} variant="mj_primary_md">
                Enregistrer
              </Button>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default OfferDetails;
