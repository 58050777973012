import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Alert, FormControl, Select, MenuItem, Chip } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
import MjErrorText from "../../../components/ui/MjErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLocation, useParams } from "react-router-dom";

import ProblemesDetails from "./ProblemesDetails";
import InterimaireDetails from "../details-Interimaire/InterimaireDetails";
import EntrepriseDetails from "../detailEntreprise/EntrepriseDetails";
import DetailContrat from "../detail-contrat/DetailContrat";

function ContentSelectorProblems() {
  const params = useParams();
  // console.log("PARAMS = ", params);
  const location = useLocation();
  // console.log("LOCATION = ", location);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [succes, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [searchedAgency, setSearchedAgency] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchedRole, setSearchedRole] = useState("");

  const [problem, setProblem] = useState({});

  const [selectedTab, setSelectedTab] = useState(1);

  const tabs = [
    {
      id: 1,
      name: "Probleme",
      icon: "",
    },
    {
      id: 2,
      name: "Interimaire",
      icon: "",
    },
    {
      id: 3,
      name: "Entreprise",
      icon: "",
    },
    {
      id: 4,
      name: "Contrat",
      icon: "",
    },
  ];

  const contentSelector = (contentId = 1) => {
    switch (contentId) {
      case 1:
        return <ProblemesDetails />;
      case 2:
        return <InterimaireDetails modify={false} idInterimaire={problem.interim_id} />;
      case 3:
        return <EntrepriseDetails modify={false} idEntreprise={problem.enterprise_id} />;
      case 4:
        return <DetailContrat modify={false} id={problem.contract_id} />;
      default:
        return <div>contrat</div>;
    }
  };

  // //get problem
  useEffect(() => {
    Api.get("/admin/problems" + `/${params?.id}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setProblem(res?.data?.problem);
        console.log("PROBLEM ::::::", res.data.problem);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" fontWeight="600" mb>
          Gestion des problèmes
        </Typography>

        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography variant="h5" component="h1" fontWeight="600">
            {"CONTRAT ID: " + problem.contract_id}
          </Typography>
          <Chip variant={problem.status === "FIXED" ? "ended_offer" : "active_offer"} label={problem.status === "PENDING" ? "En attente" : "Traité"} />
        </Box>
      </Box>

      {/* tabs */}
      <Box display="flex" sx={{ maxWidth: "700px", backgroundColor: "#FAFAFB", borderRadius: "6px", padding: "2px", flexDirection: { md: "row", xs: "column" } }} gap="6px" margin="40px 0px">
        {tabs.map((tab, index) => (
          <Button disableRipple fullWidth key={index} variant={selectedTab === tab.id ? "mj_primary_choice_active_lg" : "mj_primary_choice_lg"} onClick={() => setSelectedTab(tab.id)}>
            <Typography fontSize="20px" mr>
              {tab.icon}
            </Typography>
            {tab.name}
          </Button>
        ))}
      </Box>

      {/* content */}
      {contentSelector(selectedTab)}
    </Box>
  );
}

export default ContentSelectorProblems;
