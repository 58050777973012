import axios from "axios";
import { createContext, useReducer, useEffect } from "react";
import { Urls } from "../api/Urls";
import Api from "../api/Api";

export const UserContext = createContext();

export const userReducer = (state, action) => {
  switch (action.type) {
    case "GET_USER":
      return { userData: action.payload };
    default:
      return state;
  }
};

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, {
    userData: null,
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userAdmin"));
    const access_token = user?.access_token;
    console.log(user, "from USER CONTEXTE");
    let userData = null;

    if (access_token) {
      Api.get(Urls.GET_USER_RESOURCE, {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          if (response?.data?.status === "success") {
            userData = response?.data?.data?.user;
            console.log("USER CONTEXT USERDATA", userData);

            //get the full user from ls

            const userLS = JSON.parse(localStorage.getItem("userAdmin"));
            console.log("MY USER LOCAL STORAGE", userLS);
            userLS.user = userData;
            console.log("MY FULL USER AFTER APPEND", userLS);
            localStorage.setItem("userAdmin", JSON.stringify(userLS));

            if (userData) {
              dispatch({ type: "GET_USER", payload: userData });
            }
          }
        })
        .catch((e) => console.log(e.message));
    }
  }, []);

  console.log("UserContext state:", state);

  return <UserContext.Provider value={{ ...state, dispatch }}>{children}</UserContext.Provider>;
};
