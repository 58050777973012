//ALL COLORS
export const colors = {
  main: "#EA5B0C",
  light: "#f18c52",
  dark: "#cf692e",
  gray: "#7b7b7b",
  hoverBtnLight: "#fbfbfb",
  paperLightGray: "#F9F9F9",
  paperLightGrayBorder: "#DADADA",
  paperLightOrange: "#FFF1EA",
  paperLightOrangeHover: "#fce4d9",
  mjRedOutline: "#f0625f",
  mjRedText: "#632726",
  mjGreenOutline: "#5bb65f",
  mjGreenText: "#1e4620",
  mjYellowOutline: "#af6906",
  tFieldGray: "#d9d9d9",
  textDark: "#212121",
  mj_error: "#ec5656",

  //v2

  //text colors
  text_subtitle: "#666D83",
  text_subtitle_dark: "#4A526C",
  text_chip: "#2E3755",
  text_description: "#2E3755",
  text_clickable: "#0065FF",
  mj_text: "#212121",
  mj_navbar: "#2C2C2C",
  mj_drawer: "#FBDFCE",
  mj_sidebar: "#FBDFCE",
  mj_red: "#D30000",
  mj_red_light: "#d42020",
  mj_green: "#53B630",
  mj_green_light: "#56be30",
  mj_preselected: "#D4C500",
  mj_confirmed: "#F07F3C",
  mj_error: "#ec5656",
  mj_blue: "#4f85eb",
  mj_refused: "#707070",
  mj_pending: "#707070",
};
