import React, { useEffect } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import LensIcon from "@mui/icons-material/Lens";
import endicapeIcon from "../../../imgs/imgV2/endicapeIcon.svg";
import locationIcon from "../../../imgs/imgV2/LocationIcon.svg";
import EmailIcon from "../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../imgs/imgV2/TelIcon.svg";
import CheckedCircle from "../../../components/ui/CheckedCircle";
import PersonIcon from "@mui/icons-material/Person";
import BirthIcon from "../../../imgs/imgV2/birthdayIcon.svg";
import { Avatar, Box, CircularProgress, Divider, Rating, Step, StepButton, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useState } from "react";
import { colors } from "../../../theme/Colors";
import moment from "moment";
import iconPoint from "../../../imgs/imgV2/point.svg";
import BoxContainer from "../../../components/ui/BoxContainer";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import MyChip from "../../../components/ui/MyChip";
const stepperStyle = {
  ".MuiSvgIcon-root:not(.Mui-completed)": {
    color: colors.main,
  },
};
function DetailsProfileInterim({ interimId, idOffer, id, setIdContrat, type }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [userDetail, setUserDetails] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (type === "proposed") {
      Api.get(Urls.GET_PROFILE_IMTERIM + id + "/user", {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          console.log(res, "detail profile");
          setUserDetails(res?.data?.user);
          setData(res?.data);
          setIdContrat(res?.data?.user?.contract_id);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    if (type === "embauched") {
      Api.get(Urls.GET_DETAIL_INTERIM_EMBAUCHER + idOffer + "/interims/" + id, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          console.log(res, "detail profile");
          setUserDetails(res?.data?.user);
          setData([
            {
              id: "0",
              name: "Piéces d'identité",
              validity: res?.data?.user?.has_valid_identity_document,
            },
            {
              id: "1",
              name: "RIB",
              validity: res?.data?.user?.has_rib,
            },
            {
              id: "2",
              name: "Carte vitale",
              validity: res?.data?.user?.has_valid_vital_card,
            },
            {
              id: "3",
              name: "Justificatif de domicile",
              validity: res?.data?.user?.has_valid_address_proof,
            },
            {
              id: "4",
              name: "Permis de conduire",
              validity: res?.data?.user?.has_valid_driving_license,
            },
          ]);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    if (type === "detailsProfile") {
      Api.get(Urls.GET_USER_REC + interimId, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          console.log(res, "detail profile");
          setUserDetails(res?.data?.user);
          console.log("rana f detail te3 le profile");
          setData([
            {
              id: "0",
              name: "Piéces d'identité",
              validity:
                res?.data?.user?.administrative_documents?.national_identity_card?.control_status === "VALID" ||
                res?.data?.user?.administrative_documents?.national_identity_card?.passport === "VALID" ||
                res?.data?.user?.administrative_documents?.residence_permit?.control_status === "VALID" ||
                res?.data?.user?.administrative_documents?.residence_permit_receipt?.control_status === "VALID"
                  ? true
                  : false,
            },
            {
              id: "1",
              name: "RIB",
              validity: res?.data?.user?.profile?.rib ? true : false,
            },
            {
              id: "2",
              name: "Carte vitale",
              validity: res?.data?.user?.administrative_documents?.vital_card?.control_status === "VALID" || res?.data?.user?.administrative_documents?.social_security_certificate?.control_status === "VALID" ? true : false,
            },
            {
              id: "3",
              name: "Justificatif de domicile",
              validity: res?.data?.user?.administrative_documents?.address_proof?.control_status === "VALID" ? true : false,
            },
            {
              id: "4",
              name: "Permis de conduire",
              validity: res?.data?.user?.administrative_documents?.driving_licenses.length > 0 ? true : false,
            },
          ]);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, []);
  return (
    <Box position="relative">
      {loading === false ? (
        <Box display="flex" gap="24px">
          <Box flex={1} display="flex" flexDirection="column" gap="24px">
            {type === "proposed" && (
              <BoxContainer>
                {userDetail?.is_handicapped && (
                  <Box display="flex" alignItems="center" gap="4px">
                    <img src={endicapeIcon} alt="endicape-icon" />
                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", color: "#003E9C" }}>Travailleur handicapé</Typography>
                  </Box>
                )}
                {userDetail?.availability === "AVAILABLE" && (
                  <Typography fontSize="14px" fontWeight="400" lineHeight="22.4px">
                    ✅ Disponible
                  </Typography>
                )}
                {userDetail?.availability === "UNAVAILABLE" && (
                  <Typography fontSize="14px" fontWeight="400" lineHeight="22.4px">
                    Indisponible
                  </Typography>
                )}
                {userDetail?.availability === "AVAILABLE_AFTER" && (
                  <Typography fontSize="14px" fontWeight="400" lineHeight="22.4px">
                    Disponible à partir du {moment(userDetail?.availability_date).format("DD MMMM YYYY")}
                  </Typography>
                )}
              </BoxContainer>
            )}
            {(type === "embauched" || type === "detailsProfile") && (
              <BoxContainer display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" gap="20px">
                  <Box>
                    <Box display="flex" alignItems="center" gap="20px">
                      <Avatar sx={{ height: "80px", width: "80px", position: "relative", transition: "0.5s" }}>{userDetail?.url_avatar ? <img src={userDetail?.url_avatar} alt="profil picture" /> : <PersonIcon fontSize="large" />}</Avatar>
                      <Box>
                        <Typography fontSize="16px" fontWeight="600" lineHeight="25.6px">
                          {userDetail?.first_name} {userDetail?.last_name}
                        </Typography>

                        {userDetail?.rating_scale && <Rating sx={{ color: colors.main, fontSize: "large" }} name="half-rating-read" precision={1} defaultValue={userDetail?.rating_scale} readOnly />}
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="8px">
                    {userDetail?.profile?.is_handicapped && (
                      <Box display="flex" alignItems="center" gap="4px">
                        <img src={endicapeIcon} alt="endicape-icon" />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", color: "#003E9C" }}>Travailleur handicapé</Typography>
                      </Box>
                    )}
                    <Box display="flex" alignItems="center" gap="4px">
                      <img src={locationIcon} alt="location-icon" />
                      <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userDetail?.address ? userDetail?.address : ""}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="4px">
                      <img src={EmailIcon} alt="email-icon" />
                      <Typography variant="text_clickable">{userDetail?.email ? userDetail?.email : ""}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="4px">
                      <img src={TelIcon} alt="tel-icon" />
                      <Typography variant="text_clickable">{userDetail?.phone_number ? userDetail?.phone_number : ""}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="4px">
                      <img src={BirthIcon} alt="tel-icon" />
                      <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userDetail?.birthday ? userDetail?.birthday : ""}</Typography>
                    </Box>
                    {userDetail?.matching_availability === 1 && (
                      <Typography fontSize="14px" fontWeight="400" lineHeight="22.4px">
                        ✅ Disponible
                      </Typography>
                    )}
                    {userDetail?.matching_availability === 0 && (
                      <Typography fontSize="14px" fontWeight="400" lineHeight="22.4px">
                        Indisponible
                      </Typography>
                    )}
                  </Box>
                </Box>
              </BoxContainer>
            )}
            {(type === "embauched" || type === "detailsProfile") && (
              <BoxContainer display="flex" flexDirection="column">
                <Box marginBottom="20px" display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="text_lg" fontWeight="700">
                    Informations administratives
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="20px">
                  {data?.map((cerf, index) => (
                    <>
                      <Box key={cerf.id} display="flex" alignItems="center" gap="40px" justifyContent="space-between">
                        <Typography sx={{ marginLeft: "10px", fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>{cerf.name}</Typography>
                        <Box>
                          <CheckedCircle checked={cerf.validity} />
                        </Box>
                      </Box>
                      {index < 4 ? <Divider /> : null}
                    </>
                  ))}
                </Box>
              </BoxContainer>
            )}
          </Box>
          <Box flex={2.5} display="flex" flexDirection="column" gap="24px">
            {userDetail?.metier && (
              <BoxContainer>
                {type === "proposed" || type === "detailsProfile" ? (
                  <Box>
                    {userDetail?.metier?.map((metier) => (
                      <Box display="flex" alignItems="center" gap="5px">
                        <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                          {metier?.name}
                        </Typography>
                        <img src={iconPoint} alt="icon-point" />
                        <Typography>{metier?.experience_years} Ans</Typography>
                      </Box>
                    ))}
                    {userDetail?.metier?.length === 0 && (
                      <Box>
                        <Typography textAlign="center" fontSize="14px" fontWeight="600">
                          Contenu vide
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" gap="5px">
                    <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                      {userDetail?.metier?.name}
                    </Typography>
                    <img src={iconPoint} alt="icon-point" />
                    <Typography>{userDetail?.metier?.experience_years} Ans</Typography>
                  </Box>
                )}
              </BoxContainer>
            )}
            <BoxContainer display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center" gap="2px">
                  <Typography variant="text_xxl" fontWeight="700">
                    Mon CV
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ margin: "15px 0px" }} />
              <Box display="flex" flexDirection="column" gap="20px">
                <Box>
                  <Typography fontSize="14px" fontWeight="600" lineHeight="22.4px">
                    Expériences professionnelles
                  </Typography>
                </Box>
                {userDetail?.experiences?.length > 0 ? (
                  <Stepper nonLinear orientation="vertical" sx={stepperStyle}>
                    {userDetail?.experiences?.map((step, index) => (
                      <Step active={true}>
                        <StepButton icon={<LensIcon sx={{ marginLeft: "5px" }} fontSize="10" />} color={step.end_date ? "#000B30" : colors.main} disableRipple>
                          <StepLabel sx={{ paddingTop: "0rem" }}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
                              <Box>
                                <Typography sx={{ display: "inline", fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>{step?.job_title} à </Typography>
                                <Typography sx={{ display: "inline", fontSize: "14px", fontWeight: "700", lineHeight: "22.4px" }}>{step.societe_name}</Typography>
                              </Box>
                              <Typography fontSize="12px" fontWeight="400" lineHeight="19.2px" color="#666D83">
                                | {moment(step.start_date).format("MMMM YYYY")} - {step.end_date ? moment(step.end_date).format("MMMM YYYY") : "jusqu'à maintenant"}
                              </Typography>
                            </Box>
                          </StepLabel>
                        </StepButton>
                        <StepContent>
                          <Box>
                            <Typography fontSize="14px" fontWeight="400" lineHeight="22.4px" color="#2E3755">
                              {step.detail}
                            </Typography>
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  <Box>
                    <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "600" }}>
                      Contenu vide
                    </Typography>
                  </Box>
                )}

                <Box display="flex" gap="8px" flexDirection="column">
                  <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "22.4px" }}>Compétences</Typography>
                  {userDetail?.skills?.length > 0 ? (
                    <Box flexWrap="wrap" display="flex" alignItems="center" gap="10px">
                      {userDetail?.skills?.map((soft) => (
                        <MyChip variant="skill" label={soft.name} />
                      ))}
                    </Box>
                  ) : (
                    <Box>
                      <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "600" }}>
                        Contenu vide
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </BoxContainer>
            <BoxContainer display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="text_xxl" fontWeight="700">
                  🏆 Certifications
                </Typography>
              </Box>
              <Divider sx={{ margin: "15px 0px" }} />
              <Box display="flex" flexDirection="column" gap="20px">
                {userDetail?.certificateDocuments?.map((cerf) => (
                  <Box key={cerf.id} display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography fontWeight="600" fontSize="14px" lineHeight="160%">
                        {cerf?.certificate_document_type?.name}
                      </Typography>
                      <Typography fontSize="12px" fontWeight="400" lineHeight="160%" color="#666D83">
                        {moment(cerf.expiration_date).format("MMMM YYYY")}
                      </Typography>
                    </Box>

                    <Box display="flex">
                      <ButtonSquare onClick={() => window.open(cerf.url_file, "_blank")} action="download-blue" />
                    </Box>
                  </Box>
                ))}
              </Box>
            </BoxContainer>
            <BoxContainer display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="text_xxl" fontWeight="700">
                  📚 Scolarité
                </Typography>
              </Box>
              <Divider sx={{ margin: "15px 0px" }} />
              <Box display="flex" flexDirection="column" gap="20px">
                {userDetail?.schoolDocuments?.map((cerf) => (
                  <Box key={cerf.id} display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography fontWeight="600" fontSize="14px" lineHeight="160%">
                        {cerf?.school_document_type?.name}
                      </Typography>
                      <Typography fontSize="12px" fontWeight="400" lineHeight="160%" color="#666D83">
                        {moment(cerf.obtained_date).format("MMMM YYYY")}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <ButtonSquare onClick={() => window.open(cerf.files[0].file_url, "_blank")} action="download-blue" />
                    </Box>
                  </Box>
                ))}
              </Box>
            </BoxContainer>
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default DetailsProfileInterim;
