import React from "react";
import { useField, Form, FormikProps, Formik } from "formik";
import { TextField, Box, InputAdornment } from "@mui/material";
import downloadIcon from "../../imgs/imgV2/download.svg";
import addColoredIcon from "../../imgs/imgV2/plusColoredIcon.svg";
import downloadGrayIcon from "../../imgs/imgV2/downloadGray.svg";
import XIcon from "../../imgs/imgV2/xiCON.svg";
import downloadBlueIcon from "../../imgs/imgV2/downloadColored.svg";
import deleteIcon from "../../imgs/imgV2/deleteIcon.svg";
import AddIcon from "../../imgs/imgV2/plusIcon.svg";
import kmIcon from "../../imgs/imgV2/km.svg";
import AnsIcon from "../../imgs/imgV2/ansIcon.svg";
import euroIcon from "../../imgs/imgV2/euroIcon.svg";
import perHourIcon from "../../imgs/imgV2/PerHour.svg";
import searchIcon from "../../imgs/imgV2/RechercheIcon.svg";
function MyTextField({ disabled, setFieldValue, placeholder, endAdornment, startAdornment, value, name, error, ...props }) {
  const icons = {};
  icons["download"] = downloadGrayIcon;
  icons["km"] = kmIcon;
  icons["hour"] = perHourIcon;
  icons["ans"] = AnsIcon;
  icons["euro"] = euroIcon;
  icons["search"] = searchIcon;
  return (
    <Box display="flex" flexDirection="column">
      <TextField
        name={name}
        disabled={disabled}
        fullWidth
        value={value}
        onChange={(e) => setFieldValue(name, e.target.value)}
        InputProps={{
          endAdornment: endAdornment ? (
            <InputAdornment position="end">
              <img src={icons[`${endAdornment}`]} alt="start" />
            </InputAdornment>
          ) : null,
          startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment && <img src={icons[`${startAdornment}`]} alt="end" />}</InputAdornment> : null,
        }}
        placeholder={placeholder}
        {...props}
        error={error}
      />
    </Box>
  );
}
export default MyTextField;
