import React, { useEffect, useState } from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { chipSelector } from "../../utils/chipSelector";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { Box, TextField, Button, Typography, Checkbox, IconButton, Select, MenuItem, FormControl, OutlinedInput, InputLabel, FormControlLabel, Alert, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../theme/Colors";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik, ErrorMessage } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import axios from "axios";
import { Urls } from "../../api/Urls";
import { id } from "date-fns/locale";
import * as yup from "yup";
import { json, useNavigate } from "react-router-dom";
import { ArrowBack, ArrowBackIosOutlined, ArrowForward, ArrowLeft, ArrowRight, CheckBox, ErrorSharp, Flag } from "@mui/icons-material";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { ToastContainer, toast } from "react-toastify";
import MyChip from "../../components/ui/MyChip";

function PropositionCommercial() {
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    siret: yup.number("ce champ doit comporter un nombre").typeError("ce champ doit comporter un nombre").max(99999999999999, "un maximum de 14 chiffres"),
    rib: yup.number("ce champ doit comporter un nombre").typeError("ce champ doit comporter un nombre").max(999999999999999999999999999, "un maximum de 27 chiffres"),
    email: yup.string("ce champ doit etre un texte").email("ce champ doit etre un email"),
  });
  const columns = [
    { field: "id", headerName: "Id demande", width: 90, headerClassName: "grid-header" },
    {
      field: "entreprise.id",
      headerName: "Id entreprise",
      width: 150,
      renderCell: (params) => {
        if (params.row.enterprise.id === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.enterprise.id;
        }
      },
    },
    {
      field: "entreprise.name",
      headerName: "Entreprise",
      width: 180,
      renderCell: (params) => {
        if (params.row.enterprise.name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.enterprise.name;
        }
      },
    },
    {
      field: "created_at",
      headerName: "Date de la demande",
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (params.row.created_at === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.created_at;
        }
      },
    },
    {
      field: "control_status",
      headerName: "Status",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (params.row.control_status === "TREATED") {
          return <MyChip variant="ended_offer" label="Traitée" />;
        } else {
          return <MyChip variant="rejected_offer" label="Non traitée" />;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "start",
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="8px" alignItems="center" justifyContent="center">
            {params.row.control_status !== "TREATED" && (
              <Button
                onClick={() => {
                  setOpen(true);
                  setId(params.row.id);
                }}
                variant="mj_primary_md"
              >
                Traiter
              </Button>
            )}
          </Box>
        );
      },
      width: 220,
    },
  ];

  const [finalValues, setFinalValues] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [id, setId] = useState(null);
  const { user } = useAuthContext();
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(0);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [multipleActions, setMultipleActions] = useState("");
  const access_token = user?.access_token;
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIDs, setSelectedIDs] = useState(null);
  const [openParams, setOpenParams] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [status, setStatus] = useState([]);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [total, seTotal] = useState(0);
  const [formes, setFormes] = useState([]);
  const formik = useFormik({
    initialValues: {
      id_entreprise: "",
      id_demande: "",
      name: "",
      checkedAtt: false,
      checkedValid: false,
    },
    onSubmit: () => {
      setPage(1);
      setFinalValues(formik.values);
    },
  });

  useEffect(() => {
    if (rowsPerPage && page) {
      setLoading(true);
      Api.get(Urls.GET_COM_PROPOSITION + `?perPage=${25}`, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          ...(formik.values.name !== "" && { enterprise_name: formik.values.name }),
          ...(formik.values.id_entreprise !== "" && { enterprise_id: formik.values.id_entreprise }),
          ...(formik.values.id_demande !== "" && { id: formik.values.id_demande }),
          ...(status !== "" && { status: status }),
        },
      })
        .then((res) => {
          console.log(res.data);
          setRows(res?.data?.commercial_proposal_notifications);
          setData(res.data);
          setLoading(false);
          seTotal(res?.data?.meta?.total);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [formik.values, change]);
  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.commercial_proposal_notifications);
        setData(res.data);
        setLoading(false);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };
  const customFooter = () => {
    return <Box></Box>;
  };
  const treatDemande = () => {
    Api.patch(
      Urls.TREATE_DEMANDE + id + "/make-as-treated",
      {},
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <Box>
      <ToastContainer />
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des demandes de propositions commerciales{" "}
        </Typography>
      </Box>

      {/* Form Section */}
      <form onSubmit={formik.handleSubmit}>
        <Box className="filter" display="flex" flexDirection="column" gap="1rem">
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Box>
              <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
                Filtre :
              </Typography>
            </Box>

            {/*Search fields  */}
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="entreprise">
                Entreprise
              </label>
              <TextField onChange={formik.handleChange} name="name" placeholder="Entreprise" />
            </Box>
          </Box>

          {/* Checkboxes */}
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: "0.5rem", marginBottom: "0.5rem" }}>
            <Box display="flex" alignItems="center">
              <label htmlFor="state">Etat : </label>
            </Box>

            <FormControlLabel
              label="Traitée"
              control={
                <Checkbox
                  checked={formik.values.checkedAtt}
                  onChange={(e) => {
                    formik.setFieldValue("checkedAtt", e.target.checked);
                    formik.setFieldValue("checkedValid", false);
                    e.target.checked === true ? setStatus("TREATED") : setStatus("");
                  }}
                />
              }
            />
            <FormControlLabel
              label="Non traitée"
              control={
                <Checkbox
                  checked={formik.values.checkedValid}
                  onChange={(e) => {
                    formik.setFieldValue("checkedValid", e.target.checked);
                    formik.setFieldValue("checkedAtt", false);

                    e.target.checked === true ? setStatus("NOT_TREATED") : setStatus("");
                  }}
                />
              }
            />
          </Box>
          <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={open} onClose={() => setOpen(false)}>
            <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
              <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
                Marquer comme traitée
              </Box>
              <Box>
                <Typography display="inline-block" margin="1rem" textAlign="center">
                  Voulez vous vraiment{" "}
                  <Typography display="inline-block" fontWeight="bold">
                    Marquer cette demande comme traitée
                  </Typography>{" "}
                </Typography>
              </Box>
              <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
                <Box margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                  <Button onClick={() => treatDemande()} variant="mj_primary_md">
                    Confirmer
                  </Button>
                </Box>
                <Box onClick={() => setOpen(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                  <Button variant="mj_secondary_md">Annuler</Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Typography fontWeight="bold">Filtres avancés : </Typography>
          <Box alignItems="center" marginBottom="1rem" gap="1rem" display="flex" flexWrap="wrap">
            <Box alignItems="center" gap="0.3rem" display="flex" flexDirection="column">
              <Box alignItems="center" gap="0.3rem" display="flex">
                <TextField placeholder="Id demande" name="id_demande" onChange={formik.handleChange} />
              </Box>
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <TextField placeholder="Id entreprise" name="id_entreprise" onChange={formik.handleChange} />
            </Box>
          </Box>
        </Box>
        {/* End form section */}

        {/* Actions Section */}
      </form>

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box marginBottom="24px" sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1300px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          onCellClick={(e) => {
            console.log(e.colDef);
            if (e.field === "action") {
              setTimeout(() => {
                setFinalValues([""]);
              }, 500);
            }
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          checkboxSelection
          rowCount={total}
          loading={loading}
          page={page - 1}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
          }}
          disableSelectionOnClick
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          paginationMode="server"
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default PropositionCommercial;
