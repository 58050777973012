import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { modalStyle } from "../../theme/ModalStyle";
import { colors } from "../../theme/Colors";
import { Box, Button, Typography, Modal, Alert, Avatar, Divider } from "@mui/material";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import CircularProgress from "@mui/material/CircularProgress";
import TableHead from "@mui/material/TableHead";
import jobIcon from "../../imgs/imgV2/spec.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import RightArrowTimeOrange from "../../imgs/imgV2/RightArrowTimeOrange.svg";
import calendarIcon from "../../imgs/imgV2/calendarIcon.svg";
import XIconTime from "../../imgs/imgV2/XIconTime.svg";

import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import locationIcon from "../../imgs/imgV2/LocationIcon.svg";
import BoxContainer from "../../components/ui/BoxContainer";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import MyChip from "../../components/ui/MyChip";
import moment from "moment";
import { toast } from "react-toastify";
const BlackContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "#000B30", padding: "24px", borderRadius: "6px" }}>
      {children}
    </Box>
  );
};
function DetailOffre({ id }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { user } = useAuthContext();
  const [detailOffre, setDetailOffre] = useState({});
  const access_token = user?.access_token;
  const [agenda, setAgenda] = useState([]);
  const [succes, setSucces] = useState("");
  const [error, setError] = useState("");
  const [panier, setPanier] = useState([]);
  const [loc, setLoc] = useState([]);
  const [loading, setLoading] = useState(true);

  //modal functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setError("");
    setOpen(false);
  };
  const translationDay = {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
  };
  const arangeCalendar = (calendarOBJ) => {
    let days = [
      { id: 1, day_name: "monday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 2, day_name: "tuesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 3, day_name: "wednesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 4, day_name: "thursday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 5, day_name: "friday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 6, day_name: "saturday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 7, day_name: "sunday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
    ];
    let daysToUse = [
      { id: 1, day_name: "monday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 2, day_name: "tuesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 3, day_name: "wednesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 4, day_name: "thursday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 5, day_name: "friday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 6, day_name: "saturday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 7, day_name: "sunday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
    ];
    for (let index = 0; index < days.length; index++) {
      for (let i = 0; i < calendarOBJ.length; i++) {
        if (calendarOBJ[i]?.day_name === days[index]?.day_name && calendarOBJ[i]?.day_name === calendarOBJ[i + 1]?.day_name) {
          daysToUse[index] = { id: calendarOBJ[i]?.id, day_name: days[index]?.day_name, start_time1: calendarOBJ[i]?.start_time, end_time1: calendarOBJ[i]?.end_time, start_time2: calendarOBJ[i + 1]?.start_time, end_time2: calendarOBJ[i + 1]?.end_time };
          i++;
          index++;
        }
        if (calendarOBJ[i]?.day_name === days[index]?.day_name && calendarOBJ[i]?.day_name !== calendarOBJ[i + 1]?.day_name) {
          daysToUse[index] = { id: calendarOBJ[i]?.id, day_name: days[index]?.day_name, start_time1: calendarOBJ[i]?.start_time, end_time1: calendarOBJ[i]?.end_time, start_time2: "", end_time2: "" };
        }
      }
    }
    return daysToUse;
  };
  // get detail offer by ID AND PEFECT CANDIDATURES
  useEffect(() => {
    Api.get(`${Urls.GET_DETAILS_OFFRE}${id}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res, "data from el gharbi");
        setDetailOffre(res?.data?.offer);
        setPanier(res?.data?.offer?.baskets);
        setLoc(res?.data?.data?.offre?.location);
        setAgenda(arangeCalendar(res?.data?.offer?.weekly_planner));
        setLoading(false);

        console.log(arangeCalendar(res?.data?.offer?.weekly_planner), "agenda");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Box>
      {!loading && (
        <Box display="flex" gap="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
          <Box display="flex" flexDirection="column" gap="24px" flex={2}>
            <BoxContainer>
              <Box marginBottom="21px">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  📊 Informations
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" gap="10px">
                <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                  {detailOffre?.job_title}
                </Typography>
                <Box display="flex" flexDirection="row" gap="8px" alignItems="start">
                  <img src={jobIcon} style={{ marginTop: "3px" }} alt="job-icon" />
                  <Box>
                    <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                      {detailOffre?.metier?.name}
                    </Typography>
                    <Typography fontSize="12px" fontWeight="400" lineHeight="160%" color="#4A526C">
                      {detailOffre?.experience_years_wanted} ans d’expérience
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" gap="8px" alignItems="start">
                  <img src={calendarIcon} style={{ marginTop: "3px" }} alt="job-icon" />
                  <Box display="flex" gap="8px">
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        {moment(detailOffre?.start_date).format("DD MMMM YYYY")}
                      </Typography>
                    </Box>
                    <Box>
                      <img src={arrowRightIcon} alt="icon-point" />
                    </Box>
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        {moment(detailOffre?.end_date).format("DD MMMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" gap="8px" alignItems="start">
                  <img src={locationIcon} style={{ marginTop: "3px" }} alt="job-icon" />
                  <Box display="flex" gap="8px">
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        {detailOffre?.location_address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </BoxContainer>
            {/* Taux horaire */}
            <BoxContainer>
              <Box marginBottom="21px">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  💸 Taux horaire
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="10px">
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="text_normal" color="#4A526C">
                    Brut
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600">
                    {detailOffre?.basic_hourly_rate}€
                  </Typography>
                </Box>
                <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="text_normal" color="#4A526C">
                    Weekend
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600">
                    {detailOffre?.weekend_hourly_rate}€
                  </Typography>
                </Box>
                <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="text_normal" color="#4A526C">
                    Nuit
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600">
                    {detailOffre?.night_hourly_rate}€
                  </Typography>
                </Box>
                <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="text_normal" color="#4A526C">
                    Heures intempéries
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600">
                    {detailOffre?.severe_weather_hourly_rate}€
                  </Typography>
                </Box>
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="21px">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  🛒 Panier par jour
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="10px">
                {panier?.map((itemPanier, index) => (
                  <>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography variant="text_normal" color="#4A526C">
                        {itemPanier.name}
                      </Typography>
                      <Typography variant="text_normal" fontWeight="600">
                        {itemPanier.value}€
                      </Typography>
                    </Box>
                    {index !== panier?.length - 1 && <Divider sx={{ backgroundColor: "#EBEBEE" }} />}
                  </>
                ))}
              </Box>
            </BoxContainer>
            <Box display="flex" flexDirection="column" gap="2px">
              <BlackContainer>
                <Box sx={{ marginBottom: "21px" }}>
                  <Typography variant="text_normal" fontSize="20px" fontWeight="700" color="white" lineHeight="32px">
                    🗓️ Agenda
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="space-between" gap="16px">
                  {agenda.map((day, index) => (
                    <>
                      <Box key={day.index} display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography fontWeight="400" sx={{ fontSize: { xs: "12px", md: "12px", sm: "12px", lg: "14px" }, width: { lg: "80px", xs: "70px", sm: "70px", md: "70px" } }} color={day?.start_time1 || day?.start_time2 ? "white" : "#4A526C"}>
                          {translationDay[`${day?.day_name}`]}
                        </Typography>

                        {day?.start_time1 ? (
                          <Box justifyContent="center" display="flex" sx={{ flexDirection: { xs: "column", sm: "column", lg: "row", md: "column" }, width: { xs: "80px", sm: "150px", md: "150px", lg: "150px" }, alignItems: { xs: "center", lg: "inherit", md: "center", sm: "center" } }} gap="5px">
                            <Typography textAlign="center" variant="text_xs" fontWeight="400" color="white">
                              {day?.start_time1?.split(":")[0] + ":" + day?.start_time1?.split(":")[1]}
                            </Typography>
                            <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "inherit" } }}>
                              <img src={RightArrowTimeOrange} width="12.5px" alt="" />
                            </Box>
                            <Typography textAlign="center" variant="text_xs" fontWeight="400" color="white">
                              {day?.end_time1?.split(":")[0] + ":" + day?.end_time1?.split(":")[1]}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ width: { xs: "80px", sm: "150px", md: "150px", lg: "150px" } }} display="flex" justifyContent="center">
                            <img src={XIconTime} height="20px" alt="" />
                          </Box>
                        )}
                        {day?.start_time2 ? (
                          <Box justifyContent="center" display="flex" sx={{ flexDirection: { xs: "column", sm: "column", lg: "row", md: "column" }, width: { xs: "80px", sm: "150px", md: "150px", lg: "150px" }, alignItems: { xs: "center", lg: "inherit", md: "center", sm: "center" } }} gap="5px">
                            <Typography textAlign="center" variant="text_xs" fontWeight="400" color="white">
                              {day?.start_time2?.split(":")[0] + ":" + day?.start_time2?.split(":")[1]}
                            </Typography>
                            <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "inherit" } }}>
                              <img src={RightArrowTimeOrange} width="12.5px" alt="" />
                            </Box>{" "}
                            <Typography textAlign="center" variant="text_xs" fontWeight="400" color="white">
                              {day?.end_time2?.split(":")[0] + ":" + day?.end_time2?.split(":")[1]}
                            </Typography>
                          </Box>
                        ) : (
                          <Box display="flex" sx={{ width: { xs: "80px", sm: "150px", md: "150px", lg: "150px" } }} justifyContent="center">
                            <img src={XIconTime} height="20px" alt="" />
                          </Box>
                        )}
                      </Box>
                      {index !== agenda.length - 1 && <Divider sx={{ backgroundColor: "#1C2647" }} />}
                    </>
                  ))}
                </Box>
              </BlackContainer>
            </Box>{" "}
          </Box>
          <Box display="flex" flexDirection="column" gap="24px" flex={5}>
            <BoxContainer>
              <Box marginBottom="20px" display="flex">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  ☑️ Tâches à réaliser
                </Typography>
              </Box>
              <Divider sx={{ marginBottom: "20px" }} />
              <Box marginBottom="24px">
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "160%" }}>{detailOffre?.tasks_to_do}</Typography>
              </Box>
              <Box marginBottom="8px">
                <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                  Compétences
                </Typography>
              </Box>
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.skills?.map((skill) => (
                  <>
                    <Box sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}>
                      <MyChip variant="skill" label={skill?.name} key={skill?.id} />
                    </Box>
                    <Box sx={{ display: { xs: "inherit", lg: "none", md: "none", sm: "none" } }}>
                      <MyChip variant="skill" label={skill?.name?.slice(0, 30) + "..."} key={skill?.id} />
                    </Box>
                  </>
                ))}
                {detailOffre?.skills?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Aucune Compétence requise</Typography>}
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="20px">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  📚 Diplômes requis
                </Typography>
              </Box>

              <Divider sx={{ marginBottom: "20px" }} />
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.school_document_types?.map((cert) => (
                  <MyChip variant="skill" label={cert?.name} key={cert?.id} />
                ))}
                {detailOffre?.school_document_types?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Aucun diplôme requis</Typography>}
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="20px">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  🏆 Certifications / Habilitations obligatoires
                </Typography>
              </Box>
              <Divider sx={{ marginBottom: "20px" }} />
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.certificate_document_types?.map((cert) => (
                  <MyChip variant="skill" label={cert?.name} key={cert?.id} />
                ))}
                {detailOffre?.certificate_document_types?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Aucune Certification requise</Typography>}
              </Box>
            </BoxContainer>
            <BoxContainer>
              <Box marginBottom="20px">
                <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                  🛠 Équipements fournis
                </Typography>
              </Box>
              <Divider sx={{ marginBottom: "20px" }} />
              <Box display="flex" gap="10px" flexWrap="wrap">
                {detailOffre?.equipment?.map((eq) => (
                  <MyChip variant="skill" label={eq?.name} key={eq?.id} />
                ))}
                {detailOffre?.equipment?.length === 0 && <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", textAlign: "center" }}>Pas d'équipements founis</Typography>}
              </Box>
            </BoxContainer>
          </Box>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  /*<Box display="flex" direction="row" justifyContent="space-between" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" }, "&>:nth-of-type(2)": { xs: { order: "1" }, md: { order: "2" } }, "&>:nth-of-type(1)": { xs: { order: "2" } } }}>
      {/* CONTENT*
      <Box sx={{ margin: { xs: "1rem 0rem 0rem 0rem", md: "1rem" } }} flex={3}>
        <MainTitle title="Détail  de l'offre" size="h4" />
        {succes ? (
          <Alert
            sx={{
              border: `1px solid  ${colors.mjGreenOutline}`,
              borderRadius: "12px",
              marginBottom: "1.5rem",
            }}
            severity="success"
          >
            {succes}
          </Alert>
        ) : null}
        {loading === false ? (
          <Box>
            {detailOffre ? (
              <>
                <ContentBox style={{ marginBottom: "1rem", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Box>
                    <Typography variant="h5" fontWeight="500" sx={{ margin: "0rem 0rem 0.5rem 0rem", overflowWrap: "break-word", whiteSpace: "line", maxWidth: "600px", display: "block" }}>
                      {detailOffre.intitule_poste}
                    </Typography>
                    <MainTitle size="h5" margin="0rem 0rem 1rem 0rem" title={detailOffre?.entreprise?.name} />
                    <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                      <Typography fontWeight="500">Secteur d'activité</Typography>
                      <Typography variant="text_gray">{detailOffre?.secteur_activite?.name}</Typography>
                    </Box>
                    <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                      <Typography fontWeight="500">Métier</Typography>
                      <Typography variant="text_gray">{detailOffre?.metier?.name ? detailOffre?.metier?.name : "Aucun métier"}</Typography>
                    </Box>
                    <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                      <Typography sx={{ overflowWrap: "break-word", whiteSpace: "line", maxWidth: "600px", display: "block" }} fontWeight="500">
                        Description
                      </Typography>
                      <Typography variant="text_gray">{detailOffre.description ? detailOffre.description : "Aucune description disponible"}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Avatar sx={{ width: "10rem", height: "10rem" }}>
                      <img src={detailOffre?.entreprise?.logo ? detailOffre?.entreprise?.logo : IconUser} style={{ width: "10rem" }} alt="logo entreprise" />
                    </Avatar>
                  </Box>
                </ContentBox>
                <ContentBox style={{ marginBottom: "1rem" }}>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Taux horaire</Typography>
                    <Typography variant="text_gray">{detailOffre?.taux_horaire}€/h</Typography>
                  </Box>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Panier</Typography>
                    <Stack direction="row">
                      {panier.length !== 0 ? (
                        panier.map((panier) => (
                          <Typography key={panier.id} sx={{ marginRight: "15px" }} variant="text_gray">
                            {panier.name} {panier.value} €.{" "}
                          </Typography>
                        ))
                      ) : (
                        <Typography variant="text_gray">Aucun Panier</Typography>
                      )}
                    </Stack>
                  </Box>
                </ContentBox>
                <ContentBox sx={{ marginBottom: "1rem" }}>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Lieu</Typography>
                    <Typography variant="text_gray">{loc ? loc?.name + ", " + loc?.parent?.name : "Non defini"}</Typography>
                  </Box>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Date début de l'offre</Typography>
                    <Typography variant="text_gray">
                      {detailOffre?.planning?.start_date_time ? (
                        <>{detailOffre?.planning?.start_date_time.split(" ")[0].split("-")[2] + "/" + detailOffre?.planning?.start_date_time.split(" ")[0].split("-")[1] + "/" + detailOffre?.planning?.start_date_time.split(" ")[0].split("-")[0]}</>
                      ) : (
                        "Non defini"
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Date fin de l'offre</Typography>
                    <Typography variant="text_gray">
                      {detailOffre?.planning?.end_date_time ? <>{detailOffre?.planning?.end_date_time.split(" ")[0].split("-")[2] + "/" + detailOffre?.planning?.end_date_time.split(" ")[0].split("-")[1] + "/" + detailOffre?.planning?.end_date_time.split(" ")[0].split("-")[0]}</> : "Non defini"}
                    </Typography>
                  </Box>
                </ContentBox>
                <ContentBox sx={{ marginBottom: "1rem" }}>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Expérience souhaitée</Typography>
                    <Typography variant="text_gray">{detailOffre.experience_souhaitee === 1 ? detailOffre.number_of_years + " ans" : "Aucune expérience souhaitée"}</Typography>
                  </Box>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Diplôme</Typography>
                    <Typography variant="text_gray">{detailOffre.diplome_souhaite === 0 ? "Non demandé" : detailOffre?.document?.name}</Typography>
                  </Box>
                </ContentBox>
                <ContentBox sx={{ marginBottom: "1.5rem" }}>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Motif</Typography>
                    <Typography variant="text_gray">{detailOffre?.motif?.name ? detailOffre?.motif?.name : "Aucun motif"}</Typography>
                  </Box>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Risques professionnels</Typography>
                    <Stack direction="column">
                      {detailOffre.risque_professionnelles ? (
                        detailOffre.risque_professionnelles.length !== 0 ? (
                          detailOffre.risque_professionnelles.map((risque) => (
                            <Typography key={risque.id} variant="text_gray">
                              {risque.name}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="text_gray">Aucun risque</Typography>
                        )
                      ) : (
                        <Typography variant="text_gray">Aucun risque</Typography>
                      )}
                    </Stack>
                  </Box>
                  <Box sx={{ margin: "0rem 0rem 0.5rem 0rem" }}>
                    <Typography fontWeight="500">Equipements obligatoires</Typography>
                    <Stack direction="column">
                      {detailOffre.equipement_obligatoires ? (
                        detailOffre.equipement_obligatoires.length !== 0 ? (
                          detailOffre.equipement_obligatoires.map((equipement) => (
                            <Typography key={equipement.id} variant="text_gray">
                              {equipement.name}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="text_gray">Aucun equipement obligatoire</Typography>
                        )
                      ) : (
                        <Typography variant="text_gray">Aucun equipement obligatoire</Typography>
                      )}
                    </Stack>
                  </Box>
                </ContentBox>
              </>
            ) : null}
          </Box>
        ) : (
          <Stack sx={{ marginBottom: "30px" }} direction="row" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {succes ? null : (
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ gap: "1.5rem" }}>
            <Button onClick={handleOpen} variant="mj_primary">
              Postuler
            </Button>
            <Button onClick={handleClickIgnorer} variant="mj_secondary">
              Ignorer
            </Button>
          </Stack>
        )}
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
              Postuler a une offre
            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }}>Voulez vous vraiment postuler a cette offre</Typography>
            <Box display="flex" justifyContent="center" gap="1rem" sx={{ marginBottom: "1.5rem" }}>
              <Button onClick={handlePostuler} variant="mj_primary">
                Postuler
              </Button>
              <Button variant="mj_secondary" onClick={handleClose}>
                Annuler
              </Button>
            </Box>
            {error ? (
              <Alert
                sx={{
                  border: `1px solid  ${colors.mjYellowOutline}`,
                  borderRadius: "12px",
                }}
                severity="warning"
              >
                {error}
              </Alert>
            ) : null}
          </Box>
        </Modal>
      </Box>
      <OffreCandidatRightBar />
              </Box> */
}
export default DetailOffre;
