import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Avatar, Divider, Modal, Alert, CircularProgress, Autocomplete } from "@mui/material";
import MjLabel from "../../components/ui/MjLabel";
import DefaultProfilePic from "../../imgs/user.png";
import { useLocation } from "react-router-dom";
import docgrayIcon from "../../imgs/imgV2/DocGray.svg";

import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import MjChip from "../../components/ui/MjChip";
import { ContrastRounded } from "@mui/icons-material";
import { colors } from "../../theme/Colors";
import InterimaireDetails from "./details-Interimaire/InterimaireDetails";
import EntrepriseDetails from "./detailEntreprise/EntrepriseDetails";
import DetailContrat from "./DetailContrat";
import MyChip from "../../components/ui/MyChip";
import { ToastContainer, toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import { modalStyle } from "../../theme/ModalStyle";
import ButtonSquare from "../../components/ui/ButtonSquare";
import MyTextField from "../../components/ui/MyTextField";
import BoxContainer from "../../components/ui/BoxContainer";
import moment from "moment";
import dollarIcon from "../../imgs/imgV2/dollarIcon.svg";
import plusIconBleu from "../../imgs/imgV2/plusIconBlue.svg";
function ContratsAvenantsDetails() {
  const location = useLocation();
  const idContrat = location.pathname.split("/")[4];
  const { user } = useAuthContext();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [action, setAction] = useState("");
  const [openSuspend, setOpenSuspend] = useState(false);

  const [open, setOpen] = useState(false);
  const [contrat, setContrat] = useState({});
  const [change, setChange] = useState(0);
  const [contratD, setContratD] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const access_token = user?.access_token;
  const signerEntreprise = () => {
    Api.patch(
      Urls.SIGNER_CONTRAT_ADMIN + idContrat,
      {
        signed_by_enterprise_at: moment(new Date()).format(),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const signerInteriamire = () => {
    Api.patch(
      Urls.SIGNER_CONTRAT_ADMIN + idContrat,
      {
        signed_by_interim_at: moment(new Date()).format(),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const etats = {
    CURRENT: { label: "En cours", variant: "active_offer" },
    PENDING: { label: "En attente", variant: "pending_contract" },
    CREATING: { label: "En cours de création", variant: "skill" },
    FINISHED: { label: "Terminé", variant: "ended_offer" },
    CANCELED: { label: "Annulé", variant: "rejected_offer" },
    UPCOMING: { label: "À venir", variant: "skill" },
    SUSPENDED: { label: `Suspendu le ${moment(contrat?.suspended_at).format("DD MMMM YYYY")}`, variant: "rejected_offer" },
  };
  const [tabs, setTabs] = useState([
    {
      id: 1,
      name: "Contrat #" + idContrat,
    },
    {
      id: 2,
      name: "Intérimaire",
    },
    {
      id: 3,
      name: "Entreprise",
    },
    {
      id: 4,
      name: "Signature",
    },
  ]);
  const [initialValues, setInitialValues] = useState();
  function Signature({ contrat }) {
    return (
      <Box display="flex" flexDirection="column" gap="24px">
        <Box display="flex" alignItems="center" gap="8px">
          {!contrat?.signed_by_enterprise_at && (
            <Button onClick={() => signerEntreprise()} variant="mj_primary_md">
              Signer entreprise
            </Button>
          )}
          {!contrat?.signed_by_interim_at && (
            <Button onClick={() => signerInteriamire()} variant="mj_primary_md">
              Signer intérimaire
            </Button>
          )}
        </Box>
        <Box display="flex" gap="17px" flexWrap="wrap">
          <Box flex={1}>
            {contrat?.signed_by_enterprise_at && (
              <BoxContainer>
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box display="flex" justifyContent="space-between">
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      {contrat?.enterprise?.name} #{contrat?.enterprise?.id}
                    </Typography>
                    <MyChip variant="ended_offer" label="Entreprise" />
                  </Box>
                  <Box display="flex" gap="5px">
                    <Typography variant="text_clickable">Signé le</Typography>
                    <Typography variant="text_clickable">{contrat?.signed_by_enterprise_at?.split(" ")[0]} à </Typography>
                    <Typography variant="text_clickable">
                      {contrat?.signed_by_enterprise_at?.split(" ")[1].split(":")[0]}h{contrat?.signed_by_enterprise_at?.split(" ")[1].split(":")[1]}
                    </Typography>
                  </Box>
                </Box>
              </BoxContainer>
            )}
          </Box>
          <Box flex={1}>
            {contrat?.signed_by_interim_at && (
              <BoxContainer>
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box display="flex" justifyContent="space-between">
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      {contrat?.user?.first_name} {contrat?.user?.last_name} #{contrat?.user?.id}
                    </Typography>
                    <MyChip variant="ended_offer" label="Intérimaire" />
                  </Box>
                  <Box display="flex" gap="5px">
                    <Typography variant="text_clickable">Signé le</Typography>
                    <Typography variant="text_clickable">{contrat?.signed_by_interim_at?.split(" ")[0]} à </Typography>
                    <Typography variant="text_clickable">
                      {contrat?.signed_by_interim_at?.split(" ")[1].split(":")[0]}h{contrat?.signed_by_interim_at?.split(" ")[1].split(":")[1]}
                    </Typography>
                  </Box>
                </Box>
              </BoxContainer>
            )}
          </Box>
          <Box flex={1}>
            {contrat?.signed_by_admin_at && (
              <BoxContainer>
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box display="flex" justifyContent="space-between">
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      {contrat?.signed_by_admin?.first_name} {contrat?.signed_by_admin?.last_name} #{contrat?.signed_by_admin?.id}
                    </Typography>
                    <MyChip variant="ended_offer" label="Admin" />
                  </Box>
                  <Box display="flex" gap="5px">
                    <Typography variant="text_clickable">Signé le</Typography>
                    <Typography variant="text_clickable">{contrat?.signed_by_admin_at?.split(" ")[0]} à </Typography>
                    <Typography variant="text_clickable">
                      {contrat?.signed_by_admin_at?.split(" ")[1].split(":")[0]}h{contrat?.signed_by_admin_at?.split(" ")[1].split(":")[1]}
                    </Typography>
                  </Box>
                </Box>
              </BoxContainer>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
  const contentSelector = (contentId) => {
    switch (contentId) {
      case 1:
        return <DetailContrat contract={contratD} id={idContrat} />;
      case 2:
        return <InterimaireDetails modify={false} idInterimaire={contratD?.user?.id} />;
      case 3:
        return <EntrepriseDetails modify={false} idEntreprise={contratD?.enterprise?.id} />;
      case 4:
        return <Signature contrat={contratD} />;
      default:
        return <DetailContrat contract={contratD} id={idContrat} />;
    }
  };
  useEffect(() => {
    Api.get(Urls.GET_CONTRAT_DETAILS + idContrat, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res?.data?.contract);
        setContratD(res?.data?.contract);
        setInitialValues({
          oid_ent: res?.data?.contract?.enterprise_coffreo_oid ? res?.data?.contract?.enterprise_coffreo_oid : "",
          oid_int: res?.data?.contract?.interim_coffreo_oid ? res?.data?.contract?.interim_coffreo_oid : "",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setInitialValues({
          oid_ent: "",
          oid_int: "",
        });
      });
  }, [change]);

  //signer contrat

  const signerContrat = (Values) => {
    Api.patch(
      Urls.SIGNER_CONTRAT_ADMIN + idContrat,
      {
        interim_coffreo_oid: Values.oid_int,
        enterprise_coffreo_oid: Values.oid_ent,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        setChange(!change);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const Suspend = () => {
    Api.post(Urls.SUSPEND_ADMIN_CONTRAT + idContrat + "/suspend", null, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenSuspend(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.resposne?.data?.message);
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <ToastContainer />
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" mb>
          Gestion des contrats et avenants
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box marginBottom="1rem" display="flex" gap="10px" alignItems="center">
            <Box display="flex" alignItems="center" gap="5px">
              <img src={contratD?.contract_type === "INIT" ? docgrayIcon : contratD?.contract_type === "PROLONGATION" ? plusIconBleu : dollarIcon} alt="doc-icon" />
              <Typography variant="text_clickable">{idContrat}</Typography>
            </Box>
            <MyChip variant={etats[`${contratD?.status}`]?.variant} label={etats[`${contratD?.status}`]?.label} />
          </Box>
          {contratD?.status !== "SUSPENDED" && (
            <Button onClick={() => setOpenSuspend(true)} variant="mj_secondary_md">
              Suspendre le contrat
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap="15px">
          <Typography fontSize="15px" fontWeight="600">
            Signé par
          </Typography>
          <Box display="flex" alignItems="center" gap="8px">
            {contratD?.signed_by_admin_at && <MyChip variant="ended_offer" label="Admin" />}
            {contratD?.signed_by_enterprise_at && <MyChip variant="ended_offer" label="Entreprise" />}
            {contratD?.signed_by_interim_at && <MyChip variant="ended_offer" label="Intérmaire" />}
          </Box>
        </Box>
      </Box>
      {!loading && (
        <Box>
          {/* tabs */}
          <Box marginBottom="24px" display="flex" width="100%" sx={{ backgroundColor: "#FAFAFB", borderRadius: "6px", padding: "2px", flexDirection: { md: "row", xs: "column" } }} gap="6px">
            {tabs.map((tab, index) => (
              <Button disableRipple fullWidth key={index} variant={selectedTab === tab.id ? "mj_primary_choice_active_lg" : "mj_primary_choice_lg"} onClick={() => setSelectedTab(tab.id)}>
                <Typography fontSize="20px" mr>
                  {tab.icon}
                </Typography>
                {tab.name}
              </Button>
            ))}
          </Box>
          {selectedTab === 1 && (
            <Box marginBottom="24px">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values) => {
                  signerContrat(values);
                }}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <Box gap="20px" display="flex" flexDirection="column">
                      <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                        <Box>
                          <Box marginBottom="6px">
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                              OID entreprise
                            </Typography>
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                              *
                            </Typography>
                          </Box>
                          <MyTextField disabled={contratD?.enterprise_coffreo_oid ? true : false} setFieldValue={setFieldValue} value={values.oid_ent} name="oid_ent" placeholder="OID Entreprise..." />
                        </Box>
                        <Box>
                          <Box marginBottom="6px">
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                              OID Intérimaire
                            </Typography>
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                              *
                            </Typography>
                          </Box>
                          <MyTextField disabled={contratD?.interim_coffreo_oid ? true : false} setFieldValue={setFieldValue} value={values.oid_int} name="oid_int" placeholder="OID Intérimaire..." />
                        </Box>
                        <Box display="flex" marginTop="30px">
                          <Button type="submit" disabled={contratD?.interim_coffreo_oid && contratD?.enterprise_coffreo_oid ? true : values?.oid_ent && values?.oid_int ? false : true} variant="mj_primary_md">
                            Enregistrer
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
              <Box marginTop="24px" display="flex" flexDirection="column" gap="10px">
                {contratD?.url_contract_interim && (
                  <Box display="flex" alignItems="center" gap="15px">
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>Contrat de mission</Typography>
                    <Button onClick={() => window.open(contratD?.url_contract_interim, "_blank")} variant="mj_primary_md">
                      Télécharger
                    </Button>
                  </Box>
                )}
                {contratD?.url_contract_enterprise && (
                  <Box display="flex" alignItems="center" gap="15px">
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>Contrat de mise à disposition</Typography>
                    <Button onClick={() => window.open(contratD?.url_contract_enterprise, "_blank")} variant="mj_primary_md">
                      Télécharger
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {/* content */}
          {contentSelector(selectedTab)}
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      <Modal open={openSuspend} onClose={() => setOpenSuspend(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Suspendre le contrat
              </Typography>
              <ButtonSquare onClick={() => setOpenSuspend(false)} action="close" />
            </Box>
            <Typography sx={{ fontSize: "15px", marginBottom: "24px", fontWeight: "600", textAlign: "center" }}>Êtes-vous sûr(e) de vouloir suspendre le contrat ?</Typography>
            <Box marginTop="24px" display="flex" gap="8px" justifyContent="flex-end" alignItems="center">
              <Button variant="mj_secondary_md" onClick={() => setOpenSuspend(false)}>
                Annuler
              </Button>
              <Button onClick={() => Suspend()} variant="mj_primary_md">
                {" "}
                Enregistrer
              </Button>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>

      {/* Details section */}
    </Box>
  );
}

export default ContratsAvenantsDetails;
