import React, { useEffect, useState } from "react";
import { Chip, IconButton, FormControlLabel, Switch, TextField, FormHelperText, Select, Typography, Box, MenuItem, Button, InputLabel, FormControl } from "@mui/material";
import { useFormik } from "formik";
import OutlinedInput from "@mui/material/OutlinedInput";
import { DateTimePicker } from "@mui/x-date-pickers";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import moment from "moment/moment";
import * as yup from "yup";
import MjChip from "../../components/ui/MjChip";
import { colors } from "../../theme/Colors";
import CvFormation from "./CvFormation";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useLocation } from "react-router";
import { useAuthContext } from "../../hooks/useAuthContext";
import { parseISO } from "date-fns";
function OffreDetails() {
  const [risques, setRisques] = useState();
  const { user } = useAuthContext();
  const [secteurs, setSecteurs] = useState();
  const [metier, setMetier] = useState();
  const access_token = user?.access_token;
  const [document, setDocuments] = useState();
  const [departement, setDepartements] = useState();
  const [ville, setVilles] = useState();
  const [change, setChange] = useState(0);
  const location = useLocation();
  const [equipement, setEquipement] = useState();
  const idOffre = location.pathname.split("/")[4];
  const [motifs, setMotifs] = useState();
  const [panier, setPanier] = useState([]);
  const [offre, setOffre] = useState();
  const [isaddnigpanier, setisAddingPanier] = useState(false);
  const [formations, setformations] = useState([
    {
      name: "test name",
      school_name: "test school name",
      location: "test location",
      startDate: "10-12-2022",
      end_date: "15-12-2022",
    },
  ]);

  useEffect(() => {
    Api.get(Urls.GET_DETAILS_OFFRES + idOffre, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setOffre(res.data.offre);
        console.log(res.data.offre);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleClickAjoutPanier = () => {
    if (formik.values.name == "" || formik.values.montant == "") {
      formik.setFieldValue("checkedPanier", false);
      setisAddingPanier(false);
    } else {
      const pan = [
        {
          name: formik.values.name,
          value: formik.values.montant,
        },
      ];
      setPanier((panier) => panier.concat(pan));
      formik.setFieldValue("checkedPanier", false);
      setisAddingPanier(false);
      formik.setFieldValue("name", formik.initialValues.name);
      formik.setFieldValue("montant", formik.initialValues.montant);
      console.log("done");
    }
  };
  console.log(panier);
  const validationSchema = yup.object().shape({
    intitule_poste: yup.string().required("ce champ est obligtoire"),
    secteur_act: yup.string().required("ce champ est obligtoire"),
    metier: yup.string().required("ce champ est obligtoire"),
    lieu: yup.string().required("ce champ est obligtoire"),
    motif: yup.string().required("ce champ est obligtoire"),
    nom: yup.string().when("motif", {
      is: "remplacement",
      then: yup.string().required("ce champ est obligtoire"),
    }),

    prenom: yup.string().when("motif", {
      is: "remplacement",
      then: yup.string().required("ce champ est obligtoire"),
    }),

    checkedExpSouhaite: yup.boolean(),

    exp_souhaite: yup.number().when("checkedExpSouhaite", {
      is: true,
      then: yup.number().typeError("ce champ doit conternir un nombre").min(0, "valeur non acceptée").required("ce champ est obligatoire"),
    }),

    checkedDip: yup.boolean(),

    diplome: yup.string().when("checkedDip", {
      is: true,
      then: yup.string().required("ce champ est obligatoire"),
    }),

    checkedDiplomeSou: yup.boolean(),

    diplome_sou: yup.string().when("checkedDiplomeSou", {
      is: true,
      then: yup.string().required("ce champ est obligatoire"),
    }),
    checkedEquipementFournis: yup.boolean(),

    equipement_fournis: yup.array().when("checkedEquipementFournis", {
      is: true,
      then: yup.array().of(yup.string()).min(1, "ce champ est obligatoire"),
    }),

    departement: yup.string().required("ce champ est obligatoire"),

    ville: yup.string().required("ce champ est obligatoire"),

    checkedRisques: yup.boolean(),

    risques: yup.array().when("checkedRisques", {
      is: true,
      then: yup.array().of(yup.string()).min(1, "ce champ est obligatoire"),
    }),

    checkedFacteur: yup.boolean(),

    facteurs: yup.array().when("checkedFacteur", {
      is: true,
      then: yup.array().of(yup.string()).min(1, "ce champ est obligatoire"),
    }),

    checkedEquipementAfournir: yup.boolean(),

    equipement_a_fournis: yup.array().when("checkedEquipementAfournir", {
      is: true,
      then: yup.array().of(yup.string()).min(1, "ce champ est obligatoire"),
    }),

    taches: yup.string().required("ce champ est obligatoire"),

    start_date_time: yup.date().typeError("entrez une date valide").min(new Date(), "La date doit être supérieur à la date d'aujourd'hui").required("Ce champ est obligatoire"),

    end_date_time: yup.date().typeError("entrez une date valide").required("ce champ est obligatoire"),

    taux_horaire: yup.number().typeError("entrer un nombre valide").min(0, "entrer un nombre valide").required("ce champ est obligatoire"),

    checkedPanier: yup.boolean(),

    name: yup.string().when("checkedPanier", {
      is: true,
      then: yup.string().required("ce champ est obligatoire"),
    }),
    montant: yup.number().when("checkedPanier", {
      is: true,
      then: yup.number().typeError("entrer un nombre valide").min(0, "entrer un nombre valide").required("ce champ est obligatoire"),
    }),
  });
  const handleDeletePanier = (name) => {
    const Newpanier = panier.filter((panier) => panier.name !== name);
    setPanier(Newpanier);
  };
  const formik = useFormik({
    initialValues: {
      intitule_poste: "",
      secteur_act: "",
      metier: "",
      lieu: "",
      motif: "",
      nom: "",
      prenom: "",
      checkedExpSouhaite: false,
      exp_souhaite: "",
      checkedDip: false,
      diplome: "",
      checkedDiplomeSou: false,
      diplome_sou: "",
      checkedEquipementFournis: false,
      equipement_fournis: [],
      ville: "",
      departement: "",
      checkedRisques: false,
      risques: [],
      checkedFacteur: false,
      facteurs: [],
      taches: "",
      checkedEquipementAfournir: false,
      equipement_a_fournis: [],
      start_date_time: "",
      end_date_time: "",
      taux_horaire: "",
      checkedPanier: false,
      name: "",
      montant: "",
    },
    onSubmit: (values) => {
      let arrayEquipement = [];
      let arrayRisques = [];
      for (let index = 0; index < risques?.length; index++) {
        for (let index1 = 0; index1 < formik?.values?.risques?.length; index1++) {
          if (risques[index]?.name === formik?.values?.risques[index1]) {
            arrayRisques.push(risques[index]?.id);
          }
        }
      }
      for (let index = 0; index < equipement?.length; index++) {
        for (let index1 = 0; index1 < formik?.values?.equipement_fournis?.length; index1++) {
          if (equipement[index]?.name === formik?.values?.equipement_fournis[index1]) {
            arrayEquipement.push(equipement[index]?.id);
          }
        }
      }
      Api.patch(
        Urls.PATCH_DETAILS_OFFRE + idOffre,
        {
          paniers: panier,
          intitule_poste: formik.values.intitule_poste,
          secteur_activite_id: formik.values.secteur_act,
          metier_id: formik.values.metier,
          motif_id: formik.values.motif,
          description: formik.values.taches,
          place_of_mission: formik.values.lieu,
          experience_souhaitee: formik.values.checkedExpSouhaite ? 1 : 0,
          number_of_years: formik.values.exp_souhaite,
          diplome_souhaite: formik.values.checkedDiplomeSou ? 1 : 0,
          document_id: formik.values.diplome_sou,
          taux_horaire: formik.values.taux_horaire,
          ville_id: formik.values.ville,
          risque_professionnelles_ids: arrayRisques,
          equipement_obligatoires_ids: arrayEquipement,
          start_date_time: moment(formik?.values?.start_date_time).format().split("T")[0] + " " + moment(formik?.values?.start_date_time).format().split("T")[1].split(".")[0],
          end_date_time: moment(formik?.values?.end_date_time).format().split("T")[0] + " " + moment(formik?.values?.end_date_time).format().split("T")[1].split(".")[0],
        },
        {
          headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          console.log(res);
          setChange(change + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validationSchema: validationSchema,
  });
  // get offer detail
  useEffect(() => {
    Api.get(Urls.GET_DETAILS_OFFRES + idOffre, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setOffre(res.data.offre);
        console.log(res.data);
        Api.get(Urls.GET_VILLE_DEPARTEMENT + "-" + res.data.offre.location.parent.id, {
          headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        })
          .then((response) => {
            setVilles(response.data.data.villes);
            formik.setFieldValue("ville", res.data.offre.location.id);
            console.log(res.data.offre.location);
            console.log(response.data.data.villes);
          })
          .catch((err) => {
            console.log(err);
          });
        let array = [];
        let arrayRisques = [];
        for (let index = 0; index < res?.data?.offre?.equipement_obligatoires?.length; index++) {
          array.push(res?.data?.offre?.equipement_obligatoires[index].name);
        }
        for (let index = 0; index < res?.data?.offre?.risque_professionnelles?.length; index++) {
          arrayRisques.push(res?.data?.offre?.risque_professionnelles[index].name);
        }
        console.log("array of ids", array);
        formik.setFieldValue("intitule_poste", res?.data?.offre?.intitule_poste);
        formik.setFieldValue("secteur_act", res?.data?.offre?.secteur_activite?.id);
        formik.setFieldValue("metier", res?.data?.offre?.metier?.id);
        formik.setFieldValue("departement", res?.data?.offre?.location?.parent?.id);
        formik.setFieldValue("motif", res?.data?.offre?.motif?.id);
        formik.setFieldValue("checkedExpSouhaite", res?.data?.offre?.experience_souhaitee === 0 ? false : true);
        formik.setFieldValue("exp_souhaite", res?.data?.offre?.number_of_years ? res?.data?.offre?.number_of_years : "");
        formik.setFieldValue("checkedEquipementFournis", res?.data?.offre?.equipement_obligatoires?.length === 0 ? false : true);
        formik.setFieldValue("equipement_fournis", array);
        formik.setFieldValue("checkedRisques", res?.data?.offre?.risque_professionnelles?.length === 0 ? false : true);
        formik.setFieldValue("risques", arrayRisques);
        formik.setFieldValue("taches", res?.data?.offre?.description);
        formik.setFieldValue("taux_horaire", res?.data?.offre?.taux_horaire);
        formik.setFieldValue("start_date_time", parseISO(res?.data?.offre?.start_date_time));
        formik.setFieldValue("end_date_time", parseISO(res?.data?.offre?.end_date_time));
        formik.setFieldValue("checkedDiplomeSou", res?.data?.offre?.diplome_souhaite === 0 ? false : true);
        formik.setFieldValue("diplome_sou", res?.data?.offre?.document?.id);
        formik.setFieldValue("lieu", res?.data?.offre?.place_of_mission);

        setPanier(res?.data?.offre.paniers);
        if (res?.data?.offre?.metier?.id !== null) {
          Api.get(Urls.GET_DOCUMENTS + res?.data?.offre?.metier?.id, {
            headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
          })
            .then((resp) => {
              setDocuments(resp.data.data.documents);
              console.log("documents", resp.data.data.documents);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [change]);
  // get secteurs dactiviter
  useEffect(() => {
    Api.get(Urls.GET_SECTEUR_ACTIVITE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setSecteurs(res.data.data.secteur_activites);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // get metiers
  useEffect(() => {
    Api.get(Urls.GET_METIERS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setMetier(res.data.data.metiers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // get metiers
  useEffect(() => {
    Api.get(Urls.GET_DEPARTEMENT, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setDepartements(res.data.data.departements);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // get motifs
  useEffect(() => {
    Api.get(Urls.GET_MOTIFS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setMotifs(res.data.data.motifs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //get equipement obligatoire
  // get motifs
  useEffect(() => {
    Api.get(Urls.GET_EQUIPEMENT, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setEquipement(res.data.data.equipement_obligatoires);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //get risques pro
  useEffect(() => {
    Api.get(Urls.GET_RISQUE_PROFESSIONNEL, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setRisques(res.data.data.risque_professionnelles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // get documents
  const getDocument = (id) => {
    Api.get(Urls.GET_DOCUMENTS + id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((resp) => {
        setDocuments(resp.data.data.documents);
        formik.setFieldValue("exp_souhaite", "");

        console.log("documents", resp.data.data.documents);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // get villes
  const getVilles = (id) => {
    Api.get(Urls.GET_VILLE_DEPARTEMENT + "-" + id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((resp) => {
        setVilles(resp.data.data.villes);
        formik.setFieldValue("ville", "");
        console.log("villes", resp.data.data.villes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box sx={{ maxWidth: "800px" }}>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1" mb>
          Gestion des offres
        </Typography>
        <Typography variant="h5" component="h1">
          Détail de l'offre
        </Typography>
      </Box>
      <Box sx={{ margin: "1rem 0rem" }}>
        <Typography variant="detail_title">
          Entreprise : <Typography variant="detail_value">{offre?.entreprise_name}</Typography>
        </Typography>
      </Box>
      <Box sx={{ margin: "1rem 0rem" }}>
        <Typography variant="detail_title">
          Offre<Typography variant="detail_value">Motion graphic designer - crée le : {offre?.created_at?.split("T")[0]}</Typography>
        </Typography>
      </Box>
      <Box sx={{ margin: "1rem 0rem" }}>
        <Typography variant="detail_title">
          Etat : <Typography variant="detail_value">Non vérifié</Typography>
        </Typography>
      </Box>
      <Box sx={{ margin: "1rem 0rem" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Typography>intitulé du poste*</Typography>
            <Box sx={{ margin: "0.3rem 0rem" }}>
              <TextField fullWidth error={formik.touched.intitule_poste && Boolean(formik.errors.intitule_poste)} placeholder="Poste" value={formik.values.intitule_poste} name="intitule_poste" onChange={formik.handleChange} />
              {formik.touched.intitule_poste && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.intitule_poste}</FormHelperText>}
            </Box>
          </Box>
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Typography>Secteur d'activité*</Typography>
            <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
              {formik.values.secteur_act ? null : <InputLabel id="demo-multiple-name-label">Secteur</InputLabel>}
              <Select
                placeholder="Secteur d'activité"
                error={formik.touched.secteur_act && Boolean(formik.errors.secteur_act)}
                fullWidth
                value={formik.values.secteur_act}
                onChange={(e) => formik.setFieldValue("secteur_act", e.target.value)}
                input={formik.values.secteur_act ? null : <OutlinedInput sx={{ color: "black" }} label="Secteur" />}
              >
                {secteurs?.map((secteur) => (
                  <MenuItem key={secteur.id} value={secteur.id} selected={formik.values.secteur_act === secteur.id ? true : false}>
                    {secteur.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.secteur_act && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.secteur_act}</FormHelperText>}
          </Box>
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Typography>Sélectionner un métier*</Typography>
            <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
              {formik.values.metier ? null : <InputLabel id="demo-multiple-name-label">Métier</InputLabel>}
              <Select
                placeholder="Secteur d'activité"
                error={formik.touched.metier && Boolean(formik.errors.metier)}
                fullWidth
                value={formik.values.metier}
                onChange={(e) => {
                  formik.setFieldValue("metier", e.target.value);
                  getDocument(e.target.value);
                }}
                input={formik.values.metier ? null : <OutlinedInput sx={{ color: "black" }} label="metier" />}
              >
                {metier?.map((metier) => (
                  <MenuItem key={metier.id} value={metier.id}>
                    {metier.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.metier && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.metier}</FormHelperText>}
          </Box>
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Typography>Slectionner un département*</Typography>
            <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
              {formik.values.departement ? null : <InputLabel id="demo-multiple-name-label">département</InputLabel>}
              <Select
                placeholder="département"
                error={formik.touched.departement && Boolean(formik.errors.departement)}
                fullWidth
                value={formik.values.departement}
                onChange={(e) => {
                  formik.setFieldValue("departement", e.target.value);
                  getVilles(e.target.value);
                }}
                input={formik.values.departement ? null : <OutlinedInput sx={{ color: "black" }} label="departement" />}
              >
                {departement?.map((dep) => (
                  <MenuItem key={dep.id} value={dep.id}>
                    {dep.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.departement && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.departement}</FormHelperText>}
          </Box>
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Typography>Selectionner une ville*</Typography>
            <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
              {formik.values.ville ? null : <InputLabel id="demo-multiple-name-label">ville</InputLabel>}
              <Select
                placeholder="Secteur d'activité"
                error={formik.touched.ville && Boolean(formik.errors.ville)}
                fullWidth
                value={formik.values.ville}
                onChange={(e) => formik.setFieldValue("ville", e.target.value)}
                input={formik.values.ville ? null : <OutlinedInput sx={{ color: "black" }} label="ville" />}
              >
                {ville?.map((ville) => (
                  <MenuItem key={ville.id} value={ville.id}>
                    {ville.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.ville && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.ville}</FormHelperText>}
          </Box>
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Typography>Sélectionner un lieu*</Typography>
            <Box sx={{ margin: "0.3rem 0rem" }}>
              <TextField fullWidth error={formik.touched.lieu && Boolean(formik.errors.lieu)} placeholder="Lieu" value={formik.values.lieu} name="lieu" onChange={formik.handleChange} />
              {formik.touched.lieu && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.lieu}</FormHelperText>}
            </Box>
          </Box>
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Typography>Sélectionner un motif*</Typography>
            <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
              {formik.values.motif ? null : <InputLabel id="demo-multiple-name-label">Motif</InputLabel>}
              <Select placeholder="Motif" error={formik.touched.motif && Boolean(formik.errors.motif)} fullWidth value={formik.values.motif} onChange={(e) => formik.setFieldValue("motif", e.target.value)} input={formik.values.motif ? null : <OutlinedInput sx={{ color: "black" }} label="metier" />}>
                {motifs?.map((motif) => (
                  <MenuItem key={motif.id} value={motif.id}>
                    {motif.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.motif && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.motif}</FormHelperText>}
          </Box>
          {formik.values.motif === "remplacement" && (
            <>
              <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
                <Typography>Nom de la personne à remplacer*</Typography>
                <Box sx={{ margin: "0.3rem 0rem" }}>
                  <TextField fullWidth error={formik.touched.nom && Boolean(formik.errors.nom)} placeholder="Nom" value={formik.values.nom} name="nom" onChange={formik.handleChange} />
                  {formik.touched.nom && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.nom}</FormHelperText>}
                </Box>
              </Box>
              <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
                <Typography>Prénom de la personne à remplacer*</Typography>
                <Box sx={{ margin: "0.3rem 0rem" }}>
                  <TextField fullWidth error={formik.touched.prenom && Boolean(formik.errors.prenom)} placeholder="Prénom" value={formik.values.prenom} name="prenom" onChange={formik.handleChange} />
                  {formik.touched.prenom && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.prenom}</FormHelperText>}
                </Box>
              </Box>
            </>
          )}
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Expérience souhaitée*</Typography>
              <FormControlLabel
                checked={formik.values.checkedExpSouhaite}
                name="checkedExpSouhaite"
                control={
                  <Switch
                    checked={formik.values.checkedExpSouhaite}
                    onChange={(e) => {
                      formik.setFieldValue("checkedExpSouhaite", e.target.checked);
                      if (e.target.checked === false) {
                        formik.setFieldValue("exp_souhaite", formik.initialValues.exp_souhaite);
                      }
                    }}
                  />
                }
              />
            </Box>
            {formik.values.checkedExpSouhaite === true ? (
              <>
                <TextField fullWidth error={formik.touched.exp_souhaite && Boolean(formik.errors.exp_souhaite)} placeholder="Expérience" value={formik.values.exp_souhaite} name="exp_souhaite" onChange={formik.handleChange} />
                {formik.touched.exp_souhaite && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.exp_souhaite}</FormHelperText>}
              </>
            ) : null}
          </Box>
          {/* <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Diplôme obligatoire*</Typography>
              <FormControlLabel
                name="checkedDip"
                control={
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue("checkedDip", e.target.checked);
                      if (e.target.checked === false) {
                        formik.setFieldValue("diplome", formik.initialValues.diplome);
                      }
                    }}
                  />
                }
              />
            </Box>
            {formik.values.checkedDip === true ? (
              <Box>
                <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
                  {formik.values.diplome ? null : <InputLabel id="demo-multiple-name-label">Diplôme</InputLabel>}
                  <Select
                    placeholder="Diplôme"
                    error={formik.touched.motif && Boolean(formik.errors.diplome)}
                    fullWidth
                    value={formik.values.diplome}
                    onChange={(e) => formik.setFieldValue("diplome", e.target.value)}
                    input={formik.values.diplome ? null : <OutlinedInput sx={{ color: "black" }} label="Diplôme" />}
                  >
                    <MenuItem key="motif 1" value="CACES" selected>
                      CACES
                    </MenuItem>
                    <MenuItem key="motif 2" value="motif 2">
                      Autre diplôme
                    </MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.diplome && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.diplome}</FormHelperText>}
              </Box>
            ) : null}
            </Box>*/}
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Diplôme souhaitée*</Typography>
              <FormControlLabel
                name="checkedDiplomeSou"
                checked={formik.values.checkedDiplomeSou}
                control={
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue("checkedDiplomeSou", e.target.checked);
                      if (e.target.checked === false) {
                        formik.setFieldValue("diplome_sou", formik.initialValues.diplome_sou);
                      }
                    }}
                  />
                }
              />
            </Box>
            {formik.values.checkedDiplomeSou === true ? (
              <Box>
                <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
                  {formik.values.diplome_sou ? null : <InputLabel id="demo-multiple-name-label">Diplôme</InputLabel>}
                  <Select
                    input={formik.values.diplome_sou ? null : <OutlinedInput sx={{ color: "black" }} label="Diplôme" />}
                    placeholder="Diplôme souhaitée"
                    error={formik.touched.diplome_sou && Boolean(formik.errors.diplome_sou)}
                    fullWidth
                    value={formik.values.diplome_sou}
                    onChange={(e) => formik.setFieldValue("diplome_sou", e.target.value)}
                  >
                    {document?.map((doc) => (
                      <MenuItem key={doc.id} value={doc.id}>
                        {doc.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.touched.diplome_sou && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.diplome_sou}</FormHelperText>}
              </Box>
            ) : null}
          </Box>
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Equipements fournis*</Typography>
              <FormControlLabel
                name="checkedEquipementFournis"
                checked={formik.values.checkedEquipementFournis}
                control={
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue("checkedEquipementFournis", e.target.checked);
                      if (e.target.checked === false) {
                        formik.setFieldValue("equipement_fournis", formik.initialValues.equipement_fournis);
                      }
                    }}
                  />
                }
              />
            </Box>
            {formik.values.checkedEquipementFournis === true ? (
              <Box>
                <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
                  {formik.values.equipement_fournis.length != 0 ? null : <InputLabel id="demo-multiple-name-label">Equipements</InputLabel>}
                  <Select
                    input={formik.values.equipement_fournis.length != 0 ? null : <OutlinedInput sx={{ color: "black" }} label="Equipements fou" />}
                    multiple
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip sx={{ backgroundColor: `${colors.main}`, color: "white" }} key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    placeholder="Equipements fournis"
                    error={formik.touched.equipement_fournis && Boolean(formik.errors.equipement_fournis)}
                    fullWidth
                    value={formik.values.equipement_fournis}
                    onChange={(e) => formik.setFieldValue("equipement_fournis", e.target.value)}
                  >
                    {equipement?.map((equ) => (
                      <MenuItem key={equ.id} value={equ.name}>
                        {equ.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.touched.equipement_fournis && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.equipement_fournis}</FormHelperText>}
              </Box>
            ) : null}
          </Box>
          {/*<Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Equipements à fournir*</Typography>
              <FormControlLabel
                name="checkedEquipementAfournir"
                control={
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue("checkedEquipementAfournir", e.target.checked);
                      if (e.target.checked === false) {
                        formik.setFieldValue("equipement_a_fournis", formik.initialValues.equipement_a_fournis);
                      }
                    }}
                  />
                }
              />
            </Box>
            {formik.values.checkedEquipementAfournir === true ? (
              <Box>
                <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
                  {formik.values.equipement_a_fournis.length != 0 ? null : <InputLabel id="demo-multiple-name-label">Equipements</InputLabel>}
                  <Select
                    input={formik.values.equipement_a_fournis.length != 0 ? null : <OutlinedInput sx={{ color: "black" }} label="Equipements fou" />}
                    multiple
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip sx={{ backgroundColor: `${colors.main}`, color: "white" }} key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    placeholder="Equipements a fournir"
                    error={formik.touched.equipement_a_fournis && Boolean(formik.errors.equipement_a_fournis)}
                    fullWidth
                    value={formik.values.equipement_a_fournis}
                    onChange={(e) => formik.setFieldValue("equipement_a_fournis", e.target.value)}
                  >
                    <MenuItem key="motif 1" value="Casque de chantier" selected>
                      Casque de chantier
                    </MenuItem>
                    <MenuItem key="motif 2" value="Bottes de chantier">
                      Bottes de chantier
                    </MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.equipement_a_fournis && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.equipement_a_fournis}</FormHelperText>}
              </Box>
            ) : null}
          </Box>*/}
          <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Risques professionnels*</Typography>
              <FormControlLabel
                name="checkedRisques"
                checked={formik.values.checkedRisques}
                control={
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue("checkedRisques", e.target.checked);
                      if (e.target.checked === false) {
                        formik.setFieldValue("risques", formik.initialValues.risques);
                      }
                    }}
                  />
                }
              />
            </Box>
            {formik.values.checkedRisques === true ? (
              <Box>
                <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
                  {formik.values.risques.length != 0 ? null : <InputLabel id="demo-multiple-name-label">Risques</InputLabel>}
                  <Select
                    input={formik.values.risques.length != 0 ? null : <OutlinedInput sx={{ color: "black" }} label="risques 1" />}
                    multiple
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip sx={{ backgroundColor: `${colors.main}`, color: "white" }} key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    placeholder="risques"
                    error={formik.touched.risques && Boolean(formik.errors.risques)}
                    fullWidth
                    value={formik.values.risques}
                    onChange={(e) => formik.setFieldValue("risques", e.target.value)}
                  >
                    {risques?.map((risque) => (
                      <MenuItem key={risque.id} value={risque.name}>
                        {risque.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.touched.risques && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.risques}</FormHelperText>}
              </Box>
            ) : null}
          </Box>
          {/*<Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Facteurs de pénibilité*</Typography>
              <FormControlLabel
                name="checkedFacteur"
                control={
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue("checkedFacteur", e.target.checked);
                      if (e.target.checked === false) {
                        formik.setFieldValue("facteurs", formik.initialValues.facteurs);
                      }
                    }}
                  />
                }
              />
            </Box>
            {formik.values.checkedFacteur === true ? (
              <Box>
                <FormControl fullWidth sx={{ margin: "0.3rem 0rem 0rem 0rem" }}>
                  {formik.values.facteurs.length != 0 ? null : <InputLabel id="demo-multiple-name-label">Facteurs</InputLabel>}
                  <Select
                    input={formik.values.facteurs.length != 0 ? null : <OutlinedInput sx={{ color: "black" }} label="facteurs 1" />}
                    multiple
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip sx={{ backgroundColor: `${colors.main}`, color: "white" }} key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    placeholder="facteurs"
                    error={formik.touched.facteurs && Boolean(formik.errors.facteurs)}
                    fullWidth
                    value={formik.values.facteurs}
                    onChange={(e) => formik.setFieldValue("facteurs", e.target.value)}
                  >
                    <MenuItem key="motif 1" value="facteurs physiques" selected>
                      Facteurs physiques
                    </MenuItem>
                    <MenuItem key="motif 2" value=" Autre facteurs">
                      Autre facteurs
                    </MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.facteurs && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.facteurs}</FormHelperText>}
              </Box>
            ) : null}
          </Box> */}
          <Box sx={{ margin: "1rem 0rem" }}>
            <Typography>Tâches à réaliser*</Typography>
            <Box sx={{ width: "100%", margin: "0.3rem 0rem" }}>
              <TextField value={formik.values.taches} name="taches" error={formik.touched.taches && Boolean(formik.errors.taches)} onChange={formik.handleChange} type="input" placeholder="Détails" id="post_name" multiline fullWidth columns={5} rows={4} autoComplete="off" />
              {formik.touched.taches && <FormHelperText sx={{ color: "red" }}>{formik.errors.taches}</FormHelperText>}
            </Box>
          </Box>
          <Box sx={{ margin: "1rem 0rem" }}>
            <Box
              display="flex"
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
              }}
              justifyContent="space-between"
            >
              <Box sx={{ margin: { xs: "1rem 0rem", sm: "1rem 0rem", md: "0rem 1rem 0rem 0rem", lg: "0rem 1rem 0rem 0rem" }, width: "100%" }}>
                <Typography sx={{ marginBottom: "0.3rem" }}>date de début*</Typography>
                <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    fullWidth
                    components={{ OpenPickerIcon: CalendarMonthIcon }}
                    value={formik.values.start_date_time}
                    onChange={(value) => formik.setFieldValue("start_date_time", value)}
                    maxDateTime={formik.values.end_date_time}
                    InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          fullWidth
                          type="time"
                          {...params}
                          error={formik.touched.start_date_time && Boolean(formik.errors.start_date_time)}
                          name="start_date_time"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "jour/mois/année - heure:min",
                          }}
                        />
                      );
                    }}
                  ></DateTimePicker>
                </LocalizationProvider>
                {formik.touched.start_date_time && <FormHelperText sx={{ color: `red` }}>{formik?.errors?.start_date_time}</FormHelperText>}
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ marginBottom: "0.3rem" }}> date de fin*</Typography>
                <LocalizationProvider sx={{ width: "100" }} adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    fullWidth
                    components={{ OpenPickerIcon: CalendarMonthIcon }}
                    value={formik.values.end_date_time}
                    error={false}
                    onChange={(value) => formik.setFieldValue("end_date_time", value)}
                    minDateTime={formik.values.start_date_time}
                    InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          fullWidth
                          type="time"
                          {...params}
                          error={formik.touched.end_date_time && Boolean(formik.errors.end_date_time)}
                          name="end_date_time"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "jour/mois/année - heure:min",
                          }}
                        />
                      );
                    }}
                  ></DateTimePicker>
                </LocalizationProvider>
                {formik.touched.end_date_time && <FormHelperText sx={{ color: `red` }}>{formik?.errors?.end_date_time}</FormHelperText>}
              </Box>
            </Box>
            <Box flexDirection="column" sx={{ margin: "1rem 0rem" }}>
              <Typography>Taux horaire*</Typography>
              <Box sx={{ margin: "0.3rem 0rem" }}>
                <TextField fullWidth error={formik.touched.taux_horaire && Boolean(formik.errors.taux_horaire)} placeholder="Taux horaire €" value={formik.values.taux_horaire} name="taux_horaire" onChange={formik.handleChange} />
                {formik.touched.taux_horaire && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.taux_horaire}</FormHelperText>}
              </Box>
            </Box>
            {panier?.map((e) => (
              <Box key={e.name} sx={{ margin: "0.45rem", padding: "1rem", backgroundColor: `rgb(205, 255, 205)`, borderRadius: "15px" }}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Typography sx={{ lineHeight: "26px" }} fontWeight="500">
                    {e.name} {" - "}
                    {e.value}€
                  </Typography>
                  <Box display="flex">
                    <IconButton onClick={() => handleDeletePanier(e.name)} sx={{ margin: "0rem 1rem", padding: "0.5rem", backgroundColor: `lightgreen`, "&:hover": { bgcolor: `lightgreen` } }}>
                      <DeleteOutlinedIcon sx={{ color: `${colors.main}` }} fontSize="medium" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}
            <Box display="flex" flexDirection="column" sx={{ margin: "1rem 0rem" }}>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="mj_primary"
                  onClick={() => {
                    formik.setFieldValue("checkedPanier", true);
                    setisAddingPanier(true);
                  }}
                >
                  Ajouter un panier
                </Button>
              </Box>
              {isaddnigpanier === true ? (
                <>
                  <Box display="flex" sx={{ width: "100%", margin: "1rem 0rem", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                    <Box flexDirection="column" sx={{ width: "100%", margin: { xs: "1rem 0rem", sm: "1rem 0rem", md: "0rem 1rem 0rem 0rem", lg: "0rem 1rem 0rem 0rem" } }}>
                      <Typography>Panier*</Typography>
                      <Box sx={{ width: "100%", margin: "0.3rem 0rem" }}>
                        <TextField fullWidth error={formik.touched.name && Boolean(formik.errors.name)} placeholder="name" value={formik.values.name} name="name" onChange={formik.handleChange} />
                        {formik.touched.name && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.name}</FormHelperText>}
                      </Box>
                    </Box>
                    <Box flexDirection="column" sx={{ width: "100%", margin: { xs: "1rem 0rem 0rem 0rem", sm: "1rem 0rem 0rem 0rem", md: "0rem", lg: "0rem" } }}>
                      <Typography>Taux panier*</Typography>
                      <Box sx={{ width: "100%", margin: "0.3rem 0rem" }}>
                        <TextField fullWidth error={formik.touched.montant && Boolean(formik.errors.montant)} placeholder="Montant €" value={formik.values.montant} name="montant" onChange={formik.handleChange} />
                        {formik.touched.montant && <FormHelperText sx={{ color: "red" }}>{formik?.errors?.montant}</FormHelperText>}
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="center" gap="1.5rem">
                    <IconButton sx={{ padding: "0.5rem", bgcolor: `lightgreen`, "&:hover": { bgcolor: `lightgreen` } }} onClick={handleClickAjoutPanier}>
                      <DoneRoundedIcon sx={{ color: `${colors.main}` }} fontSize="large" />
                    </IconButton>

                    <IconButton
                      sx={{ padding: "0.5rem", bgcolor: `lightgreen`, "&:hover": { bgcolor: `lightgreen}` } }}
                      onClick={() => {
                        formik.setFieldValue("checkedPanier", false);
                        setisAddingPanier(false);
                      }}
                    >
                      <ClearRoundedIcon sx={{ color: `${colors.main}` }} fontSize="large" />
                    </IconButton>
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
          <Box>
            <Button type="submit" variant="mj_primary">
              Enregistrer
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default OffreDetails;
