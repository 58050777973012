import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Avatar, Modal, Alert, Divider } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
import DefaultProfilePic from "../../../imgs/user.png";
import { colors } from "../../../theme/Colors";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../api/Api";
import drapeauIcon from "../../../imgs/drapeau.png";
import cofaceIcon from "../../../imgs/coface.png";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import MjChip from "../../../components/ui/MjChip";
import InfosEntreprise from "./InfosEntreprise";
import InfoAdministrativeEntreprise from "./InfoAdministrativeEntreprise";
import InfosRecruteurC from "./InfosRecruteurC";
import PropositionCom from "./PropositionCom";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import BoxContainer from "../../../components/ui/BoxContainer";
import { ToastContainer, toast } from "react-toastify";
import MyChip from "../../../components/ui/MyChip";

function EntrepriseDetails({ modify = true, idEntreprise = null }) {
  const location = useLocation();
  const [status, setStatus] = useState(location?.state?.status);
  const [change, setChange] = useState(0);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const idEnt = idEntreprise ? idEntreprise : location.pathname.split("/")[4];
  const userId = location?.state?.user_id;
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [updateBord, setUpdateBoard] = useState(false);
  const [userFetch, setUSerFetch] = useState();

  const [entreprise, setEntreprise] = useState({});
  const [userA, setUser] = useState({});

  useEffect(() => {
    Api.get(Urls.GET_ENTREPRISE + idEnt, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res, "infos entreprise admin");
        setUSerFetch(res?.data);
      })
      .catch((err) => {
        console.log(err, "error entreprise infos");
      });
  }, [change]);

  useEffect(() => {
    if (modify) {
      if (!userId) navigate("/admin/gestion-des-entreprises");
    }
  }, []);

  //accept multiple users
  const handleAccept = () => {
    Api.patch(
      Urls.PATCH_STATUS_ENTREPRISE,
      {
        userId: userId,
        verification_data_status: "VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setStatus("VALID");
        setChange(change + 1);
        setOpen(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  // remove multiple users
  const handleDelete = () => {
    Api.patch(
      Urls.PATCH_STATUS_ENTREPRISE,
      {
        user_ids: [userId],
        verification_data_status: "NOT_VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setChange(!change);
        setStatus("NOT_VALID");
        setOpen(false);
        setTimeout(() => {
          window.scroll(0, 0);
        }, 500);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <ToastContainer />
      {/* Header Section */}
      {modify && (
        <Box className="header" sx={{ marginBottom: "1rem" }}>
          <Typography variant="h4" component="h1" mb>
            Gestion des entreprises
          </Typography>
          <Box gap="8px" display="flex" justifyContent="flex-end" alignItems="center">
            {userFetch?.enterprise?.user?.verification_data_status !== "VALID" && (
              <Button
                onClick={() => {
                  setOpen(true);
                  setAction("valider");
                }}
                variant="mj_primary_md"
              >
                Valider le compte
              </Button>
            )}
            {userFetch?.enterprise?.user?.verification_data_status !== "NOT_VALID" && (
              <Button
                onClick={() => {
                  setOpen(true);
                  setAction("refuser");
                }}
                variant="mj_secondary_md"
              >
                Refuser
              </Button>
            )}
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <Typography variant="h5" component="h1">
              Détails de l'entreprise
            </Typography>
            {userFetch?.enterprise?.user?.is_active === 0 && <MyChip variant="rejected_offer" label="Compte supprimé" />}
          </Box>
        </Box>
      )}
      <Box marginBottom="24px" display="flex" gap="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
        <Box flex={1}>
          <InfosEntreprise modify={modify} userFetch={userFetch} change={change} setChange={setChange} idEnt={idEnt} userId={userId} />
        </Box>
        <Box flex={1}>
          <InfoAdministrativeEntreprise modify={modify} userFetch={userFetch} change={change} setChange={setChange} idEnt={idEnt} userId={userId} />
        </Box>
        <Box flex={1}>
          <InfosRecruteurC modify={modify} userFetch={userFetch} change={change} setChange={setChange} idEnt={idEnt} userId={userId} />
        </Box>
      </Box>
      <Box display="flex" gap="24px" marginBottom="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
        <Box flex={1}>
          <PropositionCom change={change} setChange={setChange} userFetch={userFetch} idEnt={idEnt} userId={userId} modify={true} />
        </Box>
        <Box flex={1}>
          {modify && (
            <BoxContainer>
              <Box marginBottom="20px">
                <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                  Raccourcis
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="15px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Ajouter une demande</Typography>
                  <ButtonSquare
                    onClick={() =>
                      navigate(location.pathname + "/offre/creer-offre", {
                        state: {
                          entreprise: userFetch,
                        },
                      })
                    }
                    action="green-raccourcis"
                  />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Liste des demandes</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-offres", { state: { id: idEnt } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Liste contrats </Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-contrats", { state: { id: idEnt } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Gestion des problèmes</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-problemes", { state: { id: userFetch?.enterprise?.name } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Gestions des suspensions</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-suspensions", { state: { id: userFetch?.enterprise?.name } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Gestions des entretiens</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-entretiens", { state: { id: idEnt } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Déclarations des heures</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/declarer-les-heures", { state: { id: idEnt } })} action="orange-raccourcis" />
                </Box>
                <Divider />{" "}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Factures</Typography>
                  <ButtonSquare onClick={() => navigate("/admin/gestion-des-factures", { state: { id: userFetch?.enterprise?.name } })} action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Statistiques</Typography>
                  <ButtonSquare action="orange-raccourcis" />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ fontSize: "14px", lineHeight: "160%", fontWeight: "600" }}>Historique des intérimaires</Typography>
                  <ButtonSquare onClick={() => navigate(location?.pathname + "/historique-des-interimaires", { state: { id: idEnt } })} action="orange-raccourcis" />
                </Box>
              </Box>
            </BoxContainer>
          )}
        </Box>
        <Box flex={1}></Box>
      </Box>

      {/* Details section */}
      {/*<Box>
        <Avatar sx={{ width: "150px", height: "150px", marginBottom: "1rem" }}>
          <img style={{ width: "150px" }} src={DefaultProfilePic} alt="" />
        </Avatar>
        <Box sx={{ margin: "1rem 0rem" }}>
          <Typography variant="detail_orange">Entreprise</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="1rem" sx={{ margin: "1rem 0rem" }}>
          <Typography variant="detail_title">
            Eentreprise ID : <Typography variant="detail_value">{entreprise?.id}</Typography>
          </Typography>
          <MjChip name={status ? status : ""} />
        </Box>

        {/* Information de l'entreprise section 
        <Box className="section">
          <Box sx={{ margin: "1rem 0rem" }}>
            <Typography variant="detail_title">Informations de l'entreprise</Typography>
          </Box>
          <Box sx={{ marginLeft: "2rem" }}>
            <Typography>
              Nom de l'entreprise : <Typography variant="detail_value">{entreprise?.name ? entreprise?.name : "-"}</Typography>
            </Typography>
            <Typography>
              Email de l'entreprise : <Typography variant="detail_value">{entreprise?.email_de_recrutement ? entreprise?.email_de_recrutement : "-"}</Typography>
            </Typography>
            <Typography>
              RIB : <Typography variant="detail_value">{entreprise?.rib ? entreprise.rib : "-"}</Typography>
            </Typography>
            <Typography>
              Numéro de siret : <Typography variant="detail_value">{entreprise?.siret ? entreprise.siret : "-"}</Typography>
            </Typography>
            <Typography>
              Email de contact : <Typography variant="detail_value">{entreprise?.email_de_contact ? entreprise?.email_de_contact : "-"}</Typography>
            </Typography>
            <Typography>
              Email de facturation : <Typography variant="detail_value">{entreprise?.email_de_facturation ? entreprise.email_de_facturation : "-"}</Typography>
            </Typography>
            <Typography>
              Forme juridique : <Typography variant="detail_value">{entreprise?.forme_juridique?.name ? entreprise.forme_juridique?.name : "-"}</Typography>
            </Typography>
            <Typography>
              Code APE : <Typography variant="detail_value">{entreprise?.code_ape ? entreprise.code_ape : "-"}</Typography>
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "2rem", marginTop: "2rem", marginBottom: "2rem" }} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <img src={drapeauIcon} />
              <img src={cofaceIcon} />
            </Box>
            <Typography>
              Solvabilité de l'entreprise : <Typography variant="detail_value">lorem</Typography>
            </Typography>
          </Box>
        </Box>

        {/* Information supplémentaires section*
        <Box className="section">
          <Box sx={{ margin: "1rem 0rem" }}>
            <Typography variant="detail_title">Informations supplémentaires</Typography>
          </Box>
          <Box sx={{ marginLeft: "2rem" }}>
            <Typography>
              Adresse de l'entreprise : <Typography variant="detail_value">{entreprise?.address ? entreprise.address : "-"}</Typography>
            </Typography>
            <Typography>
              Tel: <Typography variant="detail_value">{entreprise?.phone_number ? entreprise.phone_number : "-"}</Typography>
            </Typography>
            <Typography>
              Numéro TVA : <Typography variant="detail_value">{entreprise?.num_tva ? entreprise.num_tva : "-"}</Typography>
            </Typography>
            <Typography>
              Site web : <Typography variant="detail_value">{entreprise?.web_site ? entreprise.web_site : "-"}</Typography>
            </Typography>
          </Box>
        </Box>

        {/* Information du recruteur section
        <Box className="section">
          <Box sx={{ margin: "1rem 0rem" }}>
            <Typography variant="detail_title">Informations du recruteur</Typography>
          </Box>
          <Box sx={{ marginLeft: "2rem" }}>
            <Typography>
              Nom : <Typography variant="detail_value">{userA?.first_name ? userA.first_name : "-"}</Typography>
            </Typography>
            <Typography>
              Prénom : <Typography variant="detail_value">{userA?.last_name ? userA.last_name : "-"}</Typography>
            </Typography>
            <Typography>
              Poste : <Typography variant="detail_value">{userA?.poste ? userA.poste : "-"}</Typography>
            </Typography>
            <Typography>
              Email de recutement : <Typography variant="detail_value">{userA?.email ? userA.email : "-"}</Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
  */}
      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={open} onClose={() => setOpen(false)}>
        <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
          <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
            {action} le compte entreprise
          </Box>
          <Box>
            <Typography display="inline-block" margin="1rem" textAlign="center">
              Voulez vous vraiment{" "}
              <Typography display="inline-block" fontWeight="bold">
                {action} le compte entreprise
              </Typography>{" "}
            </Typography>
          </Box>
          {success ? (
            <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
              {success}
            </Alert>
          ) : null}
          {error ? <Alert severity="error">{error}</Alert> : null}
          <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
            <Box onClick={() => (action === "valider" ? handleAccept() : handleDelete())} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
              <Button variant="mj_primary_md">{action}</Button>
            </Box>
            <Box onClick={() => setOpen(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
              <Button variant="mj_secondary_md">Annuler</Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* actions section 
      <Box sx={{ margin: "2rem 0rem", display: "flex", gap: "1rem" }}>
        <Button
          onClick={() => {
            setAction("valider");
            setOpen(true);
          }}
          variant="mj_primary"
        >
          Valider le compte
        </Button>
        <Button
          onClick={() => {
            setAction("refuser");
            setOpen(true);
          }}
          variant="mj_secondary"
        >
          Refuser
        </Button>
      </Box>*/}
    </Box>
  );
}

export default EntrepriseDetails;
