import React from "react";
import { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { colors } from "../../theme/Colors";
import IconFox from "../../imgs/imgV2/FoxLogoAdmin.svg";
import * as Yup from "yup";
import MjLabel from "../../components/ui/MjLabel";
import MjErrorText from "../../components/ui/MjErrorText";
import AlertError from "../../components/ui/AlertError";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogin } from "../../hooks/useLogin";
import { useNavigate } from "react-router-dom";

function Login() {
  console.log("localStorage", localStorage.getItem("userAdmin"));
  const { user } = useAuthContext();

  const navigate = useNavigate();
  const { login, errorLogin, isLoading } = useLogin();

  const onSubmitLogin = async (values, errorLogin, isLoading) => {
    await login(values.email, values.password);
  };

  //initial values form state
  const initialValues = {
    email: "",
    password: "",
  };

  //validators form state
  const validationSchema = Yup.object({
    email: Yup.string().email("Format email invalid").required("Ce champ est obligatoire"),
    password: Yup.string().required("Ce champ est obligatoire").min(8, "Entrez au moin 8 caractere"),
  });

  return (
    <Box sx={{ backgroundColor: `${colors.mj_navbar}`, width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ padding: "2.5rem", width: "550px", height: "600px", borderRadius: "24px", margin: "1rem" }} backgroundColor="white">
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "3rem" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <img src={IconFox} height={80} alt="malin job logo" style={{ marginRight: "1rem" }} />
              <Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography fontFamily="Montserrat" fontSize="2.5rem" fontWeight="500">
                    OhMyJob
                  </Typography>
                </Box>
                <Typography fontFamily="Montserrat" fontSize="1.5rem" fontWeight="500">
                  Administrateur
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
          Se connecter
        </Typography>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitLogin}>
          {({ values }) => (
            <Form>
              <Box className="field" sx={{ marginBottom: "1rem" }}>
                <MjLabel htmlFor="email" name="email">
                  Email
                </MjLabel>
                <Field
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiInputBase-input": {
                        padding: "16px 10px",
                      },
                    },
                  }}
                  autoComplete="off"
                  name="email"
                  type="email"
                  placeholder="Email"
                  id="email"
                  as={TextField}
                  fullWidth
                />
                <ErrorMessage name="email" component={MjErrorText} />
              </Box>

              <Box className="field" sx={{ marginBottom: "1rem" }}>
                <MjLabel htmlFor="password" name="password">
                  Password
                </MjLabel>
                <Field
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiInputBase-input": {
                        padding: "16px 10px",
                      },
                    },
                  }}
                  autoComplete="off"
                  name="password"
                  type="password"
                  placeholder="Password"
                  id="password"
                  as={TextField}
                  fullWidth
                />
                <ErrorMessage name="password" component={MjErrorText} />
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center">
                <Button sx={{ height: "3.5rem" }} disabled={isLoading} className="submit" type="submit" variant="mj_primary_md" fullWidth>
                  Se connecter
                </Button>
              </Box>
              {errorLogin && <AlertError>{errorLogin}</AlertError>}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default Login;
