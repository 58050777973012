import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/Theme";
import "react-toastify/dist/ReactToastify.css";

import { AuthContextProvider } from "./context/AuthContext";
import { UserContextProvider } from "./context/UserContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <UserContextProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </UserContextProvider>
      </AuthContextProvider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
