import React from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Stack from "@mui/material/Stack";
import { colors } from "../../../theme/Colors";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Typography, CardContent, Divider, TextField, InputAdornment, Grid, Autocomplete, Modal, Paper, Avatar } from "@mui/material";
// import "dayjs/locale/fr";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import arrowRight from "../../../imgs/imgV2/arrowRightBlavk.svg";
import candIcon from "../../../imgs/imgV2/iconCandidatures.svg";
import searchIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import propositionIcon from "../../../imgs/imgV2/propIcon.svg";
import { modalStyle, modalStyle2 } from "../../../theme/ModalStyle";
import styled from "@emotion/styled";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import frLocale from "date-fns/locale/fr";
import dollarIcon from "../../../imgs/imgV2/dollarIcon.svg";
import plusIconBleu from "../../../imgs/imgV2/plusIconBlue.svg";
import { ToastContainer, toast } from "react-toastify";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import docgrayIcon from "../../../imgs/imgV2/DocGray.svg";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import BoxContainer from "../../../components/ui/BoxContainer";
import MyTextField from "../../../components/ui/MyTextField";
import MyChip from "../../../components/ui/MyChip";
import CheckedCircle from "../../../components/ui/CheckedCircle";
import moment from "moment";
import * as Yup from "yup";
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
function ReleverDheures({ contratD, id }) {
  const [change, setChange] = useState(false);
  const ficheDePaie = [1, 2, 3, 4, 5, 6];
  const [open, setOpen] = useState(false);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [contrats, setContrats] = useState([]);
  const [openDayDetal, setOpenDayDetail] = useState({
    etat: false,
    date: null,
  });
  const [openForm, setOpenForm] = useState({
    etat: false,
    date: null,
  });
  const [data, setData] = useState({});
  const [openDeclareHours, setOpenDeclareHours] = useState(false);
  const [contrat, setContrat] = useState(contratD);
  function getStartOfWeek(date) {
    const dayOfWeek = date.getDay(); // Get the day of the week (0-6)
    const diff = (dayOfWeek + 6) % 7; // Calculate the difference to Monday

    date.setDate(date.getDate() - diff); // Subtract the difference from the given date

    return date; // Return the starting date of the week (Monday)
  }

  function getWeekDates(date) {
    const startOfWeek = getStartOfWeek(date); // Get the starting date of the week
    const weekDates = []; // Initialize an array to store the week's dates

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek); // Create a new date object for the current iteration
      currentDate.setDate(currentDate.getDate() + i); // Add the iteration index to get the current date

      weekDates.push(moment(currentDate).format("yyyy-MM-DD")); // Add the current date to the weekDates array
    }

    return weekDates; // Return the array containing all the week's dates
  }
  const [weekToAdd, setWeekToAdd] = useState(getStartOfWeek(new Date()));
  const [isDeclaredHours, setISdeclaredHours] = useState("");
  const [etat, setEtat] = useState("");
  const [loading, setLoading] = useState(true);
  //handle Pagination
  const [date, setDate] = useState();
  const datePicker = useRef();
  const [index, setIndex] = useState(0);
  const validationSchema = Yup.object().shape({
    tauxNormale: Yup.number().typeError("Valeur invalide").min(0, "Valeur invalide").required("Ce champ est obligatoire"),
  });
  useEffect(() => {
    setIndex(index + 1);
    console.log("index", index);
    console.log("date utiliser", date);
    if (date) {
      setTimeout(
        () => {
          const elements = document?.getElementsByClassName("MuiPickersDay-dayWithMargin");
          let element0;
          let element1;
          for (let index = 0; index < elements?.length; index++) {
            const element = elements[index];
            if (element?.textContent === moment(date).format("yyyy-MM-DD").split("-")[2]) {
              console.log(element?.textContent);
            }
            if (element?.tabIndex === 0) {
              element0 = element;
              const parentElement = element?.parentNode;
              parentElement.style.backgroundColor = "#000B30";
              parentElement.classList.remove("not-selected");
              parentElement.classList.add("selected");
              parentElement.style.borderRadius = "6px";
              parentElement.style.padding = "5px 0px";
              const childs = parentElement?.childNodes;
              for (let index = 0; index < childs.length; index++) {
                const elementchild = childs[index];
                elementchild.style.backgroundColor = "transparent";
                elementchild.style.color = "white";
              }
            } else {
              element1 = element;
              if (element?.parentNode !== element0?.parentNode) {
                element.parentNode.classList.remove("selected");
                element.parentNode.classList.add("not-selected");
              }
            }
          }
          const notSelected = document.getElementsByClassName("not-selected");
          console.log("not selected ones", notSelected);
          if (notSelected) {
            for (let index = 0; index < notSelected.length; index++) {
              const element = notSelected[index];
              const children = notSelected[index].childNodes;
              for (let index = 0; index < children.length; index++) {
                const element = children[index];
                element.parentNode.style.backgroundColor = "transparent";
                element.parentNode.style.borderRadius = "0px";
                element.style.backgroundColor = "white";
                element.style.color = "black";
                element.parentNode.style.padding = "0px 0px";
              }
            }
          }
        },
        index > 0 ? 200 : 1000
      );
    }
  }, [date]);

  const declareHoursPost = (values) => {
    Api.post(
      Urls.DECLARE_HOURS + contrat?.id + "/declaredHours",
      {
        date: moment(openForm?.date).format("yyyy-MM-DD"),
        nbr_declared_basic_hours: values?.tauxNormale,
        ...(values?.tauxWeeknd === "" ? { nbr_declared_weekend_hours: 0 } : { nbr_declared_weekend_hours: values?.tauxWeeknd }),
        ...(values?.tauxNuit === "" ? { nbr_declared_night_hours: 0 } : { nbr_declared_night_hours: values?.tauxNuit }),
        ...(values?.tauxIntemperie === "" ? { nbr_declared_severe_weather_hours: 0 } : { nbr_declared_severe_weather_hours: values?.tauxIntemperie }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenForm(false);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const [declaredHoursListe, setDeclaredHoursListe] = useState([]);
  useEffect(() => {
    Api.get(Urls.DECLARED_HOURS + id + "/declaredHours", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        start_date: moment(weekToAdd[0]).format("yyyy-MM-DD"),
        end_date: moment(weekToAdd[6]).format("yyyy-MM-DD"),
      },
    })
      .then((res) => {
        setDeclaredHoursListe(res?.data?.declared_hours);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [weekToAdd, change]);
  const IsChecked = (valeur, date) => {
    if (valeur === "interimaire") {
      console.log("interimaire");
      for (let index = 0; index < declaredHoursListe.length; index++) {
        const element = declaredHoursListe[index];
        if (moment(date).format("yyyy-MM-DD") === moment(element?.date).format("yyyy-MM-DD")) {
          if (element.nbr_declared_basic_hours_by_interim !== null) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    if (valeur === "entreprise") {
      for (let index = 0; index < declaredHoursListe.length; index++) {
        const element = declaredHoursListe[index];
        if (moment(date).format("yyyy-MM-DD") === moment(element?.date).format("yyyy-MM-DD")) {
          if (element.nbr_declared_basic_hours_by_enterprise !== null) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    if (valeur === "admin") {
      for (let index = 0; index < declaredHoursListe.length; index++) {
        const element = declaredHoursListe[index];
        if (moment(date).format("yyyy-MM-DD") === moment(element?.date).format("yyyy-MM-DD")) {
          if (element.nbr_declared_basic_hours_by_admin !== null) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return false;
  };
  const numberHoursDeclared = (valeur, date) => {
    if (valeur === "interimaire") {
      for (let index = 0; index < declaredHoursListe.length; index++) {
        const element = declaredHoursListe[index];
        console.log("number of hours", element.nbr_declared_basic_hours_by_interim);
        if (moment(date).format("yyyy-MM-DD") === moment(element?.date).format("yyyy-MM-DD")) {
          if (element?.nbr_declared_basic_hours_by_interim && element?.nbr_declared_weekend_hours_by_interim && element?.nbr_declared_night_hours_by_interim && element?.nbr_declared_severe_weather_hours_by_interim) {
            return element?.nbr_declared_basic_hours_by_interim + element?.nbr_declared_weekend_hours_by_interim + element?.nbr_declared_night_hours_by_interim + element?.nbr_declared_severe_weather_hours_by_interim;
          } else {
            return null;
          }
        }
      }
    }
    if (valeur === "admin") {
      for (let index = 0; index < declaredHoursListe.length; index++) {
        const element = declaredHoursListe[index];
        if (moment(date).format("yyyy-MM-DD") === moment(element?.date).format("yyyy-MM-DD")) {
          if (element?.nbr_declared_basic_hours_by_admin && element?.nbr_declared_weekend_hours_by_admin && element?.nbr_declared_night_hours_by_admin && element?.nbr_declared_severe_weather_hours_by_admin) {
            return element.nbr_declared_basic_hours_by_admin + element.nbr_declared_severe_weather_hours_by_admin + element.nbr_declared_weekend_hours_by_admin + element.nbr_declared_night_hours_by_admin;
          } else {
            return null;
          }
        }
      }
    }
    return null;
  };
  const [detailHeureDec, setDetailHeureDec] = useState({});
  const [conflict, setConflict] = useState(false);
  const isThereAConflict = (data) => {
    if (data?.nbr_declared_basic_hours_by_admin !== data?.nbr_declared_basic_hours_by_enterprise || data?.nbr_declared_basic_hours_by_admin !== data?.nbr_declared_basic_hours_by_interim || data?.nbr_declared_basic_hours_by_enterprise !== data?.nbr_declared_basic_hours_by_interim) {
      setConflict(true);
      return 0;
    }
    if (data?.nbr_declared_night_hours_by_admin !== data?.nbr_declared_night_hours_by_enterprise || data?.nbr_declared_night_hours_by_admin !== data?.nbr_declared_night_hours_by_interim || data?.nbr_declared_night_hours_by_enterprise !== data?.nbr_declared_night_hours_by_interim) {
      setConflict(true);
      return 0;
    }
    if (
      data?.nbr_declared_severe_weather_hours_by_admin !== data?.nbr_declared_severe_weather_hours_by_enterprise ||
      data?.nbr_declared_severe_weather_hours_by_admin !== data?.nbr_declared_severe_weather_hours_by_interim ||
      data?.nbr_declared_severe_weather_hours_by_interim !== data?.nbr_declared_severe_weather_hours_by_enterprise
    ) {
      setConflict(true);
      return 0;
    }
    if (data?.nbr_declared_weekend_hours_by_admin !== data?.nbr_declared_weekend_hours_by_enterprise || data?.nbr_declared_weekend_hours_by_admin !== data?.nbr_declared_weekend_hours_by_interim || data?.nbr_declared_weekend_hours_by_enterprise !== data?.nbr_declared_weekend_hours_by_interim) {
      setConflict(true);
      return 0;
    }
    setConflict(false);
    return 1;
  };
  useEffect(() => {
    if (openDayDetal?.date) {
      Api.get(Urls.DECLARED_HOURS + id + "/declaredHours", {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          startDate: moment(openDayDetal.date).format("yyyy-MM-DD"),
          endDate: moment(openDayDetal.date).format("yyyy-MM-DD"),
        },
      })
        .then((res) => {
          setDetailHeureDec(res?.data?.declared_hours[0]);
          isThereAConflict(res?.data?.declared_hours[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [openDayDetal]);
  const [detailContratID, setDetailContratID] = useState();
  useEffect(() => {
    Api.get(Urls.DETAIL_DECLARE_HOURS + id + "/totalDeclaredHours-total", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setLoading(true);
        setDetailContratID(res?.data?.contract);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contrat]);
  console.log(date, "date cha");
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <ToastContainer />
      <Modal
        open={openDayDetal?.etat}
        onClose={() =>
          setOpenDayDetail({
            etat: false,
            date: null,
          })
        }
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box display="flex" flexDirection="column">
              <Box marginBottom="40px" display="flex" flexDirection="column">
                <Box marginBottom="10px" display="flex" gap="10px" alignItems="center">
                  <ButtonSquare
                    onClick={() =>
                      setOpenDayDetail({
                        etat: false,
                        date: null,
                      })
                    }
                    action="close"
                  />
                  <Typography variant="text_xxl" fontWeight="700">
                    {moment(openDayDetal?.date).format("dddd")} {moment(openDayDetal?.date).format("DD MMMM yyyy")}
                  </Typography>
                </Box>
                <Box>{conflict && <MyChip variant="rejected_offer" label="Conflit" />}</Box>
              </Box>
              <Box display="flex" flexDirection="column" gap="20px">
                <BoxContainer>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box marginBottom="14px">
                      <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                        🏢 H. déclarées par l'intérmaire
                      </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        totale
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_basic_hours_by_interim + detailHeureDec?.nbr_declared_night_hours_by_interim + detailHeureDec?.nbr_declared_severe_weather_hours_by_interim + detailHeureDec?.nbr_declared_weekend_hours_by_interim}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures normales
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_basic_hours_by_interim}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures de nuits
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_night_hours_by_interim}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures week-end
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_weekend_hours_by_interim}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures intempéries
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_severe_weather_hours_by_interim}h
                      </Typography>
                    </Box>
                  </Box>
                </BoxContainer>
                <BoxContainer>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box marginBottom="14px">
                      <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                        🏢 H. déclarées par vous
                      </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        totale
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_basic_hours_by_admin + detailHeureDec?.nbr_declared_night_hours_by_admin + detailHeureDec?.nbr_declared_severe_weather_hours_by_admin + detailHeureDec?.nbr_declared_weekend_hours_by_admin}h{" "}
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures normales
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_basic_hours_by_admin}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures de nuits
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_night_hours_by_admin}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures week-end
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_weekend_hours_by_admin}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures intempéries
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_severe_weather_hours_by_admin}h
                      </Typography>
                    </Box>
                  </Box>
                </BoxContainer>
                <BoxContainer>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box marginBottom="14px">
                      <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                        🏢 H. déclarées par l'entreprise
                      </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        totale
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_basic_hours_by_enterprise + detailHeureDec?.nbr_declared_night_hours_by_enterprise + detailHeureDec?.nbr_declared_severe_weather_hours_by_enterprise + detailHeureDec?.nbr_declared_weekend_hours_by_enterprise}h{" "}
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures normales
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_basic_hours_by_enterprise}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures de nuits
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_night_hours_by_enterprise}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures week-end
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_weekend_hours_by_enterprise}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures intempéries
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailHeureDec?.nbr_declared_severe_weather_hours_by_enterprise}h
                      </Typography>
                    </Box>
                  </Box>
                </BoxContainer>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <BoxContainer>
        <Box marginTop="24px" marginLeft="24px" display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap="10px" flexDirection="column">
              <Typography variant="text_xxl" fontWeight="700">
                Contrat #{id}
              </Typography>
              <Box display="flex" gap="8px">
                <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                  🗓 {moment(contrat?.start_date).format("DD MMMM yyyy")}
                </Typography>
                <img src={arrowRight} alt="icon-arrow" />
                <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                  {moment(contrat?.end_date).format("DD MMMM yyyy")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box padding="24px" width="100%" display="flex" gap="24px">
          <Box flex={1} id="picker">
            <BoxContainer>
              <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                <CalendarPicker
                  ref={datePicker}
                  onClick={(event) => console.log(event)}
                  value={new Date(new Date(contrat.start_date))}
                  onChange={(e) => {
                    console.log(e, "change e");
                    const value = moment(e).format("yyyy-MM-DD");
                    console.log("dates", new Date(value).toISOString(), new Date(contrat?.start_date).toISOString(), new Date().toISOString());
                    if (new Date(value).toISOString() >= new Date(contrat?.start_date).toISOString() && new Date(e).toISOString() <= new Date(contrat?.end_date).toISOString()) {
                      setDate(e);
                      setWeekToAdd(getWeekDates(e));
                    }
                  }}
                />
              </LocalizationProvider>
            </BoxContainer>
          </Box>
          <Box flex={1}>
            {weekToAdd.length === 0 && (
              <BoxContainer>
                <Typography fontSize="14px" lineHeight="160%" fontWeight="500">
                  Aucune heure a declarer
                </Typography>
              </BoxContainer>
            )}
            {weekToAdd?.length > 0 && (
              <BoxContainer>
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box display="flex" gap="8px">
                    <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                      🗓 {moment(weekToAdd[0]).format("DD MMMM yyyy")}
                    </Typography>
                    <img src={arrowRight} alt="icon-arrow" />
                    <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                      {moment(weekToAdd[6]).format("DD MMMM yyyy")}
                    </Typography>
                  </Box>
                  <Divider />
                  {weekToAdd?.map((date, index) => (
                    <>
                      {new Date(date).toISOString() <= new Date(contrat?.end_date).toISOString() && new Date(date).toISOString() >= new Date(contrat?.start_date).toISOString() && new Date().toISOString() > new Date(date).toISOString() && (
                        <>
                          <Box display="flex" alignItems="center" gap="24px">
                            <Box
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (IsChecked("interimaire", date) || IsChecked("admin", date) || IsChecked("entreprise", date)) {
                                  setOpenDayDetail({
                                    etat: true,
                                    date: date,
                                  });
                                }
                              }}
                              flex={2}
                            >
                              <Typography fontSize="14px" fontWeight="500" lineHeight="160%">
                                {moment(date).format("dddd")} {moment(date).format("DD")}/{moment(date).format("MM")}
                              </Typography>
                            </Box>
                            <Box
                              onClick={() => {
                                if (IsChecked("interimaire", date) || IsChecked("admin", date) || IsChecked("entreprise", date)) {
                                  setOpenDayDetail({
                                    etat: true,
                                    date: date,
                                  });
                                }
                              }}
                              flex={1}
                              sx={{ cursor: "pointer" }}
                              display="flex"
                              alignItems="center"
                              gap="6px"
                              justifyContent="center"
                            >
                              <CheckedCircle
                                bgcolor={(IsChecked("interimaire", date) && IsChecked("entreprise", date) && IsChecked("admin", date)) || IsChecked("admin", date) ? null : "#EA5B0C"}
                                checked={IsChecked("admin", date) ? IsChecked("admin", date) : IsChecked("interimaire", date)}
                                height="12px"
                                width="12px"
                              />
                              <CheckedCircle
                                bgcolor={(IsChecked("interimaire", date) && IsChecked("entreprise", date) && IsChecked("admin", date)) || IsChecked("admin", date) ? null : "#EA5B0C"}
                                checked={IsChecked("admin", date) ? IsChecked("admin", date) : IsChecked("entreprise", date)}
                                height="12px"
                                width="12px"
                              />
                              <CheckedCircle
                                bgcolor={(IsChecked("interimaire", date) && IsChecked("entreprise", date) && IsChecked("admin", date)) || IsChecked("admin", date) ? null : "#EA5B0C"}
                                checked={IsChecked("admin", date) ? IsChecked("admin", date) : IsChecked("admin", date)}
                                height="12px"
                                width="12px"
                              />
                            </Box>
                            <Box
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (IsChecked("interimaire", date) || IsChecked("admin", date) || IsChecked("entreprise", date)) {
                                  setOpenDayDetail({
                                    etat: true,
                                    date: date,
                                  });
                                }
                              }}
                              flex={1}
                            >
                              <Typography fontSize="14px" fontWeight="500" lineHeight="160%">
                                {numberHoursDeclared("admin", date) ? numberHoursDeclared("admin", date) : numberHoursDeclared("interimaire", date) ? numberHoursDeclared("interimaire", date) : "-"}
                              </Typography>
                            </Box>

                            <Box flex={2}>
                              {!IsChecked("admin", date) && (
                                <Button
                                  variant="mj_primary_md"
                                  onClick={() =>
                                    setOpenForm({
                                      etat: true,
                                      date: date,
                                    })
                                  }
                                >
                                  Déclarer les heures
                                </Button>
                              )}
                              {IsChecked("admin", date) && (
                                <Box
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (IsChecked("entreprise", date) || IsChecked("admin", date) || IsChecked("interimaire")) {
                                      setOpenDayDetail({
                                        etat: true,
                                        date: date,
                                      });
                                    }
                                  }}
                                >
                                  <MyChip variant="ended_offer" label="validée" />
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Divider sx={{ display: `${weekToAdd[index + 1] ? "inherit" : "none"}` }} />
                        </>
                      )}
                    </>
                  ))}
                </Box>
              </BoxContainer>
            )}
          </Box>
        </Box>
      </BoxContainer>
      <BoxContainer>
        <Box display="flex" flexDirection="column">
          <Box display="flex" gap="24px">
            {detailContratID?.total_nbr_declared_basic_hours_by_interim && (
              <Box maxWidth="500px" flex={1}>
                <BoxContainer>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box marginBottom="14px">
                      <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                        🏢 H. déclarées par l'intérimaire
                      </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Nbr. de jours travaillés
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_days_by_interim}j
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures normales
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_basic_hours_by_interim}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures de nuits
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_night_hours_by_interim}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures week-end
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_weekend_hours_by_interim}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures intempéries
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_night_hours_by_interim}h
                      </Typography>
                    </Box>
                  </Box>
                </BoxContainer>
              </Box>
            )}
            {detailContratID?.total_nbr_declared_night_hours_by_admin && (
              <Box maxWidth="500px" flex={1}>
                <BoxContainer>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box marginBottom="14px">
                      <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                        🏢 H. déclarées par vous
                      </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Nbr. de jours travaillés
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_days_by_admin}j
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures normales
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_basic_hours_by_admin}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures de nuits
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_night_hours_by_admin}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures week-end
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_weekend_hours_by_admin}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures intempéries
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_severe_weather_hours_by_admin}h
                      </Typography>
                    </Box>
                  </Box>
                </BoxContainer>
              </Box>
            )}
            {detailContratID?.total_nbr_declared_basic_hours_by_enterprise && (
              <Box maxWidth="500px" flex={1}>
                <BoxContainer>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box marginBottom="14px">
                      <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                        🏢 H. déclarées par l'entreprise
                      </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Nbr. de jours travaillés
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_days_by_enterprise}j
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures normales
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_basic_hours_by_enterprise}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures de nuits
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_night_hours_by_enterprise}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures week-end
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_weekend_hours_by_enterprise}h
                      </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                        Heures intempéries
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                        {detailContratID?.total_nbr_declared_severe_weather_hours_by_enterprise}h
                      </Typography>
                    </Box>
                  </Box>
                </BoxContainer>
              </Box>
            )}
          </Box>
        </Box>
      </BoxContainer>
      <Modal
        open={openForm?.etat}
        onClose={() =>
          setOpenForm({
            etat: false,
            date: null,
          })
        }
      >
        <Box sx={modalStyle}>
          <Box padding="24px">
            <Box display="flex" marginBottom="40px" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Déclarer les heures
              </Typography>
              <ButtonSquare
                onClick={() =>
                  setOpenForm({
                    etat: false,
                    date: null,
                  })
                }
                action="close"
              />
            </Box>
            <Box>
              <Box marginBottom="16px">
                <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                  heures
                </Typography>
              </Box>
            </Box>
            <Formik
              initialValues={{
                tauxNormale: "",
                tauxWeeknd: "",
                tauxIntemperie: "",
                tauxNuit: "",
              }}
              onSubmit={(values) => {
                declareHoursPost(values);
              }}
              validationSchema={validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box maxWidth="70%">
                    <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} gap="150px">
                      <Box>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                            Normales
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Box sx={{ width: "80px" }}>
                          <Field name="tauxNormale" type="input" placeholder="0" id="tauxIntemperie" as={MyTextField} autoComplete="off" />
                        </Box>
                        <ErrorMessage component={FormikErrorText} name="tauxIntemperie" />
                      </Box>
                    </Box>
                    <Box display="flex" margin="16px 0px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} gap="150px">
                      <Box>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                            De nuits
                          </Typography>
                        </Box>
                        <Box sx={{ width: "80px" }}>
                          <Field name="tauxNuit" type="input" placeholder="0" id="tauxIntemperie" as={MyTextField} autoComplete="off" />
                        </Box>
                        <ErrorMessage component={FormikErrorText} name="tauxIntemperie" />
                      </Box>
                    </Box>
                    <Box display="flex" margin="16px 0px 24px 0px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} gap="150px">
                      <Box>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                            Weekend
                          </Typography>
                        </Box>
                        <Box sx={{ width: "80px" }}>
                          <Field name="tauxWeeknd" type="input" placeholder="0" id="tauxIntemperie" as={MyTextField} autoComplete="off" />
                        </Box>
                        <ErrorMessage component={FormikErrorText} name="tauxIntemperie" />
                      </Box>
                      <Box>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                            Intempéries
                          </Typography>
                        </Box>
                        <Box sx={{ width: "80px" }}>
                          <Field name="tauxIntemperie" type="input" placeholder="0" id="tauxIntemperie" as={MyTextField} autoComplete="off" />
                        </Box>
                        <ErrorMessage component={FormikErrorText} name="tauxIntemperie" />
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                    <Button
                      onClick={() => {
                        setOpenForm({
                          etat: false,
                          date: null,
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      Annuler
                    </Button>
                    <Button type="submit" variant="mj_primary_md">
                      Enregistrer
                    </Button>{" "}
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ReleverDheures;
