import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import ButtonSquare from "./ButtonSquare";
import BoxContainer from "./BoxContainer";

function BonDeCommande({ contrat }) {
  return (
    <BoxContainer>
      <Box display="flex" flexDirection="column" gap="15px">
        <Box>
          <Typography sx={{ fontSize: "20px", lineHeight: "32px", fontWeight: "700" }}>Bons de commandes EPI</Typography>
        </Box>

        {contrat?.map((epi) => (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {epi?.name && <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "22.4px" }}>{epi?.name}</Typography>}
            {epi?.url_file && <ButtonSquare onClick={() => window.open(epi?.url_file, "_blank")} action="download-orange" />}
          </Box>
        ))}
      </Box>
    </BoxContainer>
  );
}

export default BonDeCommande;
