import React from "react";
import { useState, useRef } from "react";
import { Alert, Container, Box, Stack, Button, Typography, Avatar, IconButton, TextField, InputLabel, MenuItem, Modal, styled, Checkbox, FormControlLabel } from "@mui/material";
import ContentBox from "../../components/ui/ContentBox";
import { colors } from "../../theme/Colors";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MyDP from "../../components/ui/MyDP";
import * as Yup from "yup";
import moment from "moment/moment";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { modalStyle } from "../../theme/ModalStyle";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";

const Label = styled(InputLabel)({
  color: `${colors.textDark}`,
  fontSize: "0.875rem",
  marginBottom: ".5rem",
});

const MyTextField = styled(TextField)({
  width: "100%",
});

const ErrorText = styled(Box)({
  color: `${colors.mj_error}`,
  fontSize: "0.875rem",
});

function CvExperience({ experiences, monthsExperience, setExperiences, access_token, idInt, setChange }) {
  //refs
  const experienceRef = useRef();
  const experienceRef2 = useRef();
  const experienceModifyRef = useRef();
  const dateFinRef = useRef();
  const doneRef = useRef();

  //states
  const [isAddingExperience, setIsAddingExperience] = useState(false);
  const [checked, setChecked] = useState(false);
  const [idExp, seTid] = useState(null);
  const [checkedModif, setCheckedModif] = useState(false);
  const [calendarColor, setCalendarColor] = useState(`${colors.main}`);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  console.log("MOIS DEXP", monthsExperience);

  const initialValuesExperience = {
    post_name: "",
    societe_name: "",
    location: "",
    start_date: "",
    end_date: "",
    detail: "",
  };

  const validationSchemaExperience = Yup.object({
    post_name: Yup.string().required("Ce champs est obligatoire"),
    societe_name: Yup.string().required("Ce champs est obligatoire"),
    location: Yup.string().required("Ce champs est obligatoire"),
    start_date: Yup.date().required("Ce champs est obligatoire"),
    detail: Yup.string().required("Ce champs est obligatoire"),
  });

  //modal functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const onChangeCheckbox = (event) => {
    setChecked(event.target.checked);
    calendarColor === `${colors.main}` ? setCalendarColor(`${colors.paperLightGrayBorder}`) : setCalendarColor(`${colors.main}`);
    if (experienceRef.current) {
      experienceRef.current.setFieldValue("end_date", null);
    }
  };

  const onChangeCheckbox2 = (event) => {
    setChecked(event.target.checked);
    calendarColor === `${colors.main}` ? setCalendarColor(`${colors.paperLightGrayBorder}`) : setCalendarColor(`${colors.main}`);
    if (experienceRef2.current) {
      experienceRef2.current.setFieldValue("end_date", null);
    }
  };
  return (
    <Box>
      <Box display="flex" flexDirection="row" sx={{ margin: "2rem 0.5rem 1rem 0.5rem", mt: "" }}>
        <Typography mr="0.3rem" display="inline-block" fontSize="1.3rem">
          Expérience{" "}
        </Typography>
        <Box>
          <Typography paddingTop="0.4rem" display="inline-block" fontSize="0.875rem" color={colors.gray}>
            ({monthsExperience} mois d'expérience)
          </Typography>
        </Box>
      </Box>

      {experiences?.map((e) => {
        let startDate = new Date(e.start_date);
        let endDate = new Date(e.end_date);

        return (
          <ContentBox mb="1rem" display="flex" flexDirection="column" key={e.id}>
            {/* Modal delete experience */}
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">
                  Supprimer une expérience
                </Typography>
                <Typography sx={{ mt: 2, mb: 4 }}>Voulez vous vraiment supprimer cette expérience?</Typography>
                <Box display="flex" justifyContent="center" gap="1rem">
                  <Button
                    variant="mj_primary"
                    onClick={() => {
                      Api.delete(Urls.DELETE_EXP + idExp + "/delete", {
                        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
                      })
                        .then((res) => {
                          handleClose();
                          setChange((change) => change + 1);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    Supprimer
                  </Button>
                  <Button variant="mj_secondary" onClick={handleClose}>
                    Annuler
                  </Button>
                </Box>
              </Box>
            </Modal>

            {/* Modal edit experience*/}
            <Modal open={openEdit} onClose={handleCloseEdit} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">
                  Modifier une expérience
                </Typography>
                <Box>
                  <Formik
                    innerRef={experienceRef2}
                    initialValues={{ post_name: e.workstation_name, societe_name: e.societe_name, location: e.location, start_date: e.start_date, end_date: e.end_date, detail: e.detail }}
                    validationSchema={validationSchemaExperience}
                    onSubmit={(values, setFieldError) => {
                      if (moment(values.start_date).format() > moment(new Date()).format()) {
                        return setFieldError("start_date", "date non valide");
                      }
                      if (moment(values.end_date).format() > moment(new Date()).format()) {
                        return setFieldError("end_date", "date non valide");
                      }
                      Api.patch(
                        Urls.UPDATE_EXP + idExp + "/update",
                        {
                          post_name: values.post_name,
                          societe_name: values.societe_name,
                          location: values.location,
                          start_date: values.start_date,
                          end_date: values.end_date,
                          detail: values.detail,
                        },
                        {
                          headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
                        }
                      )
                        .then((res) => {
                          console.log(res);
                          setChange((change) => change + 1);
                          setOpenEdit(false);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Box flexDirection="column" sx={{ margin: "1rem 0.5rem" }}>
                          <Label htmlFor="post_name">Poste occupé</Label>
                          <Field name="post_name" type="input" placeholder="Poste" id="post_name" as={MyTextField} autoComplete="off" />
                          <ErrorMessage component={ErrorText} name="post_name" />
                        </Box>
                        <Box flexDirection="column" sx={{ margin: "1rem 0.5rem" }}>
                          <Label htmlFor="societe_name">Nom de l'entreprise</Label>
                          <Field name="societe_name" type="input" placeholder="Entreprise" id="societe_name" as={MyTextField} autoComplete="off" />
                          <ErrorMessage component={ErrorText} name="societe_name" />
                        </Box>
                        <Box flexDirection="column" sx={{ margin: "1rem 0.5rem" }}>
                          <Label htmlFor="location">Lieu</Label>
                          <Field name="location" type="input" placeholder="Lieu" id="location" as={MyTextField} autoComplete="off" />
                          <ErrorMessage component={ErrorText} name="location" />
                        </Box>
                        <Box className="debutFin" display="flex" sx={{ flexDirection: { md: "row", xs: "column" } }}>
                          <Box flex={1} display="flex" flexDirection="column" sx={{ margin: "0rem 0.5rem" }}>
                            <Label htmlFor="start_date">Date de début</Label>
                            <Field setFieldValue={setFieldValue} max={values.end_date ? values.end_date : new Date()} name="start_date" color={colors.main} type="input" id="start_date" as={MyDP} autoComplete="off" />
                            <ErrorMessage component={ErrorText} name="start_date" />
                          </Box>

                          <Box flex={1} display="flex" flexDirection="column" sx={{ margin: { md: "0rem 0.5rem", xs: "1rem 0.5rem" } }}>
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                              <Label htmlFor="end_date">Date de fin</Label>

                              <Box display="flex" flexDirection="row" gap="0.5rem" justifyContent="center" marginBottom="0rem">
                                <Label sx={{ margin: "0rem" }} htmlFor="end_date">
                                  Jusqu'à présent
                                </Label>
                                <Checkbox
                                  onChange={(e) => {
                                    setCheckedModif(e.target.checked);
                                    if (e.target.checked === true) setFieldValue("end_date", initialValuesExperience.end_date);
                                    else setFieldValue("end_date", initialValuesExperience.end_date);
                                  }}
                                  checked={checkedModif}
                                  disableRipple
                                  sx={{ padding: "0rem", marginBottom: "0.3rem" }}
                                />
                              </Box>
                            </Box>
                            <Field setFieldValue={setFieldValue} min={values.start_date} max={new Date()} disabled={checkedModif} color={calendarColor} name="end_date" type="input" id="end_date" as={MyDP} autoComplete="off" />
                            <ErrorMessage component={ErrorText} name="end_date" />
                          </Box>
                        </Box>

                        <Box flexDirection="column" sx={{ margin: "1rem 0.5rem" }}>
                          <Label htmlFor="detail">Détails</Label>
                          <Field name="detail" type="input" placeholder="Détails" id="post_name" multiline fullWidth columns={5} rows={4} as={TextField} autoComplete="off" />
                          <ErrorMessage component={ErrorText} name="detail" />
                        </Box>
                        {/* <Box width="6rem">
                          <pre>{JSON.stringify(values, null, 2)}</pre>
                          <pre>{JSON.stringify(checked, null, 2)}</pre>
                        </Box> */}
                        <Box display="flex" justifyContent="center" gap="1rem">
                          <Button variant="mj_primary" type="submit">
                            Modifier
                          </Button>
                          <Button variant="mj_secondary" onClick={handleCloseEdit}>
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Modal>

            <Typography sx={{ lineHeight: "26px" }} fontWeight="500">
              {e.workstation_name} {" - "}
              {e.societe_name}
            </Typography>
            <Box>
              <Typography sx={{ lineHeight: "26px" }} fontWeight="500">
                {e?.location ? e.location : "-"}
              </Typography>
              <Typography sx={{ lineHeight: "26px" }} fontWeight="500">
                {"(" + startDate.toLocaleDateString("fr-fr", { year: "numeric", month: "short" })}
                {" - "}
                {e.end_date ? endDate.toLocaleDateString("fr-fr", { year: "numeric", month: "short" }) + ")" : "jusqu'à présent)"}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography sx={{ whiteSpace: "pre-line", lineHeight: "26px", overflowWrap: "break-word", maxWidth: { md: "620px", sm: "480px", xs: "360px" } }} variant="text_gray">
                {e.detail}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" gap="1.5rem" sx={{ mt: 2 }}>
              <IconButton
                sx={{ padding: "0.5rem", bgcolor: `${colors.main}`, "&:hover": { bgcolor: `${colors.light}` } }}
                onClick={() => {
                  handleOpenEdit();
                  seTid(e.id);
                }}
              >
                <EditIcon sx={{ color: "white" }} fontSize="medium" />
              </IconButton>
              <IconButton
                sx={{ padding: "0.5rem", bgcolor: `${colors.main}`, "&:hover": { bgcolor: `${colors.light}` } }}
                onClick={() => {
                  handleOpen();
                  seTid(e.id);
                }}
              >
                <DeleteOutlinedIcon sx={{ color: "white" }} fontSize="medium" />
              </IconButton>
            </Box>
          </ContentBox>
        );
      })}

      <Box flexDirection="column">
        <Box display="flex" justifyContent="center">
          <Button variant="mj_primary" onClick={() => setIsAddingExperience(true)}>
            Ajouter une expérience
          </Button>
        </Box>

        {isAddingExperience ? (
          //FORM ADD EXPERIENCE
          <Box>
            <Formik
              innerRef={experienceRef}
              initialValues={initialValuesExperience}
              validationSchema={validationSchemaExperience}
              onSubmit={(values) => {
                console.log(values);
                Api.post(
                  Urls.CREATE_EXP + idInt + "/create-experience",
                  {
                    post_name: values.post_name,
                    societe_name: values.societe_name,
                    location: values.location,
                    start_date: values.start_date,
                    end_date: values.end_date,
                    detail: values.detail,
                  },
                  {
                    headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
                  }
                )
                  .then((res) => {
                    console.log(res);
                    setChange((change) => change + 1);
                  })

                  .catch((err) => {
                    console.log(err);
                  });

                setIsAddingExperience(false);
                console.log(values);
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Box flexDirection="column" sx={{ margin: "1rem 0.5rem" }}>
                    <Label htmlFor="post_name">Poste occupé</Label>
                    <Field name="post_name" type="input" placeholder="Poste" id="post_name" as={MyTextField} autoComplete="off" />
                    <ErrorMessage component={ErrorText} name="post_name" />
                  </Box>
                  <Box flexDirection="column" sx={{ margin: "1rem 0.5rem" }}>
                    <Label htmlFor="societe_name">Nom de l'entreprise</Label>
                    <Field name="societe_name" type="input" placeholder="Entreprise" id="societe_name" as={MyTextField} autoComplete="off" />
                    <ErrorMessage component={ErrorText} name="societe_name" />
                  </Box>
                  <Box flexDirection="column" sx={{ margin: "1rem 0.5rem" }}>
                    <Label htmlFor="location">Lieu</Label>
                    <Field name="location" type="input" placeholder="Lieu" id="location" as={MyTextField} autoComplete="off" />
                    <ErrorMessage component={ErrorText} name="location" />
                  </Box>
                  <Box className="debutFin" display="flex" sx={{ flexDirection: { md: "row", xs: "column" } }}>
                    <Box flex={1} display="flex" flexDirection="column" sx={{ margin: "0rem 0.5rem" }}>
                      <Label htmlFor="start_date">Date de début</Label>
                      <Field setFieldValue={setFieldValue} max={values.end_date ? values.end_date : new Date()} name="start_date" color={colors.main} type="input" id="start_date" as={MyDP} autoComplete="off" />
                      <ErrorMessage component={ErrorText} name="start_date" />
                    </Box>

                    <Box flex={1} display="flex" flexDirection="column" sx={{ margin: { md: "0rem 0.5rem", xs: "1rem 0.5rem" } }}>
                      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Label htmlFor="end_date">Date de fin</Label>

                        <Box display="flex" flexDirection="row" gap="0.5rem" justifyContent="center" marginBottom="0rem">
                          <Label sx={{ margin: "0rem" }} htmlFor="end_date">
                            Jusqu'à présent
                          </Label>
                          <Checkbox
                            onChange={(e) => {
                              setChecked(e.target.checked);
                              if (e.target.checked === true) setFieldValue("end_date", initialValuesExperience.end_date);
                              else setFieldValue("end_date", initialValuesExperience.end_date);
                            }}
                            checked={checked}
                            disableRipple
                            sx={{ padding: "0rem", marginBottom: "0.3rem" }}
                          />
                        </Box>
                      </Box>
                      <Field setFieldValue={setFieldValue} min={values.start_date} max={new Date()} disabled={checked} color={calendarColor} name="end_date" type="input" id="end_date" as={MyDP} autoComplete="off" />
                      <ErrorMessage component={ErrorText} name="end_date" />
                    </Box>
                  </Box>

                  <Box flexDirection="column" sx={{ margin: "1rem 0.5rem" }}>
                    <Label htmlFor="detail">Détails</Label>
                    <Field name="detail" type="input" placeholder="Détails" id="post_name" multiline fullWidth columns={5} rows={4} as={TextField} autoComplete="off" />
                    <ErrorMessage component={ErrorText} name="detail" />
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="center" gap="1.5rem">
                    <IconButton sx={{ padding: "0.5rem", bgcolor: `lightgreen`, "&:hover": { bgcolor: `lightgreen` } }} type="submit">
                      <DoneRoundedIcon sx={{ color: `${colors.main}` }} fontSize="large" />
                    </IconButton>

                    <IconButton sx={{ padding: "0.5rem", bgcolor: `lightgreen`, "&:hover": { bgcolor: `lightgreen` } }} onClick={() => setIsAddingExperience(false)}>
                      <ClearRoundedIcon sx={{ color: `${colors.main}` }} fontSize="large" />
                    </IconButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

export default CvExperience;
